import Election from "./Election";
import Candidate from "./Candidate";
import {Ballot, ElectionResult} from "./Ballot";
import {PluralityElection, PluralityResult} from "./PluralityElection";


class IRVResult extends ElectionResult {
  rounds: Array<PluralityResult>

  constructor(winners: Array<Candidate>, rounds: Array<PluralityResult>) {
    super(winners)
    this.rounds = rounds
  }
}

class InstantRunoffElection extends Election {
  result: ElectionResult

  constructor(ballots: Array<Ballot>, candidates: Set<Candidate>) {
    super(ballots, candidates);
    this.result = this.computeResult()
  }

  computeResult = (): ElectionResult => {
    let activeCandidates = new Set(this.candidates)
    let rounds: Array<PluralityResult> = []
    let losers: Array<Candidate> = []

    while (activeCandidates.size > 1) {
      let plurality = new PluralityElection(this.ballots, activeCandidates)
      rounds.push(plurality.result as PluralityResult)
      let loserIdx = plurality.result.orderedCandidates.length - 1
      let loser = plurality.result.orderedCandidates[loserIdx]
      losers.push(loser)
      activeCandidates.delete(loser)
    }
    if (activeCandidates.size !== 1) {
      console.log("Active candidates must have size of 1")
    }
    // console.log(`losers '${losers.map(c => c.name).join(" > ")}'  activeCandidates: ${Array.from(activeCandidates).map(c => c.name).join(" > ")}`)
    losers = losers.concat(Array.from(activeCandidates))
    // in place reversal!!!
    let winners = losers.reverse()
    // console.log(`winners '${winners.map(c => c.name).join(" > ")}'`)
    return new IRVResult(winners, rounds)
  }
}

export {InstantRunoffElection}
export {IRVResult}
