import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class Tribalism extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }

// <p>Humans are tribal animals.</p>
// <p>All the way back to chimpanzees.</p>
// <p>Henri Tajfel's experiments</p>
// <p>Tribes can also unite and support each other</p>
// <p>Social Media</p>
// <p>The Industry of Fear and Anger</p>
// <p>Factions within Factions</p>
// <p>No society is proof against division</p>
// <p>But What if the rules were different?</p>

  render() {
    // let mdash = "\u2014"

    return (
        <Article title={"Tribalism"}
                 subtitle={"The destructive synergy of tribalism and GTO Politics."}>
          <div className={styles.textContent}>
            <p>
              Humans are inherently tribal. We seem to divide along racial, religious, and political lines at the
              slightest excuse. History is littered with examples of societies dividing over one issue or another with
              devastating consequences. Henri Tajfel narrowly escaped one of history’s most notorious incidents of
              tribalism and spent much of the rest of his life trying to understand why humans are so prone to division.
            </p>
            <p>
              Tajfel was a Jew born in Poland but left to attend college in France because Jews were barred from
              higher education in Poland. When World War II broke out. Tajfel joined the French Army and was captured in 1940
              when France surrendered. Interred in a German POW camp, Tajfel’s survival depended on the German
              misconception that he was a French Jew rather than a Polish Jew. This slender thread was the only thing
              separating Tajfel from near-certain death in a concentration camp. After the war, Tajfel discovered that
              his entire family and everyone he knew before the war was dead.
            </p>
            <p>
              In a series of landmark experiments in the 1960s and 1970s, Tajfel set about identifying the triggers
              that divide people into us versus them. As a Polish Jew and Holocaust survivor, Tajfel knew full well that
              the religious and cultural differences between Jews and Christians were large enough differences for a
              demagogue to exploit, but could even smaller differences be exploited? What was the smallest possible
              difference that could trigger a group to divide and attack each other?
            </p>
            <p>
              Tajfel started with the most homogenous possible group of people he could find: 60 male students of the
              same age from the same grade at the same school. He then showed each of the students a picture with a
              cluster of dots on it and asked them how many dots were shown on the screen.
              The students were forced to make a guess at the number because there were too many dots to count. After
              each of the students had taken the dot-guessing test, they were divided into two groups: students who
              generally guessed high and students who guessed low. Crucially, the results from the test were ignored,
              and the students were assigned to the high and low groups randomly.
            </p>
            <p>
              In a bit of misdirection typical of psychological experiments, the students were told that the next phase
              of the test was completely separate from the dot-guessing game. In this phase, the students were given
              some money to divide between two other students. They weren’t given the student's names, but they were told
              which groups – high guessers or low guessers – the two recipients were from. If the two recipients were from
              the same group, they divided the money evenly, but if the two recipients were from different groups, the
              students gave more money to the student in their own group.
            </p>
            <p>
              In later experiments, Tajfel limited the choices so that in order to create a difference between the
              groups, the students had to give less money to their own group. To illustrate, they could give $0.50 to
              each student or $0.40 to their own group, and $0.20 to the other group. In this case, the students chose to
              give less money to their own group in order to create a difference with the other group.
            </p>
            <p>
              Tajfel’s experiments are sobering. This group of children was more homogenous than any society and under
              no competitive pressure. There wasn’t any competition for jobs, resources, or education. There wasn’t any
              conflict over race, language, culture, or religion. And most importantly, there weren’t demagogic
              politicians calling high guessers a basket of deplorables or labeling low guessers as unwilling to take
              personal responsibility for their lives.
            </p>
            <p>
              .
              Hillary Clinton and Mitt Romney aren’t even close to being demagogues. But their very mildness makes it
              easy to imagine how a true demagogue could take advantage of a factional voting system by manipulating our
              inherently tribal nature to rip our country apart and destroy our democracy.
            </p>
            <p>
              Tajfel’s experiments show that no society – no matter how homogenous – is immune to being manipulated by
              tribalism. Any voting system that hands power to one faction while robbing the other side of any say
              in government is a tool that no demagogue can resist. Our inherent tribalism makes factional democracy
              inherently unstable. External threats can temporarily unify a society by making the threat the enemy
              tribe. But it is a matter of time before the right constellation of events and ambition coincide to divide
              families, friends, and a nation.
            </p>
            <p>
              It's not hard to predict the outcome when you combine our inherent tribalism with an electoral system
              that favors extremism. The simulations don't factor in tribalism, but they still forecast the division
              we see today. Tribalism is like pouring napalm on the fire. The prospect of demagogic politicians
              deliberately exploiting a flawed election system that favors extremism by playing on our inherent
              tribalism is terrifying.
            </p>
            <h3>
              Factions are Fractal
            </h3>
            <p>
              If you took all of the members of either political party and put them on an island, they would immediately
              divide into moderates and the more ideologically extreme. Former friends and allies would suddenly
              discover irreconcilable differences they used to overlook have suddenly become life-or-death issues. These
              newly found foes would recycle their insults and otherization to create new baskets of deplorables
              fervently opposed to people unwilling to take responsibility for their lives. If you separated these newly
              found foes onto their own islands, they would split again. This process would repeat over ever more
              trivial issues until every member was standing on their own island.
            </p>
            <p>
              The idea some members of Congress robbed of opposition to denigrate is amusing – Ted Cruz, for instance –
              but it begs a very important question: If either party would find issues to divide themselves when robbed
              of opposition, what does that say about the original division when Americans were deposited on our
              not-so-hypothetical political island divided into Democrats and Republicans?
              The issues dividing Democrats and Republicans are significant and important. But I’m wondering if it’s
              possible for us to work on these issues together as friends and allies who are all trying to build a
              better America instead of denigrating and insulting our fellow Americans.
            </p>

          </div>
        </Article>
    )
  }
}




















