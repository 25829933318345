import Layout from "./Layout";
import Bubble from "./Bubble";
import Point from "./Point";
import * as d3 from "d3"
import NormalSequencer from "./normalSequencer";
import {SemiSigmoidSequencer, SigmoidSequencer} from "./SigmoidSequencer";
import ParabolicTrajectory from "./ParabolicTrajectory";
import DirectTrajectory from "./DirectTrajectory";

class LayoutAnimator {
  layout: Layout
  paths!: Array<(t: number) => Point>
  bubbles!: Array<Bubble>
  name: string
  renderMethod: (pctComplete: number) => void
  timer!: d3.Timer | undefined
  durationMillis: number = 0

  constructor(name: string,
              bubbles: Array<Bubble>,
              layout: Layout,
              renderMethod: (pctComplete: number) => void) {

    this.name = name
    this.layout = layout
    this.bubbles = bubbles
    this.renderMethod = renderMethod
  }

  stop = () => {
    if (this.timer) this.timer.stop()
  }

  animate = () => {
    this.stop()
    this.durationMillis = this.layout.duration

    this.layout.reset()
    this.paths = this.bubbles.map((b) => {
      this.layout.setTargetLocation(b)
      let trajectory = this.layout.route(b)
      return trajectory.path(new Point(b.x, b.y), new Point(b.tx, b.ty))
    })
    this.timer = d3.timer(this.update)
  }

  update = (elapsedMillis: number) => {
    // console.log(`update ${elapsedMillis}`)
    let pctComplete = elapsedMillis / this.durationMillis
    // console.log(`LayoutAnimator.update: elapsedMillis ${elapsedMillis} durationMillis: ${this.durationMillis}, pctComplete ${pctComplete.toFixed(3)}`)
    let dirty = false;
    this.bubbles.forEach((b, i) => {
      let p = this.paths[i](pctComplete)
      // if (i === 10) console.log(`path:  b: ${b.x} ${b.y} p: ${p.x} ${p.y} ${b.tx} ${b.ty}`)
      if (Math.abs(b.x - b.tx) < .1)
        b.x = b.tx
      if (Math.abs(b.y - b.ty) < .1)
        b.y = b.ty

      if (b.x !== b.tx || b.y !== b.ty) {
        b.x = p.x
        b.y = p.y
        dirty = true
      }
    })
    if (!dirty) {
      // console.log(`Animator(${this.name}): bubbles no longer dirty! ${pctComplete}`)
      this.timer?.stop()
    }
    if (pctComplete > 3)
      this.timer?.stop()
    this.renderMethod(pctComplete)
  }
}

let parabolicRoute = () => new NormalSequencer(new SemiSigmoidSequencer(new ParabolicTrajectory(-300)), .5, .5)
let directRoute = () => new NormalSequencer(new SemiSigmoidSequencer(new DirectTrajectory()), .5, .5)
let slowDirectRoute = () => new NormalSequencer(new SigmoidSequencer(new DirectTrajectory()), .5, .5)
let immediateDirectRoute = (duration: number) => {
  return () => new NormalSequencer(new SigmoidSequencer(new DirectTrajectory()), 0, 0, duration)
}
export {LayoutAnimator, parabolicRoute, directRoute, slowDirectRoute, immediateDirectRoute}
