import Bubble from "./Bubble";
import Layout from "./Layout";
import OffscreenLayout from "./OffscreenLayout";
import RangesAndGradients from "./RangesAndGradients";
import SVGAxis from "./SVGAxis";

abstract class Controller {
  abstract bubbles: Array<Bubble>
  ranges = new RangesAndGradients()
  dirty = false
  opacityFcn: <T extends Bubble>(b: T) => number = (_b: Bubble) => .931
  colorFcn: <T extends Bubble>(b: T) => string = (_b: Bubble) => "chartreuse"
  colorName = "none"
  layout!: Layout
  layoutName = "none"
  axes: Array<SVGAxis> = []
  controllerName = "Controller"
  canvasContext!: CanvasRenderingContext2D
  visName: string = ""
  savedLocations = new Map<string, number[][]>()
  svg!: d3.Selection<any, any, any, any>


  setNewSVG = (svg: d3.Selection<any, any, any, any>) => {
    this.svg = svg
  }
  setNewCanvas = (canvas: CanvasRenderingContext2D, newVisName: string) => {
    // save existing Bubble state:
    this.savedLocations.set(this.visName, this.bubbles.map((b) => [b.x, b.y]))

    if (this.savedLocations.has(newVisName)) {
      let locations = this.savedLocations.get(newVisName)!
      if (locations.length === this.bubbles.length) {
        locations.forEach((l, i) => {
          this.bubbles[i].x = l[0]
          this.bubbles[i].y = l[1]
          this.bubbles[i].cx = l[0]
          this.bubbles[i].cy = l[1]
        })
      }
    }
    this.canvasContext = canvas
    this.visName = newVisName
  }

  updateFcn: (b: Bubble) => void = (_b: Bubble) => {

  }

  protected constructor() {
    this.layout = new OffscreenLayout()
    this.colorFcn = (b: Bubble) => this.ranges.colorByIdeology(b.ideology)
  }

  clearAxes = (): void => {
    this.axes.forEach(a => a.clear())
    this.axes = []
    this.dirty = true
  }

  addAxis = (axis: SVGAxis): void => {
    axis.id = `${this.visName}-${axis.id}`
    this.axes.push(axis)
    this.dirty = true
  }

  setColor = (colorFcn: <T extends Bubble> (b: T) => string, description: string) => {
    this.colorFcn = colorFcn
    this.colorName = description
    this.dirty = true
  }

  setLayout = (layout: Layout, name: string = "unspecified") => {
    this.layout = layout
    this.layoutName = name
    this.dirty = true
  }

  setUpdate = (updateFcn: <T extends Bubble> (b: T) => void) => {
    this.updateFcn = updateFcn
    this.dirty = true
  }

  setOpacity = (opacityFcn: <T extends Bubble> (b: T) => number) => {
    this.opacityFcn = opacityFcn
    this.dirty = true
  }

  protected applyUpdate = () => {
    this.bubbles.forEach((b: Bubble) => {
      this.updateFcn(b)
    })
  }

  applyLayoutAndForce = () => {
    this.applyLayout()
    this.bubbles.forEach((b: Bubble) => {
      b.x = b.tx
      b.y = b.ty
    })
  }

  protected applyLayout = () => {
    this.layout.reset()
    this.bubbles.forEach((b: Bubble) => {
      this.layout.setTargetLocation(b)
    })
  }

  applyColorAndOpacity = () => {
    this.bubbles.forEach((b) => {
      b.color = this.colorFcn(b)
      b.opacity = this.opacityFcn(b)
    })
  }

  renderStart = (): void => {
    let context = this.canvasContext
    context.save()
    let buffer = context.canvas.width * 2
    context.clearRect(-buffer,
        -buffer,
        context.canvas.width + 2 * buffer,
        context.canvas.height + 2 * buffer)
  }

  renderFinish = (): void => {
    this.canvasContext.restore()
  }
}

export default Controller