import {Party} from "./Party";

let count_index = 0
class Candidate {
  name: string
  party: Party
  ideology: number
  quality: number
  ivec: number[]
  counter: number

  constructor(name: string, party: Party, ideology: number, quality: number, ivec: number[] = []) {
    this.name = name
    this.party = party
    this.quality = quality
    this.counter = count_index
    count_index += 1

    // hack for backward compatibility with changing to unit scaling for ideology.
    // old scale was mean/stddev of 30/30 for parties, it's now 1/1 so we just divide by 30
    // it should all work out
    if (ivec.length === 0) {
      this.ideology = ideology / 30
      this.ivec = [ideology / 30]
    } else {
      this.ivec = ivec
      this.ideology = ivec[0]
    }
  }
}

export default Candidate