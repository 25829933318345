// import HistogramLayout from "../ui/elements/HistogramLayout";
// import LayoutByScale from "../ui/elements/LayoutByScale";
// import Bubble from "../ui/elements/Bubble";
// import CandidateBubble from "../ui/elements/CandidateBubble";
// import {BarChartAxis} from "../ui/elements/BarChartAxis";
// import Candidate from "../core/Candidate";
// import {Democrats, Republicans} from "../core/Party";
// import VoterBubble from "../ui/elements/VoterBubble";
import * as d3 from "d3";
import {GeoPath} from "d3";
import * as d3Zoom from "d3-zoom";
import {feature} from "topojson-client";
import {Feature, FeatureCollection, GeoJsonProperties, Geometry} from 'geojson'
import FIPSData from "../../core/FIPSData";
import CombinedDistrictData, {DistrictVotingRecord} from "../../core/CombinedDistrictData";
import RangesAndGradients from "./RangesAndGradients";
import DisplayRectangle from "../../VoterRepresentation/DisplayRectangle";
import SVGSlider from "./SVGSlider";
import blockTimer from "../../core/BlockTimer";


class SliderDefinition {
  startingDVR: DistrictVotingRecord
  onUpdate: (dvr: DistrictVotingRecord) => void

  constructor(startingDVR: DistrictVotingRecord, onUpdate: (dvr: DistrictVotingRecord) => void) {
    this.startingDVR = startingDVR
    this.onUpdate = onUpdate
  }
}

class MapController {
  // this.scrollerApp = new SurveyLayout(this.mainDiv, dvr, DWNominate, simulationResults, sampleCongress, usTopo)
  states: Array<Feature<Geometry | null>>
  districts: Array<Feature<Geometry | null>>
  path: GeoPath
  usTopo: any
  outerSVG: d3.Selection<any, any, any, any>
  outerG: d3.Selection<any, any, any, any>
  mapSVG: d3.Selection<any, any, any, any>

  mapInner: d3.Selection<any, any, any, any>
  zoom: d3Zoom.ZoomBehavior<any, any>
  fipsData: FIPSData
  combinedDistrictData: CombinedDistrictData
  colorFunction = (_district: string): string => { return "gray" }
  rangesAndGradients = new RangesAndGradients()
  needsRender = true
  needsColor = false
  active = false
  mapRect = new DisplayRectangle(0, 0, 0, 0)
  sliderRect = new DisplayRectangle(0, 0, 0, 0)
  captionRect = new DisplayRectangle(0, 0, 0, 0)
  sliderActive: boolean = false

  currentDVR: DistrictVotingRecord
  needsZoom: boolean = false
  onSelect = (dvr: DistrictVotingRecord) => { }

  slider?: SVGSlider | undefined
  caption?: string | undefined
  transitionDuration = 300
  title = "No Title Set"


  constructor(mapParent: d3.Selection<any, any, any, any>,
              usTopo: any,
              combinedDistrictData: CombinedDistrictData) {
    let projection = d3.geoAlbersUsa()
    this.path = d3.geoPath().projection(projection)
    this.usTopo = usTopo
    this.states = ((feature(usTopo, usTopo.objects.states) as unknown) as FeatureCollection).features
    this.districts = ((feature(usTopo, usTopo.objects.districts) as unknown) as FeatureCollection).features

    ///////////////////////////////////////////////////////////////////////////////////
    // this would seem to be unnecessary, but on a hot reload it will duplicate
    // copies of the mapOuterSVG (and map) consuming memory and looking bad.
    mapParent.selectAll("#mapOuterSVG").remove()

    let opacity = 0

    this.outerSVG = mapParent.append("svg")
        .attr("id", "mapOuterSVG")
        .style("opacity", 1)
        .attr("width", "100%")
        .attr("height", "100%")

    this.outerG = this.outerSVG.append("g")
        .attr("id", "mapOuterG")
        .style("opacity", opacity)

    this.mapSVG = this.outerG.append("svg")
        .attr("id", "mapSVG")
        .style("opacity", 1)

    this.mapInner = this.mapSVG.append("g").attr("id", "mapInner")

    this.fipsData = new FIPSData()
    this.combinedDistrictData = combinedDistrictData
    this.currentDVR = combinedDistrictData.sortedDVR[0]
    this.zoom = d3.zoom()
        .scaleExtent([1, 24])
        .on("zoom", this.zoomed)

    this.drawMap()
  }

  setNewParent = (mapParent: d3.Selection<any, any, any, any>) => {
    mapParent.node().appendChild(this.outerSVG.node())
  }

  clearLegend = () => {
    this.mapSVG.selectAll("#legend-rect").remove()
    this.mapSVG.selectAll("#legend-gradient").remove()
    this.mapSVG.selectAll("#legend-axis").remove()
  }

  renderLegend = () => {
    // console.log("renderLegend()")
    this.clearLegend()
    if (this.mapRect.width === 0 || this.mapRect.height === 0)
      return

    let defs = this.mapSVG.append("defs")
    let steps = 30
    let range = d3.range(0, 1, 1.0 / steps)
    range.push(1.0)
    let linearGradient = defs.append("linearGradient").attr("id", "legend-gradient")
    let domain = this.rangesAndGradients.electorateRepresentationColorScale.domain()
    let min = domain[0]
    let max = domain[domain.length - 1]
    let scale = Math.min(this.mapRect.width, this.mapRect.height) / 1000
    let height = 400 * scale
    let width = 40 * scale
    let top = 20 * scale
    let left = 20 * scale
    // console.log(`renderLegend: scale ${scale} height ${height} width ${width}`)
    linearGradient
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "0%")
        .attr("y2", "100%")
        .selectAll("stop")
        .data(range)
        .enter()
        .append("stop")
        .attr("offset", (d, i) => i / range.length)
        .attr("stop-color", (d: number) => this.rangesAndGradients.electorateRepresentationColorScale(d * (max - min) + min))

    this.mapSVG.append("rect")
        .attr("id", "legend-rect")
        .attr("x", left)
        .attr("y", top)
        .attr("width", width)
        .attr("height", height)
        .style("fill", "url(#legend-gradient)")

    let colorScale = d3.scaleLinear([20, 100], [height, 0])
    let yAxis = d3.axisRight(colorScale).ticks(5)

    this.mapSVG.append("g")
        .attr("id", "legend-axis")
        .call(yAxis)
        .attr("transform", `translate(${left + width + 5}, ${top})`)

  }


  display = (displayRect: DisplayRectangle,
             sliderDefinition: SliderDefinition | undefined = undefined) => {

    if (sliderDefinition) {
      let sliderHeight = 30
      let captionHeight = 20
      this.mapRect = new DisplayRectangle(displayRect.x, displayRect.y, displayRect.width, displayRect.height - sliderHeight - captionHeight)
      this.sliderRect = new DisplayRectangle(displayRect.x, displayRect.y + this.mapRect.height, displayRect.width, sliderHeight)
      this.captionRect = new DisplayRectangle(
          this.sliderRect.x,
          this.sliderRect.y + this.sliderRect.height,
          displayRect.width,
          captionHeight)
      this.currentDVR = sliderDefinition.startingDVR
      this.onSelect = sliderDefinition.onUpdate
      this.needsZoom = true
      this.updateCaptionText()
    } else {
      this.mapRect = displayRect
      this.sliderRect = new DisplayRectangle(0, 0, 0, 0)
    }

    // console.log("display Called")
    if (!this.active) {
      this.needsRender = true
      this.needsColor = true
    }
    this.active = true
  }
  clear = (duration: number = 300): void => {
    if (this.active) {
      this.active = false
      this.needsRender = true
      this.slider = undefined
      this.caption = undefined
      this.transitionDuration = duration;
    }
  }

  zoomed = (event: d3.D3ZoomEvent<any, any>) => {
    if (event.sourceEvent) {
      event.sourceEvent.cancelBubble = true
      event.sourceEvent.stopPropagation()
    }
    this.mapInner.attr("transform", event.transform as any)
  }

  setColorByRepresentation = () => {
    let fcn = (district: string): string => {
      let representation = this.combinedDistrictData.actualRepresentation(district)
      return this.rangesAndGradients.electorateRepresentationColorScale(representation)
    }
    this.setColorFunction(fcn, "United States House Districts Colored by Representation")
  }

  setColorFunction(fcn: (district: string) => string, title: string) {
    this.title = title
    this.colorFunction = fcn
    this.needsColor = true
  }

  districtIsDefined = (geoId: number): boolean => {
    if (this.fipsData.hasGeoId(geoId)) {
      let district = this.fipsData.stringId(geoId)
      return this.combinedDistrictData.has(district)
    } else {
      return false
    }
  }


  applyColorFunction = (): void => {
    if (this.active) {
      // console.log("Map: applyColorFunction")
      let filterDistrict = (d: any): boolean => {
        if (this.districtIsDefined(+d.id))
          return true
        else {
          return false
        }
      }

      this.mapInner.selectAll("path")
          .filter(".district")
          .filter(filterDistrict)
          .style("fill", (d: any) => {
            let district = this.fipsData.stringId(+d.id)
            return this.colorFunction(district)
          })
    }
  }

  enterCallback = (o1: MouseEvent, o2: Feature<Geometry | null, GeoJsonProperties>) => {
    // console.log(`enterCallback ${o2.id}`, o1, o2)
  }
  exitCallback = (o1: MouseEvent, o2: Feature<Geometry | null, GeoJsonProperties>) => {
    // console.log("exitCallback ", o1, o2)
  }
  clickCallback = (o1: MouseEvent, o2: Feature<Geometry | null, GeoJsonProperties>) => {
    // console.log(`clickCallback ${o2.id}`, o1, o2)
    if (o2.id) {
      let geoId = +o2.id
      this.zoomToDistrict(geoId)
      this.currentDVR = this.combinedDistrictData.dvrForGeoId(geoId)
    }
  }

  render = () => {
    blockTimer(() => {this._render()}, "MapController.render()")
  }

  protected _render = () => {
    // console.log(`map.render: active=${this.active}`)
    if (!this.needsRender && !this.needsColor)
      return
    let opacity = this.active ? 1.0 : 0.0
    this.outerG
        .transition("fadeOut")
        .duration(this.transitionDuration)
        .style("opacity", opacity)
    this.needsRender = false
    if (this.active) {
      this.applyColorFunction()
      this.updateMapRect()
      this.renderLegend()
      this.renderTitle()
    }
  }

  clearTitle = () => {
    this.outerG.selectAll("#map-title").remove()
  }

  renderTitle = () => {
    this.clearTitle()
    this.outerG.append("text")
        .attr("id", 'map-title')
        .text(this.title)
        .style("font-size", "22pt")
        .style("text-anchor", "middle")
        .style("fill", "black")
        .attr("x", this.mapRect.x + this.mapRect.width / 2)
        .attr("y", 20)
  }

  updateMapRect = () => {
    this.mapSVG
        .transition("mapSVGLocation")
        .duration(500)
        .attr("x", this.mapRect.x)
        .attr("y", this.mapRect.y)
        .attr("width", this.mapRect.width)
        .attr("height", this.mapRect.height)
    return
  }

  drawMap = () => {
    this.mapInner.selectAll(".state").remove()
    this.mapInner.selectAll(".district").remove()
    this.outerG.selectAll("#mapSlider").remove()
    this.outerG.selectAll("#mapCaption").remove()

    this.mapSVG
        .transition("mapSVGLocation")
        .duration(500)
        .attr("x", this.mapRect.x)
        .attr("y", this.mapRect.y)
        .attr("width", this.mapRect.width)
        .attr("height", this.mapRect.height)

    this.mapSVG.call(this.zoom)

    this.mapInner.selectAll(".state")
        .data(this.states)
        .join("path")
        .attr("class", "state")
        .attr("d", this.path)
        .style("stroke", "#aaa")
        .style("fill", "none")
        .style("stroke-width", "1")

    this.mapInner.selectAll(".district")
        .data(this.districts)
        .join("path")
        .attr("class", "district")
        .attr("d", this.path)
        .style("fill", "#888")
        .style("stroke", "#aaa")
        .style("stroke-width", "0.1px")
        .style("opacity", 1)
        .on("mouseover", this.enterCallback)
        .on("mouseout", this.exitCallback)
        .on("click", this.clickCallback)

    this.renderLegend()
    // console.log("map.draw: path complete")
    if (this.sliderActive) {
      this.slider = new SVGSlider(
          "mapSlider",
          this.outerG,
          this.sliderRect,
          [0, 435],
          250,
          10,
          "black",
          "gray",
          "More Liberal",
          "More Conservative",
          this.sliderStart,
          this.sliderUpdate,
          this.sliderEnd)
      this.slider.render()
    }
    if (this.needsZoom) {
      this.zoomToDistrict(this.currentDVR.geoId)
      this.needsZoom = false
    }
    this.renderCaptionText()
    if (this.needsColor) {
      this.needsColor = false
      this.applyColorFunction()
    }
  }
  sliderStart = (_sliderValue: number) => {
  }
  sliderUpdate = (sliderValue: number) => {
    this.currentDVR = this.combinedDistrictData.dvrForRank(sliderValue)
    this.updateCaptionText()
    this.renderCaptionText()
  }
  sliderEnd = (sliderValue: number) => {
    let dvr = this.combinedDistrictData.dvrForRank(sliderValue)
    console.log(`onSlider, district is ${dvr.district} - ${dvr.geoId}`)
    this.zoomToDistrict(dvr.geoId)
    this.onSelect(dvr)
  }


  clearCaptionText = () => {
    this.outerG.selectAll("#mapCaption").remove()
  }

  updateCaptionText = () => {
    this.caption = `${this.currentDVR.district}  ${this.currentDVR.incumbent}`
  }

  renderCaptionText = () => {
    this.clearCaptionText()
    if (this.caption) {
      this.outerG.append("text")
          .attr("id", "mapCaption")
          .text(this.caption)
          .attr("x", this.captionRect.x + this.captionRect.width / 2)
          .attr("y", this.captionRect.y)
          .attr("dy", ".4em")
          .attr("font-size", "14pt")
          .style("text-anchor", "middle")
          .style("fill", "black")
    }
  }

  zoomToDistrict = (geoId: number): void => {
    // pad with leading zeros.
    let geoIdStr = ("0000" + geoId).slice(-4)

    console.log(this.districts[0])
    let districtOpt = this.districts.filter(p => p.id === geoIdStr)
    districtOpt.forEach((district) => {
      let bounds = this.path.bounds(district)
      let x1 = bounds[0][0]
      let y1 = bounds[0][1]
      let x2 = bounds[1][0]
      let y2 = bounds[1][1]
      this.zoomToRect(x1, y1, x2, y2, .2)
    })
    if (this.sliderActive) {
      let index = this.combinedDistrictData.sortedDVR.findIndex((dvr: DistrictVotingRecord) => dvr.geoId === geoId)
      this.slider?.setValue(index)
    }
  }

  zoomToRect = (x1: number, y1: number, x2: number, y2: number, margin: number) => {
    let w = x2 - x1
    let h = y2 - y1
    let x = (x1 + x2) / 2
    let y = (y1 + y2) / 2

    let scale = (1 - margin) / Math.max(w / this.mapRect.width, h / this.mapRect.height)
    console.log(`DistrictMap.zoomToRect: scale ${scale.toFixed(2)}`)

    this.mapSVG
        .transition("mapZoom")
        .duration(600)
        .call(
            this.zoom.transform,
            d3.zoomIdentity
                .translate(this.mapRect.width / 2, this.mapRect.height / 2)
                .scale(scale)
                .translate(-x, -y)
        )
  }

  getDistrictRect = (geoId: number): DisplayRectangle => {
    let geoIdStr = ("0000" + geoId).slice(-4)
    let district = this.districts.find(p => p.id === geoIdStr)
    if (district) {
      let bounds = this.path.bounds(district)
      let x1 = bounds[0][0]
      let y1 = bounds[0][1]
      let x2 = bounds[1][0]
      let y2 = bounds[1][1]
      return new DisplayRectangle(x1, y1, x2 - x1, y2 - y1)
    }
    return new DisplayRectangle(0, 0, 0, 0)
  }
}

export {SliderDefinition}
export default MapController