import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import VisualizationWidget from "../../../VisualizationWidget/VisualizationWidget";
import {AppMetadata} from "./App";
import {ConsensusVotingApp} from "../../../Introduction/ConsensusVotingApp";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";
import {ResultWidget2} from "../../elements/ResultWidget2";
import HouseFlyover from "../content/HouseFlyover/HouseFlyover";

interface Props {
  metaData: AppMetadata
}

export class Madison extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }

  render() {
    let mdash = "\u2014"

    return (
        <Article title={"Madison's Dream"}
                 subtitle={"There is more at stake than winning an election."}>
          <div className={styles.textContent}>
            <HouseFlyover className={styles.flyover}/>

            <h2>
              The Profound Implications of Representing the Voters
            </h2>

            <p>
              Consensus Voting allows voters to elect the candidate they want. Polarizing candidates won’t be able to
              dictate
              the outcome, and the results will be more representative. Those are good things but fail to capture the
              implications. The differences seemed more profound, and I struggled to explain the impact. The fact that I
              couldn't explain it well also hinted that I did not understand it fully.

            </p>
            <p>
              Simulating elections had helped me see the implications of changing our election process, but it was a
              black
              box that other people did not trust. My simulations showed me a House and Senate that looked more
              functional,
              but was it? And how did this fit in with the original intent of the constitution? What does it mean for a
              candidate to represent all of their voters?
            </p>
            <p className={styles.pullQuote}>
              “By a faction, I understand a number of citizens, whether amounting to a majority or a minority of the
              whole, who are united and actuated by some common impulse of passion, or of interest, adversed to the
              rights of other citizens, or to the permanent and aggregate interests of the community.” James Madison –
              Federalist
              10
            </p>
            <h3>
              Factions
            </h3>
            <p>

              My breakthrough came when reading Federalist 10 and then later, Federalist 51. What struck me was
              Madison’s
              overriding concern about factions and his proposed solutions. Even though these essays were written more
              than 200 years ago, they are essential to understanding the problems we face today and how to solve them.
            </p>
            <p>
              In Federalist 10, Madison describes the failures of pure democracies (direct democracy). Madison writes
              that
              direct democracies are “brief in their lives and violent in their deaths” because it is inevitable that a
              faction will gain power and abuse the rights of the minority.
            </p>
            <p>

              To Madison, factions are the Achilles heel of democracy because they are “adverse to the rights of other
              citizens.” Madison considers it inevitable that a faction will gain power in a pure democracy. “Adverse to
              the rights of other citizens” is highly subjective; a person in the majority faction may sincerely believe
              that whatever policy they impose is fair and just. Of course, those citizens in the minority see things
              otherwise, and that’s where the “violent in their deaths” part comes in.
            </p>
            <p className={styles.pullQuote}>


              “It is of great importance in a republic, not only to guard the society against the oppression of its
              rulers; but to guard one part of the society against the injustice of the other part.” Madison –
              Federalist
              51
            </p>
            <p>

              The problem with direct democracies isn’t they are direct; the problem is when a faction tramples
              the rights of the minority. Madison and the other authors of the Constitution mount a multi-layer defense
              against factions. Scholars who are far more qualified than I am have documented this, but I do want to
              focus on a few relevant things Madison says in Federalist 10 and 51.
            </p>
            <p>

              In Federalist 10, Madison focuses on the conflict between the “public good” and the “immediate interests.”
              Madison argues that representatives in a republic will possess the “enlightened views” and “virtuous
              sentiments” required to govern in the “public good.” He lists a number of reasons why a republic will
              produce better results than past direct democracies, but I’m going to concentrate on two of them.
            </p>
            <p className={styles.pullQuote}>
              “... by comprehending in the society so many separate descriptions of citizens, as will render an unjust
              combination of a majority of the whole, very improbable, if not impracticable.” James Madison – Federalist
              51
            </p>
            <p>
              First, Madison argues that there will be so many competing factions in a larger republic that no single
              faction
              will ever be able to comprise a majority. The United States spans a huge geographic area with different
              economic interests, traditions, and political priorities. The sheer scale of the new republic made the
              concept of a single faction becoming dominant seem implausible.

            </p>
            <p>
              Second, each representative in a republic will represent 30,000 people (⅗’s rule aside). This large group
              of people will contain a diversity of interests and not a single faction. Parties formed almost
              immediately but didn't meet the definition of factions – more on that later.
            </p>
            <h3> Madison vs. Game Theory Optimal Politics </h3>
            <p>
              Madison’s advantage of a republic over a democracy hinges on the idea that representatives will not be
              purely representatives of a ‘faction’ but will instead represent all of their voters. Though I’m hesitant
              to say someone of Madison’s stature is wrong, I wonder what he’d say about today’s Congress.

            </p>
            <p>
              Madison could not have anticipated our primary system or modern GTO politics. Where Madison hopes for
              enlightened views and governing in the “public good,” we have primary elections that function as partisan
              purity tests and ensure that the winner of an election will only represent the priorities of a single
              party. In effect, our republic has recreated the problems of direct democracy with a smaller group
              of people.
            </p>
            <p>
              One constant in every representative democracy is that candidates are dependent on the voters who elect
              them to office. GTO politics dictates that candidates prioritize the objectives of the voters who elect
              them over everything else. Any candidate who doesn't wholly prioritize the goals of their voters is
              vulnerable to an opponent who does.
            </p>
            <p>
              This single-minded obsession by candidates with their core voters isn’t necessarily evil. First, it is
              simply the definition of democracy: candidates must win elections to serve. Second, a candidate’s voters
              may value respecting the rights of other voters {mdash} or not.
            </p>
            <p>
              GTO politics isn’t limited to the United States; candidates in every democracy must prioritize the
              objectives of the voters who elected them over everyone else if they want to be elected and reelected.
              The details of which voters a candidate is accountable to change from country to country, but the
              principle is the same.
            </p>
            <p>
              In proportional representation systems, candidates represent their party exclusively. The hope is that
              each group of voters will be represented in the legislature/parliament, though that may not do the
              minority any good. More on that later.
            </p>
            <p>
              In the United States, Candidates and representatives live in fear of a primary challenge, and every action
              is weighed based on its impact on their primary voters. Placing “country before party” is a fast path to a
              primary challenge and life in the private sector.
            </p>

            <h3>
              Why now?
            </h3>

            <p>
              In the 1950s, American politics enjoyed a period of cooperation, compromise, and progress. Both parties
              tried to recruit Eisenhower, and though he ran and served as a Republican, he governed straight down the
              middle. America appeared to be a model of democratic stability and good governance.

            </p>
            <p>
              At that time, neither party met Madison’s definition of a faction. Neither Democrats nor Republicans were
              united in their policy goals. Each party had a diversity of opinion within their caucus. Whichever party
              controlled the House or Senate represented a diversity of interests. No “faction” ever managed control.
              The whole system worked, even if not entirely as Madison had intended.
            </p>
            <p>
              Madison hoped that the individual representatives would act for the public good, but the important issue
              is not the individual representatives but the resulting policy. Each party had representatives that were
              accountable to voters with different priorities. The parties themselves did not have rigidly defined
              ideologies, allowing representatives the ability to compromise. The resulting policy reflected the
              priorities of a spectrum of voters and respected the rights of the minority.
            </p>
            <p>
              In “Breaking The Two-Party Doom Loop,” Lee Drutman details the chronology of the sorting of the parties
              into two distinct camps starting in 1960 and completing in 2010. One issue after issue, Republicans chose
              what we now call the conservative side while Democrats chose the liberal side. This sorting eroded the
              diversity within each party, and they each became the factions the founders dreaded.

            </p>
            <p>
              Today, the parties are very clearly defined with zero overlap at the elected level, though there is still
              significant ideological overlap amongst the voters. Today’s parties meet the definition the founders use
              for factions of “a number of citizens … united by a common impulse or passion … averse to the rights of
              other citizens”. When either party gains control, they declare that the voters have spoken, have a
              mandate, and proceed to govern in the sole interest of their faction.

            </p>
            <p>
              Each party denies governing “averse to the rights of other citizens.” They each argue that their
              priorities are in the best interest of the other citizens, even though the other citizens would vehemently
              disagree. It is a transparent justification for governing in the sole interest of the voters that elected
              them while disregarding the rights of the minority.

            </p>
            <p>
              We have reached a level of toxicity and divisiveness that has reached the degree of factional warfare that
              the founders feared. This behavior isn’t the fault of the candidates or leaders, it is the fault of a
              system that rewards divisiveness and punishes representation. Candidates must either play by the rules of
              GTO politics or lose to opponents who do. To change this behavior, we must change the rules.

            </p>
            <p>
              Candidates and politicians will always play GTO politics. We can not change that. We can change what GTO
              politics means. Madison’s dream of enlightened representatives fails because representatives are
              accountable only to the voters required to elect them. We can’t change that either, but we can change who
              those voters are. We need an election system where candidates are accountable to every voter.

            </p>
            <p>
              This seems like a tough problem, and it is. Fortunately, a lot of smart people have been working on it.
              I’ll dig into the technical details later, but no election system is perfect. Every voting system has
              flaws, but some systems are far better than others.
            </p>
            <h3>
              Consensus Voting
            </h3>
            <p className={styles.pullQuote}>
              “You can fool some of the people some of the time and all of the people some of the time, but you cannot
              fool all of the people all of the time” – Abraham Lincoln.
            </p>
            <p>
              Fortunately, we don’t need a perfect system. We need a system that changes the dynamics of GTO-Politics so
              that candidates need to represent all of their voters instead of just some narrow slice of voters.
              ‘Condorcet consistent’ election systems were developed by the Marquis de Condorcet just prior to the
              writing of the Constitution, and they do what we need. They give every voter a meaningful say in the
              outcome. I call them Consensus Voting because that best describes what they
              do: They find consensus.

            </p>
            <p>
            </p>
            <p>
              Consensus does not mean that every voter gets exactly what they want. That is impossible. Consensus means
              that when every voter is allowed to participate, the voters prefer one candidate to each of the other
              possible candidates. You might think that we already do that, but we don’t.

            </p>

            <p>
              I describe the mechanics of Consensus Voting in more detail in Consensus Voting; here I’m going to discuss
              some
              of the implications of Consensus Voting by looking at a part of an election.
            </p>
            <div className={styles.consensusDiv}>

              <ResultWidget2
                  tableInfo={this.state?.simulationState?.h2hResults.tables[0]}
                  id={0}
              />

              <VisualizationWidget
                  metaData={this.props.metaData}
                  className={styles.dividedHouse}
                  id={"actualHouse"}
                  height={1000}
                  width={1000}
                  nVoters={8000}
                  radius={3.5}
                  render={(app: ConsensusVotingApp) => {
                    app.h2hVoterSelectAll(app.Anne().name, app.Juan().name)
                  }}
                  onUpdate={this.onUpdate}
              />
            </div>


            <p>
              To win this election, Anne (yellow) needs support from voters to her right to defeat Sue (purple). Anne
              also
              needs support from voters on her left to defeat Maria (green). If Anne alienates any large group of
              voters,
              she will likely lose to a similar candidate who doesn't alienate that group. To win, Anne needs to balance
              the needs of all of the voters she represents.

            </p>
            <p>
              Anne has to represent every voter even when there isn’t an active candidate challenging her. If Anne moves
              too far right, she will invite a challenge from her left. The same applies to failing to balance the
              priorities of all of her constituents in every dimension. Anne must represent all of her constituents and
              cannot win if she ignores or “acts averse to the rights” of any of her voters.

            </p>
            <p>
              Consensus Voting changes GTO politics to reward candidates who balance the needs of all of their constituents
              instead of rewarding candidates who only represent some segment of their voters and ignore or attack the
              rest. Winning candidates will always be the best possible representative of all of their voters simply
              because they’re the candidate the voters prefer to each of the other candidates.

            </p>

            <p>
              Consensus Voting allows all of the voters to have a say and does what they say. With these ballots, choosing
              any candidate other than Anne override the wishes of the voters. You can’t choose Sue because the voters
              prefer Anne to Sue by 53.1% to 46.9% and you certainly can’t choose Juan because the voters prefer Anne to
              Juan by 66.5% to 33.5%.

            </p>
            <p>
              Winning candidates will be as diverse as their voters, but each will represent a spectrum of voters
              instead
              of a single faction. No democratic government has ever had an election system that rewarded candidates for
              representing all of their voters. It is stunning that this has never happened.

            </p>
            <p>
              Madison’s vision for a republic relies on the premise that representatives will represent all of their
              constituents, not just the fraction that elected them. Unfortunately, the rules we use to choose our
              leaders
              guarantees the opposite. In most elections in America, the election system favors divisive, toxic
              candidates
              who only represent their primary voters. At best, candidates may represent their party and some swing
              voters
              in a balanced district.

            </p>
            <p>
              Changing the rules so that elections favor candidates who represent all of their voters will make our
              government function as it was intended. We can fulfill Madison’s dream of a republic where the
              representatives are indeed governing in the “public good.” I’m still going to have to take a pass on
              “virtuous sentiments.”

            </p>
            <p>
              The implications electing the candidates voters prefer go beyond just a functioning House of
              Representatives. There are deep implications for the Senate, the courts, minority representation,
              gerrymandering, money in politics, and choosing truth over conspiracy theories. I’ll get to all of that,
              but
              I have to pick some sequence for these things.
            </p>
          </div>
        </Article>
    )
  }
}




















