import Bubble from "./Bubble";
import Point from "./Point";
import Trajectory from "./Trajectory";
abstract class Layout {
  abstract setTargetLocation<T extends Bubble>(b: T ): void
  abstract reset(): void
  abstract route<T extends Bubble>(b: T): Trajectory
  duration: number = 3000

  updateCxCy = <T extends Bubble>(b: T, progress: number): Point => {
    progress *= 1.5
    progress = Math.max(Math.min(progress, 1), 0)
    let dx = b.tx - b.x
    let dy = b.ty - b.y
    let x = dx * progress + b.x
    let y = dy * progress + b.y
    b.cx = x
    b.cy = y
    return new Point(x, y)
  }

}
export default Layout