import React from 'react';
import {DSVRowArray} from "d3";
import styles from "./IRV2D.module.scss"
import Step from "../ui/components/Step";
import VisualizationArea from "../ui/components/VisualizationArea";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
import ResultsBox from "../ElectionSimulation/ResultsBox";
import IRV2DApp from "./IRV2DApp";
import ElectionSimStoryBase from "../ElectionSimStoryBase/ElectionSimStoryBase";
import BallotWidget from "../ui/elements/BallotWidget";


class IRV2DStory extends ElectionSimStoryBase {

  visualizationAreas = [
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",

    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",

    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
  ]
  mapAreas = this.visualizationAreas

  createApp = (
      _visDivId: string,
      dvr: DSVRowArray,
      DWNominate: DSVRowArray,
      simulationResults: DSVRowArray,
      sampleCongress: DSVRowArray,
      usTopo: any): ElectionSimApp => {
    return new IRV2DApp(this.visualizationAreas[0], dvr, DWNominate, simulationResults, sampleCongress, usTopo, 20001, 2.5)
  }

  render() {
    let mdash = "\u2014"
    return (
        <div className={styles.content}>
          <div>
            <VisualizationArea
                className={styles.drawing1}
                id={"drawingArea1"}
            >
              {this.renderSliders()}
            </VisualizationArea>
            { /* 0 */}
            <Step className={styles.stepElement} first={true}>
              <h2>
                Instant Runoff Voting
              </h2>

              <div style={{
                border: "1px solid black",
                paddingBottom: "25px",
                marginBottom: "40px",
              }}>
                <h3>In Brief:</h3>
                <ul>
                  <li>Instant Runoff Voting (IRV) is often described as Ranked Choice Voting.</li>
                  <li>Explains the mechanics of the ballot and how votes are counted.</li>
                  <li>Shows the limited circumstances where IRV improves outcomes.</li>
                  <li>Explains the more common situation where IRV overrides the express intent of the voters.</li>
                  <li>IRV is really mostly just Instant Primaries</li>
                  <li>Shows that IRV is not a solution for polarization or factionalism.</li>
                </ul>
              </div>

              <h3>Counting the Ballots with Instant Runoff</h3>

              <BallotWidget
                  title={"Example Ballot"}
                  colors={["gold", "chartreuse", "violet"]}
                  choiceOrder={["Tiffany", "Jared", "Bruce"]}
                  candidates={["Bruce", "Jared", "Tiffany"]}
                  caption={"An Example ranked choice ballot with three candidates"}
              />

              <p>
                Instant Runoff starts with the same ranked choice ballot used by <a href={"/ConsensusVoting"}>Consensus
                Voting</a>. This allows the voter to more fully express their views. Unfortunately, that additional
                information is often ignored.
              </p>

              <p>
                Instant Runoff counts each ballot as a vote for the first active candidate on the ballot. If a candidate
                has a majority, they are the winner. Otherwise, the candidate with the fewest votes is eliminated.
                Eliminated candidates no longer active, and ballots that had counted for them are transferred to the
                next active candidate on the ballot. If there are no active candidates left on a ballot, it is
                considered 'exhausted' and discarded.
              </p>
              <p>
                This process of checking for a majority winner, eliminating a candidate, and transferring ballots
                continues until a candidate wins by reaching a majority of the remaining ballots.
              </p>
            </Step>

            { /* 1 */}
            <Step className={styles.stepElement}>
              <h3>Eliminating a Spoiler</h3>
              <p>
                This example is chosen to loosely resemble Maine's 2018 House District 2 election. Tiffany, a less
                viable independent candidate, has split off critical votes and would throw the election to the
                Republican candidate in a closely fought contest if the election used plurality rules, but Instant Runoff
                produces a more representative result.
              </p>
              <BallotWidget
                  colors={["gold", "chartreuse", "violet"]}
                  choiceOrder={["Tiffany", "Jared", "Bruce"]}
                  candidates={["Bruce", "Jared", "Tiffany"]}
                  title={"Steve's Ballot"}
              />
              <p>
                Steve, a Democratic-leaning voter, has chosen Tiffany as his first choice even though Tiffany is
                unlikely to prevail.
              </p>
              <p>
                Bruce (R) leads in the first round but fails to reach the 50% threshold required to win.
                The third-place candidate {mdash} Tiffany {mdash} is eliminated from the election, and ballots that
                had counted for her are transferred to the second-place candidate on those ballots. After those votes
                are transferred, Jared leads the election with more than 50% of the vote and is declared the winner.
              </p>

              <p>
                In the actual 2018 Maine House District 2 election, Jared Golden was elected in spite of Bruce
                Poliquin's first-round lead after Tiffany Bond and William Hoar were eliminated in the first round of
                IRV.
              </p>
              <p>
                The results below are both generated from the same candidates and voters and show that a traditional
                primary/plurality election would have elected Bruce, but IRV eliminated the spoiler and allowed Jared
                to prevail.
              </p>
              <div className={styles.sideBySide}>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("spoiler")?.irvResults}
                    title={"Instant Runoff"}
                    caption={"Select a round to show voter preference for that round \u2014 and stop the animation."}
                />
                <div style={{minWidth: "2%"}}/>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("spoiler")?.primaryResults}
                    title={"Primary/Plurality"}
                    caption={"Select a contest to show the outcome in that contest \u2014 and stop the animation."}
                />
              </div>
            </Step>

            { /* 2 */}
            <Step className={styles.stepElement}>
              <h3>Instant Runoff in a Local Election</h3>
              <p>
                Instant Runoff has been adopted by over eighty cities for their municipal elections. Most of these
                cities are in heavily Democratic areas, and this illustrates how such an election might play out.
              </p>

              <BallotWidget
                  colors={["violet", "gold", "chartreuse"]}
                  choiceOrder={["Rebecca (R)", "Diane (D)", "David (D)"]}
                  candidates={["David (D)", "Diane (D)", "Rebecca (R)"]}
                  title={"Tim's Ballot"}
              />
              <p>
                In this election, Tim, a Republican-leaning voter, has chosen Rebecca (R) as his first choice and the
                less partisan Democrat, Diane, as his second choice.
              </p>
              <div className={styles.sideBySide}>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("local")?.irvResults}
                    title={"Instant Runoff"}
                    caption={"Select a round to show voter preference for that round \u2014 and stop the animation."}
                />
                <div style={{minWidth: "2%"}}/>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("local")?.primaryResults}
                    title={"Primary/Plurality"}
                    caption={"Select a contest to show the outcome in that contest \u2014 and stop the animation."}
                />
              </div>
              <p>
                David leads the first round but fails to reach the 50% threshold for victory, so Instant Runoff will
                eliminate the candidate with the fewest votes. In this heavily Democratic election, that candidate is
                Republican, Rebecca.
              </p>
              <p>
                When Rebecca is eliminated, ballots from Republican-leaning voters who listed Rebecca first are
                transferred to the second-place candidate on each ballot. Almost all of these voters have listed the
                less partisan Democrat, Diane, as their second choice.
              </p>
              <p>
                With the influx of new votes, Diane now passes David, reaches a majority, and is declared the winner by
                Instant Runoff.
              </p>
              <p>
                A Democrat was always going to win in this heavily Democratic city. The question was, "Which Democrat?"
                All of the voters participated in that crucial decision, and the result represents the voters.
                Republican voters rewarded Diane for her outreach to them and their support enabled her to win.
              </p>
              <p>
                Instant Runoff has a good track record in municipal elections, and studies have shown that IRV reduces
                negative advertising and produces more representative results in municipal elections.
              </p>
              <p>
                But unlike state and federal elections, municipal elections often come down to two candidates from the
                same party. Let's see what happens when the environment becomes more competitive.
              </p>
            </Step>

            { /* 3 */}
            <Step className={styles.stepElement}>
              <h3>A Rematch Four Years Later</h3>
              <p>
                The same three candidates face off again four years later, but the electorate has shifted. The election
                still favors Democrats, but it is less liberal than four years ago. Surprisingly, the winner is more
                liberal. What happened?
              </p>
              <div className={styles.sideBySide}>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("competitiveLocal")?.irvResults}
                    title={"Instant Runoff"}
                    caption={"Select a round to show voter preference for that round \u2014 and stop the animation."}
                />
                <div style={{minWidth: "2%"}}/>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("competitiveLocal")?.primaryResults}
                    title={"Primary/Plurality"}
                    caption={"Select a contest to show the outcome in that contest \u2014 and stop the animation."}
                />
              </div>
              <h3>How it Happened</h3>
              <p>
                Like four years earlier, no candidate has reached a majority, so IRV will eliminate the last-place
                candidate. But this time, Diane is trailing.
              </p>
              <p>
                When Diane is eliminated, ballots from Democratic-leaning voters who had listed Diane first are
                transferred to the next active candidate on each ballot. For these Democratic-leaning voters, that's
                almost all David. With the influx of new votes and Diane out of the election, David easily defeats
                Rebecca.
              </p>
              <p>
                Republican voters are frustrated and confused by the outcome. The voters preferred the moderate Diane to
                the liberal David four years earlier by 57% to 43% and the electorate has become less liberal since
                then. If anything, it seems like Diane should have won by an even larger margin. How did this
                happen?
              </p>
              <p>
                Four years earlier, Rebecca was eliminated in the first round and the votes of Republican-leaning voters
                were transferred to their second choice. But this time, Rebecca survived to the final pairing, and IRV
                ignored the second-place choices of Republican-leaning voters. Ironically, Republican-leaning voters get
                less say because there are more of them.
              </p>
            </Step>

            { /* 4 */}
            <Step className={styles.stepElement}>
              <h3>Overriding the Voters</h3>
              <ResultsBox
                  results={this.sectionResults("competitiveLocal")?.h2hResults}
                  title={"Majority Rule"}
                  caption={""}
                  className={styles.singleResultBox}
              />
              <p>
                Every voter expressed a preference between the two Democrats in this election when they filled out their
                ballot. If they ranked Diane before David, they prefer Diane to David and vice versa. It doesn't matter
                if those rankings are first and second or second and third. They still prefer one candidate over the
                other.
              </p>
              <p>
                Examining all of the ballots and counting the number of voters that prefer David and the number of
                voters that prefer Diane tells us that the voters prefer Diane to David by an even bigger margin
                than they did four years earlier.
              </p>
              <p>
                Instant Runoff overrode the express intent of the voters.
              </p>
              <p>
                Diane is clearly a better representative of the voters than David. But, if Rebecca enters
                the race, IRV declares that David is now the winner. To be blunt, that's crazy. Rebecca's
                participation {mdash} or non-participation {mdash} in the race shouldn't change the outcome unless she
                wins.
              </p>
              <p>
                Every voting system has flaws, but the flaws in IRV are devastating and common.
              </p>
            </Step>
          </div>

          <div className={styles.spacerDiv}/>

          <div>
            <VisualizationArea
                className={styles.drawing2}
                id={"drawingArea2"}
            >
              {this.renderSliders()}
            </VisualizationArea>
            { /* 5 */}
            <Step className={styles.stepElement} first={true}>
              <h2>State and Federal Elections</h2>
              <p>
                Instant Runoff performs even less well in more polarized and competitive state and federal
                elections where the final pairing includes candidates from each party. The results below show the
                outcome of a five-candidate election changes {mdash} or doesn't change {mdash} under <a
                  href={"/ConsensusVoting"}>Consensus Voting</a>, Instant Runoff, and primary/plurality.
              </p>
              <div className={styles.sideBySide}>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("Details")?.h2hResults}
                    title={"Consensus Voting"}
                    caption={"Select a round to show voter preference for that round \u2014 and stop the animation."}
                />
                <div style={{minWidth: "1%"}}/>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("Details")?.irvResults}
                    title={"Instant Runoff"}
                    caption={"Select a round to show voter preference for that round \u2014 and stop the animation."}
                />
                <div style={{minWidth: "1%"}}/>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("Details")?.primaryResults}
                    title={"Primary/Plurality"}
                    caption={"Select a contest to show the outcome in that contest \u2014 and stop the animation."}
                />
              </div>
              <p>
                The Consensus Voting results show that the voters preferred Anne to each of the other candidates, so
                how did Instant Runoff produce a different outcome with the same ballots? We know that the primary
                system creates a <a href={"/Factionalism"}>factional democracy</a> because each faction chooses their
                own candidate. But why does Instant Runoff get the same result as primaries?
              </p>
              <p>
                That's a reasonable question and one I asked when I began looking at simulation results.
              </p>
            </Step>

            { /* 6 */}
            <Step>
              <h3>Instant Runoff, Round One</h3>
              <p>
                The visualization on the right shows the voters whose preference between Anne and Maria was
                considered by Instant Runoff. The other voters are faded to show that IRV ignored their preference.
              </p>
              <p>
                These simulated voters all filled out their complete ballot, and every one of them expressed a
                preference
                between Anne and Maria. But, the rules of IRV only consider the top active ranking on a ballot.
                Everything else is ignored. So, if a voter listed Sue, then Anne, then Maria, Instant Runoff will ignore
                their preference for Anne over Maria until Sue is eliminated from the race. Since Anne was eliminated
                before Sue, IRV completely ignored their preference.
              </p>
              <p>
                Democratic-leaning voters all expressed a preference for Anne over Maria, but IRV ignored
                their preferences by only looking at the first active candidate on their ballot. Even most Republican
                voters get no say in this outcome because they listed Juan first.
              </p>
              {this.irvRoundTable(3, "irvRound1")}
            </Step>

            { /* 7 */}
            <Step className={styles.stepElement}>
              <h3>IRV Round 2, Republicans Pick Their Finalist</h3>
              <p>
                Now it's time for Republican voters to choose between Maria and Juan to be their finalist. Here again,
                only Republican-leaning voters matter in the choice between these two candidates.
              </p>
              <p>
                Democratic-leaning voters all ranked Maria over Juan, but their opinions are ignored because their
                preferred Democratic candidates are still active in the race.
              </p>
              <p>
                These first two rounds of IRV function as a sort of mini-primary where Republican-leaning voters choose
                a Republican candidate to survive to the final round.
              </p>
              {this.irvRoundTable(2, "irvRound2")}
            </Step>

            { /* 8 */}
            <Step className={styles.stepElement}>
              <h3>Democrats Pick Their Finalist</h3>
              <p>
                In the next round of IRV, Democratic-leaning voters hold their own mini-primary to decide which
                candidate survives to the final round. These Democratic-leaning voters pick Bob, who also wins a
                Democratic primary.
              </p>
              <p>
                Republican-leaning all expressed a preference for Sue over Bob, but IRV ignored their preferences
                because Juan was still active in the election.
              </p>
              {this.irvRoundTable(1, "irvRound3")}
            </Step>

            { /* 9 */}
            <Step className={styles.stepElement}>
              <h3>The Final Round</h3>
              <p>
                Only now, after partisans on each side have eliminated all of the more representative candidates, do
                all of the voters finally get a say.
              </p>
              <p>
                Republican-leaning voters chose a Republican finalist, while Democratic-leaning voters chose a
                Democratic
                finalist. That's why I call this voting rule "Instant Primaries" instead of Instant Runoff.
              </p>
              <p>
                It's hard to see how the results will be any different from the factional democracy we have today.
              </p>
              <ResultsBox
                  results={this.sectionResults("final")?.irvResults}
                  title={"Instant Runoff"}
                  caption={"Select a round to show voter preference for that round \u2014 and stop the animation."}
                  className={styles.singleResultBox}
              />
            </Step>


            { /* 10 */}
            <Step className={styles.stepElement}>
              <h3>It Is Almost Impossible for a Representative Candidate to Win</h3>

              <p>
                Try it yourself. Drag the candidates and see if you can create a scenario where a candidate near the
                center of the electorate wins without moving either Bob or Juan. Partisans can always eliminate
                candidates near the center of the electorate by behaving strategically.
              </p>

              <div className={styles.sideBySide}>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("impossible")?.h2hResults}
                    title={"Consensus Voting"}
                    caption={"Select a one-on-one comparison to show voter preferences between those two candidates \u2014 and stop the animation."}
                />
                <div style={{minWidth: "2%"}}/>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("impossible")?.irvResults}
                    title={"Instant Runoff"}
                    caption={"Select a round to show voter preference for that round \u2014 and stop the animation."}
                />
                <div style={{minWidth: "2%"}}/>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("impossible")?.primaryResults}
                    title={"Primary/Plurality"}
                    caption={"Select a contest to show the outcome in that contest \u2014 and stop the animation."}
                />
              </div>
              <p>
                How likely are quality candidates to contest an election they have little chance of winning?
                Representative candidates will have difficulty persuading donors, volunteers, and the media that they
                are viable when the playing field is tilted against them. Will they even contest such elections?
              </p>
              <p>
                We rarely see high-quality independents contest elections today because the system was built by the
                parties to cement their power. The primary system gives the illusion of obeying majority rule because
                representative candidates are eliminated long before election day.
              </p>
              <p>
                Would Instant Runoff do any better? Would quality candidates in the center of the electorate contest
                elections when hyper-partisan candidates can easily force them from the race? IRV might seem to elect
                the "majority rule winner" in many cases only because truly representative candidates never even run.
              </p>
              <p>
                It is possible to engineer a win for Anne without moving either Juan or Bob, but it takes some creative
                fratricide by the factional candidates. And that also presumes that neither Juan nor Bob adjust their
                strategy. Try it and make your own judgment about how likely that is.
              </p>
              <p>
                You can also explore election results using <a href={"/ElectionVisualization"}>Election
                Visualization</a>, where you can adjust the influence of party preference, uncertainty, candidate
                quality, and the partisan lean of the electorate.
              </p>
            </Step>


            { /* 11 */}
            <Step className={styles.stepElement}>
              <h3>Further Exploration</h3>
              <p>
                This example illustrates that factional candidates can continue to dominate election outcomes
                under Instant Runoff Voting. Partisan candidates can ensure more representative candidates
                are eliminated by doing exactly what they do today. My presumption is that since they can,
                they will.
              </p>

              <ul style={{
                listStyleType: "disc",
                listStylePosition: "inside",
                marginTop: "30px",
                marginLeft: "30px",
              }}>
                <li><a href={"/LeviathanMath"}>Leviathan Math</a>: The science behind simulation.</li>
                <li><a href={"/ConsensusVoting"}>Consensus Voting</a>: Abiding by majority rule.</li>
                <li><a href={"/ElectionSim"}>Election Simulation</a>: Perform your own simulations.</li>
                <li><a href={"/Democracy"}>Democracy</a>: Democracy is broken, but what should it be?</li>
              </ul>
            </Step>

          </div>
          <div>
            <div style={{
              minHeight: "30vh"
            }}>
            </div>
          </div>
        </div>
    )
  }
}

export default IRV2DStory
