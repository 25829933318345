import React from 'react'
import styles from './HelpTopic.module.scss'

interface Props {
  title: string
  images: string[]
  children?: JSX.Element | JSX.Element[];
}

const HelpTopic = (props: Props) => {
  return (
      <div  className={styles.content}>
        <h3>{props.title}</h3>
        <div className={styles.imageBlock}>
          {
            props.images.map((image, index) => {
              return (<img className={styles.floatingImage} alt={image} key={index} src={image}/>)
            })
          }
          <div>
            {props.children}
          </div>
        </div>
      </div>
  )
}

export default HelpTopic