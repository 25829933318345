import React from 'react'
import styles from './Title.module.scss'

const Title = () => {
  return (
      <div className={styles.content}>
        <p className={styles.titleText}>
          The Deeper Purpose of Elections
        </p>
        <div className={styles.subtextDiv}>
          <p className={styles.titleSubtext}>
            We blame politicians, parties, our inherent tribalism, and the pressures of a changing world for our
            deepening political divide, but the mathematics of elections allows no other outcome. Candidates behave the
            way they do because it works. If you want to change the behavior, you must change the rules.
          </p>
          <p className={styles.titleSubtext}>
            But which rules, and why? Democracy is broken, but what does fixing it mean?
            BetterChoices explains how to change election rules so that when candidates act in their self-interest,
            they act in the public interest.
          </p>
        </div>
      </div>
  )
}

export default Title