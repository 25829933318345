import * as d3 from "d3";
import {DSVRowArray} from "d3";
import React from 'react';
import MapController from "../ui/elements/MapController";
import CombinedDistrictData from "../core/CombinedDistrictData";
import ElectionSimApp from "./ElectionSimApp";
import styles from "./ElectionSim.module.scss";
import {ControlState} from "../ui/elements/SimulationControls";
import ResultsBox from "./ResultsBox";
import HelpTopic from "../ui/components/content/HelpTopic/HelpTopic";
import CandidateControls from "../ui/elements/CandidateControls";
import ElectionSimStoryBase from "../ElectionSimStoryBase/ElectionSimStoryBase";


class ElectionSimStory extends ElectionSimStoryBase {
  mainDiv = "ElectionSim"
  svg!: d3.Selection<any, any, any, any>
  map!: MapController
  combinedDistrictData!: CombinedDistrictData
  app!: ElectionSimApp
  helpVisible = false
  controlsVisible = false

  visualizationAreas = ["drawingDiv"]
  mapAreas = ["drawingDiv"]


  createApp = (
      visDivId: string,
      dvr: DSVRowArray,
      DWNominate: DSVRowArray,
      simulationResults: DSVRowArray,
      sampleCongress: DSVRowArray,
      usTopo: any): ElectionSimApp => {
    return new ElectionSimApp("drawingDiv", dvr, DWNominate, simulationResults, sampleCongress, usTopo, 20000, 2.0)
  }

  onControlUpdate = (s: ControlState) => {
    this.app.onControlUpdate(s)
  }

  toggleHelp = () => {
    if (this.controlsVisible)
      this.toggleControls()

    this.helpVisible = !this.helpVisible
    d3.selectAll(".helpModal")
        .style("display", this.helpVisible ? "block" : "none")
  }

  toggleControls = () => {
    if (this.helpVisible)
      this.toggleHelp()

    this.controlsVisible = !this.controlsVisible
    let v = this.controlsVisible
    this.app.updateSliders(v, v, v)
  }

  simState = () => {
    // console.log("simState this.state:  ", this.state)
    if (this.state.simulationState)
      return this.state.simulationState
    else
      return this.state
  }

  render() {
    let c2d = this.simState()?.controlState?.activeCandidate
    let candidateName = c2d ? c2d.name : "NoName"
    let candidateParty = c2d ? c2d.party.shortName : "NoParty"
    let candidateQuality = c2d ? c2d.candidate.quality : 0
    // console.log("\t\t\trender:  ", c2d)
    return (

        <div>
          <div style={{
            minHeight: "5vh"
          }}/>

        <div className={styles.mainDiv + " step"} id={this.mainDiv}>
          <div className={styles.buttons}>
            <button
                onClick={() => {
                  this.toggleHelp()
                }}>Help
            </button>
            <button
                onClick={() => {
                  this.toggleControls()
                }}>
              Advanced Controls {"\u2699"}
            </button>
          </div>
          <div id={"drawingDiv"} className={styles.htmlArea}>
            <canvas className={styles.canvasArea} id={"members1"}/>
            <canvas className={styles.canvasArea} id={"members2"}/>
            <canvas className={styles.canvasArea} id={"voters"}/>
            <canvas className={styles.canvasArea} id={"voters1D"}/>
            <svg className={styles.svgArea} id={"svgArea"}>
              <g id={"drawingG"}/>
            </svg>
            {this.renderSliders()}
          </div>
          <CandidateControls
              name={candidateName}
              party={candidateParty}
              quality={candidateQuality}
              changeCallback={this.onCandidateEdit}
          />
          <div className={styles.resultsAndControls}>
            <div className={styles.resultsContainer}>
              <ResultsBox
                  title={"Consensus Voting"}
                  results={this.simState().h2hResults}
                  caption={"Select a pair of candidates to see voter choices."}
              />
              <ResultsBox
                  title={"Instant Runoff Voting"}
                  results={this.simState().irvResults}
                  caption={"Select a round to show voter choices."}
              />
              <ResultsBox
                  title={"Primary/Plurality"}
                  results={this.simState().primaryResults}
                  caption={"Select a contest to show voter choices."}
              />
            </div>
          </div>
          <div className={styles.helpModal + " helpModal"}>
            <div className={styles.modalNav}>
              <h2>Election Simulation</h2>
              <button id={'x'} className={styles.cancelButton}
                      onClick={() => {
                        d3.selectAll(".helpModal")
                            .style("display", "none")
                        this.helpVisible = false
                      }}
              >
                X
              </button>
            </div>
            <div className={styles.scrollDiv}>
              <div className={styles.modalContentDiv}>
                <p>
                  This is an interactive tool for exploring election outcomes under different electoral processes, you
                  can drag the candidates to see how different scenarios play out and also change the parameters of the
                  simulation.
                </p>
                <p>
                  There are 20,000 voters arranged by their ideology in two dimensions. The two dimensions are social
                  and economic issues the upper-right being conservative on both and the lower-left being liberal in
                  both. There are also five candidates shown as black outlined circles. Each voter prefers the candidate
                  closest to them and is colored for that candidate.
                </p>
                <p>
                  The results tables on the right show the outcomes for three different election processes:
                  Consensus Voting, Instant Runoff, and Primary/Plurality (our current voting system). All three
                  election systems use the exact same set of voter preferences, the only difference is how they
                  are interpreted.
                </p>

                <p>
                  For instance, if a voter's first choice is Maria and then Bob then they will be presumed to vote
                  for Maria in the Democratic primary and then Bob in the general election. The ballots for Instant
                  Runoff and Consensus Voting are identical, the <i>only</i> difference in the outcomes between those
                  two systems is how the ballots are interpreted.
                </p>

                <HelpTopic
                    title={"Changing Candidates"}
                    images={["CandidateImage.png"]}
                >
                  <p>
                    This circle represents a candidate. Drag the candidate to a different position to see how that
                    affects voter preference and election outcomes under different systems.
                  </p>
                </HelpTopic>

                <HelpTopic
                    title={"Visualizing Voter Preferences"}
                    images={[
                      "VoterPreference-Consensus.png", "VoterPreference-Sue-Juan.png",
                    ]}>
                  <p>
                    Selecting a line in the consensus results tables will show which voters preferred each of the two
                    candidates in that line.
                  </p>

                  <p>
                    Selecting a line in the consensus results tables will show which voters preferred each of the two
                    candidates in that line. This shows which voters preferred Sue and Juan.
                  </p>
                </HelpTopic>


                <HelpTopic
                    title={"Visualizing Voter Choices"}
                    images={[
                      "IRV-Round2-Results.png", "IRV-Round2-Voters.png",
                    ]}>
                  <p>
                    Clicking on a results table for Instant Runoff or Plurality will show voter preference in that
                    round of Instant Runoff or part of the plurality election process.
                  </p>
                </HelpTopic>

                <HelpTopic
                    title={"Results Summary"}
                    images={[
                      "ResultsSummary.png"
                    ]}>
                  <p>
                    This is the summary of results, it shows that Sue is the winner under Consensus Voting. A
                    Representation of 99 means that voters would prefer Sue to 99% of the rest of the
                    population. For more on Representation, read <a href={"/VoterRepresentation"}>this article.</a>
                  </p>
                </HelpTopic>

                <HelpTopic
                    title={"Voter Preference"}
                    images={[
                      "VoterPreference.png"
                    ]}>
                  <p>
                    Voters prefer the candidate who most closely represents their values. This creates a dividing
                    line
                    between each pair of candidates where voters one one side prefer one candidate and on the other
                    side they prefer the other.
                  </p>
                  <p>
                    We start with this simple model because it's easiest to understand, but it's not actually that
                    simple. There is also <i>party loyalty</i> and <i>uncertainty</i> to consider, those are covered
                    under advanced controls.
                  </p>
                </HelpTopic>

                <HelpTopic
                    title={"Population Distribution"}
                    images={[
                      "Population.png"
                    ]}>
                  <p>
                    The population shown here is represented in two dimensions, this is a departure from many areas of
                    the site where the population is shown in a single left-right dimension. The two dimensional view
                    is shown here to provide more options for exploring the dynamics presented on the website.
                  </p>
                  <p>
                    In this view, the center of the Republican party is in the upper-right, while the Democratic party
                    is in the lower-left. Independent voters are centered at the center.
                  </p>
                  <p>
                    The traditional left-right axis is shown in red on the left and you can align the candidates along
                    that line to replicate the traditional left-right dynamics.
                  </p>
                  <p>
                    Future versions may allow for more control over the distribution and makeup of the voter
                    population.
                  </p>
                </HelpTopic>

                <h3>
                  Advanced Controls
                </h3>

                <p>
                  These controls can be accessed with the Advanced Controls button. In general, these controls don't
                  have a huge amount of impact on outcomes.
                </p>

                <p>
                  They are available here to allow people who are curious to confirm that these concepts have been
                  carefully considered and the outcomes shown on the site are robust. The biggest impact is that
                  party loyalty can allow even less representative outcomes under Instant Runoff and Plurality
                  based voting under some situations.
                </p>

                <p>
                  The controls are left off by default because they make the visualizations harder to interpret and
                  don't change the concepts we are trying to explore.
                </p>
                <p>
                  I've attempted to explain them, but it's advisable to drag them back and forth and observe the change
                  in the voter preferences to understand their impact.
                </p>


                <HelpTopic
                    title={"Uncertainty"}
                    images={[
                      "Uncertainty.png"
                    ]}>
                  <p>
                    Uncertainty refers to the idea that voters do not have perfect and identical information about
                    candidates. Voters have consumed different media and gotten different information from their peers.
                    Voters may also make decisions for reasons that are not captured by the two dimensions shown here.
                  </p>
                  <p>
                    When uncertainty is used a voter that is close to the same distance from two candidates may
                    choose the one that is farther away. In this case the voters on the line between purple and
                    green/brown may choose either candidate.
                  </p>
                  <p>
                    Bob (green) and Maria (brown) are very near each other. With uncertainty, the support for the two
                    candidates is a diffuse across all voters instead of a crisp line separating the support for the
                    two candidates.
                  </p>
                </HelpTopic>

                <HelpTopic
                    title={"Party Preference"}
                    images={[
                      "PartyPreference.png"
                    ]}>

                  <p>
                    Party preference refers to a voter's preference to vote for a candidate within their party even when
                    that candidate is not as close them ideologically.
                  </p>

                  <p>
                    The image shows that the line separating Sue-D (purple) and Juan-R (blue) has become mixed as
                    Republican voters closer to Sue stick with Juan while Democratic voters on Juan's side prefer
                    Sue anyway. The boundary between supporters of Anne (pink) and Juan (blue) remains distinct
                    because both candidates are Republicans and are equally affected by voter preference.
                  </p>


                </HelpTopic>
                <HelpTopic
                    title={"Population Distribution"}
                    images={[
                      "PartisanLean.png"
                    ]}>

                  <p>
                    The population initially shown is neutral with 40% Democrats, 40% Republicans and 20% Independents.
                    Changing the partisan lean changes the number of voters from each party.
                  </p>
                  <p>
                    The maximum lean corresponds to a 20% gap between Republicans and Democrats.
                  </p>

                  <p>
                    Changing the lean also moves the position of the candidate who would defeat any other candidate of
                    equal quality and therefore alters the representation score of all candidates.
                  </p>

                </HelpTopic>

              </div>
            </div>
          </div>
        </div>
        </div>
    );
  }
}

export default ElectionSimStory
