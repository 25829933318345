import React from 'react';
import {DSVRowArray} from "d3";
import styles from "./RepresentativeCongress.module.scss"
import Step from "../ui/components/Step";
import VisualizationArea from "../ui/components/VisualizationArea";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
import ElectionSimStoryBase from "../ElectionSimStoryBase/ElectionSimStoryBase";
import RepresentativeCongressApp from "./RepresentativeCongressApp";


class RepresentativeCongressStory extends ElectionSimStoryBase {

  visualizationAreas = [
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",


  ]
  mapAreas = this.visualizationAreas

  createApp = (
      _visDivId: string,
      dvr: DSVRowArray,
      DWNominate: DSVRowArray,
      simulationResults: DSVRowArray,
      sampleCongress: DSVRowArray,
      usTopo: any): ElectionSimApp => {
    return new RepresentativeCongressApp(this.visualizationAreas[0], dvr, DWNominate, simulationResults, sampleCongress, usTopo, 20001, 2.5)
  }

  render() {
    // let mdash = "\u2014"
    return (
        <div className={styles.content}>
          <div>
            <VisualizationArea
                className={styles.drawing1}
                id={"drawingArea1"}
            >
              {this.renderSliders()}
            </VisualizationArea>

            <Step className={styles.stepElement} first={true}>
              <h2>
                Simulation Populations
              </h2>
            </Step>

            <Step className={styles.stepElement} >
              <h2>
                Combined Populations
              </h2>
            </Step>
            <Step className={styles.stepElement} >
              <h2>
                House DWNominate Dim-1
              </h2>
            </Step>
            <Step className={styles.stepElement} >
              <h2>
                House, Simulated Primaries, Sigma.
              </h2>
            </Step>
            <Step className={styles.stepElement} >
              <h2>
                Simulated Instant Runoff
              </h2>
            </Step>
            <Step className={styles.stepElement} >
              <h2>
                House, Simulated Minimax, Sigma.
              </h2>
            </Step>
            <Step className={styles.stepElement} >
              <h2>
                Actual Representation
              </h2>
            </Step>
            <Step className={styles.stepElement} >
              <h2>
                Simulated Primary Representation
              </h2>
            </Step>
            <Step className={styles.stepElement} >
              <h2>
                Simulated Minimax
              </h2>
            </Step>
            <Step className={styles.stepElement} >
              <h2>
                Instant Runoff
              </h2>
            </Step>
            <Step className={styles.stepElement} >
              <h2>
                Blank
              </h2>
            </Step>


          </div>
          <div>
            <div style={{
              minHeight: "30vh"
            }}>
            </div>
          </div>
        </div>
    )
  }
}

export default RepresentativeCongressStory
