import React from 'react';
import {DSVRowArray} from "d3";
import styles from "./SimulationTutorial.module.scss"
import Step from "../ui/components/Step";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
import SimulationTutorialApp from "./SimulationTutorialApp";
import ResultsBox from "../ElectionSimulation/ResultsBox";
import ElectionSimStoryBase from "../ElectionSimStoryBase/ElectionSimStoryBase";


class SimulationTutorialStory extends ElectionSimStoryBase {
  visualizationAreas = [
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",

    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",

    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
  ]
  mapAreas = this.visualizationAreas

  createApp = (
      visDivId: string,
      dvr: DSVRowArray,
      DWNominate: DSVRowArray,
      simulationResults: DSVRowArray,
      sampleCongress: DSVRowArray,
      usTopo: any,
      nVoters: number,
      radius: number): ElectionSimApp => {
    return new SimulationTutorialApp(this.visualizationAreas[0], dvr, DWNominate, simulationResults, sampleCongress, usTopo, nVoters, radius)
  }

  render() {
    return (
        <div className={styles.content}>
          <div>
            {this.renderVisArea(styles.drawing1, "drawingArea1")}
            { /* 0 */}
            <Step className={styles.stepElement} first={true}>
              <h2>Understanding Election Simulation</h2>
              <h3>The Purpose of Election Simulation</h3>
              <p>
                This article is a high-level explanation of the science and mathematics
                behind <a href={"/ElectionSim"}>election simulation</a>.
              </p>
              <p>
                The purpose of election simulation is <i>not</i> to predict the outcome of an individual election. That
                would require polling information at a level which is simply not possible. The purpose is instead to
                understand the forces that drive the outcomes of elections.
              </p>

              <p>

                It is possible to have perfect "polling" information in simulation. I know the exact ideology of every
                voter and every candidate because I assigned the numbers to them. This creates a powerful tool.
                Simulation allows me (and you!) to study how rational, well-informed voters make their choices and how
                party loyalty, uncertainty, candidate quality, and the voting rule itself alter election outcomes.

              </p>

              <p>
                My goal with simulation is to understand how these forces influence outcomes and what changes are needed
                to make election results more accurately reflect the wishes of the voters. The population distributions
                used here are imperfect, but if these simulated voters who make well informed, rational decisions cannot
                elect candidates whom they prefer, what chance do we have in the real world?
              </p>


            </Step>

            <Step className={styles.stepElement}>
              <h3>The simplest case</h3>
              <p>
                The Election Simulator shows the outcomes of elections under various voting rules. You can use it as
                is, or you can dive into some more interesting details. This tutorial attempts to explain election
                simulation in more detail. It also touches on population distributions used to do district-by-district
                election simulations and estimations of voter representation.
              </p>

              <p>
                We will start with the simplest model of voter choice. Each voter chooses the candidate they
                view as being the best representative of their values. In a spatial model of voter opinion
                that is the candidate they are closest to.
              </p>

              {this.generalResultTable()}
              <p>
                This interactive image shows the outcome of a two-candidate race, each voter is colored for the
                candidate they are closest to. Drag the candidates to see different scenarios.
              </p>

            </Step>

            <Step className={styles.stepElement}>
              <h3>Understanding Party Preference</h3>
              <p>
                The first set of results assumed that voters vote purely for the candidate that most closely represents
                their values. That makes the results easier to interpret but isn't quite accurate. Voters prefer
                to stick within their own party and many will do so even if there is a candidate from another
                party who is seemingly closer to their ideology. I call this "party preference".
              </p>
              <p>
                This shows how party preference impacts voters' decisions. You see moderate and somewhat liberal
                Republicans sticking with Republican candidates while moderate and conservative Democrats will
                stick with Democratic candidates.
              </p>
              <p>
                The line of separation between candidates becomes "fuzzy" when the candidates represent different
                parties, but remains crisp between candidates of the same party. You can halt the animation
                at any time by adjusting the party loyalty slider.
              </p>
            </Step>

            <Step className={styles.stepElement}>
              <h3>The Impact of Party Preference and Election Outcomes</h3>
              <p>
                This shows the outcome of an election under both Instant Runoff and Consensus Voting.
              </p>
              <p>
                Party preference is one factor that allows more partisan and potentially divisive candidates to dominate
                our current election system. It has a similar effect under Instant Runoff with voters choosing partisan
                candidates from their own party above moderate candidates from the opposition party.
              </p>
              <p>
                A dominant party will be favored under all systems, but Party Preference does not otherwise influence
                the outcome under Consensus Voting. Thus a Democratic leaning district will likely choose a Democrat,
                but
                party preference has no impact when comparing two candidates from the same party.
              </p>
              <div className={styles.sideBySide}>
                <ResultsBox
                    results={this.state.simulationState?.h2hResults}
                    title={"Consensus Voting"}
                />
                <div style={{minWidth: "2%"}}>
                </div>
                <ResultsBox
                    results={this.state.simulationState?.irvResults}
                    title={"Instant Runoff"}
                />
              </div>
            </Step>

            <Step className={styles.stepElement}>
              <h3>The Use of Party Preference on this Site</h3>
              <p>
                Most of the outcomes are shown without party preference turned off. Turning it on adds further support
                to Consensus Voting because party preference does not distort election outcomes.
                Explaining party preference in the context of the introduction story distracted from the main point.
              </p>
              <p>
                Turning it on makes the central thesis of this site more compelling but at the cost of some visual
                complexity.
                I chose to leave it off by default.
              </p>

              <div className={styles.sideBySide}>
                <ResultsBox
                    results={this.state.simulationState?.h2hResults}
                    title={"Consensus Voting"}
                />
                <div style={{minWidth: "2%"}}>
                </div>
                <ResultsBox
                    results={this.state.simulationState?.irvResults}
                    title={"Instant Runoff"}
                />
              </div>
            </Step>

            <Step className={styles.stepElement}>
              <h3>Uncertainty</h3>
              <p>
                Each voter consumes different media and makes judgments for different reasons that are not captured
                in the two-dimensional voter ideology shown here. Voters also prioritize issues differently. The
                crisp lines of voter preference are not how things actually happen. They do reflect <i>probability of
                choice</i>, rather than actual voter choices.
              </p>
              <p>
                Uncertainty captures that concept as a scaled unit normal random number added to the score of each
                candidate. This shows how uncertainty affects the outcome of an election. Adjust the uncertainty
                slider at the bottom to see how that impacts the outcome.
              </p>
              <p>
                Uncertainty affects outcomes most when candidates are close together ideologically.
                A voter who is on the other side of the political spectrum is unlikely to know that one is a marginally
                closer to them ideologically. It often just swaps a bunch of voters around with no strong net effect.
              </p>
              <p>
                Uncertainty is under <b>Advanced Controls</b> on Election Simulation.
              </p>
            </Step>

            { /* 2 */}
            <Step className={styles.stepElement}>
              <h3>Candidate Quality</h3>
              <p>
                Candidate quality refers to the factors other than party membership and ideology that influence voters'
                opinion of a candidate: name recognition, experience, integrity, communication skills, campaign skills,
                media coverage, and, sadly, money. Voters may choose a higher quality but less representative candidate
                over one that is closer ideologically.
              </p>
              <p>

                All simulations on this site are done with candidates of equal quality. An analogy would be the
                100-meter dash where some lanes get a 20-meter head start and runners get to choose their lane. I want
                to analyze which lanes might have an advantage. Under our current system the most partisan and often
                divisive "lanes", lane 1 and lane 8, almost always win. This makes it hard to recruit qualified
                candidates to the "less divisive lanes".
              </p>

              <p>
                Even when a candidate in the middle lanes is great personally, they are often not perceived as viable.
                Candidates in these lanes have trouble raising money and generating the media coverage that is the
                lifeblood of a political campaign.
              </p>

              <p>
                It may be tempting to adjust the quality of the candidates to generate good outcomes under a
                given system. That is unrealistic. There is no data to support the idea that the divisive forces
                that dominate our political world will suddenly be unable to produce quality candidates or money.
              </p>
              <p>
                In almost all simulations I hold candidate quality equal for all candidates. One goal is to understand
                if there are ideological positions that are more or less favored under each voting rule. It's somewhat
                analogous to a 100-meter dash, but certain lanes get a 20-meter head start, or penalty, and every racer
                gets to choose their lane. If an ideological position is favored, it will be easier to attract
                high-quality candidates, media coverage and donors.
              </p>

              <p>
                OK, you've been warned but you want to see the effect of candidate quality. Double-click on a
                candidate. A popup window will appear with a "quality" slider for that candidate. Adjusting this will
                change the election results in real-time. You can also change the candidate's party.
              </p>

              <p>
                You may notice that the straight lines of voter preference become curved. That's correct behavior and
                caused by using Euclidean (straight line) distances.
              </p>

            </Step>
          </div>
          <div>
            { /* 3 */}
            <div className={styles.textContent}>

              <h3>Population Distributions</h3>
              <p>
                What is the ideology of the U.S. population? The spatial model of voter preference is widely accepted
                as the best model of voter behavior, but you have to choose a distribution of the voters. The most
                common
                method is to use a normally distributed (gaussian) population in some number of dimensions. These
                models do not account for political party affiliation, which is definitely a part of the political
                landscape in the United States.
              </p>
              <h3>Two-Dimensional Distributions</h3>
              <p>
                The two-dimensional distributions shown for most election simulations on this website are not intended
                to correspond to any specific district or state. They are intended to illustrate how the voting rules
                perform in multiple dimensions. I chose social and fiscal policy as examples of how such a
                two-dimensional
                ideology space might play out. I'm happy to discuss how the voters are distributed, but it's more detail
                than is appropriate for this context.
              </p>


              <h3>One-Dimensional Distributions</h3>
              <img className={styles.pewIdeology} alt="ideological distribution" src={"images/pew-ideology.png"}/>
              <p className={styles.caption}>
                Source data taken from the 2017 PEW typology survey, Phase-A. N=2504. Respondents are asked 9 questons with
                a "liberal" or "conservative" response. Ideology is calculated as the number of conservative answers minus
                the number of liberal answers, yielding an ideology from -9 to 9.
              </p>
              <p>

              </p>
              <p>
                When we say that American politics is polarized, we are also implying that it is dominated by a single
                dimension. There is a dominant left-right axis with the parties forming the poles at either end of these
                axes. There are as many dimensions to American politics as there are voters, but one-dimensional
                analysis can also capture most of the dynamics of the system.
              </p>
              <p>
                There is also far better polling information available in a single dimension. First, there is the
                ultimate polling information: election results. These are available with both detail and "perfect"
                accuracy. Second, there is polling information for how voters are distributed along this dominant
                left-right axis.
              </p>
              <p>
                The PEW typology data provides a useful insight into the makeup of the electorate. The survey makes two
                statements, and the respondent is asked which statement they agree with. If they indicate they don't
                agree with either statement, the interviewer asks them if there is one they agree with more than the
                other. For example:
              </p>
              <ul>
                <li> Government is almost always wasteful and inefficient.</li>
                <li> Government often does a better job than people give it credit for.</li>
              </ul>

              <p>
                The results of the survey show that there is significant overlap between Democratic and Republican
                voters, where ideological preferences and party identification are not perfectly aligned.
              </p>

              <p>
                Transforming the PEW data into normal distributions and rounding only slightly yields:
              </p>

              <div style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}>
                <table>
                  <tbody>
                  <tr key={1}>
                    <th key={1}> Party</th>
                    <th key={2}> Mean</th>
                    <th key={3}> Sigma</th>
                  </tr>
                  <tr key={2}>
                    <td key={1}> Republican</td>
                    <td style={{textAlign: 'center'}} key={2}> 1</td>
                    <td style={{textAlign: 'center'}} key={3}> 1</td>
                  </tr>
                  <tr key={3}>
                    <td key={1}> Democratic</td>
                    <td style={{textAlign: 'center'}} key={2}> -1</td>
                    <td style={{textAlign: 'center'}} key={3}> 1</td>
                  </tr>
                  <tr key={4}>
                    <td key={1}> Independent</td>
                    <td style={{textAlign: 'center'}} key={2}> 0</td>
                    <td style={{textAlign: 'center'}} key={3}> 1.3</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <p>
                I have made a deliberate decision to model the population symmetrically, even though Democrats have
                higher party registration by 32% to 27% in the PEW data. I also chose to model party affiliation as
                "lean Democrat" or "lean Republican" as opposed to actual party membership. Estimates of swing voters
                range from 6% to 20% depending on the methodology used. I model voters as 40% leaning Democrat and 40%
                leaning Republican with 20% being modeled as Independents.
              </p>
              <p>
                Note that Independents are not really "swing voters" or "centrists". Independents are spread across the
                political spectrum. Many will be so far to the left or right that though they may not consider
                themselves a part of either party, they are very unlikely to vote for the other party.
              </p>
              <h3>Modeling Voters in an Individual District</h3>
              <p>
                I use presidential election results and data from the Cook Political Report to estimate a partisan
                lean for each district. The population is then shifted and balanced to replicate the lean for
                the district. Shifting alters the mean for each population group, moving them in the direction of
                the partisan lean. Balancing alters the proportion of Democrats and Republicans.
              </p>
              <h3>Modeling Election Outcomes in an Individual District</h3>
              <p>
                To model district-by-district elections, I draw three candidates for each party from a distribution
                centered on the party. These candidates are chosen to represent a reasonable set of ideological choices
                for candidates from each party. I also add one candidate from the majority party at the median of all
                voters. This "median" candidate would not be expected to be present in most actual elections, simply
                because they would lose.
              </p>

              <p>
                The outcomes show that the unrepresentative, partisan, and potentially divisive candidates dominate
                results when primary-based elections are used. These results are very similar to the best estimates
                of representativeness for our actual representatives.

              </p>

              <p>
                The "median" candidates are included specifically to demonstrate that they lose under primary-based
                systems
                and win under Consensus Voting using the same voters, candidates, and voter preferences.
              </p>

            </div>
          </div>
          <div>
            <Step className={styles.stepElement}>
            </Step>
          </div>

          <div>
            <div style={{
              minHeight: "30vh"
            }}>
            </div>
          </div>
        </div>
    )
  }
}

export default SimulationTutorialStory
