import PopulationGroup from "./PopulationGroup";
import {Voter} from "./Voter";
import {Democrats, Party, Republicans} from "./Party";
import Random from "./Random";

abstract class Population {
  abstract samplePopulation: (nVoters: number, lean: number) => Array<Voter>
}

class CombinedPopulation extends Population {
  populationGroups: Array<PopulationGroup>
  summedWeight: number

  constructor(populationGroups: Array<PopulationGroup>) {
    super()
    this.populationGroups = populationGroups
    this.summedWeight = 0
    populationGroups.forEach((p) => this.summedWeight += p.weight)
    populationGroups.forEach((p) => p.weight = p.weight / this.summedWeight)
  }


  samplePopulation = (nVoters: number, lean: number): Array<Voter> => {
    let weights = this.populationGroups.map(p => p.weight)
    let dIdx = this.populationGroups.findIndex(p => p.party === Democrats)
    let rIdx = this.populationGroups.findIndex(p => p.party === Republicans)

    if (lean !== 0 && rIdx >= 0 && dIdx >= 0) {
      weights[dIdx] -= lean / 2
      weights[rIdx] += lean / 2
    }

    let voters = this.populationGroups.flatMap((pg: PopulationGroup, idx: number) => pg.sampleVoters(Math.round(weights[idx] * nVoters)))
    voters.forEach(v => {
      v.ivec[0] += lean
      if (v.ivec.length === 2)
        v.ivec[1] += lean
    })
    return voters
  }
}


class PopulationND extends Population {
  dims: number[][]
  raw_ivec: number[][]
  sin_t: number
  cos_t: number
  partyRand: number[] = []
  groups: PopulationGroup[]


  constructor(dims: number[][],
              nVoters: number,
              groups: PopulationGroup[],
              theta: number) {
    super()
    this.dims = dims
    this.raw_ivec = []
    this.sin_t = Math.sin(theta)
    this.cos_t = Math.cos(theta)
    this.groups = groups

    let rand = new Random("Consensus")
    for (let i = 0; i < nVoters; ++i) {
      let x_raw = rand.nextNormal() * this.dims[0][0] + this.dims[0][1]
      let y_raw = rand.nextNormal() * this.dims[1][0] + this.dims[1][1]
      this.raw_ivec.push([x_raw, y_raw])
      this.partyRand.push(rand.nextFloat())
    }
  }


  partyForIvec = (voterIvec: number[], index: number): Party => {
    let probabilityRaw = this.groups.map(pg => pg.probability(voterIvec) * pg.weight / pg.ivec[0][1])
    let probabilitySum = 0
    probabilityRaw.forEach(p => {
      probabilitySum += p
    })
    let pick = this.partyRand[index]
    let probability = probabilityRaw.map(p => p / probabilitySum)
    let i = 0
    while (pick > 0) {
      pick -= probability[i]
      if (pick < 0)
        return this.groups[i].party
      i += 1
    }
    return this.groups[0].party
  }

  samplePopulation = (nVoters: number, lean: number): Array<Voter> => {
    let voters: Voter[] = []

    // let nVisible = 0
    // let scale = rangesAndGradients.scale
    for (let i = 0; i < nVoters; ++i) {
      let x_raw = this.raw_ivec[i][0]
      let y_raw = this.raw_ivec[i][1]

      let x_adj = (x_raw + lean) * this.cos_t - y_raw * this.sin_t
      let y_adj = (x_raw + lean) * this.sin_t + y_raw * this.cos_t

      // if (Math.abs(x_adj) < scale && Math.abs(y_adj) < scale)
      //   ++nVisible
      // if (i < 10) {
      // console.log(`x_raw ${x_raw}`)
      // console.log(`y_raw ${y_raw}`)
      // console.log(`x_adj ${x_adj}`)
      // console.log(`y_adj ${y_adj}`)
      // }
      let ivec = [x_adj, y_adj]
      let party = this.partyForIvec(ivec, i)
      voters.push(new Voter(ivec, party))
    }
    // console.log(`nRepublicans: ${voters.filter(v => v.party === Republicans).length}`)
    // console.log(`nDemocrats: ${voters.filter(v => v.party === Democrats).length}`)
    // console.log(`nIndependents: ${voters.filter(v => v.party === Independents).length}`)
    // console.log(`nVisible: ${nVisible}, ${100 * nVisible / nVoters}%`)
    return voters
  }
}

export {Population, PopulationND}
export default CombinedPopulation
