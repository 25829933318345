import {DSVRowArray, DSVRowString} from "d3";
import {Democrats, Independents, Party, partyForCode, Republicans} from "./Party";
import FIPSData, {fipsData} from "./FIPSData";
import blockTimer from "./BlockTimer";
import {rangesAndGradients} from "../ui/elements/RangesAndGradients";

class ElectionTypeResult {
  party: Party
  ideology: number
  representation: number

  constructor(party: Party, ideology: number, representation: number) {
    this.party = party
    this.ideology = ideology
    this.representation = representation
  }
}

class SimulationResult {
  district: string
  medianVoterIdeology: number
  actualRepresentation: number
  results: Array<ElectionTypeResult>

  constructor(simResult: DSVRowString) {
    this.district = simResult.district!
    this.medianVoterIdeology = +simResult.medianVoterIdeologyStr!
    this.actualRepresentation = +simResult.actualVoterSatisfactionStr!
    this.results = []

    let prefixes = ["primary", "irPrimary", "h2hPrimary", "instantRunoff", "headToHead", "final5"]

    prefixes.forEach(p => {
      let party = simResult[`${p}PartyStr`]!
      let ideology = simResult[`${p}IdeologyStr`]!
      let representation = simResult[`${p}SatisfactionStr`]!

      let r = new ElectionTypeResult(this.partyFor(party), +ideology, +representation)
      this.results.push(r)
    })
  }

  partyFor = (partyName: string): Party => {
    if (partyName === "R") return Republicans
    if (partyName === "D") return Democrats
    return Independents
  }
}

class CombinedSimulationResults {
  results: Array<SimulationResult>
  indices: { [prefix: string]: number }
  actualRepresentation: number
  medianVoterIdeology: number


  constructor(results: Array<SimulationResult>) {
    this.results = results
    let prefixes = ["primary", "irPrimary", "h2hPrimary", "instantRunoff", "headToHead", "final5"]
    this.indices = {}
    prefixes.forEach((p: string, i: number) => {
      this.indices[p] = i
    })
    this.actualRepresentation = this.results[0].actualRepresentation
    this.medianVoterIdeology = this.results[0].medianVoterIdeology
  }

  sampleIdeology = (prefix: string): number => {
    let index = this.indices[prefix]!
    return this.results[0].results[index].ideology
  }

  ideology = (prefix: string): number => {
    let index = this.indices[prefix]!
    let sum = this.results.map(x => x.results[index].ideology).reduce((a, b) => a + b, 0.0)
    return sum / this.results.length
  }

  representation = (prefix: string): number => {
    let index = this.indices[prefix]!
    let sum = this.results.map(x => x.results[index].representation).reduce((a, b) => a + b, 0.0)
    return sum / this.results.length
  }
}

// congress,chamber,icpsr,state_icpsr,district_code,state_abbrev,party_code,occupancy,last_means,bioname,bioguide_id,
// born,died,nominate_dim1,nominate_dim2,nominate_log_likelihood,nominate_geo_mean_probability,nominate_number_of_votes,
// nominate_number_of_errors,conditional,nokken_poole_dim1,nokken_poole_dim2

class DWNominateRow {
  congress: number
  chamber: string
  districtCode: number
  district: string
  representativeName: string
  partyCode: number
  nominateDim1: number
  nominateDim2: number
  state: string
  dw: DSVRowString
  party: Party

  constructor(dw: DSVRowString) {
    this.congress = +dw.congress!
    this.chamber = dw.chamber!
    this.districtCode = +dw.district_code!
    this.state = dw.state_abbrev!
    this.district = dw.state_abbrev! + "-" + ("000" + this.districtCode).slice(-2)
    this.representativeName = dw.bioname!
    this.partyCode = +dw.party_code!
    this.party = partyForCode(this.partyCode)
    this.nominateDim1 = +dw.nominate_dim1!
    this.nominateDim2 = +dw.nominate_dim2!
    this.dw = dw
  }
}


class Congress {
  house = new Map<string, DWNominateRow>()
  senate = new Map<string, DWNominateRow>()
  DWNominate: Array<DSVRowString>
  congress: number

  constructor(DWNominate: Array<DSVRowString>) {
    this.DWNominate = DWNominate
    this.congress = +DWNominate[0].congress!
    DWNominate.forEach((dw: DSVRowString) => {
      if (dw.chamber === "House") {
        let row = new DWNominateRow(dw)
        this.house.set(row.district, new DWNominateRow(dw))
      } else if (dw.chamber === "Senate") {
        let seat = `${dw.state_abbrev}-1}`
        if (this.senate.has(seat))
          seat = `${dw.state_abbrev}-2}`
        this.senate.set(seat, new DWNominateRow(dw))
      }
    })


    let districts = Array.from(this.house.values())
    districts.forEach((d) => {
      if (d.nominateDim1 === 0)
        this.fixupNoData(d.district, d.party)
    })
  }


  // assigns an ideology equal to the mean for their party to two representatives for which there is no data.
  fixupNoData = (district: string, party: Party) => {
    let districts = Array.from(this.house.values())
    let sum = 0
    districts = districts.filter((d) => d.party === party && d.district !== district)
    districts.forEach(d => { sum += d.nominateDim1 })
    let ideology = sum / districts.length
    this.house.get(district)!.nominateDim1 = ideology
  }


}

class DWNominateData {
  congresses = new Map<number, Congress>()

  constructor(DWNominate: DSVRowArray) {
    let rows: DSVRowString[] = []
    DWNominate.forEach((dw, i) => {
      if (i + 1 === DWNominate.length || DWNominate[i + 1].congress !== DWNominate[i].congress) {
        this.congresses.set(+dw.congress!, new Congress(rows))
        rows = []
      } else {
        rows.push(dw)
      }
    })

    // for (let congress = 1; congress <= 117; ++congress) {
    //   let rows = DWNominate.filter(r => +(r.congress!) === congress)
    //   this.congresses.set(congress, new Congress(rows))
    // }
  }

  house = (congress: number, district: string): DWNominateRow => {
    if (!this.congresses.has(congress)) {
      console.log(`no congress for ${congress}`)
      return new DWNominateRow({})
    }
    if (!this.congresses.get(congress)?.house.has(district)) {
      console.log(`no district for ${district}`)
      return new DWNominateRow({})
    }
    return this.congresses.get(congress)?.house.get(district)!
  }

  senate = (congress: number, district: string): DWNominateRow => {
    return this.congresses.get(congress)?.senate.get(district)!
  }
}

type DVRRow = {
  district: string
  incumbent: string
  lean: string
  dPct1: string
  rPct1: string
  dPct2: string
  rPct2: string
}

class DistrictVotingRecord {
  district: string
  incumbent: string
  lean: string
  dPct1: number
  rPct1: number
  dPct2: number
  rPct2: number
  observedLean: number
  geoId: number

  constructor(dvr: DVRRow) {
    this.district = dvr.district
    this.incumbent = dvr.incumbent
    this.lean = dvr.lean
    this.dPct1 = +dvr.dPct1
    this.rPct1 = +dvr.rPct1

    if (dvr.dPct2 === "null") {
      this.dPct2 = +dvr.dPct1
      this.rPct2 = +dvr.rPct1
    } else {
      this.dPct2 = +dvr.dPct2
      this.rPct2 = +dvr.rPct2
    }
    let l1 = this.rPct1 - this.dPct1
    let l2 = this.rPct2 - this.dPct2
    this.observedLean = (l1 + l2) / 2
    this.geoId = fipsData.geoId(this.district)
  }
}


class CombinedDistrictData {
  DWNominate: DWNominateData
  simulationResults: { [district: string]: CombinedSimulationResults } = {}
  sampleCongress: { [district: string]: CombinedSimulationResults } = {}

  dvrByGeoId: { [geoId: number]: DistrictVotingRecord } = {}
  dvrByName: { [district: string]: DistrictVotingRecord } = {}

  districts: string[]
  sortedDVR: DistrictVotingRecord[]
  fipsData = new FIPSData()

  constructor(dvr_dsv: DSVRowArray,
              DWNominate: DSVRowArray,
              simulationResults: DSVRowArray,
              sampleCongress: DSVRowArray) {

    let districtSet = new Set(simulationResults.map(sr => sr.district!))
    this.districts = Array.from<string>(districtSet)

    // stooopid typescript sorts in place.  cretins.
    this.sortedDVR = dvr_dsv.map(d => new DistrictVotingRecord(d as DVRRow))
    this.sortedDVR.sort((a, b) => a.observedLean - b.observedLean)

    this.sortedDVR.forEach((dvr) => {
      this.dvrByGeoId[dvr.geoId] = dvr
      this.dvrByName[dvr.district] = dvr
    })

    this.DWNominate = blockTimer(() => new DWNominateData(DWNominate), "construct DWNominate")

    simulationResults.forEach(r => {
      let district = r.district!
      let rr: Array<SimulationResult> = []
      if (this.simulationResults[district]) {
        rr = this.simulationResults[district]!.results
      }
      this.simulationResults[district!] = new CombinedSimulationResults(rr.concat(new SimulationResult(r)))
    })
    sampleCongress.forEach(r => {
      this.sampleCongress[r.district!] = new CombinedSimulationResults([new SimulationResult(r)])
    })
  }

  dvrForGeoId = (geoId: number): DistrictVotingRecord => {
    return this.dvrByGeoId[geoId]
  }
  dvrForName = (name: string): DistrictVotingRecord => {
    return this.dvrByName[name]
  }
  dvrForRank = (rawRank: number): DistrictVotingRecord => {
    let index = Math.min(rawRank, this.districts.length - 1)
    return this.sortedDVR[Math.floor(index)]
  }

  houseIdeologyForDistrict = (congress: number, district: string): number => {
    return +this.DWNominate.house(congress, district).nominateDim1 * rangesAndGradients.dwNominateScale
  }

  has = (district: string): boolean => {
    return district in this.simulationResults
  }

  actualRepresentation = (district: string): number => {
    return this.simulationResults[district].actualRepresentation
  }
  medianVoterIdeology = (district: string): number => {
    return this.simulationResults[district].medianVoterIdeology
  }
  simulationRepresentation = (simType: string, district: string): number => {
    return this.simulationResults[district].representation(simType)
  }
  sampleIdeology = (simType: string, district: string): number => {
    // this used to divide the ideology by 30 to make it line up with the nokken-based +/- 100 ideology numbers.
    // now it is just standard deviations.
    return this.sampleCongress[district].ideology(simType)
  }
  logCount = 0
  sampleRepresentation = (simType: string, district: string): number => {
    if (this.logCount < 10 && simType === "primary") {
      this.logCount += 1
    }
    return this.sampleCongress[district].representation(simType)
  }
}

export {DistrictVotingRecord}
export default CombinedDistrictData