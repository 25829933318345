import * as d3 from "d3";
import Point from "./Point";
import {RenderItem} from "./RenderItem";


class ImageSpec {
  id: string
  path: string
  location: Point
  width: number
  height: number

  constructor(id: string, path: string, location: Point, width: number, height: number) {
    this.id = id
    this.path = path
    this.location = location
    this.width = width
    this.height = height
  }

  render = (svg: d3.Selection<any, any, any, any>) => {
    svg.append('image')
        .attr("id", this.id)
        .attr('xlink:href', this.path)
        .attr('width', this.width)
        .attr('height', this.height)
        .attr('x', this.location.x)
        .attr('y', this.location.y)
        .attr("opacity", 0)

    svg.selectAll(`#${this.id}`)
        .transition("image-fade-in")
        .duration(1000)
        .attr("opacity", 1)
  }
  clear = (svg: d3.Selection<any, any, any, any>): void => {
    svg.selectAll(`#${this.id}`).remove()
  }
}

class ImageController extends RenderItem {
  svg: d3.Selection<any, any, any, any>
  images: Array<ImageSpec> = []
  dirty = false
  id: string;

  constructor(svg: d3.Selection<any, any, any, any>, id: string) {
    super()
    this.svg = svg
    this.id = id
  }

  setSVG = (svg: d3.Selection<any, any, any, any>) => {
    this.dirty = true
    this.svg = svg
  }

  clear = (): void => {
    this.images.forEach(i => i.clear(this.svg))
    this.images = []
    this.dirty = false
  }

  render = (): void => {
    if (!this.dirty) return
    this.dirty = false
    this.images.forEach(i => i.render(this.svg))
  }

  addImage(id: string, path: string, location: Point, width: number, height: number) {
    this.images.push(new ImageSpec(id, path, location, width, height))
    this.dirty = true
  }

}

export default ImageController