import Article from "../content/Article/Article";
import styles from "../content/Article/Article.module.scss"

// import styles from "./VotingRequirements.module.scss"

export function VotingRequirements() {
  return (
      <Article title={"Principles of Reform"}
               subtitle={"Reform must be purposeful, not just different."}>
        <div className={styles.textContent}>
          <p>
            What is the goal of reform? "Everybody" agrees things are messed up, but they may all have different ideas
            about what the problem is and very different ideas about how to fix it. My goal is to fix polarization and
            dysfunction by changing the election system to reward candidates who represent the values of all of their
            voters.
          </p>
          <p>
            I focus on a few simple rules. These are not the mathematical properties often used to describe strengths
            and weaknesses of voting systems. They are attributes we need our voting system to have to make our
            democracy successful.
          </p>
          <p>
            A system that satisfies these rules will fix our election system, eliminate toxic polarization, and allow
            our government to function. Conversely, failure to fix these problems will ensure that toxic polarization
            not only continues but gets worse.
          </p>

          <ul className={styles.list}>

            <li>
              <h6 className={styles.bold}>
                Give every voter a say.
              </h6>
            </li>

            <li>
              <h6 className={styles.bold}>
                Voters should be able to submit an honest ballot.
              </h6>
            </li>

            <li>
              <h6 className={styles.bold}>
                Candidates must be rewarded for representing all of the voters.
              </h6>
            </li>

          </ul>

          <h3>A Meaningful Say</h3>

          <p>
            Toxic polarization exists because it works. If it were not an effective campaign strategy, it would be
            quickly punished at the ballot box and vanish. Instead it has thrived. Toxic Polarization thrives because
            the people who are targeted, voters who prefer the minority party in each district have no real say in
            election outcomes.
          </p>

          <p>
            The ability to vote is not enough. Most state and national elections have a dominant party and any voter who
            doesn't participate in that primary has no say in the only decision that matters. This creates the illusion
            of democracy when many voters have no ability to influence the outcome. The result is a government we have
            today: unrepresentative and paralyzed by toxic polarization.
          </p>

          <p>
            Having a meaningful say is more than just a question of fairness, it is essential to create a functional,
            and stable government that represents the voters. Every voter needs to be able to have a say in all of the
            decisions that matter.
          </p>

          <p>
            Is our democracy functioning when so many voters are excluded from meaningful participation?
          </p>

          <h3>Honest Ballots</h3>
          <p>
            Voters must be able to vote honestly. Sometimes a voter could have more influence on an election by
            submitting a ballot that's not a true reflection of their preferences. In many states, a voter could vote in
            the opposition primary to try to have more influence. Or, a voter might list a less divisive candidate from
            the opposition party ahead of their true preference in their own party in an <a href={"/IRV2D"}>Instant
            Runoff</a> election. Instead, voters must know that it is their interest to submit an honest ballot.
          </p>

          <p>
            Voters don't really have a say if they can't submit an honest ballot. Many voters will distrust advice to
            vote strategically and understandably distrust any election process that requires strategic voting.
          </p>

          <h3>Candidate Motivations</h3>
          <p>
            Toxic behavior by candidates and elected officials is not an accident. Candidates behave the way they do
            because the election system rewards it. Instead, we need an election system that rewards candidates who
            represent all of their voters and reject divisive and toxic language.
          </p>

          <p>
            Toxic behavior works because voters in the broader electorate have no meaningful say in the decisions that
            matter. There is no incentive for candidates to represent all of their voters unless voters in the minority
            party have the ability to express a meaningful preference for a less divisive candidate in the other party.
          </p>

          <p>
            The election process must stop rewarding toxic behavior. If you want to change the behavior, you must change
            the system.
          </p>

          <h3>Conclusions</h3>

          <p>
            No voting system is perfect; all will fail at one or more of these requirements in some circumstances. The
            question is how often they will fail, under what circumstances, and what are the consequences of that
            failure. Our current system fails all of these requirements in nearly every election. Failure has become so
            common and so expected that many good candidates never even enter the race.
          </p>

          <p>
            Elected officials are reluctant to change the rules that put them into power. The obvious path is through
            ballot initiatives, but there are a limited number of places where that path is viable. The hope is that
            good governance is contagious. If we can pass meaningful reform through ballot initiatives and that reform
            reduces polarization and creates a functional government, we can pursue options through legislatures.
          </p>

          <p>
            Conversely, if we pass voting reforms that do not make a significant difference, we will discredit election
            reform as a means for improving our democracy.
          </p>

          <p>
            Other areas of the website describe various voting rules and the circumstances where they fail to
            satisfy these requirements. It is up to you to judge how relevant these failure modes are: How often is
            this scenario likely to happen? How much impact does the failure have on the outcome? Finally, will the
            voting rule solve the problems of polarization and dysfunction that are crippling our nation? These
            questions are beyond the mathematics, you must answer them.
          </p>

        </div>
      </Article>
  )
}

// <a className={styles.inlineLink} href='/VotingSystems' > preferences</a>
