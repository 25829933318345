class UnitGaussian {

  cumulativeNormalDistribution = (xRaw: number): number => {
    let neg: number = (xRaw < 0) ? 1 : 0
    let k = 1.0 / (1.0 + 0.2316419 * Math.abs(xRaw))
    let y = ((((1.330274429 * k - 1.821255978) * k + 1.781477937) * k - 0.356563782) * k + 0.319381530) * k
    y = 1.0 - 0.398942280401 * Math.exp(-0.5 * (xRaw * xRaw)) * y
    return (1 - neg) * y + neg * (1 - y)
  }

  interpolate = (x0: number, y0: number, x1: number, y1: number, yTarget: number, fcn: (x: number) => number): number => {
    let dydx = (y1 - y0) / (x1 - x0)
    let deltaY = yTarget - y0
    let deltaX = deltaY / dydx
    let xTarget = x0 + deltaX
    let yActual = fcn(xTarget)
    if (Math.abs(yActual - yTarget) > 1e-5) {
      let x2 = (x0 + xTarget) / 2
      let y2 = fcn(x2)
      return this.interpolate(xTarget, yActual, x2, y2, yTarget, fcn)
    } else
      return xTarget
  }

  pdf = (x: number): number => {
    return 1 / Math.sqrt(2 * Math.PI) * Math.exp(-.5 * (x * x))
  }

  generateIdealSamples = (nSamples: number): Array<number> => {
    let samples = Array<number>()
    let start = 5.0
    let x = -start
    let end = start
    let xStep = 1e-5

    while (x < end && samples.length < nSamples) {
      let y = this.cumulativeNormalDistribution(x)
      if (y * nSamples > samples.length + 1) {
        let x0 = x - xStep
        let y0 = this.cumulativeNormalDistribution(x0)
        let xExact = this.interpolate(x0, y0, x, y, (samples.length + 1) / nSamples, this.cumulativeNormalDistribution)
        samples.push(xExact)

        let n = samples.length
        xStep = (n > 1) ? samples[n - 1] - samples[n - 2] : 1e-5
        x = xExact + xStep
      } else {
        x += xStep
      }
    }
    return samples
  }
}

export default UnitGaussian