import React from 'react'
import styles from './ArticleIntro.module.scss'

interface Props {
  title: string
  subtitle?: string
  teaser?: string
  link: string
  imageUrl: string
  className?: string
}

const ArticleIntro = (props: Props) => {
  return (
    <div className={props.className + " " + styles.mainBox}>
      <a href={props.link}>
        <div className={styles.content}>
          <div className={styles.textContent}>
            <p className={styles.title}>{props.title}</p>
            <p className={styles.subtitle}>{props.subtitle}</p>
            <p className={styles.teaser}>{props.teaser}</p>
          </div>
          <div className={styles.imageDiv}>
            <img src={props.imageUrl} alt={props.title} className={styles.imageContent}/>
          </div>
        </div>
      </a>
    </div>
  )
}

export default ArticleIntro