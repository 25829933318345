import {BarChartLayout} from "./BarChartLayout";
import CategoryAxis from "./CategoryAxis";
import Point from "./Point";
import * as d3 from 'd3'

class BarChartAxis extends CategoryAxis {
  barChart: BarChartLayout
  labels: Array<string>
  id: string
  topLeft: Point
  svg: d3.Selection<any, any, any, any>

  constructor(id: string, svg: d3.Selection<any, any, any, any>, barChart: BarChartLayout, labels: Array<string>, title: string) {
    super(id, svg, labels, barChart.bottomLeft, barChart.axisLength, title)
    this.svg = svg
    this.barChart = barChart
    this.labels = labels
    this.id = id
    this.topLeft = barChart.bottomLeft
  }
}

export {BarChartAxis}