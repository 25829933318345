import React from 'react'
import styles from './SectionTitle.module.scss'

interface Props {
  caption?: string
  title?: string
}

const SectionTitle = (props: Props) => {
  return (
    <div className={styles.content}>
      {props.title !== undefined &&
        <h2 className={styles.title}>
          {props.title}
        </h2>
      }
      {props.caption !== undefined &&
        <p className={styles.supertext}>
          {props.caption}
        </p>
      }
    </div>
  )
}

export default SectionTitle