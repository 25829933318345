import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class DefiningReality extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }


  render() {
    // let mdash = "\u2014"

    return (
        <Article title={"Defining Reality"}
                 subtitle={"We must be able to talk to each other."}>
          <div className={styles.textContent}>
            <p>
              Elections do more than elect a leader; they amplify and legitimize the reality candidates embrace.
              Winning candidates get a platform to spread their views and amplify like-minded voices in the media
              through their words and actions. In this way, we use elections to define our collective reality.
            </p>
            <p>
              In a factional democracy, candidates are wholly beholden to their faction, and this extends beyond the
              policy positions they take and into the reality they embrace and promote. These separate factional
              realities are so different one wonders if they aren't living in parallel universes. How can two people
              have such
              vastly different views of the same situation? Is Russia really defending itself by invading Ukraine?
            </p>
            <p>
              Even the most bizarre conspiracy theories have become mainstream topics of conversation. When Majorie
              Taylor Green began saying that the California wildfires of 2021 were started by "space solar generators,"
              that quickly morphed into "Jewish space lasers." If Green had not been a Member of Congress, nobody would
              have noticed, but as a U.S. Congresswoman, anything she says becomes news. No matter how stupid it is.
            </p>
            <p>
              Compromise is difficult and maybe not even always possible. But these contradictory realities make the
              process of finding the compromises necessary for a peaceful society almost impossible. How can a leader
              justify compromising when they have convinced their faction that the other side is morally bankrupt?
            </p>
            <p>
              When elections are decided by Consensus Voting, candidates can no more embrace a factional conspiracy
              theory or extremist media than they can adopt extremist policy positions. At least not if they want to
              win. Instead, they must adopt and promote a more balanced view of the news, just like they must balance
              the ideology of their voters. A candidate who embraces a conspiracy theory endorsed by one faction but
              which offends everyone else would risk defeat by a quality candidate who rejected conspiracy theories.
            </p>
            <p>
              Censorship cannot be a part of any free society, but perhaps all of the voters should be able to
              participate in the process of defining which reality our elections promote and endorse. Should just a
              small slice of the population be able to define our collective reality by controlling the output of the
              primary process? Or should all of the voters get to participate in defining our reality?
            </p>
            <p>
              Nothing confers legitimacy or relevance like winning elections. Election rules that foster divisive
              candidates have promoted the dueling divisive realities our society inhabits. That toxicity has crept out
              of the political sphere and infected our daily lives, making every conversation fraught. By rewarding
              candidates who balance the views of all voters, Consensus Voting would stop legitimizing the voices on the
              fringe.
            </p>
            <p>
              If you choose democracy, then you must believe in the collective wisdom of the people, however imperfect.
              But that collective wisdom can only emerge and grow by including everyone when we define it.
            </p>
          </div>
        </Article>
    )
  }
}




















