import Article from "../content/Article/Article";
import styles from "../content/Article/Article.module.scss"

// import styles from "./VotingRequirements.module.scss"

export function Top5() {
  return (
      <Article title={"The Top Five Primary"}
               subtitle={"The what and why of a Top-5 primary."}>
        <div className={styles.textContent}>
          <h3>A Top-5 Primary</h3>

          <p>
            You may have noticed that the simulations on this site all start with a Ranked-Choice general election with
            5 candidates. These elections assume that candidates qualify for the general election by competing in a
            Top-5 primary.
          </p>
          <p>
            The mechanics of the Top-5 primary are fairly simple: All candidates from all parties compete in the Top-5
            primary with 5 candidates regardless of party advancing to the general election. There is no guarantee about
            party representation in the general election, either party may not have any candidates in the top 5 or they
            may have several.
          </p>
          <p>
            The Top-5 primary uses simple plurality voting where each voter can select a single candidate. There are
            other methods for conducting this Top-5 primary that arguably produce better (more representative) results.
            I'm not recommending them at this time simply to reduce the complexity for the voter.
          </p>

          <h3>Why Five?</h3>
          <p>
            More candidates means more choices, so why not more than five? Why have a limit at all?
          </p>
          <p>
            We have to start at the beginning: The goal of reforming the voting system is to improve representation and
            reduce toxic polarization. Divisive behavior works because voters from the minority party don't have a
            meaningful say in election outcomes and there is little penalty for disparaging them. The voters that are
            being attacked need to be able to respond at the ballot box.
          </p>
          <p className={styles.pullQuote}> If voters never express a preference for less toxic behavior, why would
            anything change? </p>
          <p>
            A ranked choice ballot gives a voter the chance to submit a ballot that lists their own party first and then
            less toxic candidates from the other party.
          </p>
          <p>
            For that to happen, voters from the each party must be able to vote for members from their own party and
            then less divisive candidates from the other party. i.e. listing their own party as their first and
            second choices and a less divisive, more inclusive candidate from the other party third.
          </p>
          <p>
            It is those critical third place votes that will elect less toxic candidates and reward inclusive
            behavior. Conversely, if voters never express a preference for less toxic behavior, why would anything
            change?
          </p>
          <p>
            OK, but why five? Voters are likely to list their own party first and then the opposition. If there are too
            many candidates, voters become less likely to rank deeply enough to list that less divisive candidate from
            the other party.
          </p>
          <p>
            There is also a limit to the number of candidates the voters can be expected to know and rate. Voters will
            likely use voter guides from trusted sources for less important contests, but it's helpful if they know
            something about the candidates in the major races.
          </p>
          <h3> Conclusions: </h3>
          <p>
            To end toxic polarization voters must reward less divisive behavior and punish toxic behavior at the ballot
            box. To do this, less divisive candidates need to be able to participate in the general election featuring
            more choices where all of the voters can have a say. Voters need the ability to list candidates from their
            own party and then less divisive candidates in the other party.
          </p>
          <p>
            Finally, having gone to all of the trouble to get a voters preference for a less divisive candidate, we
            need a voting rule that actually uses it. Instant Runoff-RCV ignores choices until all higher rankings are
            eliminated from the race. Since the minority party will almost always stay in the race until the end, those
            preferences for less toxic candidates in the other party will rarely matter.
          </p>
          <p>
            Consensus Voting uses every ranking on every ballot in every election. Voter choices within the opposition
            party will matter and reward less divisive behavior.
          </p>
          <p>
            A Top Five Primary gives the voter a chance to express more choices, but we need a voting rule that uses
            those choices.
          </p>
          <p>
            If you want to change the behavior, you must change the system.
          </p>
        </div>
      </Article>
  )
}

