import Article from "../content/Article/Article";
import styles from "../content/Article/Article.module.scss"

export function OtherVotingSystems() {
  return (
      <Article title={"Other Voting Systems"}
               subtitle={""}>
        <div className={styles.textContent}>
          <p>
            There isn't a shortage of voting systems, unfortunately they all suffer from problems to one degree or
            another. Election scientists have analyzed many of these and identified various mathematical problems with
            them; things like clone independence, independence of irrelevant alternatives, monotonicity, strategic
            voting incentives, etc. These mathematical flaws are important, if a voting system existed that had no
            mathematical flaws it would, in theory not have any flaws in the real world. The problem is that <b>every
          </b> voting system has flaws (including Head-to-Head). The question is how important are the flaws, are they
            likely to alter the outcome of elections and by how much, can they be exploited by candidates, and how often
            will these things happen?
          </p>
          <p>
            these questions and the answers to them are the ones that matter when choosing a voting system.
            unfortunately, these questions can only be partially answered by pure mathematics. in the end it comes down
            to human behavior and our estimates of how likely people are to vote strategically and how likely candidates
            are to encourage their voters to vote strategically.
          </p>
          <p>

            We will start with an approval voting scenario because it is the simplest system and illustrates one problem
            that is common to many voting systems of this type. Approval voting work for multi candidate races where
            each voter can vote for as many candidates as they want. The idea is that they vote for all of the
            candidates they "approve of". The candidate with the most votes wins. This is a simple system and if every
            voter is completely honest and uses the same metric for being close enough to their ideal to be approved of
            it would produce good results.

          </p>

          <p>
            Let's consider a situation where approval voting might not produce a good result. Consider a race in a
            situation that leans slightly to the Republican side. Presume that there are three candidates: Ruth and
            Robert being two Republicans and David a Democrat. Under plurality voting this would be a problem because
            Rene and Robert would split the Republican vote and David would be the plurality winner. At first glance,
            approval voting seems to solve the problem because voters that favor a Republican can vote for both Ruth and
            Robert.
          </p>

          <p>
            The problem is that for voters that prefer Ruth, they know that if they list Robert as their second choice
            that will enable Robert to win. By listing only Ruth, she will win. Of course, Robert's voters also know
            this and they also choose to <i>bullet vote </i>, or vote for only one candidate. If the race close between
            Republicans and Democrats and even a few voters choose to bullet vote that will allow David to win.
          </p>

          <p>
            Estimates of bullet voting vary from as few as 11% (French presidential exit poll; N=2,597) to as high as
            90%. Actual results are going to depend greatly on the field of candidates and the circumstances of the
            election, but even as few as 10% bullet votes would likely tip a close election toward the side of the
            party with fewer candidates. <i>But that's not the biggest problem with Approval voting.</i>
          </p>

          <p>
            In order to elect more moderate, more representative candidates it is necessary for members of each party
            to indicate a preference for more moderate members of the other party. In an election with multiple members
            of each party, it is highly unlikely that voters who prefer one party would choose to also vote for moderate
            members of the other party in significant numbers. By voting for a moderate member of the other party, they
            that candidate the same benefit they gave their preferred candidate within their own party.
          </p>

          <p>
            This comes down to a judgment call, if you think that significant numbers of Republicans are going to vote
            for moderate Democrats and vice-versa, then Approval Voting may have a chance. If you don't think that's
            going to happen then there is no chance for approval voting to reduce the polarization we are experiencing
            today.
          </p>

          <p>
            Star, Range and Borda counts all suffer from this same problem. For any of them to reduce polarization,
            voters from one party must vote for (to some degree) members of the other party at the potential expense of
            their own. Voters, candidates and political parties must also choose not to manipulate the election in a
            large variety of ways. Each of these systems is vulnerable to diluting the vote by elevating similar
            candidates and each of them reward having some form of informal primary to limit the number of candidates
            for each party. This informal primary is even more vulnerable to manipulation than our current official
            primary system.
          </p>

          <p>
            No election system is perfect, but some are better than others and we can clearly do better than this. If
            we go to the expense and difficulty of altering the election process, we should choose one that produces
            the best results, is the most difficult to manipulate, and solves the problem of polarization that is
            crippling our nation.
          </p>

        </div>
      </Article>
  )
}