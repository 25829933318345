import React from 'react'
import styles from './IRVTopics.module.scss'
import SectionTitle from '../../typography/SectionTitle'
import ArticleIntro from "../ArticleIntro/ArticleIntro";
import TopicHeader from "../TopicHeader/TopicHeader";

interface Props {

}

const ConsensusTopics = (props: Props) => {
  return (
      <div className={styles.content}>
        <SectionTitle
            title="Consensus Voting"
            caption="Taming the Leviathan"
        />
        <ul>

          <li>
            <ArticleIntro
                title="The Math of the Leviathan"
                subtitle={
                  "A gentle introduction to the mathematics of elections."
                }
                link={"/LeviathanMath"}
                imageUrl={"/images/LeviathanMath.png"}
            />
          </li>

          <li>
            <ArticleIntro
                title="Consensus Voting"
                subtitle={
                  "How to elect leaders who represent all of the voters."
                }
                link={"/ConsensusVoting"}
                imageUrl={"/images/ConsensusVoting.png"}
            />
          </li>

          <li>
            <ArticleIntro
                title="Fostering Compromise"
                subtitle={
                  "How Consensus Voting fosters compromise."
                }
                link={"/FosteringCompromise"}
                imageUrl={"/images/FosteringCompromise.png"}
            />
          </li>

          <li>
            <ArticleIntro
                title="How Consensus Voting Shapes Politics"
                subtitle={
                  "Stepping toward consensus at the ballot box."
                }
                link={"/ShapingPolitics"}
                imageUrl={"/images/ShapingPolitics.png"}
            />
          </li>

          <li>
            <ArticleIntro
                title="Gerrymandering"
                subtitle="How Consensus Voting Ends Gerrymandering As We Know It"
                link="/Gerrymandering"
                imageUrl={"/images/Gerrymandering.png"}
            />
          </li>

          <li>
            <ArticleIntro
                title="Defining Reality"
                subtitle="When we elect a candidate, we legitimize their reality."
                link="/DefiningReality"
                imageUrl={"/images/DefiningReality.png"}
            />
          </li>

          <li>
            <TopicHeader
                title="Final Thoughts"
                description="A few final thoughts, this is about more than just elections."
                link="/Conclusions"
            />
          </li>

        </ul>
      </div>
  )
}

export default ConsensusTopics
