import React from 'react'

interface Props {
  className?: string
}

const Footer = (props: Props) => {
  return (
      <div style={{
        minHeight: "30vh",
        borderTop: "1px solid black",
        marginTop: "5vh",
        marginLeft: "15%",
        marginRight: "15%",
        fontSize: "10pt",
      }}>
        <p>
          Copyright 2023, Robbie Robinette
        </p>
      </div>
  )
}

export default Footer