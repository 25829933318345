import Layout from "./Layout";
import Bubble from "./Bubble";
import Trajectory from "./Trajectory";
import DirectTrajectory from "./DirectTrajectory";

class MultiLayout extends Layout {
  filterPairs: Array<[(b: Bubble) => boolean, Layout]>
  constructor(filterPairs: Array<[(b: Bubble) => boolean, Layout]>) {
    super();
    this.filterPairs = filterPairs
  }
  reset = () => {
    this.filterPairs.forEach(pair => pair[1].reset())
  }
  route = (b: Bubble): Trajectory => {
    let i = 0
    while(i < this.filterPairs.length) {
      if (this.filterPairs[i][0](b)) {
        return this.filterPairs[i][1].route(b)
      }
      ++i
    }
    return new DirectTrajectory()
  }
  setTargetLocation<T extends Bubble>(b: T) {
    let i = 0
    while(i < this.filterPairs.length) {
      if (this.filterPairs[i][0](b)) {
        this.filterPairs[i][1].setTargetLocation(b)
        break
      }
      ++i
    }
  }
}

export default MultiLayout
