import React from 'react';
import styles from "../Introduction/ConsensusVoting.module.scss"
import Step from "../ui/components/Step";
import ElectionSimApp, {SimulationState} from "../ElectionSimulation/ElectionSimApp";
import {FirstPlaceFallacyApp} from "./FirstPlaceFallacyApp";
import Scroller from "../ui/elements/Scroller";
import ResultsBox from "../ElectionSimulation/ResultsBox";
import ElectionSimStoryBase from "../ElectionSimStoryBase/ElectionSimStoryBase";
import {DSVRowArray} from "d3";

interface Props {
  section: number
  progress: number
  simulationState?: SimulationState
}


class FirstPlaceFallacyStory extends ElectionSimStoryBase {

  mapAreas = [
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",


  ]
  visualizationAreas = [
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
  ]

  constructor(props: Props) {
    super(props);
    this.props = props
    this.scroller = new Scroller(this.onNewSection, this.onProgress)
    this.state = {
      section: 0,
      progress: 0,
      simulationState: undefined
    }
  }

  createApp = (
      visDivId: string,
      dvr: DSVRowArray,
      DWNominate: DSVRowArray,
      simulationResults: DSVRowArray,
      sampleCongress: DSVRowArray,
      usTopo: any): ElectionSimApp => {
    return new FirstPlaceFallacyApp(this.visualizationAreas[0], dvr, DWNominate, simulationResults, sampleCongress, usTopo, 20001, 2.5)
  }

  render() {
    let mdash = "\u2014"
    return (
        <div className={styles.content}>
          <div>
            {this.renderVisArea(styles.drawing1, "drawingArea1")}
            { /* 0 */}
            <Step className={styles.stepElement} first={true}>
              <h3>The First Place Vote Argument</h3>
              <p>
                One of the more common arguments against Consensus Voting is that candidates who don't receive many
                first-place votes in a crowded field {mdash} more than two candidates {mdash} can win elections.

              </p>
              <p>
                When there are only two candidates in a race, Mayes theorem and basic common sense say that the
                majority winner should win. But that all breaks down when there are more than two candidates,
                and first-place votes aren't a good way to decide things.
              </p>

              <p>
                I won't spend time refuting every argument against Consensus Voting, but a enough people have suggested
                this to merit discussing. It's also an opportunity to explain spoilers.
              </p>
              <p>
                A simple example shows why first-place votes aren't the way to decide things.
              </p>


            </Step>


            { /* 1 */}
            <Step className={styles.stepElement}>
              <h3>
                A Two Candidate race.
              </h3>
              {this.generalResultTable()}

              <p>
                We start with a two-candidate race in a balanced district. The outcome is clear {mdash} Anne is the
                winner by almost 2 to 1.
              </p>

              <p>
                It doesn't get simpler or more obvious than this: Anne represents these voters better simply because the
                voters prefer Anne to Bob. It would be insane to declare Bob the winner instead of Anne.
              </p>

              <p>
                But watch this...
              </p>


            </Step>

            { /* 2 */}
            <Step className={styles.stepElement}>
              <h3>Adding a Spoiler</h3>
              <p>
                In the 2022 Midterm elections, the Democratic party spent $19 million supporting MAGA Republican
                candidates in Republican primary elections. Many of these candidates won their primary, but every
                one of them went on to lose the general election. Let's see how that plays out under Instant Runoff.
              </p>
              <p>
                Anne is eliminated because she gets the fewest first place votes in the first round of IRV. Juan, the
                MAGA Republican goes on to lose just like those candidates did in the 2022 midterms. This strategy
                {mdash} which is not unique to 2022 {mdash} is equally effective under Instant Runoff.
              </p>
              <p>
                Even though all of the candidates are in the general election with Instant Runoff, a clever candidate
                can win by using spoilers to engineer the elimination of stronger opponents and then win the final round
                against a weaker opponent. That strategy is simply not practical under Consensus Voting.
              </p>
              <p>
                If first place votes really were the true metric of a candidates value, then Bob's win due to
                Democratic manipulation would reflects the voters' values and be the "right" outcome. I don't think
                anybody believes that.
              </p>
              <p>
                The lesson here is that first place votes can easily be diluted by uncompetitive candidates, but that
                doesn't make the candidate who got diluted less representative. Consensus Voting sees through that fog
                by using every ranking on every ballot to determine the winner.
              </p>

              <div className={styles.sideBySide}>
                <ResultsBox
                    results={this.state.simulationState?.h2hResults}
                    title={"Consensus Voting"}
                    className={styles.sideBySideBox}
                />
                <div style={{minWidth: "2%"}}>
                </div>
                <ResultsBox
                    results={this.state.simulationState?.irvResults}
                    title={"Instant Runoff"}
                    className={styles.sideBySideBox}
                />
              </div>
            </Step>


            <Step className={styles.stepElement}>
              <h3>Explore More</h3>
              <ul style={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}>
                <li>
                  <a href={"/ConsensusVoting"}>The introduction to Consensus Voting</a> explains what's wrong with our
                  current system and suggests the simple, but radical solution of electing candidates the voters prefer.
                </li>
                <li>
                  <a href={"/ElectionSim"}>The Election Simulator</a> Explore outcomes under
                  different election systems and different assumptions.
                </li>
                <li>
                  <a href={"/IRV"}>Instant Runoff Voting</a> describes how Instant Runoff Voting.
                </li>
                <li>
                  <a href={"/Perfect"}>No election system is perfect</a>: What is the flaw in Consensus Voting? How is
                  it handled?
                </li>
                <li>
                  <a href={"/LeviathanMath"}>The Math of the Leviathan</a>: A gentle introduction to the math used to
                  analyze elections.
                </li>
              </ul>
            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div style={{
            minHeight: "30vh"
          }}>
          </div>
        </div>
    )
  }
}

export default FirstPlaceFallacyStory
