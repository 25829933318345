import React from 'react'
import styles from './HouseFlyover.module.scss'

interface Props {
  className?: string
}

const HouseFlyover = (props: Props) => {
  return (
      <div className={`${styles.flyover} ${props.className}`} id={"detailsFlyover"} style={{
        display: "none",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: 1,
      }}>
        <h6> Representative: <span id={"memberName"}>foo</span></h6>
        <h6> Party: <span id={"party"}>foo</span></h6>
        <h6> District: <span id={"districtName"}>foo</span></h6>
        <h6> Left/Right Ideology: <span id={"ideology"}>0</span></h6>
        <h6><span id={"representation"}> </span></h6>
      </div>
  )
}

export default HouseFlyover