import React from 'react'
import styles from './Article.module.scss'
import ArticleFooter from "../ArticleFooter/ArticleFooter";

interface Props {
  title: string
  subtitle: string
  children?: JSX.Element | JSX.Element[];
}

const Article = (props: Props) => {
  return (
      <div className={styles.content}>
        {props.title !== undefined &&
        <h2>
          {props.title}
        </h2>
        }
        {props.subtitle !== undefined &&
        <h4>
          {props.subtitle}
        </h4>
        }
        {props.children}
        <ArticleFooter/>
      </div>
  )
}

export default Article