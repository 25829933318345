import Trajectory from "./Trajectory";
import Point from "./Point";

class SigmoidSequencer extends Trajectory {
  trajectory: Trajectory
  // this is the span of x values where sigmoid(x) goes from ~0 to ~1.
  // it ranges from -10 to 10.
  sigmoidDuration = 20

  constructor(trajectory: Trajectory) {
    super();
    this.trajectory = trajectory
  }

  sigmoid = (x: number): number => {
    if (x < this.sigmoidDuration / 2 - .001) {
      let s = Math.exp(-x)
      return 1 / (s + 1)
    } else
      return 1.0
  }

  path = (p0: Point, p1: Point): (t: number) => Point => {
    let originalPath = this.trajectory.path(p0, p1)
    // wraps path(t) in path(sigmoid(t * sigmoidDuration - 6))
    // makes path(0)  equal to path(sigmoid(-6))
    return (t: number): Point => {
      return originalPath(this.sigmoid(t * this.sigmoidDuration - 6))
    }
  }
}

// a fast start sigmoid.  This starts with a 1 to 1 ratio, but tapers.
class SemiSigmoidSequencer extends SigmoidSequencer {
  path = (p0: Point, p1: Point): (t: number) => Point => {
    let originalPath = this.trajectory.path(p0, p1)
    return (t: number): Point => {
      return originalPath((this.sigmoid(t * this.sigmoidDuration / 2) - .5) * 2)
    }
  }
}

// this was an attempt to mimic the nice and smooth transition
// of the SemiSigmoidSequencer w/o the expensive call to exp()
// I can't get it to work quite right, but it has potential with
// some more fiddling.
class EaseSequencer extends SigmoidSequencer {
  path = (p0: Point, p1: Point): (t: number) => Point => {
    let originalPath = this.trajectory.path(p0, p1)
    return (t: number): Point => {
      let range = 20
      let power = 2
      let t2 = t**power * range**power
      let easedT = t2 / (1 + t2)
      if (easedT > 1.0) easedT = 1.0
      return originalPath(easedT)
    }
  }

}

export {SigmoidSequencer, SemiSigmoidSequencer, EaseSequencer}