import {Party} from "../../core/Party";
import {Voter} from "../../core/Voter";
import Bubble from "./Bubble";
import {Ballot} from "../../core/Ballot";

class VoterBubble extends Bubble {
  voter: Voter
  radius: number
  party: Party
  ideology: number
  candidateIndex: number = -1
  primaryIndex: number = -1
  generalIndex: number = -1
  ballot!: Ballot
  irvRound = 0
  primary: Party|undefined

  constructor(voter: Voter, radius: number) {
    super()
    this.voter = voter
    this.radius = radius
    this.color = "orange"
    this.party = voter.party
    this.ideology = voter.ideology
    this.primary = undefined
  }
  ivec = (): number[] => {
    return [this.voter.ideology, 0]
  }
}

export default VoterBubble