import Election from "./Election";
import {Ballot, ElectionResult} from "./Ballot";
import Candidate from "./Candidate";
import {PluralityElection, PluralityResult} from "./PluralityElection";


class HeadToHeadResult extends ElectionResult {
  scores: { [name: string]: number } = {};
  results: Map<string, number>

  constructor(orderedCandidates: Array<Candidate>, results: Map<string, number>) {
    super(orderedCandidates)
    this.results = results
  }

  marginOfVictory = (c1: Candidate, c2: Candidate): number => {
    let k = `${c1.name}-${c2.name}`
    return this.results.get(k) as number
  }
}

class HeadToHeadElection extends Election {
  pairResults = new Map<string, PluralityResult>()
  pairMargin = new Map<string, number>()
  result: HeadToHeadResult
  cArray: Array<Candidate>
  nCandidates: number

  // negative means a loss, positive is a win.

  constructor(ballots: Array<Ballot>, candidates: Set<Candidate>) {
    super(ballots, candidates)
    this.cArray = Array.from(candidates)
    this.nCandidates = this.cArray.length

    for (let c1i = 0; c1i < this.nCandidates - 1; c1i++) {
      for (let c2i = c1i + 1; c2i < this.nCandidates; c2i++) {
        let c1 = this.cArray[c1i]
        let c2 = this.cArray[c2i]
        let plurality = new PluralityElection(this.ballots, new Set([c1, c2]))
        let r = plurality.result as PluralityResult
        let margin = r.voteMap.get(c1)! - r.voteMap.get(c2)!
        let k1 = this.key(c1, c2)
        let k2 = this.key(c2, c1)
        this.pairMargin.set(k1, margin)
        this.pairMargin.set(k2, -margin)
        this.pairResults.set(k1, r)
        this.pairResults.set(k2, r)
      }
    }

    // computes ordered candidates with minimax
    // note that this recomputes the max-losses after selecting each candidate.

    let remainingCandidates = this.cArray
    let orderedCandidates: Array<Candidate> = []
    while (remainingCandidates.length >= 2) {
      let maxLosses = this.computeMaxLosses(remainingCandidates)
      let winner = maxLosses[0][0]
      orderedCandidates.push(winner)
      remainingCandidates = remainingCandidates.filter(c => c !== winner)
    }
    orderedCandidates.push(remainingCandidates[0])
    this.result = new HeadToHeadResult(orderedCandidates, this.pairMargin)
  }

  key = (c1: Candidate, c2: Candidate): string => {
    let c1i = this.cArray.indexOf(c1)
    let c2i = this.cArray.indexOf(c2)
    return `${c1i}-${c2i}`
  }

  c1TieOrWin = (c1: Candidate, c2: Candidate): boolean => {
    let [v1, v2] = this.pairResult(c1, c2)
    return v1 >= v2
  }

  pairResult = (c1: Candidate, c2: Candidate): [number, number] => {
    let r = this.pairResults.get(this.key(c1, c2))!
    return [r.voteMap.get(c1)!, r.voteMap.get(c2)!]
  }

  computeMaxLosses = (candidates: Array<Candidate>): Array<[Candidate, number]> => {
    let maxLosses: Array<[Candidate, number]> = []

    candidates.forEach((c1) => {
      let minMargin = 1e20
      candidates.forEach((c2) => {
        if (c1 !== c2) {
          let k = this.key(c1, c2)
          let margin = this.pairMargin.get(k)!
          minMargin = Math.min(minMargin, margin)
        }
      })
      maxLosses.push([c1, minMargin])
    })
    maxLosses.sort((a, b) => b[1] - a[1])
    return maxLosses
  }
}

export {HeadToHeadElection}
export {HeadToHeadResult}