import React, {ReactNode} from 'react'
import styles from './DualPane.module.scss'

interface Props {
  leftHandSide: ReactNode,
  rightHandSide: ReactNode
}

const DualPane = (props: Props) => {
  return (
    <div className={styles.content}>
      {props.leftHandSide}
      {props.rightHandSide}
    </div>
  )  
}

export default DualPane