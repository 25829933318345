import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import VisualizationWidget from "../../../VisualizationWidget/VisualizationWidget";
import {AppMetadata} from "./App";
import {ConsensusVotingApp} from "../../../Introduction/ConsensusVotingApp";

interface Props {
  metaData: AppMetadata
}

export function OldOverview(props: Props) {
  let mdash = "\u2014"
  let lt = "<<"
  let gt = ">>"
  return (
      <Article title={"Introduction"}
               subtitle={"There is more at stake than elections"}>
        <div className={styles.textContent}>
          <div className={styles.flyover} id={"detailsFlyover"} style={{
            display: "none",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 1,
          }}>
            <h6> Representative: <span id={"memberName"}>foo</span></h6>
            <h6> Party: <span id={"party"}>foo</span></h6>
            <h6> District: <span id={"districtName"}>foo</span></h6>
            <h6> Left/Right Ideology: <span id={"ideology"}>0</span></h6>
            <h6><span id={"representation"}> </span></h6>
          </div>

          <VisualizationWidget
              metaData={props.metaData}
              className={styles.dividedHouse}
              id={"actualHouse"}
              height={1000}
              width={1000}
              nVoters={8000}
              radius={3.5}
              render={(app: ConsensusVotingApp) => {
                app.showHouseAndVoters(1)
                // app.displayHouse(400, 1000)
                // app.addRenderItem(new TextItem("The United States House of Representatives, Arranged by Ideology",
                //     "house-title", app.svg, 500, 50, "black", "middle", "22pt"))
                // app.addRenderItem(new TextItem("Drag the slider to show ideology for different Congresses.",
                //     "slider-instructions", app.svg, 500, 550, "black", "middle", "14pt"))
              }}
          />

          <p className={styles.caption}>
            This particular visualization of Congress is used repeatedly on the website. It's a clear illustration of
            the polarization and resulting dysfunction in our Congress and throughout our government. These are the
            435 U.S. House Members arranged by the ideological score of their voting records. Hover over a circle for
            information on the Representative.
          </p>

          <p>
            This website is about the causes of and solutions for polarization and political dysfunction in American
            politics. There is a long list of potential causes for polarization, but the root cause is that the election
            system itself rewards divisive and polarizing candidates. My goal is to empower voters to elect candidates
            that represent the values of the broader electorate instead of just one side or the other. If we do this, we
            can reduce polarization, allow our government to function, and make elections something that unites us
            instead of divides us.
          </p>

          <p>
            My journey in election science and voting reform started with reading and watching the news. I could not
            reconcile the conflict I saw on television with my own experience of Americans being reasonable people.
            Why were all of the politicians inflexible, divisive, and even toxic while the people I knew from
            both parties were much more reasonable?
          </p>

          <p>
            As if things weren't bad enough, they kept getting worse. After each election cycle, the rhetoric became
            more toxic, and I started to see people I knew buying into some of the crazy things on cable news. We were
            and are in a vicious downward spiral of polarization, something Lee Drutman would later call a "Doom
            Loop".
          </p>

          <p>
            I remember having lunch with Mark Melton, my best friend, and business partner. I had found a PEW study of
            the political ideology of voters and a different source for the ideology of Congress they elected.
            Combined, they showed the image you see above. I came to lunch with copies of the two studies. To me, these
            two pieces of data pointed firmly at the source of the problem: the game theory of the election process.
            Mark is a natural skeptic, but he was also convinced: something was broken about the election process.
          </p>

          <h3>Origins</h3>
          <p>
            At the time, I was busy helping to run my company, RGM Advisors. Mark, Richard Gorelick, and I had started
            RGM Advisors, a proprietary trading firm, a decade earlier in Austin, Texas. Our bet was that our
            understanding of data science, machine learning, artificial intelligence, and software design was good
            enough to be profitable in the stock market. We were right.
          </p>

          <p>
            We had started trading from my tiny living room in December of 2001. Our total assets consisted of
            $28,500 in trading capital and a $900 Compaq PC named 'catbox' sitting in a rack at our broker in New York.
            The account minimum was $25,000, so we felt like we had a healthy amount of buffer in case something
            went wrong. We knew absolutely nothing about trading. At that time, my goal was to have more time and
            flexibility to ride my bike. It didn't turn out that way.
          </p>

          <p>
            By the time of that lunch with Mark, we had grown that $28,500 into one of the world's largest
            trading firms. We were trading around 500 million shares a day in the U.S. stock market as well as
            futures and FX on exchanges in the United States, Europe, and Asia from 42 data centers spread around the
            world. The entire 17 years I was involved with RGM were incredibly intense, with little time to pursue my
            passion for understanding and fixing the problems in our democracy.
          </p>

          <p>
            We sold RGM in the fall of 2017, and I was able to exit my responsibilities shortly afterward. I was tired
            of trading and dismayed by the state of our democracy. The time seemed ripe for me to dig into the problems
            plaguing our nation. I was not the only person studying this; many other people were also working on the
            problem. I set about learning what other people had discovered and how I might make a contribution.
          </p>

          <h3>GTO Politics</h3>

          <p>
            I'm a terrible poker player, but competitive poker has some great concepts. The relevant one is "Game Theory
            Optimal", or GTO. GTO refers to the best possible play given the rules of the game and everything the
            player knows about the hand. Computer programs use sophisticated neural networks to find the optimal play
            for any circumstances, and high-level poker players use these programs to study GTO poker. Players who don't
            study GTO poker simply lose.
          </p>

          <p>
            Watching or reading the news from my perspective as a data scientist is fascinating and frustrating.
            It's clear to me that <i>every</i> politician is playing GTO politics, whether they know it or not. I'm not
            saying {mdash} yet {mdash} whether politicians are brilliant strategists or blithering idiots. Still, those
            that play GTO politics for whatever reason survive, and those who don't lose. Blaming politicians for their
            behavior is pointless. If they don't play GTO politics, they will simply lose to candidates who do.
          </p>

          <p>
            To me and many others, it is obvious the problem isn't the politicians but the rules of the game. But, what
            are the rules? Unlike poker, politics doesn't have a nice little rulebook and a mathematically precise set
            of rules for who wins or loses an election. Lacking a rulebook, I set about building my own by simulating
            election outcomes.
          </p>

          <h3>Simulating Elections</h3>

          <p>
            I needed to model the outcome of the election process: What kind of candidates are favored? What are
            the ideal strategies? Most importantly, what effect would a given change to the election process have
            on the types of candidates who are favored to win? Candidates are going to play GTO politics; we need
            to change the rules so that GTO politics means something that's good for the nation and the voters instead
            of divisive and destructive. And we need to do it soon.
          </p>


          <VisualizationWidget
              metaData={props.metaData}
              className={styles.simulatedHouse}
              id={"simulatedHouse"}
              height={1000}
              width={1000}
              render={(app: ConsensusVotingApp) => {
                app.showSimulatedHouse(0)
              }}
          />

          <p className={styles.caption}>
            Simulated and Actual ideology of the U.S. House of Representatives. The map is color-coded for the
            representation score for each congressional district. The 435 circles below are for the U.S. House
            Members arranged by their ideology. Representation scores are explained in <a href={"/Representation"}>How
            to
            Fix a Broken System</a>.
          </p>


          <p>
            It turns out that simulating elections is a lot easier than predicting the stock market. This was
            even easier because I wasn't trying to simulate a specific election. I was trying to understand the
            fundamental forces that drive elections. For almost any simulation, the simplest one that works is best.
            Even a pretty simple election simulation model predicts the polarized result we see today.
          </p>

          <p>
            Simulation is explained in detail in the <a href={"/SimulationTutorial"}>Simulation Tutorial</a> but
            starting with reasonable and simple assumptions, the simulation predicts the divided Congress we see today.
            This is actually encouraging and scary. It is encouraging because there are many things that we can't fix: a
            divided population, social media, cable news, or divisive rhetoric. Fortunately, those things aren't the
            cause of the problem. The only thing that is required to create the problem is the election process itself,
            and that we can fix.
          </p>

          <p>
            The scary part is that if elections produce leaders that are more polarized than the electorate, they
            empower divisive voices in the media, embrace and amplify divisive narratives, and create divisions in the
            population. This new, more divided population then elects even more polarizing leadership in the next
            election cycle. Back to Drutman's "Doom Loop."
          </p>

          <p>
            One of the goals of writing the simulation was to prove that Ranked Choice Voting with Instant
            Runoff {mdash} the widely proposed solution for polarization {mdash} would reduce polarization and make our
            democracy more stable. It didn't. At first, it looked like Instant Runoff might be slightly better than our
            current system, but when I accounted for candidates employing GTO politics, it was clear that there was no
            meaningful difference.
          </p>

          <p>
            I had discovered "factions." They were neither new nor easy to deal with.
          </p>

          <div style={{
            marginTop: "80px"
          }} className={styles.pullQuote}>
            <p>
              "A zeal for different opinions concerning religion, concerning government, and many other points, as well
              of speculation as of practice; an attachment to different leaders ambitiously contending for pre-eminence
              and power; or to persons of other descriptions whose fortunes have been interesting to the human passions,
              have, in turn, divided mankind into parties, inflamed them with mutual animosity, and rendered them much
              more disposed to vex and oppress each other than to co-operate for their common good."
            </p>
            <p>
              James Madison {mdash} Federalist 10
            </p>
          </div>

          <h3>Factions</h3>

          <p>
            My fancy computer simulation had managed to "discover" something that Madison and Hamilton predicted with
            goose-quill pens 200+ years ago. That's humbling, but it wasn't <i>completely</i> useless.
          </p>

          <p>
            Factions and the abuse of power by a dominant faction had plagued earlier attempts at democracy and deeply
            concerned the founding fathers. Hamilton and Madison viewed the formation of factions as inevitable. They
            focused on limiting the ability of the majority to trample the rights of the minority. Federalist papers 9
            and 10 specifically detail the measures in the Constitution designed to limit the ability of factions to
            unfairly oppress the minority and undermine democracy.
          </p>

         <p>
            Direct democracy was vulnerable to a majority faction abusing their power, but Madison and Hamilton hoped
            that a republic where leaders are elected to represent groups of voters would work better. Madison hoped
            that these representative leaders would possess "enlightened views and virtuous sentiments" because of the
            larger number of people involved in selecting them. I'm hesitant to ever say someone of Madison's stature
            is wrong, but I wonder what he'd say about our current Congress.
          </p>

          <p>
            One constant through every democracy {mdash} not just America's {mdash} is that candidates represent the
            group of voters that got them elected {mdash} <i>and nobody else</i>. In the United States, this mostly
            means a few primary voters. At best, it means the voters from the candidate's party. GTO Politics demands
            that candidates ignore or even attack voters that don't help them win. The winning candidate then
            represents just that majority required to win, not all of the voters in their district or state. In short,
            Madison's enlightened leaders can't win their primary.
          </p>


          <p>
            It seems like an intractable problem. Elections are won by the majority party (faction) at each level; the
            minority has no voice in who will represent them. These candidates represent only "their voters", not the
            entire electorate. The legislature (House, Senate, Statehouse, etc.) is filled with partisans who each
            represent their parties and no one else. No one represents all of the voters. Whichever side has a majority
            gets to do whatever they want. In the long term, the checks and balances in the Constitution are only as
            good as the people we elect.
          </p>


          <p style={{
            marginTop: "80px"
          }} className={styles.pullQuote}>
            "You can fool all the people some of the time and some of the people all the time, but you cannot fool
            all the people all the time." – Abraham Lincoln (probably)
          </p>

          <h3>The Wisdom of Lincoln</h3>
          <p>
            Lincoln may not have actually said this, but it's essential wisdom for American democracy. The point
            is that small groups of people can't be trusted to make good decisions. Sadly, that's exactly what we do:
            small groups of primary voters make the most important and often only decision in our elections: who
            will represent the dominant party? Not only do they make bad choices, but the officeholders they elect are
            then beholden to that same small group. Instead, we need a system where every voter participates in every
            decision, and the officeholders are beholden to all of their constituents.
          </p>

          <p>
            To fulfill Madison's dream of enlightened representatives and save our democracy, we must change the game so
            that GTO Politics means that candidates represent all of their voters, not just their primary voters and
            not just their party's voters. Fortunately, people have been working on this for a long time.
          </p>

          <VisualizationWidget
              metaData={props.metaData}
              className={styles.consensusCongress}
              id={"ConsensusCongress"}
              render={(app: ConsensusVotingApp) => {
                app.showConsensusHouse(0)
              }}
          />

          <p className={styles.caption}>
            Consensus Voting voter representation and ideology of the U.S. House of Representatives. The map is color
            coded for the representation score for each congressional district. The 435 circles below are for the U.S.
            House Members arranged by their ideology. Representation scores are explained in
            <a href={"/Representation"}>How to Fix a Broken System</a>.
          </p>

          <p>
            What I found is something I call Consensus Voting. Consensus Voting uses the same ballot that Instant Runoff
            uses but interprets it differently. The details are explained later, but Consensus Voting uses every ranking
            on every ballot to identify the candidate who does the best job of balancing the needs of all of the voters.
            Consensus Voting changes the GTO strategy for candidates. Instead of focusing on some group of voters
            required to get elected and ignoring or attacking everyone else, candidates need to balance the needs of all
            of their constituents.
          </p>

          <p>
            Consensus Voting elects candidates who each represent a spectrum of voters instead of individual factions.
            Officeholders will never be held hostage by narrow factions voters who will evict them at the first hint of
            compromise. Instead, officeholders will be judged by all of their voters for representing all of their
            interests. Winners under Consensus Voting will not be clones; they will be as diverse as the districts
            they represent. Elections will take a step toward consensus where winners will each reflect the unique
            consensus of their constituents.
          </p>

          <p>
            Best of all, Consensus Voting does all of this by simply doing what the voters want: electing the candidate
            the voters choose over each of the other candidates. Choosing any other result would override the will of
            the voters.
          </p>

          <p>
            This website is dedicated to explaining how Consensus Voting works, the science behind it, and the profound
            implications of electing leaders who represent all of their voters. The navigation bar at the top of the
            page has next ({gt}) and previous ({lt}) links that allow you to progress in a suggested reading order.
            There is also an "Explore More" section at the end of each Article that allows you to jump around to things
            that may interest you. You can also use the home page as a table of contents.
          </p>
        </div>
      </Article>
  )
}




















