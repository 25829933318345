import * as d3 from 'd3'
import {ScaleLinear} from 'd3'
import {Democrats, Independents, QAnon, Republicans} from '../../core/Party';
import Bubble from "./Bubble";

class RangesAndGradients {
  stddev = 1


  redGrayBlue = ["blue", "blue", "purple", "red", "red"]
  ideologyColorScale = d3.scaleLinear()
      .domain([-3, -1.5, 0, 1.5, 3])
      .range(this.redGrayBlue as any)
      .interpolate(d3.interpolateHcl as any) as ScaleLinear<number, unknown, never> as ScaleLinear<number, string, never>

  colorByIdeology = (ideology: number): string => {
    return this.ideologyColorScale(ideology) as unknown as string
  }

  dwNominateScale = 2.5
  ideologyScale = d3.scaleLinear([-3, 3], [10, 990])
  ideologyYScale = d3.scaleLinear([-3, 3], [10, 990])
  histogramScale = d3.scaleLinear([-2.25, 2.25], [10, 990])
  nominateScale = d3.scaleLinear([-0.8, .8], [10, 990])

  leanRange = 150
  leanScale: ScaleLinear<number, number> = d3.scaleLinear()
      .domain([-this.leanRange, this.leanRange])
      .range([0, 1000])

  scale = 2
  xScale2d: ScaleLinear<number, number> = d3.scaleLinear()
      .domain([-this.scale, this.scale])
      .range([0, 1000])

  yScale2d: ScaleLinear<number, number> = d3.scaleLinear()
      .domain([-this.scale, this.scale])
      .range([1000, 0])


  colorByParty = (b: Bubble): string => {
    if (b.party === Republicans) return "red"
    else if (b.party === Democrats) return "blue"
    else if (b.party === QAnon) return "green"
    else if (b.party === Independents) return "gray"
    else return "pink"
  }

  oranges = ["green", "yellow", "orange", "brown", "black"]
  voterRepresentationColorScale = d3.scaleLinear()
      .domain([200, 170, 140, 110, 80])
      .range(this.oranges as any)
      .interpolate(d3.interpolateHcl as any) as ScaleLinear<number, unknown, never> as ScaleLinear<number, string, never>

  electorateRepresentationColorScale = d3.scaleLinear()
      .domain([100, 80, 60, 40, 20])
      .range(this.oranges as any)
      .interpolate(d3.interpolateHcl as any) as ScaleLinear<number, unknown, never> as ScaleLinear<number, string, never>

}

let rangesAndGradients = new RangesAndGradients()
export default RangesAndGradients
export {rangesAndGradients}