import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class Top2Hope extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }

  render() {
    // let mdash = "\u2014"

    return (
        <Article title={"A Glimmer of Hope"}
                 subtitle={"An example from California's Top-2 illustrates a key concept."}>
          <div className={styles.textContent}>
            <p> Dan Howle's story from an Oakland election points the way. </p>
            <p> Two serious candidates, both democrats.</p>
            <p> One Candidate endorsed by every major Democratic organization.</p>
            <p> ... a perfect and pure avatar of the Democratic faction </p>
            <p> The other candidate chose to reach out to Republicans and moderate Democrats.</p>
            <p> the candidate who reached out to Republicans won easily</p>
          </div>
        </Article>
    )
  }
}




















