import React from 'react';
import styles from './SliderWidget.module.scss'


interface SliderProps {
  id: string
  title: string
  leftLabel: string
  rightLabel: string
  min: number
  max: number
  value: number;
  step: number;
  onChange: (value: number) => void
}

class SliderWidget extends React.Component<any, any> {
  x: number
  constructor(props: SliderProps) {
    super(props)
    this.x = 0
  }

  render = () => {
    return (
        <div className={`${styles.sliderOuter} sliderOuter`} id={this.props.id}>
          <h6 className={styles.sliderTitle + " title"}>{this.props.title}</h6>
          <div className={styles.sliderBox}>
            <div className={styles.leftLabel + " label"}>
              {this.props.leftLabel}
            </div>
            <input id={`${this.props.id}-input`} className={`${styles.slider} slider`}
                   type={"range"}
                   min={this.props.min}
                   max={this.props.max}
                   step={this.props.step}
                   value={this.props.value}
                   onChange={(event) => {
                     this.props.onChange(+event.target.value)
                   }
                   }/>
            <div className={styles.rightLabel + " label"}>
              {this.props.rightLabel}
            </div>
          </div>
        </div>
    )
  }
}

export default SliderWidget