import {Ballot, ElectionResult} from "./Ballot";
import Candidate from "./Candidate";

abstract class Election {
  abstract result: ElectionResult
  ballots: Array<Ballot>
  candidates: Set<Candidate>
  protected constructor(ballots: Array<Ballot>, candidates: Set<Candidate>) {
    this.ballots = ballots
    this.candidates = candidates
  }
}

export default Election