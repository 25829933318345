import * as d3 from "d3";
import {DSVRowArray} from "d3";
import MapController from "../ui/elements/MapController";
import StoryBase from "../StoryBase/StoryBase";
import CombinedDistrictData from "../core/CombinedDistrictData";
import {Democrats, Independents, NoParty, Party, partyForShortName, Republicans} from "../core/Party";
import {defaults} from "../core/Defaults";
import Voters2D, {Voter2D} from "../ui/elements/Voters2D";
import Bubble from "../ui/elements/Bubble";
import Candidates2D, {Candidate2D} from "../ui/elements/Candidates2D";
import Candidate from "../core/Candidate";
import Random from "../core/Random";
import {HeadToHeadElection} from "../core/HeadToHeadElection";
import {ElectionConfig} from "../core/ElectionConfig";
import {CandidateResultTable, TableItem} from "../ui/elements/ResultWidget2";
import blockTimer from "../core/BlockTimer";
import {PluralityElection, PluralityResult} from "../core/PluralityElection";
import {PopulationND} from "../core/CombinedPopulation";
import PopulationGroup from "../core/PopulationGroup";
import {Voter} from "../core/Voter";
import {ControlState} from "../ui/elements/SimulationControls";
import {GeometricMedian} from "../core/GeometricMedian";
import {TextItem} from "../ui/elements/TextItem";
import OffscreenLayout from "../ui/elements/OffscreenLayout";
import DisplayRectangle from "../VoterRepresentation/DisplayRectangle";
import MemberBubble from "../ui/elements/MemberBubble";
import LayoutMembersVsVoters from "../ui/elements/LayoutMembersVsVoters";
import SVGButton from "../ui/elements/SVGButton";
import BallotWidget from "../ui/elements/BallotWidget";
import VoterController from "../ui/elements/VoterController";
import voterController from "../ui/elements/VoterController";

// import ImageController from "../ui/elements/ImageController";


export interface ElectionData {
  tables: CandidateResultTable[]
  winner: Candidate2D
}

export interface ButtonSpec {
  label: string
  id: string
  cb: () => void
}

export interface SimulationResults {
  h2hResults: ElectionData
  irvResults: ElectionData
  primaryResults: ElectionData
  allResults: ElectionData
  pluralityResults: ElectionData
  controlState: ControlState
}

export class SimulationState {
  simulationResults = new Map<string, SimulationResults>()
  /* These methods are all @deprecated, but no real way to mark them as such. */
  h2hResults?: ElectionData
  irvResults?: ElectionData
  primaryResults?: ElectionData
  allResults?: ElectionData
  pluralityResults?: ElectionData
  controlState?: ControlState
}


class PrimaryBasedResult {
  democraticPrimary: PluralityResult
  republicanPrimary: PluralityResult
  generalResult: PluralityResult

  constructor(democraticPrimary: PluralityResult, republicanPrimary: PluralityResult, generalResult: PluralityResult) {
    this.democraticPrimary = democraticPrimary
    this.republicanPrimary = republicanPrimary
    this.generalResult = generalResult
  }
}


class ElectionSimApp extends StoryBase {
  map: MapController
  voters1D: voterController
  voters: Voters2D
  candidates2D: Candidates2D
  combinedDistrictData: CombinedDistrictData
  h2hElection: HeadToHeadElection
  primaryElection: PrimaryBasedResult
  colorMap: Map<Candidate, string>
  meanIVec = new Array(2).fill(0)
  controlState: ControlState = {
    uncertainty: 0,
    partyPreference: 0,
  }
  lean: number = 0
  applyVoterColors = () => {}
  showLeanSlider: boolean = false
  showUncertaintySlider: boolean = false
  showLoyaltySlider: boolean = false
  cachedSimulationState: SimulationState = new SimulationState()
  candidateDragSelect = (c: Candidate) => {}
  actualButton?: SVGButton
  primaryButton?: SVGButton
  consensusButton?: SVGButton
  activeSimulation: string = "Default"
  buttons: SVGButton[] = []

  constructor(visDivId: string,
              dvr: DSVRowArray,
              DWNominate: DSVRowArray,
              simulationResults: DSVRowArray,
              sampleCongress: DSVRowArray,
              usTopo: any,
              nVoters: number,
              voterRadius: number) {
    super(visDivId, defaults.rightLeanPopulation);
    let groups = [
      new PopulationGroup(Democrats, [-1, -1], [1, 1], [0, 0], .40),
      new PopulationGroup(Republicans, [1, 1], [1, 1], [0, 0], .40),
      new PopulationGroup(Independents, [0, 0], [1.3, 1.3], [0, 0], .20),
    ]
    let population = new PopulationND([[1.5, 0], [.75, 0]], nVoters, groups, Math.PI / 4)
    this.voters = new Voters2D(nVoters, this.voterCanvas.context, population, voterRadius)
    this.candidates2D = new Candidates2D(this.svg)
    this.candidates2D.bubbles = this.baseCandidates()
    this.colorMap = new Map(this.candidates2D.bubbles.map(cb => [cb.candidate, cb.candidateColor]))

    this.computeMedianVoter()
    this.combinedDistrictData = new CombinedDistrictData(dvr, DWNominate, simulationResults, sampleCongress)
    this.map = new MapController(this.visDiv, usTopo, this.combinedDistrictData)
    this.memberController = this.createMemberController(this.combinedDistrictData)
    // this.h2hMemberController = this.createH2HMemberController(this.combinedDistrictData)
    this.h2hElection = new HeadToHeadElection([], new Set(this.candidates2D.candidates()))
    let dummy = new PluralityResult([], [])
    this.primaryElection = new PrimaryBasedResult(dummy, dummy, dummy)
    this.processCandidateUpdate(this.candidates2D.bubbles)
    this.candidates2D.onCandidateSelect = this.onCandidateSelect
    this.candidates2D.setColor((b: Bubble) => (b as Candidate2D).candidateColor, "candidateColor")
    this.voters1D = new VoterController(nVoters, this.voterCanvas1D.context, defaults.noLeanPopulation, 3)

    this.candidates2D.onUpdate = ((cb: Candidate2D) => {
      this.processCandidateUpdate([cb])
      this.stopAllTimers()
      this.candidateDragSelect(cb.candidate)
    })
  }

  setActiveSimulation = (activeSimulation: string) => {
    this.activeSimulation = activeSimulation
  }

  computeMedianVoter = () => {
    this.findMedian()
  }

  onEditCandidateClick = (event: Event) => {
    let element = document.getElementById('editCandidate')!
    let inside = event.composedPath().includes(element)
    if (inside) {
    } else {
      d3.selectAll(".editCandidate")
          .style("display", "none")
      document.removeEventListener("click", this.onEditCandidateClick)
    }
  }
  onCandidateEdit = (name: string, partyShortName: string, quality: number) => {
    let p = partyForShortName[partyShortName]
    let c2d = this.candidates2D.bubbles.find((c) => c.name === name)
    if (c2d) {
      c2d.party = p
      c2d.candidate.party = p
      c2d.candidate.quality = quality
      this.processCandidateUpdate([c2d])
    } else {
    }

  }


  onCandidateSelect = (c: Candidate2D) => {
    this.controlState.activeCandidate = c
    this.onUpdate(this.currentSimulationState())
    let candidateSelection = d3.selectAll(".editCandidate")
    candidateSelection.style("display", "block")
    document.addEventListener('click', this.onEditCandidateClick)
  }


  findMedian = () => {
    let points = this.voters.bubbles.map(v => v.voter.ivec)
    let gm = new GeometricMedian(points, .1, .001)
    this.meanIVec = gm.median

    this.voters.bubbles.forEach((v, _idx) => {
      v.distanceFromMean = v.distance(this.meanIVec)
    })
  }

  updateRepresentationScore = (c: Candidate2D) => {
    let dummyVoter = new Voter(c.candidate.ivec, NoParty)

    let candidateDistance = dummyVoter.distance(this.meanIVec)
    c.distanceFromMedian = candidateDistance

    let closer = 0
    this.voters.bubbles.forEach(v => {
      if (candidateDistance < v.distanceFromMean)
        closer = closer + 1
    })
    c.voterRepresentation = 100 * closer / this.voters.bubbles.length
  }

  runPrimaryBasedElection = (): PrimaryBasedResult => {
    this.voters.bubbles.forEach(vb => vb.partyPrimary = NoParty)
    let dResult = this.runPartyPrimary(Democrats)
    let rResult = this.runPartyPrimary(Republicans)
    let independents = this.candidates2D.candidates().filter(c => c.party === Independents)
    let gResult = this.runGeneral(dResult, rResult, independents)
    return new PrimaryBasedResult(dResult, rResult, gResult)
  }
  runPartyPrimary = (party: Party): PluralityResult => {
    let candidates = this.candidates2D.candidates().filter(c => c.party === party)
    if (candidates.length === 0) {
      return new PluralityResult([], [])
    }
    let rand = new Random("Consensus")
    let primaryVoters = this.voters.bubbles.filter((v: Voter2D) => v.party === party && rand.nextFloat() < .75)
    primaryVoters.forEach((vb) => vb.partyPrimary = party)

    let ballots = primaryVoters.map(v => v.ballot)
    let plurality = new PluralityElection(ballots, new Set(candidates))
    return plurality.result as PluralityResult
  }

  runGeneral = (dResult: PluralityResult, rResult: PluralityResult, independents: Candidate[]): PluralityResult => {
    let candidates: Candidate[] = []
    if (dResult.orderedCandidates.length === 0) {
      candidates = rResult.orderedCandidates.slice(0, 2)
    } else if (rResult.orderedCandidates.length === 0) {
      candidates = dResult.orderedCandidates.slice(0, 2)
    } else {
      candidates = [dResult.winner, rResult.winner].concat(independents)
    }
    return this.runPluralityElection(this.voters.bubbles, candidates)
  }


  createAnne = () => new Candidate2D(new Candidate("Anne", Republicans, 0, 0.01, [.07, .19]), "gold", 10)
  createBob = () => new Candidate2D(new Candidate("Bob", Democrats, 0, 0, [-.70, -.95]), "lightseagreen", 10)
  createJuan = () => new Candidate2D(new Candidate("Juan", Republicans, 0, 0, [.75, .75]), "cyan", 10)
  createMaria = () => new Candidate2D(new Candidate("Maria", Republicans, 0, 0.005, [.55, .3]), "chartreuse", 10)
  createSue = () => new Candidate2D(new Candidate("Sue", Democrats, 0, 0.015, [-.35, -.14]), "violet", 10)


  createDavid = () => new Candidate2D(new Candidate("David", Democrats, 0, 0.01, [-1.05, -0.90]), "violet", 10)
  createDiane = () => new Candidate2D(new Candidate("Diane", Democrats, 0, 0.01, [-.55, -.48]), "gold", 10)
  createDianeX = () => new Candidate2D(new Candidate("Diane", Democrats, 0, 0.01, [-.4, -.4]), "gold", 10)
  createRyan = () => new Candidate2D(new Candidate("Ryan", Republicans, 0, 0.015, [+.35, +.22]), "violet", 10)
  createRebecca = () => new Candidate2D(new Candidate("Rebecca", Republicans, 0, 0.005, [.75, .75]), "chartreuse", 10)

  createRyanX = () => new Candidate2D(new Candidate("Ryan", Republicans, 0, 0.015, [+.5, +.55]), "violet", 10)
  createRebeccaX = () => new Candidate2D(new Candidate("Rebecca", Republicans, 0, 0.005, [1.25, 1.45]), "chartreuse", 10)

  createSteve = () => new Candidate2D(new Candidate("Steve", Republicans, 0, -0.3, [1.2, .95]), "magenta", 10)


  twoCandidateBase = () => [this.createJuan(), this.createBob()]
  twoCandidateAlternate = () => [this.createJuan(), this.createAnne()]

  createBruce = () => new Candidate2D(new Candidate("Bruce", Republicans, 0, 0, [.55, .55]), "gold", 10)
  createJared = () => new Candidate2D(new Candidate("Jared", Democrats, 0, 0.005, [-.55, -.4]), "chartreuse", 10)
  createTiffany = () => new Candidate2D(new Candidate("Tiffany", Independents, 0, -0.4, [-1.25, -.14]), "violet", 10)

  threeCandidateSpoiler = () => [
    this.createBruce(),
    this.createJared(),
    this.createTiffany()
  ]

  fiveCandidateAll = () => [
    this.createAnne(),
    this.createBob(),
    this.createJuan(),
    this.createMaria(),
    this.createSue(),
  ]
  twoCandidateJAB = () => [
    this.createAnne(),
    this.createBob(),
    this.createJuan(),
  ]

  threeCandidateDRR = () => [
    this.createDiane(),
    this.createRyan(),
    this.createRebecca(),
  ]

  threeCandidateDDR = () => [
    this.createDianeX(),
    this.createDavid(),
    this.createRebecca(),
  ]
  threeCandidateDRRX = () => [
    this.createDiane(),
    this.createRyanX(),
    this.createRebeccaX(),
  ]

  Bob = () => this.candidates2D.candidates().find(c => c.name === "Bob")!
  Maria = () => this.candidates2D.candidates().find(c => c.name === "Maria")!
  Sue = () => this.candidates2D.candidates().find(c => c.name === "Sue")!
  Juan = () => this.candidates2D.candidates().find(c => c.name === "Juan")!
  Anne = () => this.candidates2D.candidates().find(c => c.name === "Anne")!

  baseCandidates = () => {
    return this.fiveCandidateAll()
  }


  findCandidateBubble = (candidate: Candidate): Candidate2D => {
    return this.candidates2D.bubbles.filter(cb => cb.candidate === candidate)[0]
  }


  currentSimulationState = (): SimulationState => {

    // save the current simulation results to the map of all simulation results
    let result = this.computeSimulationResults()
    this.cachedSimulationState.simulationResults.set(this.activeSimulation, result)

    // Save direct access to the most recent sim for legacy code.
    // These methods are deprecated, but typescript doesn't handle
    // deprecation well afaik (which isn't much).
    this.cachedSimulationState.controlState = result.controlState
    this.cachedSimulationState.h2hResults = result.h2hResults
    this.cachedSimulationState.irvResults = result.irvResults
    this.cachedSimulationState.primaryResults = result.primaryResults
    this.cachedSimulationState.allResults = result.allResults
    this.cachedSimulationState.pluralityResults = result.pluralityResults

    return this.cachedSimulationState
  }

  updateSliders = (leanSlider: boolean = false, loyaltySlider: boolean = false, uncertainty: boolean = false): void => {

    let dirty = false
    if (this.showLeanSlider !== leanSlider) {
      this.showLeanSlider = leanSlider
      dirty = true
    }
    if (this.showLoyaltySlider !== loyaltySlider) {
      this.showLoyaltySlider = loyaltySlider
      dirty = true
    }
    if (this.showUncertaintySlider !== uncertainty) {
      this.showUncertaintySlider = uncertainty
      dirty = true
    }

    if (dirty)
      this.onUpdate(this.cachedSimulationState!)
  }
  computeSimulationResults = (): SimulationResults => {
    return {
      h2hResults: {
        tables: this.h2hResultTables(),
        winner: this.findCandidateBubble(this.h2hElection.result.winner)
      },
      irvResults: {
        tables: this.irvResultTables(),
        winner: this.findCandidateBubble(this.irvResult.winner)
      },
      primaryResults: {
        tables: this.primaryResultTables(),
        winner: this.findCandidateBubble(this.primaryElection.generalResult.winner)
      },
      pluralityResults: {
        tables: this.pluralityResultTables(),
        winner: this.findCandidateBubble(this.irvResult.rounds[0].winner)
      },
      allResults: {
        // gross way to find the candidate
        tables: this.candidates2D.candidates().map(c => this.h2hTable(c, false)),
        winner: this.candidates2D.bubbles[0]
      },
      controlState: this.controlState
    }
  }


  primaryResultTables = (): CandidateResultTable[] => {
    return [
      this.generateGeneralTable(this.primaryElection.generalResult),
      this.generatePrimaryTable(Democrats, this.primaryElection.democraticPrimary),
      this.generatePrimaryTable(Republicans, this.primaryElection.republicanPrimary),
    ]
  }

  generateResultRows = (result: PluralityResult, onSelect: () => void, irvRound: boolean = false): TableItem[][] => {
    return result.orderedCandidates.map((c, index) => {
      let vs: string = this.pctVote(result.voteMap.get(c)!)
      let c2d: Candidate2D = this.candidates2D.bubbles.find(cc => cc.candidate === c)!
      let color: string = c2d ? c2d.candidateColor : 'gray'
      let className: string = ""
      if (index === 0) className = "winningCandidate"
      else if (irvRound && index === result.orderedCandidates.length - 1) className = "eliminatedCandidate"
      else className = "losingCandidate"
      return [
        new TableItem(c.name, {background: color}, onSelect, className, c),
        new TableItem(vs, {background: color}, onSelect, className)
      ]
    })
  }

  dragWithPrimary = (c: Candidate, generalDisplayed: boolean) => {
    let generalResult = this.primaryElection.generalResult
    if (generalDisplayed &&
        (c === generalResult.orderedCandidates[0] || c === generalResult.orderedCandidates[1])) {
      // do nothing, note that if the general candidate no long wins the general it will switch to the primary
    } else {
      let result = c.party === Republicans ? this.primaryElection.republicanPrimary : this.primaryElection.democraticPrimary
      this.selectPrimaryResult(result, c.party)
    }
  }

  selectGeneralResult = (result: PluralityResult) => {
    let n0 = result.orderedCandidates[0].name
    let n1 = result.orderedCandidates[1].name
    this.colorVotersByPreference(new Set(result.orderedCandidates), `General Election Voter Choices between ${n0} and ${n1}`)
    this.highlightGeneral()
    this.stopAllTimers()
    this.applyVoterColors = () => {
      // get the current general result, because the candidates may have changed since
      // onSelect was run.
      let r = this.primaryElection.generalResult
      let n0 = r.orderedCandidates[0].name
      let n1 = r.orderedCandidates[1].name
      this.colorVotersByPreference(new Set(result.orderedCandidates), `General Election Voter Choices between ${n0} and ${n1}`)
    }
    this.candidateDragSelect = (c: Candidate) => { this.dragWithPrimary(c, true) }
  }

  generateGeneralTable = (result: PluralityResult): CandidateResultTable => {
    let title = "General Election"
    let onSelect = () => {
      this.stopAllTimers()
      this.selectGeneralResult(result)
    }
    let rows = this.generateResultRows(result, onSelect)
    return new CandidateResultTable(title, "", [], rows, "resultTable", "generalResult")
  }

  selectPrimaryResult = (result: PluralityResult, party: Party) => {
    let tableId = `${party.name}Primary`
    this.colorVotersByPreference(new Set(result.orderedCandidates),
        `${party.name} Primary Voter Choices`,
        (vb: Voter2D) => vb.partyPrimary === party)
    this.highlightResult(tableId)
    this.stopAllTimers()
    this.applyVoterColors = () => {
      this.colorVotersByPreference(new Set(result.orderedCandidates),
          `${party.name} Primary Voter Choices`,
          (vb: Voter2D) => vb.partyPrimary === party)
    }
    this.candidateDragSelect = (c: Candidate) => { this.dragWithPrimary(c, false) }
  }

  generatePrimaryTable = (party: Party, result: PluralityResult): CandidateResultTable => {
    let title = `${party.name} Primary`
    let tableId = `${party.name}Primary`
    let onSelect = () => {
      this.stopAllTimers()
      this.selectPrimaryResult(result, party)
    }
    let rows = this.generateResultRows(result, onSelect)
    return new CandidateResultTable(title, "", [], rows, "resultTable", tableId)
  }


  dragWithIRV = (c: Candidate, round: number) => {
    // work backwards to find a round that contains this candidate.
    let r = round
    while (r > 0 && this.irvResult.rounds[r].orderedCandidates.indexOf(c) < 0)
      --r
    this.selectIRVTable(r)
  }

  selectIRVTable = (round: number) => {
    this.colorVotersByIRVRound(round)
    this.applyVoterColors = () => {
      this.colorVotersByIRVRound(round)
    }
    this.candidateDragSelect = (c: Candidate) => {
      this.dragWithIRV(c, round)
    }
  }

  pluralityTable = (result: PluralityResult): CandidateResultTable => {
    let onSelect = () => {}
    let irvRound = false
    let rows = this.generateResultRows(result, onSelect, irvRound)
    let tableId = "pluralityTable"
    return new CandidateResultTable(`Voter Preferences`,
        "",
        [],
        rows,
        "resultTable",
        tableId)
  }

  irvTable = (result: PluralityResult, round: number, irvRound: boolean): CandidateResultTable => {
    let tableId = `irvRound${round}`
    let onSelect = () => {
      this.stopAllTimers()
      this.selectIRVTable(round)
    }
    let rows = this.generateResultRows(result, onSelect, irvRound)

    return new CandidateResultTable(`IRV Results Round ${round + 1}`,
        "",
        [],
        rows,
        "resultTable",
        tableId)
  }

  highlightGeneral = () => {
    this.highlightResult("generalResult")
  }

  highlightConsensusRow = (c1: Candidate, c2: Candidate) => {
    d3.selectAll(".h2hRow").style("opacity", .4)
    d3.selectAll(`.h2hRow.${c1.name}-${c2.name}`).style("opacity", 1)
    // d3.selectAll(`.${c1.name}-${c2.name}`).style("opacity", 1)
  }

  highlightConsensusBlock = (candidateName: string) => {
    let tableId = `${candidateName}_ConsensusResults`
    this.highlightResult(tableId)
  }

  highlightIRVRound = (round: number) => {
    this.highlightResult(`irvRound${round}`)
  }
  highlightResult = (resultName: string) => {
    let tables = d3.selectAll(".resultTable")
    tables.style("opacity", ".6")

    let id = `#${resultName}`
    let target = d3.selectAll(id)
    target.style("opacity", "1")
  }

  pluralityResultTables = (): CandidateResultTable[] => {
    return [this.pluralityTable(this.irvResult.rounds[0])]
  }

  irvResultTables = (): CandidateResultTable[] => {
    return this.irvResult.rounds.map((r, i) => {
      return this.irvTable(r, i, i < this.irvResult.rounds.length - 1)
    }).reverse()
  }

  h2hResultTables = (): CandidateResultTable[] => {
    return this.h2hElection.result.orderedCandidates.slice(0, -1).map(c => this.h2hTable(c))
  }

  pctVote = (n: number): string => {
    return (100 * n / this.voters.bubbles.length).toFixed(1) + '%'
  }

  dragWithH2H = (c: Candidate, c1: Candidate, c2: Candidate) => {
    if (c !== c1 && c !== c2) {
      this.selectH2HPair(c1, c)
    }
  }

  selectH2HPair = (c1: Candidate, c2: Candidate) => {
    console.log(`select h2hPair:  ${c1.name}, ${c2.name}`)
    this.colorVotersByPreference(new Set([c1, c2]), `Voter Preference between ${c1.name} and ${c2.name}`)
    this.highlightConsensusBlock(c1.name)
    this.highlightConsensusRow(c1, c2)
    this.applyVoterColors = () => {
      this.colorVotersByPreference(new Set([c1, c2]), `Voter Preference between ${c1.name} and ${c2.name}`)
    }
    this.candidateDragSelect = (c: Candidate) => {
      this.dragWithH2H(c, c1, c2)
    }
  }

  cycleH2HPairs = (pairs: Candidate[][], index: number) => {
    this.selectH2HPair(pairs[index][0], pairs[index][1])
    let nextIndex = (index + 1) % pairs.length
    this.addTimer("cycleH2HPairs", () => { this.cycleH2HPairs(pairs, nextIndex)}, 3000)
  }

  h2hTable = (c1: Candidate, winsOnly: boolean = true): CandidateResultTable => {
    let tableId = `${c1.name}_ConsensusResults`
    let colorMap = new Map(this.candidates2D.bubbles.map(cb => [cb.candidate, cb.candidateColor]))
    let c2Array = this.h2hElection.result.orderedCandidates.filter(c2 => c1 !== c2)
    if (winsOnly)
      c2Array = c2Array.filter(c2 => this.h2hElection.c1TieOrWin(c1, c2))

    let winLossClass = (a: number, b: number): string => {
      if (a > b) return "winningCandidate"
      else return "losingCandidate"
    }
    let rows: TableItem[][] = c2Array.map(c2 => {
      let onSelect = () => {
        this.stopAllTimers()
        this.selectH2HPair(c1, c2)
      }
      let [v1, v2] = this.h2hElection.pairResult(c1, c2)
      let v1s = this.pctVote(v1)
      let v2s = this.pctVote(v2)
      let classes = `h2hRow ${c1.name}-${c2.name} `
      return [
        new TableItem(c1.name, {background: colorMap.get(c1)}, onSelect, classes + winLossClass(v1, v2), c1),
        new TableItem(v1s, {background: colorMap.get(c1)}, onSelect, classes + winLossClass(v1, v2)),
        new TableItem(c2.name, {background: colorMap.get(c2)}, onSelect, classes + winLossClass(v2, v1), c2),
        new TableItem(v2s, {background: colorMap.get(c2)}, onSelect, classes + winLossClass(v2, v1)),
      ]
    })

    return new CandidateResultTable(
        winsOnly ? `${c1.name}'s Wins` : `${c1.name}'s Comparisons`,
        "",
        [],
        rows,
        "resultTable", tableId)
  }

  onUpdate = (simulationState: SimulationState): void => {
    // console.log(simulationState)
  }

  onLeanUpdate = (districtLean: number): void => {
    if (districtLean !== this.lean) {
      this.lean = districtLean
      this.voters.updatePopulation(districtLean)
      this.computeMedianVoter()
      this.voters.setLayout2D(500)
      this.voters.render()
      this.processCandidateUpdate(this.candidates2D.bubbles)
    }
  }

  onControlUpdate = (s: ControlState): void => {
    let updatedCandidates: Candidate2D[] = []
    this.controlState = s;
    this.processCandidateUpdate(updatedCandidates)
  }

  runElections = (): void => {
    let candidates = this.candidates2D.candidates()
    let config = new ElectionConfig(this.controlState.partyPreference, this.controlState.uncertainty)
    blockTimer(() => {
      this.setVoterBallots(candidates, config)
      this.irvResult = this.computeIRVResult(candidates)
      this.h2hElection = new HeadToHeadElection(this.voters.bubbles.map(vb => vb.ballot), new Set(candidates))
      this.primaryElection = this.runPrimaryBasedElection()
    }, "computeElectionResults")
  }

  processCandidateUpdate = (updated: Candidate2D[]) => {
    updated.forEach(cb => this.updateRepresentationScore(cb))
    this.runElections()
    this.applyVoterColors()
    this.onUpdate(this.currentSimulationState())
  }

  setNewCandidates = (candidates: Candidate2D[]) => {
    this.candidates2D.setNewCandidates(candidates)
    candidates.forEach(cb => this.updateRepresentationScore(cb))
    candidates.forEach(cb => cb.active = true)
    this.runElections()
    this.onUpdate(this.currentSimulationState())
  }


  logCount = 0
  votersByPreferenceFcn = (activeCandidates: Set<Candidate>,
                           indexCandidates: Candidate[],
                           filter: (vb: Voter2D) => boolean = (_vb: Voter2D) => true): (v: Bubble) => string => {

    return (v: Bubble) => {
      let vb = (v as Voter2D)
      if (filter(vb)) {
        let index = vb.ballot.preferredIndex(activeCandidates, indexCandidates)
        if (index < 0 || index > this.candidates2D.bubbles.length) {
          if (this.logCount < 10) {
            // console.log(`index out of range: ${index}`, activeCandidates, indexCandidates)
            this.logCount++
          }
          return "gray"
        }
        return this.candidates2D.bubbles[index].candidateColor
      } else {
        return "aliceblue"
      }
    }
  }
  clearGraphTitle = () => {
    this.clearRenderItem("graphTitle")
  }

  setGraphTitle = (title: string) => {
    this.addRenderItem(new TextItem(title, "graphTitle", this.svg,
        500, 30,
        "black",
        "middle",
        "30pt"))
  }

  colorVotersByPreference = (activeCandidates: Set<Candidate>,
                             title: string,
                             filter: (vb: Voter2D) => boolean = (_vb: Voter2D) => true): void => {

    this.setGraphTitle(title)
    this.renderRenderItems()
    this.voters.setOpacity((v: Bubble) => filter(v as Voter2D) ? .8 : 0)
    this.voters.bubbles.forEach(vb => {
      vb.opacity = filter(vb) ? .8 : 0
    })

    this.voters.setColor(this.votersByPreferenceFcn(activeCandidates, this.candidates2D.candidates(), filter), `color by preference`)
    this.candidates2D.setActiveCandidates(activeCandidates)
    this.candidates2D.setOpacity((b: Bubble) => (b as Candidate2D).active ? 1 : .3)
  }

  colorVotersByIRVRound = (round: number) => {
    this.applyVoterColors = () => {
      this._colorVotersByIRVRound(round)
    }
    this.applyVoterColors()
  }

  _colorVotersByIRVRound = (round: number) => {
    let activeCandidates = new Set(this.irvResult.rounds[round].orderedCandidates)
    this.colorVotersByPreference(activeCandidates, `IRV Round ${round + 1} Voter Preference`)
    this.highlightIRVRound(round)
  }

  addTextInstruction = (x: number, y: number, text: string) => {
    this.svg.append("text")
        .attr("class", "textInstruction")
        .text(text)
        .attr("x", x)
        .attr("y", y)
        .style("font-size", "12pt")
        .style("text-anchor", "end")
        .style("fill", "black")
  }

  addInstructions = () => {
    this.clearInstructions()
    // this.addTextInstruction(990, 70, "Drag the candidate circles to try different scenarios.")
  }

  clearInstructions = () => {
    this.svg.selectAll(".textInstruction").remove()
  }

  electionSim = (_progress: number) => {
    this.voters.setColor((_b: Bubble) => "pink", "pink")
    this.candidates2D.setColor((b: Bubble) => (b as Candidate2D).candidateColor, "candidateColor")
    this.voters.setLayout2D(3000)
    this.candidates2D.setLayout2D(1000)
    this.voters.createAxes(this.svg)
    this.colorVotersByIRVRound(0)
    this.candidates2D.onUpdate = (cb: Candidate2D) => {
      this.processCandidateUpdate([cb])
      this.stopAllTimers()
      this.candidateDragSelect(cb.candidate)
    }
    this.addInstructions()
  }

  render = (): void => {
    this.voters.render()
    this.voters1D.render()
    this.candidates2D.render()
    this.map.render()
    this.memberController.render()
    this.h2hMemberController?.render()
    this.htmlController.render()
    this.renderRenderItems()
  }
  showIRVRaceCandidates = (newCandidates: Candidate2D[]) => {
    this.htmlController.clear()
    this.displayVoters()
    this.setNewCandidates(newCandidates)
    this.candidates2D.setLayout2D(100)
    this.candidates2D.onUpdate = (cb: Candidate2D) => {
      this.processCandidateUpdate([cb])
    }
    this.candidates2D.setColor((b: Bubble) => (b as Candidate2D).candidateColor, "candidateColor")
    this.colorVotersByIRVRound(0)
    this.candidates2D.setLayout2D(1000)
    this.clearInstructions()
  }

  clearVoters1D = (duration: number = 1000) => {
    this.clearInstructions()
    this.voters1D.clearAxes()
    this.voters1D.setLayout(new OffscreenLayout())
  }

  clearVoters = (duration: number = 1000) => {
    // super doesn't work?
    this.voters.setLayout(new OffscreenLayout(-1000, -900, 0, 1000, duration), "clearVoters")
    this.voters.clearAxes()
    this.clearInstructions()
  }


  displayHouse = (yValue: number, duration: number) => {
    this.clearVoters(0)
    this.memberController.layoutByIdeology(this.svg, yValue, duration)
    this.memberController.colorByParty()
    this.memberController.createSlider(yValue + 80)
    this.addRenderItem(new TextItem("Drag the slider to show ideology for different Congresses.",
        "slider-instructions", this.svg, 500, yValue + 140, "black", "middle", "14pt"))

  }

  showHouse = (_progress: number) => {
    this.displayHouse(850, 1000)
  }

  showHouseAndVoters = (_progress: number) => {
    this.clearGraphTitle()
    this.voters.setOpacity((_b: Bubble) => .3)
    this.memberController.layoutByIdeology(this.svg, 850, 1000)
    this.memberController.colorByParty()
    this.memberController.createSlider(930)
    this.candidates2D.clear(1)
    // get rid of x/y axis from 2D voters.
    this.voters.clearAxes()
    this.layoutVotersByIdeology(500)
    this.voters.setColor(this.colorBubbleByIdeology, "votersByIdeology")
    this.updateSliders()
  }

  showSimulatedHouse = (_progress: number) => {
    this.htmlController.clear()
    this.clearVoters(1000)
    this.clearGraphTitle()
    this.candidates2D.clear(1000)
    this.memberController.layoutBySampleIdeology(this.svg, "primary", 900, 2000, "Simulated United States House of Representatives Using Primaries")
    this.voters.setColor(this.colorBubbleByIdeology, "votersByIdeology")
    let cFcn = (district: string): string => {
      let representation = this.combinedDistrictData.sampleRepresentation("primary", district)
      return this.ranges.electorateRepresentationColorScale(representation)
    }
    this.map.setColorFunction(cFcn, "U.S. House Districts Colored by Representation simulated representation")
    this.map.display(new DisplayRectangle(20, 50, 900, 600))
    this.clearButtons()

    this.createButtons(
        [
          { label: "Actual", id: "actual", cb: this.selectActual },
          { label: "Simulated Primaries", id: "primaries", cb: this.selectPrimary },
        ]
    )
    this.selectPrimary()
    this.updateSliders()
  }


  displayVoters = (opacity: number = .8) => {
    this.onLeanUpdate(this.lean)
    this.clearMembers(1)
    this.map.clear()
    this.voters.setLayout2D(2000)
    this.voters.setOpacity((_b: Bubble) => opacity)
    this.voters.clearAxes()
    this.voters.createAxes(this.svg)
  }


  showVoters2D = (_progress: number) => {
    this.clearRenderItem("slider-instructions")
    this.displayVoters()
    this.resetVoterOpacity(.8)
    this.voters.setColor(this.colorBubbleByIdeology, "colorByIdeology")
    this.candidates2D.clear(1000)
    this.setGraphTitle("Voters by Fiscal and Social Ideology")
    this.clearInstructions()
    this.onUpdate(this.currentSimulationState())
    this.updateSliders()
  }


  layoutCandidatesAndVoters = (voterOpacity: number = .8) => {
    this.displayVoters(voterOpacity)
    this.voters.setLayout2D(3000)
    this.candidates2D.setColor((b: Bubble) => (b as Candidate2D).candidateColor, "candidateColor")
    this.candidates2D.setLayout2D(100)
    this.addInstructions()
  }

  displayOutcome = (candidates: Candidate2D[]) => {
    // this.setLoyalty(0)
    this.resetVoterOpacity(.9)
    this.displayVoters()
    this.candidateDragSelect = () => {}
    this.setNewCandidates(candidates)
    let gcSet = new Set(candidates.map(c => c.candidate))
    let title = candidates.length === 2 ? `Voter Preference between ${candidates[0].name} and ${candidates[1].name}` :
        "Voter Preferences"
    this.colorVotersByPreference(gcSet, title)
    this.layoutCandidatesAndVoters()
    this.addInstructions()
    this.applyVoterColors = () => {
      this.colorVotersByPreference(gcSet, title)
    }
  }
  showGeneralOutcome = () => {
    // this.setUncertainty(.02)
    this.displayOutcome(this.twoCandidateBase())
  }

  showAlternateOutcome = () => {
    // this.setUncertainty(.02)
    let ac = this.twoCandidateAlternate()
    let acSet = new Set(ac.map(c => c.candidate))
    this.voters.createAxes(this.svg)
    this.setNewCandidates(ac)
    this.colorVotersByPreference(acSet, `Voter Preference between ${ac[0].name} and ${ac[1].name}`)
    this.layoutCandidatesAndVoters()
  }


  selectHouseVoters = (colorName: string, extractor: (mb: MemberBubble) => number)  => {
    let layout = new LayoutMembersVsVoters(
        extractor,
        this.ranges.ideologyScale,
        this.ranges.leanScale,
        this.combinedDistrictData,
    )
    this.memberController.setLayout(layout, `MembersVsVoters-${colorName}`)
    this.memberController.colorBySampleIdeology(colorName)
  }

  showHouseVsVoters = (extractor: (mb: MemberBubble) => number) => {
    // console.log("showHouseVsVoters")
    this.clearVoters()
    this.clearMap()
    let layout = new LayoutMembersVsVoters(
        extractor,
        this.ranges.ideologyScale,
        this.ranges.leanScale,
        this.combinedDistrictData,
    )
    this.memberController.setLayout(layout, "MembersVsVoters")
    this.memberController.colorBySampleIdeology("headToHead")
    this.memberController.setOpacity((_m: Bubble) => 1.0)
    this.memberController.createMemberVoterAxes(this.svg, "Ideology")

    let selectActual = (): void => {
      this.selectHouseVoters("actual", (mb: MemberBubble) => mb.ideology)
    }
    // let selectPrimary = (): void => {
    //   this.selectHouseVoters("primary", (mb: MemberBubble) => this.combinedDistrictData.sampleIdeology("primary", mb.member.district))
    // }
    let selectConsensus = (): void => {
      this.selectHouseVoters("headToHead", (mb: MemberBubble) => this.combinedDistrictData.sampleIdeology("headToHead", mb.member.district))
    }


    this.createButtons(
        [
          { label: "Actual", id: "actual", cb: selectActual },
          // { label: "Simulated Primaries", id: "primaries", cb: selectPrimary },
          { label: "Consensus Voting", id: "consensus", cb: selectConsensus },
        ]
    )

    this.addRenderItem(new TextItem(
        "2021 United States House of Representatives",
        "memberTitle",
        this.svg,
        500,
        40,
        "black",
        "middle",
        "18pt"
    ))

    this.selectButton("actual")
    this.render()
  }

  showLeanVsPolicyLines = (showConsensusLine: boolean = false) => {
    // this.clearVoters(0)
    // this.clearMembers(0)
    this.memberController.createMemberVoterAxes(this.svg, "Policy")
    let l1 = {
      x1: this.ranges.leanScale(0),
      y1: this.ranges.ideologyYScale(-1),
      x2: this.ranges.leanScale(60),
      y2: this.ranges.ideologyYScale(-2),
      color: "red"
    }
    let l2 = {
      x1: this.ranges.leanScale(0),
      y1: this.ranges.ideologyYScale(1),
      x2: this.ranges.leanScale(-60),
      y2: this.ranges.ideologyYScale(2),
      color: "blue"
    }

    let l3 = {
      x1: this.ranges.leanScale(-60),
      y1: this.ranges.ideologyYScale(1),
      x2: this.ranges.leanScale(60),
      y2: this.ranges.ideologyYScale(-1),
      color: "url(#line-gradient)"
    }

    let lines = showConsensusLine ? [l3] : [l1, l2]
    let strokeWidth = 10

    let defs = this.svg.append('defs').attr('class', "foo")

    defs.append("linearGradient")
        .attr("id", "line-gradient")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", l3.x1)
        .attr("y1", 0)
        .attr("x2", l3.x2)
        .attr("y2", 0)
        .selectAll("stop")
        .data([
          {offset: "0%", color: "blue"},
          {offset: "100%", color: "red"}
        ])
        .enter().append("stop")
        .attr("offset", function (d) { return d.offset; })
        .attr("stop-color", function (d) { return d.color; });

    this.svg.selectAll(".policyLine")
        .data(lines)
        .join(
// @ts-ignore
            enter => {
              enter
                  .append("line")
                  .classed("policyLine", true)
                  .attr("x1", (l) => l.x1)
                  .attr("y1", (l) => l.y1)
                  .attr("x2", (l) => l.x2)
                  .attr("y2", (l) => l.y2)
                  .style("stroke", (l) => l.color)
                  .style("stroke-width", strokeWidth)
                  .style("stroke-linecap", "round")
            },
            update => {
              update.attr("x1", (l) => l.x1)
                  .attr("y1", (l) => l.y1)
                  .attr("x2", (l) => l.x2)
                  .attr("y2", (l) => l.y2)
                  .style("stroke", (l) => l.color)
                  .style("stroke-width", strokeWidth)
                  .style("stroke-linecap", "round")
            },
            exit => {
              exit.each(b => {
                // console.log(`removing: `, b)
              }).remove()
            }
        )

  }

  showAllVoterOutcomes = () => {
    this.layoutCandidatesAndVoters()
    this.voters.createAxes(this.svg)
    let ac = this.twoCandidateAlternate()
    this.setNewCandidates(ac)
    this.candidates2D.setOpacity((b: Bubble) => (b as Candidate2D).active ? 1 : 0)
    this.candidates2D.setActiveCandidates(new Set([this.candidates2D.bubbles[0].candidate]), 0)
    this.resetVoterOpacity(.9)
    this.candidateDragSelect = () => {}
    this.applyVoterColors = this.colorVotersByAlternate
    this.updateRepresentationScore(this.candidates2D.bubbles[0])
    this.colorVotersByAlternate()
    this.setGraphTitle("Voters Who Would Defeat Juan")
    this.onUpdate(this.currentSimulationState())
    this.updateSliders()
  }
  showActualHouse = () => {
    this.memberController.slider!?.setValue(117)
    this.voters.setLayout(new OffscreenLayout(-600, -600, 0, 0, 500))
    this.candidates2D.clear(1)
    this.clearGraphTitle()
    this.map.setColorByRepresentation()
    this.map.display(new DisplayRectangle(20, 50, 900, 600))
    this.map.caption = "Voter Representation in the U.S. House of Representatives"
    this.memberController.layoutByIdeology(this.svg, 900, 1000)
    this.updateSliders()
  }


  showAllOpponents = () => {
    this.onLeanUpdate(this.lean)
    this.layoutCandidatesAndVoters()
    let newCandidates = this.fiveCandidateAll()
    this.setNewCandidates(newCandidates)
    this.colorVotersByPreference(new Set([this.Juan(), this.Anne()]), `Voter Preference between ${this.Juan().name} and ${this.Anne().name}`)
    this.highlightConsensusBlock(this.Juan().name)
    this.updateSliders()
  }

  cycleHousePolarization = (index: number): void => {
    let keyDates = [1951, 1961, 1971, 1981, 1991, 2001, 2007, 2009, 2011, 2013, 2015, 2017, 2019]
    let congresses = keyDates.map(d => (d - 1787) / 2)
    let congress = congresses[index % keyDates.length]
    this.memberController.slider!?.setValue(congress)
    let obj = this
    this.memberController.slider!?.onUserUpdate(() => {
      obj.stopTimer("cycleHousePolarization")
    })
    if (index < keyDates.length)
      this.addTimer("cycleHousePolarization", () => {this.cycleHousePolarization(index + 1)}, 1000)
  }

  cyclePrimaryResults = (index: number) => {
    if (index === 0 || index === 1) {
      let party = [Republicans, Democrats][index]
      let result = [this.primaryElection.republicanPrimary, this.primaryElection.democraticPrimary][index]
      this.selectPrimaryResult(result, party)
    } else {
      this.selectGeneralResult(this.primaryElection.generalResult)
    }
    this.addTimer("cyclePrimaryResults", () => {this.cyclePrimaryResults((index + 1) % 3)}, 3000)
  }

  showPrimaryResults = (party: Party, newCandidates = this.fiveCandidateAll()) => {
    this.onLeanUpdate(this.lean)
    this.layoutCandidatesAndVoters()
    this.voters.createAxes(this.svg)
    this.setNewCandidates(newCandidates)
    let primaryCandidates = newCandidates.filter(c => c.party === party).map(c => c.candidate)
    this.colorVotersByPreference(
        new Set(primaryCandidates),
        `${party.name} Primary Voter Choices`,
        (vb) => vb.partyPrimary === party
    )
    let result = party === Republicans ? this.primaryElection.republicanPrimary : this.primaryElection.democraticPrimary
    this.selectPrimaryResult(result, party)
    this.timerCount = 3
    this.cyclePrimaryResults(1)
  }

  colorVotersByAlternate = () => {
    this.candidates2D.setOpacity((b: Bubble) => (b as Candidate2D).active ? 1 : 0)
    this.voters.setColor((v: Bubble) => {
      let vb = (v as Voter2D)
      if (vb.distanceFromMean < this.candidates2D.bubbles[0].distanceFromMedian) {
        return "dodgerblue"
      } else {
        return this.twoCandidateAlternate()[0].candidateColor
      }
    }, `color by preference`)
  }

  resetVoterOpacity = (opacity: number) => {
    this.voters.setOpacity((_v: Bubble) => opacity)
    this.voters.bubbles.forEach(vb => {
      vb.opacity = opacity
    })
  }

  findCandidate = (name: string): Candidate => {
    return this.candidates2D.candidates().find((c) => c.name === name)!
  }

  h2hVoterSelectAll = (c1Name: string, c2Name: string) => {
    this.h2hVoterSelect(c1Name, c2Name, this.fiveCandidateAll())
  }

  h2hVoterSelect = (c1Name: string, c2Name: string, candidates: Candidate2D[]) => {
    this.displayVoters()
    this.setNewCandidates(candidates)
    let c1 = c1Name ? this.findCandidate(c1Name) : this.h2hElection.result.orderedCandidates[0]
    let c2 = c2Name ? this.findCandidate(c2Name) : this.h2hElection.result.orderedCandidates[1]
    this.colorVotersByPreference(new Set([c1, c2]), `Voter Preference between ${c1.name} and ${c2.name}`)
    this.candidates2D.setLayout2D(1000)
    this.addInstructions()
    this.highlightConsensusBlock(c1.name)
    this.updateSliders()
    this.selectH2HPair(c1, c2)
    this.timerCount = 1
    this.addTimer("selectCandidates", () => { this.selectH2HPair(c1, c2)}, 1000)
    // this.timerCount = pairs.length
    // this.cycleH2HPairs(pairs, 0)
  }

  showConsensusHouse = (_progress: number) => {
    this.clearGraphTitle()
    this.createButtons(
        [
          { label: "Actual", id: "actual", cb: this.selectActual },
          { label: "Simulated Primaries", id: "primaries", cb: this.selectPrimary },
          { label: "Consensus Voting", id: "consensus", cb: this.selectConsensus },
        ]
    )
    this.candidates2D.clear(1000)
    this.clearVoters(1)
    this.map.display(new DisplayRectangle(20, 50, 900, 600))
    this.selectConsensus()
    this.buttons.find(b => b.id === "consensus")?.activate()
    this.updateSliders()
  }

  clearButtons = () => {
    this.buttons.forEach((b: SVGButton) => {this.clearRenderItem(b.id)})
    this.buttons = []
  }

  selectButton = (id: string): void => {
    let b = this.buttons.find((b: SVGButton) => b.id === id)
    if (b) {
      b.activate()
      b.clickCB()
    }
  }

  createButtons = (buttonsToCreate: ButtonSpec[]) => {
    let buttonWidth = 150
    let xPos = 670
    let yStart = 670
    let height = 20
    let fontSize = "14pt"
    let fg = "white"
    let bg = "blue"
    let inactiveBG = "lightgray"
    let inactiveFG = "black"
    let gap = 30
    let index = 0

    let makeButton = (label: string, id: string, cb: () => void): SVGButton => {

      let cbWrapper = () => {
        this.buttons.forEach((b: SVGButton) => {
          if (b.id === id) b.activate()
          else b.deactivate()
          cb()
          this.render()
        })
      }

      let b = new SVGButton(label, id, this.svg, false, xPos, yStart + gap * index, height, buttonWidth,
          bg, fg, inactiveBG, inactiveFG, fontSize, cbWrapper)
      index += 1
      this.addRenderItem(b)
      return b
    }
    this.clearButtons()
    this.buttons = buttonsToCreate.map((b: ButtonSpec) => makeButton(b.label, b.id, b.cb))
  }

  consensusMapAndMembers = (altTitle: string = "") => {
    this.memberController.layoutBySampleIdeology(this.svg, "headToHead", 900, 1000, "Simulated House Of Representatives with Consensus Voting")
    let cFcn = (district: string): string => {
      let representation = this.combinedDistrictData.sampleRepresentation("headToHead", district)
      return this.ranges.electorateRepresentationColorScale(representation)
    }
    let title = (altTitle === "") ? "U.S. House Districts Colored by Representation Using Consensus Voting" : altTitle
    this.map.setColorFunction(cFcn, title)
  }

  primaryMapAndMembers = () => {
    this.memberController.layoutBySampleIdeology(this.svg, "primary", 900, 1000, "Simulated House Of Representatives with Primaries")
    let cFcn = (district: string): string => {
      let representation = this.combinedDistrictData.sampleRepresentation("primary", district)
      return this.ranges.electorateRepresentationColorScale(representation)
    }
    this.map.setColorFunction(cFcn, "Simulated U.S. House Districts Colored by Representation")
  }

  actualMapAndMembers = () => {
    this.memberController.layoutByIdeology(this.svg, 900, 1000)
    this.map.setColorByRepresentation()
  }

  selectConsensus = (title: string = "") => {
    this.consensusMapAndMembers(title)
  }
  selectPrimary = () => {
    this.primaryMapAndMembers()
  }

  selectActual = () => {
    this.actualMapAndMembers()
  }


  showRCVBallotDefault = () => {
    let candidates = ["Anne-R", "Bob-D", "Juan-R", "Maria-R", "Sue-D"]
    let choiceOrder = ["Sue-D", "Bob-D", "Anne-R", "Maria-R", "Juan-R"]
    let colors = ["gold", "lightseagreen", "cyan", "chartreuse", "violet"]
    let title = "Emily's Ballot"
    let caption = "Candidate colors are provided for clarity, but would not be present on an actual ballot"
    this.showRCVBallot(candidates, choiceOrder, colors, title, caption)
  }
  showRCVBallot = (candidates: string[],
                   choiceOrder: string[],
                   colors: string[],
                   title: string,
                   caption: string) => {
    this.updateSliders()
    this.clearGraphTitle()
    this.clearButtons()
    this.map.clear()
    this.clearVoters(1)
    this.clearMembers(1)
    let ballot = new BallotWidget({
      choiceOrder: choiceOrder,
      candidates: candidates,
      title: title,
      caption: caption,
      colors: colors
    })
    this.htmlController.set(ballot)
  }

  setLoyalty = (partyPreference: number) => {
    if (partyPreference !== this.controlState.partyPreference) {
      let controlState: ControlState = {
        uncertainty: this.controlState.uncertainty,
        partyPreference: partyPreference
      }
      this.onControlUpdate(controlState)
    }
  }

  setUncertainty = (uncertainty: number) => {
    if (uncertainty !== this.controlState.uncertainty) {
      let controlState: ControlState = {
        partyPreference: this.controlState.partyPreference,
        uncertainty: uncertainty
      }
      this.onControlUpdate(controlState)
    }
  }

  onPartyPreferenceTimer = (newPreference: number) => {
    this.stopTimer("partyPreference")
    this.setLoyalty(newPreference)
    let nextPreference = newPreference + .2
    if (nextPreference > 1) {
      nextPreference = 0
    }
    else {
      this.addTimer("partyPreference", () => {this.onPartyPreferenceTimer(nextPreference)}, 1000)
    }
  }

  showPartyPreferenceLoop = (_progress: number) => {
    this.updateSliders()
    this.timerCount = 5
    this.onPartyPreferenceTimer(0)
  }

  progressBasic = (_progress: number) => {
  }

  newSectionMethods = [
    this.electionSim,
  ]

  progressMethods = [
    this.progressBasic
  ]

}

export default ElectionSimApp