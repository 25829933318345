import React from 'react';
import {DSVRowArray} from "d3";
import styles from "./ConsensusVoting.module.scss"
import Step from "../ui/components/Step";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
// import HorizontalResultsBox from "../ElectionSimulation/HorizontalResultsBox";
import ResultsBox from "../ElectionSimulation/ResultsBox";
import ElectionSimStoryBase from "../ElectionSimStoryBase/ElectionSimStoryBase";
import BallotWidget from "../ui/elements/BallotWidget";
import {ConsensusVotingApp3} from "./ConsensusVotingApp3";

class ConsensusVotingStory3 extends ElectionSimStoryBase {

  mapAreas = [
    "drawingArea1", // 0
    "drawingArea1", // 1
    "drawingArea1", // 2
    "drawingArea1", // 3
    "drawingArea1", // 4

    "drawingArea1", // 5
    "drawingArea1", // 6
    "drawingArea2", // 7
    "drawingArea2", // 8
    "drawingArea2", // 9

    "drawingArea2", // 10
    "drawingArea2", // 11
    "drawingArea2", // 12
  ]
  visualizationAreas = [
    "drawingArea1", // 0
    "drawingArea1", // 1
    "drawingArea1", // 2
    "drawingArea1", // 3
    "drawingArea1", // 4

    "drawingArea1", // 5
    "drawingArea1", // 6
    "drawingArea2", // 7
    "drawingArea2", // 8
    "drawingArea2", // 9

    "drawingArea2", // 10
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",

  ]

  createApp = (
      visDivId: string,
      dvr: DSVRowArray,
      DWNominate: DSVRowArray,
      simulationResults: DSVRowArray,
      sampleCongress: DSVRowArray,
      usTopo: any): ElectionSimApp => {
    return new ConsensusVotingApp3(this.visualizationAreas[0], dvr, DWNominate, simulationResults, sampleCongress, usTopo, 5001, 4)
  }


  render() {
    let mdash = "\u2014"
    return (
        <div className={styles.content}>
          <div className={styles.flyover} id={"detailsFlyover"} style={{
            display: "none",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 1,
          }}>
            <h6> Representative: <span id={"memberName"}>foo</span></h6>
            <h6> Party: <span id={"party"}>foo</span></h6>
            <h6> District: <span id={"districtName"}>foo</span></h6>
            <h6> Left/Right Ideology: <span id={"ideology"}>0</span></h6>
            <h6><span id={"representation"}> </span></h6>
          </div>
          <div>
            {this.renderVisArea(styles.drawing1, "drawingArea1")}
            <div style={{
              minHeight: "30vh"
            }}/>

            { /* 0 */}
            <Step title="Consensus Voting" className={styles.stepElement} first={true}>
              <p>
                <b>
                  The candidate the voters prefer over every other candidate wins.
                </b>
              </p>
              <p>
                When the voters prefer one candidate over each of the other candidates, that candidate is the Consensus
                Candidate and the winner. Choosing any other candidate would override the express wishes of the voters.
              </p>
              <p>
                Consensus Voting uses a simple three-step process.
              </p>
              <ol>
                <li>Voters rank the candidates.</li>
                <li>When most voters rank Candidate A over Candidate B, the voters <i>prefer</i> A over B.</li>
                <li>The candidate the voters prefer over every other candidate is the Consensus Candidate and the
                  winner.
                </li>
              </ol>
              <div style={{
                minWidth: 600,
              }} className={styles.centeredResultBox}>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("Consensus0")?.h2hResults}
                    title={"Consensus Voting Results"}
                />
              </div>
              <p>
                This shows the outcome of an election with three candidates held under Consensus Voting. You can click
                on a line in the results to show which candidate each voter chose in that comparison.
              </p>
              <p>
                Keep scrolling to walk through each step in the process.
              </p>
            </Step>

            { /* 1 */}
            <Step title="Step 1: Rank the Candidates" className={styles.stepElement}>
              <p>
                This election features three candidates, Ryan (Republican), Rebecca (Republican), and Diane (Democrat),
                in an electorate that favors a Republican candidate.
              </p>
              <div className={styles.centeredResultBox}>
                <BallotWidget
                    colors={["gold", "chartreuse", "violet"]}
                    choiceOrder={["Diane", "Ryan", "Rebecca"]}
                    candidates={["Diane", "Rebecca", "Ryan"]}
                    title={"Alice's Ballot"}
                />
              </div>
              <p>
                This is a sample ballot for Alice, a Democratic voter. Like all Democratic-leaning voters, Alice lists
                Diane as her first choice.
              </p>
              <p>
                Crucially, the ranked-choice ballot allows Alice to express a preference between the two
                Republican candidates. Alice rewards Ryan's less partisan approach by listing him second.
              </p>
              <p>
                Consensus Voting uses every ranking on every ballot to determine the winner.
              </p>
            </Step>

            { /* 2 */}
            <Step title="Step 2: Counting the ballots - Ryan Vs. Rebecca" className={styles.stepElement}>
              <p>
                <i>
                  If most voters rank Ryan over Rebecca, the voters prefer Ryan over Rebecca.
                </i>
              </p>

              <div className={styles.centeredResultBox}>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("RyanVRebecca")?.h2hResults}
                    title={"Consensus Voting Results"}
                />
              </div>

              <p>
                Each ballot where the voter ranked Ryan over Rebecca counts as a vote for Ryan and vice versa.
                Examining every ballot shows that 64% of the voters ranked Ryan over Rebecca, but only 36%
                of voters ranked Rebecca over Ryan. The voters <i>prefer</i> Ryan over Rebecca.
              </p>
              <p>
                Ryan is a better representative of these voters because the voters prefer Ryan over Rebecca.
              </p>
              <p>
                Democrats like Alice combine with independents and moderate Republicans to give Ryan a decisive
                victory over Rebecca.
              </p>
            </Step>

            { /* 3 */}
            <Step title="Step 2: Counting the ballots - Ryan Vs. Diane" className={styles.stepElement}>
              <p><i>
                If most voters rank Ryan over Diane, the voters prefer Ryan over Diane.
              </i>
              </p>
              <div className={styles.centeredResultBox}>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("DianeVRyan")?.h2hResults}
                    title={"Consensus Voting Results"}
                />
              </div>
              <p>
                Counting all of the ballots that ranked Ryan above Diane shows that 58.1% of voters prefer Ryan, while
                only 41.9% of voters prefer Diane.
              </p>
              <p>
                Republican and independent voters give Ryan a clear victory over Diane.
              </p>
            </Step>

            { /* 4 */}
            <Step title="Step 3: Finding the Consensus Candidate" className={styles.stepElement}>
              <div style={{
                minWidth: 600,
              }} className={styles.centeredResultBox}>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("Consensus2")?.h2hResults}
                    title={"Consensus Voting Results"}
                />
              </div>
              <p>
                The voters prefer Ryan over Rebecca, and they also prefer Ryan over Diane. Ryan is the <b>Consensus
                Candidate</b> and the winner.
              </p>
              <p>
                A majority of voters prefer Ryan over each of the alternatives, but it is a different majority depending
                on the opposition. Conservatives support Ryan against a more liberal candidate, while liberals support
                Ryan against a more conservative candidate. Almost every voter supports Ryan against one candidate or
                the
                other.
              </p>
              <p>
                Consensus doesn't mean everybody gets exactly what they want. That's impossible. Instead, Consensus
                means the voters prefer one candidate over every other candidate.
              </p>
              <p>
                In a democratic society, the consensus candidate <i>must</i> be the winner.
              </p>
            </Step>

            { /* 5 */}
            <Step title="A More Fair Process" className={styles.stepElement}>
              <div className={styles.sideBySide}>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("Consensus3")?.h2hResults}
                    title={"Consensus Voting Results"}
                />
                <div style={{minWidth: "2%"}}/>

                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("Consensus3")?.primaryResults}
                    title={"Primary Results"}
                />
              </div>
              <p>
                This shows the outcome of this election under Consensus Voting and our existing primary-based system.
              </p>
              <p>
                The voters prefer Ryan over Rebecca by a huge margin, but primary elections allow partisan forces to
                eliminate more representative candidates {mdash} candidates the voters prefer {mdash} before the
                general election. With no reasonable alternatives, voters are forced to choose between the lesser of two
                evils.
              </p>
              <p>
                We don't often see well-qualified candidates like Ryan contest elections because they know the field is
                tilted against them. Further, it's hard to attract the volunteers, donors, and media attention required
                to run a successful campaign. These candidates are normally eliminated long before election day.
              </p>
              <p>
                Primaries create a veneer of legitimacy, but the outcome does not reflect the will of the voters.
              </p>
            </Step>

            { /* 6 */}
            <Step title="A More Fair Process, Part 2" className={styles.stepElement}>
              <div className={styles.sideBySide}>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("Consensus4")?.h2hResults}
                    title={"Consensus Voting Results"}
                />
                <div style={{minWidth: "2%"}}/>

                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("Consensus4")?.irvResults}
                    title={"Instant Runoff Results"}
                />
              </div>
              <p>
                <a href={"/IRV"}>Instant Runoff Voting (IRV)</a> is a different way to interpret ranked-choice ballots.
                IRV works by eliminating the candidate with the fewest first-place votes until a candidate reaches a
                majority. Advocates of IRV claim it reduces polarization, but it almost always produces the same outcome
                a partisan primaries would produce for state and federal elections.
              </p>
              <p>
                IRV only looks at the <a href={"/IRV"}>first choice</a> on a ballot when eliminating candidates. Because
                Democratic-leaning voters have listed Diane as their first choice, IRV ignores their preference for Ryan
                over Rebecca.
              </p>
              <p>
                Without the opinions of Democratic-leaning voters, Republican-leaning voters get to decide
                which Republican is eliminated in their own "Instant Primary." They eliminate Ryan, just like they did
                with a partisan primary. Rebecca then wins against Diane.
              </p>
              <h3>Why this is wrong</h3>
              <p>
                The purpose of an election is to find the candidate who best represents the values of the voters. In a
                one-on-one election between Ryan and Rebecca, the voters prefer Ryan over Rebecca by 64% to 36%. Any
                voting rule, including IRV, would declare Ryan the winner. Ryan is clearly a better representative of
                the voters.
              </p>
              <p>
                But, when Diane enters the race, suddenly IRV declares that Rebecca is a better representative of the
                voters than Ryan. That's ludicrous. Why should Diane's presence or absence make any difference at all
                unless she wins the election?
              </p>
              <p>
                No election system is perfect, but this type of mistake is so bad and so common that candidates like
                Ryan won't even contest elections.
              </p>
            </Step>
          </div>

          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing2, "drawingArea2")}

            {/* 7 */}
            <Step title="Consensus Voting Ends Polarization" first={true} className={styles.stepElement}>
              { /* The difference a Representative democracy makes*/}
              <p>
                This is voter representation and Congressional ideology when Congress is elected using Consensus Voting.
              </p>
              <p>
                Voter representation is explained <a href={"/Representation"}>here</a>, but the green in this map of
                voter representation is a stark contrast with the brown map plurality-based elections create today.
                These are the leaders the voters would elect if the election system allowed them to elect the
                candidates they prefer.
              </p>
              <p>
                <i>Use the blue and gray buttons under the map to display outcomes under different voting rules.</i>
              </p>
              <p>
                The stark difference between Consensus Voting and our primary process dispels any illusion that the
                leaders we elect represent our values. These simulations all included a <i>representative</i> candidate near
                the center of the electorate. Such a candidate almost never runs, but they were included in the
                simulation to prove a point.
              </p>
              <p>
                When given the chance, the voters elected that <i>representative</i> candidate in every single election under
                Consensus Voting. That same candidate lost every single time under primary/plurality.
              </p>

              <p>
                Consensus Voting allows the voters to elect the candidates they collectively prefer over any other
                possible option. Candidates know that the path to victory lies in representing the common good
                for all voters. Instead of discouraging representative candidates, Consensus Voting discourages
                division, toxicity, and factionalism.
              </p>

              <p>
                The Leviathan is the intersection of election rules, mathematics, and human ambition. It cannot be
                slain, but it can be tamed. We cannot change math or human nature, but Consensus Voting changes the
                election rules so that when candidates act in their self-interest, they act in the public interest.
              </p>
            </Step>
            {/* 8 */}
            <Step title="Explore More" className={styles.stepElement}>
              <p>
                This is a deep conversation with profound implications, and this barely scratches the surface.
              </p>

              <ul style={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}>
                <li>
                  <a href={"/Democracy"}>The Purpose of Democracy</a> explains why choosing candidates who represent
                  all of their voters and can find compromise is essential to a peaceful society.
                </li>
                <li>
                  <a href={"/Factionalism"}>Factionalism</a> defines factional democracy and why factional democracies
                  will always fail. Madison saw the danger, but his plan never really had a chance.
                </li>
                <li>
                  <a href={"/ElectionSim"}> The Election Simulator </a> This explores election outcomes under
                  different election systems. How often does Instant Runoff-RCV produce bad results?
                  How often does Consensus Voting produce bad results?
                </li>
                <li>
                  <a href={"/IRV"}>Instant Runoff Voting</a> describes how Instant Runoff Voting (often called
                  Ranked Choice Voting) works and why it is not a solution for factional democracy or political
                  polarization.
                </li>
                <li>
                  <a href={"/Prefect"}>No Election System is Perfect</a> discusses the possibility of a three-way
                  tie where no candidate is undefeated and why it's not a problem.
                </li>
                <li>
                  <a href={"/SimulationTutorial"}>A Tutorial on Simulation</a> The science behind election simulation
                  both for the election visualization and for the district-by-district simulations of the U.S. House
                  of Representatives.
                </li>
              </ul>
            </Step>
          </div>
          <div style={{
            minHeight: "30vh"
          }}/>
        </div>
    )
  }
}

export default ConsensusVotingStory3
