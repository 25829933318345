import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class Global extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }


  render() {
    // let mdash = "\u2014"

    return (
        <Article title={"Factionalism is not limited to America."}
                 subtitle={"The Leviathan Stalks All Democracies."}>
          <div className={styles.textContent}>
            <div style={{
              // border: "1px solid black",
              paddingBottom: "25px",
              marginBottom: "40px",
            }}>
              <h3>In Brief:</h3>
              <ul>
                <li>Factionalism plagues all democracies.</li>
                <li>Proportional representation systems are particularly fraught.</li>
                <li>Why should a majority compromise with the minority?</li>
              </ul>
            </div>

            <p>
              Proportional representation systems attempt to create a multi-party political space where the parties are
              represented in government proportionally to their support in the electorate. The exact proportioning
              mathematics and minimum vote total to qualify for parliament varies from country to country. Many
              Parliaments split the seats between single-member districts and proportional representation districts. At
              its best, proportional representation creates legislative bodies where the members mirror the ideological
              breakdown of the electorate.
            </p>
            <p>
              If anything, proportional representation systems are more factional than the American primary/plurality
              system. Every single-member district contains a spectrum of voters from various political points of view.
              Game theory may dictate that candidates prioritize the voters who will or may vote for them, but they at
              least theoretically represent a spectrum of voters. That’s not how proportional representation works.
            </p>
            <p>
              In party-list proportional representation, there are no districts. Voters nationwide rank the parties, and
              the parties are allocated seats based on their percentage of the vote. If a party gets 25 seats, the first
              25 legislators on their list are elected. Should a legislator show any troubling signs of independent
              thinking, they are simply omitted from the list of candidates in the next election.
            </p>
            <p>
              Other proportional representation systems don’t grant the parties such god-like power. But in any
              proportional representation system, candidates represent the voters who vote for them, not those who vote
              against them. There is simply no concept of representing voters with divergent points of view. In theory,
              those voters will be represented by some other party.
            </p>
            <p>
              But who, then, can place country before party?
            </p>
            <p>
              Like our primary system, there is no penalty for telling the voters whatever excites and motivates them,
              even when that’s divisive. Instead, the penalty is for telling voters things they don’t want to hear.
              The lack of accountability to the entire electorate creates an environment that's ripe for any candidate
              who is willing to exploit our tribal differences.
            </p>
            <p>
              When these factional avatars convene to form a government, what happens to the minority? What ability to
              influence policy does the minority have? How are their rights protected? If some faction or coalition of
              factions possesses a majority, why should they compromise with the minority? How would the majority
              justify compromise to their voters?
            </p>

            <h3>Failures Abound</h3>
            <p>
              Proportional representation systems often work well for long periods when forming a majority forces the
              inclusion of parties that will compromise with the minority and respect the rule of law. But respecting
              the rule of law and the rights of the minority in any democratic government is at the discretion of
              whoever controls the levers of power. These systems may function well for decades or even centuries, but
              it is a winner-take-all system, and eventually, the winner will take all. And they don’t give it back.
            </p>
            <p>
              In Poland, party-list proportional representation allowed the “Law and Justice Party” to achieve a
              majority in parliament. The Law and Justice party opposes liberal democracy and immediately used their
              majority to undermine Poland’s democracy. Law and Justice passed a raft of blatantly unconstitutional
              measures and flooded the court system with sympathetic judges to ensure their approval. They then closed
              independent media and purged all critical voices from state media. The opposition parties and much of
              Europe watched helplessly as Law and Justice systematically tilted the electoral playing field in their
              favor. When a faction controls the government, the minority only has the rights the majority grants them.
            </p>
            <p>
              One place that was not horrified by the events in Poland was Hungary, where Victor Orban used a
              combination of single-member districts and proportional representation to implement the same authoritarian
              playbook: pack the judiciary, control the media, pack the state bureaucracy, and tilt the electoral
              playing field in his favor. When he was accused of voter tourism, bringing in noncitizens from neighboring
              countries for a day to vote in national elections, he rewrote the law to make that legal. And now he
              brings them in by the busload. The opposition howls in protest but has absolutely zero ability power.
            </p>
            <p>
              This authoritarian script has also been used in Turkey and Venezuela. In Israel, Benjamin Netanyahu is
              attempting to implement the first step in the authoritarian playbook by “reforming” the judiciary. In each
              of these countries, authoritarian forces exploited factional divisions and a powerless opposition to
              rewrite the rules of democracy in their favor.
            </p>
            <p>
              Authoritarian forces lurk in every democracy, biding their time and waiting for an opportunity they can
              exploit to make their bid for power. Every democracy will face crises of one form or another, and the
              measure of its stability is how it weathers such crises. It's not enough to function well when times are
              good and the population is unified. What happens when times are bad and there is conflict over the
              response?
            </p>
            <p>
              Some democracies seem so stable that it's hard to conceive of them degenerating into polarization,
              dysfunction, and eventual authoritarianism. The parties may choose to compromise with the minority, and
              the institutions that protect democracy may appear invulnerable. But today's political dysfunction would
              have been inconceivable in 1950s America.
            </p>
            <p>
              As Anne Applebaum says in Twilight of Democracy, “Given the right conditions, any society can turn against
              democracy. Indeed, if history is anything to go by, all of our societies eventually will.”
            </p>
            <p>
              I have one caveat to add to Applebaum's quote. She was referring to factional democracies simply because
              that is the only form of democracy we know. No one has ever disagreed when I describe factional democracy
              and label America as one. On occasion, people have questioned the need for the definition. How could
              democracy be any other way? To them, labeling American democracy -- or any other democracy -- as being
              factional is like defining water as being wet. As far as they are concerned, democracy is always
              factional. Why bother labeling it? How could it be any other way? What alternative is there?
            </p>

          </div>
        </Article>
    )
  }
}




















