
class ScaleAndOffsets {
  svg: d3.Selection<any, any, any, any>
  xOffset = 0
  yOffset = 0
  scale: number
  width: number
  height: number
  scaledWidth: number
  scaledHeight: number

  constructor(svg: d3.Selection<any, any, any, any>, scaledWidth: number, scaledHeight: number) {
    this.svg = svg
    this.scaledWidth = scaledWidth
    this.scaledHeight = scaledHeight
    let box = svg.nodes()[0].getBoundingClientRect()
    // console.log(`ScaleAndOffsets:  ${box.width}`, box)
    let scale = Math.min(box.width / this.scaledWidth, box.height / this.scaledHeight)
    // let scale = box.width / 1000

    if (box.width > box.height) {
      this.xOffset = (box.width - this.scaledWidth * scale) / 2
    }
    else if (box.height > box.width) {
      this.yOffset = (box.height - this.scaledHeight * scale) / 2
    }

    this.width = box.width
    this.height = box.height
    this.scale = scale
  }
}

export default ScaleAndOffsets