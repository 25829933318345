import Candidate from "./Candidate";
import {Voter} from "./Voter";
import {ElectionConfig} from "./ElectionConfig";


class CandidateScore {
  candidate: Candidate
  score: number

  constructor(candidate: Candidate, score: number) {
    this.candidate = candidate
    this.score = score
  }
}

class ElectionResult {
  orderedCandidates: Array<Candidate>
  winner: Candidate

  constructor(orderedCandidates: Array<Candidate>) {
    this.orderedCandidates = orderedCandidates
    this.winner = orderedCandidates[0]
  }
}
let logCount = 0
class Ballot {
  voter: Voter
  unorderedCandidates: Array<Candidate>
  config: ElectionConfig
  scores: Array<CandidateScore>


  constructor(voter: Voter, candidates: Array<Candidate>, config: ElectionConfig) {
    this.voter = voter
    this.unorderedCandidates = candidates
    this.config = config
    this.scores = candidates.map(c => new CandidateScore(c, this.voter.score(c, this.config)))
    this.scores.sort((a, b) => b.score - a.score)
  }

  log() {
    console.log(`Ballot for voter: ${this.voter.ideology.toFixed(3)}  ${this.voter.party.shortName}`)
    this.scores.forEach(cs => {
      let c = cs.candidate
      console.log(`${c.name} ${c.party.shortName} ${cs.candidate.ideology.toFixed(3)} ${cs.score.toFixed(3)}`)
    })
  }

  pickFrom = (candidates: Set<Candidate>): CandidateScore | undefined => {
    let result = this.scores.find(score => candidates.has(score.candidate))
    if (!result && logCount < 10) {
      console.log("candidates not in scores", candidates, this.scores)
      logCount++
    }
    return result
  }
  preferredIndex = (activeCandidates: Set<Candidate>, indexCandidates: Array<Candidate>): number => {
    let preferredScore = this.pickFrom(activeCandidates)
    if (preferredScore)
      return indexCandidates.indexOf(preferredScore.candidate)
    else {
      if (logCount < 10) {
        console.log("can't find candidate in indexCandidates", activeCandidates, indexCandidates)
        logCount++
      }
      return 0
    }
  }

}

export {Ballot}
export {ElectionResult}
export {CandidateScore}

