import React from 'react'
import styles from './MainArticle.module.scss'
import SectionTitle from '../../typography/SectionTitle'

interface Props {
  caption: string
  title: string
  details: string
  link: string
  image1Url: string
}

const MainArticle = (props: Props) => {
  return (
      <a href={props.link}>
        <div className={styles.content}>
          <div className={styles.textSection}>
            <SectionTitle title={props.title} caption={props.caption}/>
            <p className={styles.mainText}>
              {props.details}
            </p>
          </div>
          <div className={styles.imageSection}>
            <img src={props.image1Url} alt={props.title} className={styles.imageContent}/>
          </div>

        </div>
      </a>
  )
}

export default MainArticle