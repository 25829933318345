import PopulationGroup from "./PopulationGroup";
import {Democrats, Independents, Republicans} from "./Party";
import CombinedPopulation from "./CombinedPopulation";


class Defaults {
  noLeanGroups = [
    new PopulationGroup(Republicans, 1, 1, .5, .42),
    new PopulationGroup(Democrats, -1, 1, -.5, .42),
    new PopulationGroup(Independents, -0, 1, -0, .16)
  ]
  noLeanPopulation = new CombinedPopulation(this.noLeanGroups)

  leanRight = .2
  leanRightGroups = [
    new PopulationGroup(Republicans, 1 + this.leanRight, 1, .5, .53),
    new PopulationGroup(Democrats, -1 + this.leanRight, 1, -.5, .32),
    new PopulationGroup(Independents, this.leanRight, 1, -0, .15)
  ]
  rightLeanPopulation = new CombinedPopulation(this.leanRightGroups)

  leanLeft = -.2
  leanLeftGroups = [
    new PopulationGroup(Republicans, 1 + this.leanLeft, 1, .5, .32),
    new PopulationGroup(Democrats, -1 + this.leanLeft, 1, -.5, .53),
    new PopulationGroup(Independents, this.leanLeft, 1, -0, .15)
  ]
  leftLeanPopulation = new CombinedPopulation(this.leanLeftGroups)


  neutral2DGroups = [
    new PopulationGroup(Democrats, [-1, -1], [1, 1], [0, 0], .35),
    new PopulationGroup(Republicans, [1, 1], [1, 1], [0, 0], .35),
    new PopulationGroup(Independents, [0, 0], [2, 2], [0, 0], .30),
  ]
  neutral2DPopulation = new CombinedPopulation(this.neutral2DGroups)

  populationForLean = (rPct: number, dPct: number, skewFactor: number, partisanship: number, stddev: number, primaryShift: number): CombinedPopulation => {
    let iWeight = .20
    let rWeight = Math.max(0.05, (1 - iWeight) * rPct)
    let dWeight = Math.max(0.05, (1 - iWeight) * dPct)
    let skew = (rWeight - dWeight) / 2.0 * skewFactor * 100

    let rep = new PopulationGroup(Republicans, partisanship + skew, stddev, primaryShift, rWeight)
    let dem = new PopulationGroup(Democrats, -partisanship + skew, stddev, -primaryShift, dWeight)
    let ind = new PopulationGroup(Independents, skew, stddev, 0, iWeight)
    return new CombinedPopulation([rep, dem, ind])
  }

}

let defaults = new Defaults()

export {defaults}

