import React from "react";
import {ElectionData} from "./ElectionSimApp";
import {ResultWidget2} from "../ui/elements/ResultWidget2";
import styles from "./HorizontalResultsBox.module.scss";
import WinnerAndScore from "./WinnerAndScore";

interface Props {
  title: string
  results: ElectionData | undefined
}

class HorizontalResultsBox extends React.Component<any, any> {
  foo: string
  props: Props

  constructor(props: Props) {
    super(props);
    this.props = props
    this.foo = "bar"
  }

  render = (): JSX.Element => {
    let ed = this.props.results
    if (!ed)
      return (
          <div className={styles.resultBox}>
            <div className={styles.titleDiv}>
              {this.props.title}
            </div>
          </div>
      )

    return (
        <div className={styles.resultBox}
             style={{
               justifyContent: "flex-start"
             }}>
          <div>
            <div className={styles.titleDiv}>
              {this.props.title}
            </div>
            <div className={styles.blackBorder}>
              <div style={{
                maxWidth: "20vw",
              }}>
                <div className={styles.winnerAndScore}>
                  <WinnerAndScore candidate={this.props.results?.winner}/>
                </div>
                <div style={{
                  minHeight: "10%",
                  minWidth: "50%",
                }}>
                </div>
                <div className={styles.generalResults}>
                  <ResultWidget2 tableInfo={ed.tables[0]}/>
                </div>
                <p className={styles.caption}>
                  Click on a results table to show voter preference for that part of the process.
                </p>
              </div>

              <div style={{
                minWidth: "5%",
              }}>


              </div>
              <div>
                <ResultWidget2 tableInfo={ed.tables[1]}/>
                <div style={{
                  minHeight: "5%",
                }}>
                </div>
                <ResultWidget2 tableInfo={ed.tables[2]}/>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default HorizontalResultsBox