import React from 'react';
import {DSVRowArray} from "d3";
import styles from "./ConsensusVoting.module.scss"
import Step from "../ui/components/Step";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
import {ConsensusVotingAppOld} from "./ConsensusVotingAppOld";
import HorizontalResultsBox from "../ElectionSimulation/HorizontalResultsBox";
import ResultsBox from "../ElectionSimulation/ResultsBox";
import ElectionSimStoryBase from "../ElectionSimStoryBase/ElectionSimStoryBase";

class ConsensusVotingStoryOld extends ElectionSimStoryBase {

  mapAreas = [
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",

    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea4",
    "drawingArea4",

    "drawingArea4",
    "drawingArea4",
    "drawingArea4",
    "drawingArea5",
    "drawingArea5",
  ]
  visualizationAreas = [
    "drawingArea1", // 0
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",

    "drawingArea1", //5
    "drawingArea2",
    "drawingArea3",
    "drawingArea4",
    "drawingArea4",

    "drawingArea4", //10
    "drawingArea4",
    "drawingArea4",
    "drawingArea5",
    "drawingArea5",

  ]

  createApp = (
      visDivId: string,
      dvr: DSVRowArray,
      DWNominate: DSVRowArray,
      simulationResults: DSVRowArray,
      sampleCongress: DSVRowArray,
      usTopo: any): ElectionSimApp => {
    return new ConsensusVotingAppOld(this.visualizationAreas[0], dvr, DWNominate, simulationResults, sampleCongress, usTopo, 5001, 4)
  }


  render() {
    let mdash = "\u2014"
    return (
        <div className={styles.content}>
          <div className={styles.flyover} id={"detailsFlyover"} style={{
            display: "none",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 1,
          }}>
            <h6> Representative: <span id={"memberName"}>foo</span></h6>
            <h6> Party: <span id={"party"}>foo</span></h6>
            <h6> District: <span id={"districtName"}>foo</span></h6>
            <h6> Left/Right Ideology: <span id={"ideology"}>0</span></h6>
            <h6><span id={"representation"}> </span></h6>
          </div>
          <div>
            {this.renderVisArea(styles.drawing1, "drawingArea1")}
            { /* 0 */}

            <Step className={styles.stepElement} first={true}>
              <h3>A Polarized Congress</h3>
              <p>
                These are the 435 members of the 117th United States House of Representatives organized by their
                left-right political ideology. The existence of so many partisan members is unsurprising, but
                the complete absence of any member in the center surprised me.
              </p>
              <p>
                Despite being closely divided, Congress will produce policy that reflects only the priorities of
                whichever party has a majority. Either it will produce exclusively "blue" policy or exclusively "red"
                policy. There is no option to produce policy that represents the needs of both sides.
              </p>
            </Step>


            { /* 1 */}
            <Step className={styles.stepElement}>
              <h3>
                The Voters Are Not Polarized
              </h3>
              <p>
                The voters are not polarized. This is a simplified left-right representation of voter ideology based on
                survey information, election results, and simulation. Still, the results are clear: there are voters in
                the center and no Representatives in the center.
              </p>

              <p> This makes the inability to produce any policy that balances the priorities of the electorate even
                more frustrating and unjustifiable.
              </p>
              <p> Neither party is a monolith, there are moderate and even liberal Republicans as well as moderate and
                conservative democrats. Independents have very diverse and not necessarily centrists. </p>
            </Step>

            { /* 2 */}
            <Step className={styles.stepElement}>
              <h3>Voter Representation</h3>
              <p>
                This expands the view of voter opinion into two dimensions: fiscal and social. Republicans are primarily
                in the upper right and Democrats in the lower left. This group of voters is balanced, with an even mix
                of Democrats and Republicans.
              </p>
              <p>
                Voters views on social and fiscal issues are <i>correlated</i>, meaning that if they are fiscally
                conservative they are more likely to be socially conservative. The same also applies a fiscally liberal
                voter {mdash} they are more likely to be socially liberal.
              </p>
              <p>
                The voters are colored for their left-right position: blue in the lower-left and red in the upper-right.
              </p>

            </Step>

            { /* 3 */}
            <Step className={styles.stepElement}>
              <h3>An Election Between Two Candidates</h3>

              {this.generalResultTable()}

              <p>
                This shows a typical outcome of an election between two candidates in a balanced Congressional district.
                Voters are colored for the candidate that most closely matches their values. Juan has won the contest
                and is a better candidate than Bob to represent these voters. This is a foundational principle of
                democracy: Juan is a better representative of these voters here simply because the voters prefer
                him.
              </p>
              <p>
                Many people {mdash} starting with Juan and Bob {mdash} would say to look no further, but there are
                problems with this outcome.
              </p>
              <p>
                Juan, or whoever wins, will go on to represent the voters who elected him, not all of the voters in the
                district. Despite getting 48% of the vote, Bob's supporters will have zero say in the policy that Juan
                supports as a House member. It is winner take all as the Representatives are elected and then again
                at the legislative level depending on which side has a majority in the House.
              </p>
              <p>
                Like many of the visualizations on this site, this image is interactive. Dragging the candidate circles
                on the left will update the results as you drag.
              </p>
              {this.renderBalanceCaption()}
            </Step>
            { /* 4 */}
            <Step className={styles.stepElement}>
              <h3>Madison vs GTO Politics</h3>
              <p>
                Madison hoped for representatives who would have "enlightened views and virtuous sentiments" and who
                would govern "in the public good".
              </p>
              <p>
                Instead, we have partisan primaries that ensure the winner will govern only in the interests of their
                party.
              </p>
              <p>
                Candidates who attempt to balance the needs of the voters as Madison hoped are eliminated in the
                partisan primary.
              </p>
              <p>
              </p>
              <div className={styles.horizontalResults}>
                <HorizontalResultsBox
                    title={"Election Results"}
                    results={this.state.simulationState?.primaryResults}
                />
              </div>
            </Step>

            { /* 5 */}
            <Step className={styles.stepElement}>
              <h3>The Voters Wanted a Different Outcome</h3>
              <p>
                The voters would have preferred Anne, Sue, or Maria to Juan. That is the definition of representation:
                given two candidates the one the voters prefer is the better representative.
              </p>

              <p>
                Juan is only able to win if more representative candidates {mdash} better candidates {mdash} are
                eliminated in the primary process.
              </p>
              {this.consensusTable("Juan")}

            </Step>
          </div>

          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing3, "drawingArea2")}
            { /* 6 */}
            <Step first={true} className={styles.stepElement}>
              <h3>
                Unrepresentative Results
              </h3>
              <p>
                By stitching together data from election results, surveys, simulation, and Congressional voting records,
                it is possible to estimate the <a href={"/Representation"}>representation score</a> for each member of
                Congress.
              </p>
              <p>
                Representation is awful because it has to be awful. Candidates must play GTO Politics to win their
                elections and that means representing only the voters that elected them. They would never survive
                their primary if they were to consider the needs of all of their voters.
              </p>
              <p>
                GTO politics is so ingrained in our world that the phrase "their voters" generally means just the voters
                that voted for the candidate and not all of the voters that live in their district. Shouldn't a
                "candidate's voters" be all of the voters in the district or state? Shouldn't they represent all of
                the people and not just their faction?
              </p>
              <p>
                Madison's vision of representatives with "enlightened views" who govern in the "public good" is
                crushed by an election process that eliminates those candidates in the primary process.
              </p>
            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing4, "drawingArea3")}
            { /*7 */}
            <Step first={true} className={styles.stepElement}>
              <h3>A Better System</h3>
              <p>
                We start with a general election with the same <a href="/TopFive">five candidates</a> shown in the
                primary-based example. The first step to use a Ranked Choice Ballot to allow every voter to express
                their opinion about each candidate.
              </p>
              {/*<p>*/}
              {/*  Consensus Voting finds the Consensus Candidate to determine the winner. To find the Consensus Candidate,*/}
              {/*  each pair of candidates is compared one-on-one. A voter is said to prefer a candidate if they rank them*/}
              {/*  higher on their ballot. When comparing Anne and Juan, a voter who ranked Anne second and Juan fourth,*/}
              {/*  prefers Anne to Juan. If more voters prefer Anne to Juan than vice-versa, Anne wins that comparison. The*/}
              {/*  candidate who wins all of their comparisons is the Consensus Candidate and the winner.*/}
              {/*</p>*/}

              <p>
                We will follow Emily and how her ballot is counted to explain how to find the "Consensus
                Candidate" {mdash} the candidate the voters prefer to each of the other candidates.
              </p>
              <p>
                To set the stage: Emily is a Democrat who is frustrated by divisive politics from both parties. Juan
                has represented Emily's district for several terms and Emily finds him divisive and partisan. Emily is
                hopeful that Sue or Anne might change things. Emily's ballot is shown on the right.
              </p>
            </Step>
          </div>

          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing5, "drawingArea4")}
            { /* 8 */}
            <Step first={true} className={styles.stepElement}>
              <h3>Emily's ballot and Juan</h3>
              <p>
                Consensus Voting works by comparing each candidate to each of the other candidates. Here
                we see Juan compared with each of the other candidates.
              </p>
              <p>
                Juan loses to Anne because 66.5% of the voters ranked Anne above Juan and only 33.5% ranked Juan above
                Anne. To find the winner for each pairing we simply count the number of ballots that ranked each
                candidate higher.
              </p>
              <p>
                Emily ranked Juan last on her ballot so her ballot will count as a vote against Juan in each comparison.
              </p>
              {this.consensusTable("Juan")}
            </Step>


            { /* 10 */}
            <Step className={styles.stepElement}>
              <h3>Emily's ballot and Maria</h3>
              <p>
                Emily ranked Maria fourth on her ballot. Emily's ballot counts as a vote for Maria in Maria's
                comparison with Juan, but against Maria in Maria's comparisons with each of the other candidates.
              </p>
              <p>
                Maria easily defeats both Bob and Juan when they are compared head-to-head.
              </p>
              {this.consensusTable("Maria")}
            </Step>
            { /* 11 */}
            <Step className={styles.stepElement}>
              <h3>Emily's ballot and Sue</h3>
              <p>
                Sue was Emily's first choice. Emily's ballot counted as a vote for Sue in each of Sue's comparisons.
              </p>
              <p>
                Listing second, third and fourth choices did not harm Sue in any way. Emily's ballot still counts
                as a vote for Sue in every comparison.
              </p>
              <p>
                The voters prefer Sue to three of the candidates but not to Anne.

              </p>
              {this.consensusTable("Sue")}
            </Step>
            { /* 12 */}
            <Step className={styles.stepElement}>
              <h3>Emily's Ballot and Anne</h3>
              <p>
                Anne was Emily's third choice. Emily listed members of her own party first and second, and listed Anne
                third as a less divisive alternative from the other party.
              </p>
              <p>
                Anne is the Consensus Candidate and the winner. Being the Consensus Candidate doesn't mean Anne is every
                voters first choice, that's simply not possible. Consensus means the voters do prefer Anne to each of
                the other
                candidates.
              </p>
              <p>
                To win, Anne needed support from voters to her left against Maria and Juan. She also needed support from
                voters to her right against Sue and Bob. Anne can't be a representative of any single faction, she must
                instead balance the needs of all of the voters to win.
              </p>
              {this.consensusTable("Anne")}

            </Step>
            { /* 13 */}
            <Step className={styles.stepElement}>
              <h3>Consensus Results</h3>
              <p>
                This shows the Consensus Voting results in a more compact form.
              </p>

              <p>
                I've include the Instant Runoff-RCV results using the same ballots for comparison. Representative
                candidates cannot win under Instant Runoff if partisan candidates {mdash} Juan and Bob in this case
                {mdash} play GTO politics. This isn't hard for them to do. See if you can make one of the more
                representative candidates win without moving Juan or Bob.
              </p>

              <p>
                Adjust the candidates. Play with difference scenarios. Try the full <a href={"/ElectionSim"}>Election
                Simulator </a> to explore outcomes under Consensus, Instant Runoff, and Primaries.
              </p>

              <div className={styles.sideBySide}>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.state.simulationState?.h2hResults}
                    title={"Consensus Voting"}
                />
                <div style={{minWidth: "2%"}}></div>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.state.simulationState?.irvResults}
                    title={"Instant Runoff-RCV"}
                />
              </div>
            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing6, "drawingArea5")}
            { /* 14 */}
            <Step first={true} className={styles.stepElement}>
              <h3>A Representative House of Representatives</h3>

              <p>
                This is a simulation of the U.S. House of Representatives under Consensus Voting. The only difference
                between this simulation and the simulation using primaries is how the voter preferences are
                interpreted.
                These two simulations use the same candidates and the same voter preferences, yet the outcomes are
                dramatically different. Primaries produce a dysfunctional Congress that does not represent the voters,
                while Consensus Voting elects a representative Congress with those same candidates and voters.
              </p>

              <p>
                These ideological scores may seem the same but they are not. A conservative under primaries is not the
                same as a conservative under Consensus Voting. Under Consensus Voting each of these Members represents all
                of
                the voters in their district, not some narrow slice of their voters. This makes compromise
                fundamentally
                easier, compromise can't be shut down by some small faction even for a conservative representative.
                Consensus Voting makes placing country over party not only possible, but essential.
              </p>

              <p>
                Simulation shows that the polarization in our political leaders does not reflect a polarized society.
                If
                we simply elect the candidates the voters prefer, we would have a functional government capable of
                dealing the challenging problems we face.
              </p>

              <p>
                Going all the way back to Hamilton and Madison, the underlying presumption of representative democracy
                is that elections produce representative results. Our democracy is failing because our elections fail
                to produce representative results. We must fix this.
              </p>

            </Step>

            { /* 15 */}
            <Step className={styles.stepElement}>
              <h3>Explore More</h3>
              <p>
                This is a deep conversation with profound implications and I have barely scratched the surface here.
              </p>

              <ul style={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}>
                <li>
                  <a href={"/ElectionSim"}> The Election Simulator </a> This is where you can explore outcomes under
                  different election systems. How often does Instant Runoff-RCV produce bad results?
                  How often does Consensus Voting produce bad results?
                </li>
                <li>
                  <a href={"/IRV2D"}>Instant Runoff Voting</a> describe how Instant Runoff Voting works and why is not
                  the best solution for political polarization.
                </li>
                <li>
                  <a href={"/Ties"}>Ties in Consensus Voting </a> discusses the possibility of a tie, where no candidate
                  beats every other candidate. Consensus Voting is known to election science as Condorcet voting and
                  there has been endless debate about the possibility of a rock/paper/scissors style cycle where
                  no candidate is undefeated. This explains how that is handled and why it's not a problem.
                </li>
                <li>
                  <a href={"/SimulationTutorial"}>A Tutorial on Simulation</a> The science behind election simulation
                  both for the election visualization and for the district-by-district simulations of the U.S. House
                  of
                  Representatives.
                </li>
                <li>
                  Approximately one million more ideas and thoughts... This, like our democracy itself, is work in
                  progress.
                </li>
              </ul>
            </Step>
            <div style={{
              minHeight: "30vh"
            }}>
            </div>
          </div>
        </div>
    )
  }
}

export default ConsensusVotingStoryOld
