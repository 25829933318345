import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class Democracy extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }

  render() {
    let mdash = "\u2014"

    return (
        <Article title={"The Purpose of Democracy"}
                 subtitle={"Why Democracy? Why reform it?"}>
          <div className={styles.textContent}>
            <p>
              "Toxic polarization is threatening our democracy; it must be fixed."
            </p>
            <p>
              I’ve had hundreds of conversations with friends, family, politicians, and people from the voting reform
              community, and they all involve someone making that statement in one form or another because almost
              everyone from every political tribe agrees that our democracy is teetering on the brink of disaster. But
              this superficial agreement breaks down the moment the discussion moves to the causes and solutions. That’s
              because everyone interprets threats to democracy through the lens of their personal political views. Like
              public education or immigration, there’s widespread agreement that the current situation is unacceptable,
              but the next step is total paralysis because everyone’s vision of the solution is different. We do not
              agree about the fundamental causes of polarization or what form a solution should take.
            </p>
            <p>
              Political polarization is even more fraught than education, immigration, or any other issue because
              changes to our political system will shift the balance of power and consequently affect every contentious
              issue we face. Even worse, today's election system has been shaped by the people in power to keep
              themselves in power. Those in power perceive any attempt at reform as an implicit attack on themselves.
              Why would you want to reform the election system for any reason other than to elect different people?
              Consequently, those in power are highly motivated to block any attempt at reform and will exploit any
              division among the reformers.
            </p>
            <p>
              Many people view our democracy as critically endangered, and their instinct is to jump into action to save
              it before it succumbs to authoritarianism. I found the studies cited to support different types of voting
              reform to be unconvincing and often contradictory. I felt that I could use my expertise with modeling
              large
              systems to provide some more objective measures of the impact that voting reform would have on our
              politics and our society.
            </p>
            <p className={styles.pullQuote}>
              You can’t say who any individual voter will prefer; they may decide they love or hate a candidate for
              reasons that are impossible to predict, but thousands or millions of voters will abide by well-known
              statistical rules of voter preference.
            </p>
            <p>
              Agent modeling allows the simulation of seemingly complex systems by treating each element as an agent
              following simple rules. A flock of starlings seems to have a mind of its own, but the seemingly complex
              behavior can be understood as each bird following simple rules based on the birds nearby.
            </p>
            <p>
              For me, the chaos of our elections can be understood through agent modeling, where the individual agents
              are the voters, candidates, and parties that make up our political universe. As individuals, people are
              all but impossible to predict, but larger groups of people are a different matter. Voters prefer
              candidates who share their political views, their political party if they have one, and who are good
              candidates. You can’t say who any individual voter will prefer; they may decide they love or hate a
              candidate for reasons that are impossible to predict, but thousands or millions of voters will abide by
              well-known statistical rules of voter preference. I felt that I could use these rules of voter behavior to
              study different voting rules and predict the types of candidates that would win under each.
            </p>
            <p>
              The results of my simulations were interesting and surprising. Some things that seemed like obviously good
              ideas would make almost no difference at all, while other approaches relied on unrealistic expectations of
              human behavior. But the most important discovery was that our existing voting rules will divide any
              society and destroy any democracy.
            </p>
            <p>
              After researching the options, I found a voting rule that would reduce polarization, make “otherizing”
              voters a strategic error, increase the power of political and social minorities, reduce the destructive
              power of gerrymandering, and foster bipartisan compromises. All of this would be accomplished by allowing
              voters to elect candidates they would prefer if every voter had a say instead of eliminating those
              candidates in partisan primaries. The rule favors candidates who represent all voters in a 60/40 district
              and not just the 60. In short, I was proposing to overthrow the oligarchy of extremism that rules our
              democracy.
            </p>
            <p className={styles.pullQuote}>
              People didn’t argue with the math; the math was clear. Instead, they admitted that two plus two is four
              but asked if those were the correct numbers to add and how do you interpret the answer?
            </p>
            <p>
              When I presented the results, I assumed people would question the math. I expected questions like “How do
              you measure voter happiness and why will it increase?” or “Why do more representative candidates have an
              advantage?” Any voting system must answer questions about how candidates, political parties, and voters
              will attempt to game the system, and I expected to have to answer questions about those issues. There are
              also arcane mathematical questions in election science that must be addressed when evaluating a voting
              system. I was prepared to answer all of these questions, but those were not the questions they asked.
            </p>
            <p>
              Instead, I found myself trying to explain why the changes I suggested were a good thing. Why should a
              candidate in a 60/40 district represent all voters and not just the 60? Why is bipartisanship a good
              thing? Why is demonizing voters in the other party bad for our society? People didn’t argue with the math;
              the math was clear. Instead, they admitted that two plus two is four but asked if those were the correct
              numbers to add and how do you interpret the answer?
            </p>
            <p>
              If you accept that our political universe is careening toward disaster, small changes are not enough. To
              fix the problem, we must dismantle the industry of fear and anger that is so effective at raising money,
              electing divisive candidates, and alienating us from our fellow Americans. These are not small changes. In
              the zero-sum game of political power, new winners will create new losers. But who should those winners and
              losers be? By advocating for a particular set of changes to our voting system, I also advocated taking
              power away from the political extremes and giving it to the center. This seemed obvious and necessary, but
              you should always question the word “obvious.”
            </p>

            <h3>
              Hard Questions
            </h3>
            <p>
              When people questioned the goals of my proposed reforms, my first reaction was that they wanted to
              rebalance power to favor their political viewpoint. Though that was often the case, their questions had a
              legitimate basis. What should democracy look like? What is the purpose of democracy? What defines a
              functional democracy? What should our democracy aspire to? These questions are not rhetorical, and the
              answers are not obvious. If you were to ask those questions of a hundred different people, you would
              probably get a hundred different answers.
            </p>
            <p className={styles.pullQuote}>
              My goal is to put people who oppose reform in an uncomfortable position where they must admit that what
              they oppose is not reform but democracy itself.
            </p>
            <p>
              Before we embark on changing the rules that govern our elections and shape our society, we must examine
              our underlying assumptions about democracy, society, and the definition of the public good. Some of these
              things are unhealed wounds where we have papered over our differences to avoid uncomfortable discussions
              for fear of revealing irreconcilable differences. But by failing to address these problems, we allowed
              them to fester and grow out of sight and out of mind. To heal our democracy, we must dig deeply at these
              core issues – no matter how uncomfortable.
            </p>
            <p>
              We cannot begin to work on fixing our democracy before agreeing on what a working democracy looks like.
              The discussion won’t be productive if one side’s unspoken goal is strengthening the political parties
              while the other views weakening the parties as essential. Debates like this eventually end in
              contentious, fruitless disputes over methods when the source of disagreement is buried in our underlying
              assumptions about democracy. I’ve seen these discussions play out repeatedly. That’s not to say those
              people can’t have a productive conversation, but they are not ready to discuss solutions when they have
              conflicting objectives. They should start by understanding each other’s goals and their reasons. We should
              discuss why we believe that stronger or weaker political parties are better before discussing how to
              reform the election system.
            </p>
            <p>
              My desire to dig deeper is also motivated by my hope to find core principles we can all agree to and
              then build from those shared principles to form a more coherent basis for democracy. My goal is to
              put people who oppose reform in an uncomfortable position where they must admit that what they oppose is
              not reform but democracy itself.
            </p>
            <p>
              So, let’s get started.
            </p>

            <h3>
              Some Early Examples
            </h3>
            <p>
              The United States Department of State defines the purpose of democracy to be the promotion of human
              rights, religious freedom, workers’ rights, and economic prosperity. Those are all arguably good things,
              but they are not the purpose of democracy. Many democratic societies choose to support these qualities,
              but that is not the purpose of democracy itself. In theory, a democratic society could decide not to
              pursue those goals or could define them in ways we think are unreasonable. To define democracy, we need to
              back up a few steps. OK, maybe a lot of steps – to chimpanzees.
            </p>
            <p>
              In his book Sapiens, Yuval Noah Hariri describes the dynamics of competing for leadership in a tribe of
              chimpanzees, and this contest seems disturbingly similar to our elections. The competitors use personal
              contact, grooming, and, of course, "hugging, back-slapping, and kissing baby chimps” to form coalitions.
              The size of these coalitions usually determines the tribe’s leader. I'm skeptical that the larger group
              wins the leadership contest because of democratic concepts of justice. Instead, the winner is chosen
              because the larger group would win a physical confrontation. Even so, there is a primitive level of
              voting. The chimpanzees are only missing a flood of TV advertising, robocalls, and lawsuits to qualify as
              a democratic republic.
            </p>

            <p className={styles.pullQuote}>
              Think of it as democracy version 0.01: “voting with swords.”
            </p>
            <p>
              Less widely known than the Greeks and more evolved than the chimpanzees, the medieval Germans used a form
              of democracy to choose a new king. The tribes assembled and voted, but the decision had to be unanimous
              because it was not acceptable to have some clans openly opposing the new king.
            </p>
            <p>
              The German method seems acceptable at first glance, but unanimous decisions are difficult or impossible to
              achieve. To make unanimity possible, the Germans allowed coercion. In this case, coercion meant sticking
              swords in people who disagreed with the choice of the new king. Think of it as democracy version 0.01:
              “voting with swords.” Eventually, the Germans worked their way up to the level of a tribe of chimpanzees
              and began to count the various factions as a proxy for sticking swords in people. Though less entertaining
              than swords, voting avoided weakening the kingdom with civil war.
            </p>
            <p>
              Aside from enjoying comparing our political elite to apes, I mention chimpanzees to underscore that the
              dynamic of a majority dominating a minority predates the Greeks, the Germans, democracy, and even homo
              sapiens. Counting heads, swords, or chimpanzees to avoid open conflict is not democracy. That’s merely
              recognizing that a superior force can impose its will.
            </p>
            <p>
              Early direct democracies in Greece and the Mediterranean basin were superior to the later German versions.
              Their goal was not just to avoid civil war but also to create a more just and prosperous society; and the
              ancient Greeks had periods of dazzling success. Every adult male could vote in Athens – regardless of
              whether they owned a sword or could use it. But, these democracies could also fall into the same trap
              where a majority dominated the minority. If a majority faction chooses to abuse the minority by imposing
              whatever policy they want, does it matter if that majority possesses a majority of swords or votes?
            </p>

            <h3>
              The American Experiment
            </h3>

            <p className={styles.pullQuote}>
              Alexander Hamilton describes the history of the early democracies in Greece and Italy as
              “impossible to read … without feelings of horror and disgust.”
            </p>
            <p>
              In much of the modern world, we presume that democracy is the ideal form of government despite its
              apparent flaws. Winston Churchill's famous quote best describes our attitude toward democracy: “No one
              pretends that democracy is perfect or all-wise. Indeed, it has been said that democracy is the worst form
              of government except for all those other forms that have been tried from time to time…” As tepid as
              Churchill’s opinion was, democracy was even less well thought of at the birth of American democracy.
            </p>
            <p>
              In Federalist 9, Alexander Hamilton describes the history of the early democracies in Greece and Italy as
              “impossible to read … without feelings of horror and disgust” because they were in “perpetual vibration
              between tyranny and anarchy.” Hamilton admits that there were short-lived successes but that these were
              soon to be “overwhelmed by the tempestuous waves of sedition and party rage.”
            </p>
            <p>
              The authors of the Constitution had to convince themselves that a stable democracy was possible before
              they could sell it to a skeptical public. To the authors of the U.S. Constitution, those early democracies
              served only as an example of what not to do because they all suffered from some critical flaw that made
              them unstable and prone to violence. Without identifying and “fixing” a problem that no previous democracy
              had been able to contain, the founders themselves would not choose democracy.
            </p>
            <p>
              Indeed, during the Constitutional Convention, only a few months before penning Federalist 9, Hamilton
              wrote a draft Constitution where the President and Senators were both elected for life and immune to
              political pressures once in office.
            </p>

            <p className={styles.hardQuote}>
              Hence it is that such democracies have ever been spectacles of turbulence and contention; … and have in
              general been as short in their lives as they have been violent in their deaths. {mdash} James Madison,
              Federalist 10
            </p>
            <p>
              The founders’ fears resulted from a pattern of failure that happened in every democracy as they descended
              into chaos and violence. A faction would gain control – presumably as a majority – and implement policies
              without consideration of the minority’s views, needs, or fate. As James Madison describes it: “measures
              are too often decided, not according to the rules of justice and the rights of the minor party, but by the
              superior force of an interested and overbearing majority.” Using democracy to violate someone’s rights
              does not make it acceptable, and as Madison put it, “Hence it is that such democracies have ever been
              spectacles of turbulence and contention; … and have, in general, been as short in their lives as they have
              been violent in their deaths.”
            </p>
            <p>
              Early Democracy’s instability goes back to the fundamental reality that a superior force can impose its
              will upon society. Using democracy instead of swords to violate the rights of the minority does not make
              it acceptable to the minority and is its own kind of violence. It seems like an impossible conflict: if
              violence is inevitable when the majority imposes its will on the minority, it would be even worse should
              the minority impose its will. How, then, is a democracy, or any large society of humans, possible without
              violence of one form or another? This is the challenge that Madison and the framers of the Constitution
              had to solve. To make the problem even more challenging, they had to solve it without telling anyone the
              solution.
            </p>

            <h3>
              The Founders Plan
            </h3>
            <p className={styles.hardQuote}>
              By a faction, I understand a number of citizens, whether amounting to a majority or a minority of the
              whole, who are united and actuated by some common impulse of passion, or of interest, adversed to the
              rights of other citizens, or to the permanent and aggregate interests of the community. – James Madison,
              Federalist 10.
            </p>

            <p>
              Madison’s description of a faction in Federalist 10 is still definitive; factions are simply any group of
              people who want to advance shared political views. A faction could be a political party, a wing of a
              political party, or some alliance of political parties, and they are a normal part of any healthy society.
              Madison’s argument in Federalist 10 is that because factions cannot be eliminated, they must be
              controlled, or they will destroy democracy.
            </p>
            <p>
              There is a subtle genius in Madison’s definition of factions because it implicitly excludes the reader
              from being a part of a faction. No one believes they are “adversed to the rights of others” or acting
              against the “interests of the community,” even when they are. In the most egregious example, Southern
              slaveholders – including Madison – deluded themselves that they were acting in the best interests of the
              slaves. When Madison blames factions for being an insidious force that must be contained to preserve
              democracy, the reader assumes Madison is referring to someone else, and they won’t be affected by measures
              meant to contain these insidious groups.
            </p>
            <p>
              By describing factions in this way, Madison allows the reader to delude themselves into thinking that they
              can get what they want and that only “factions” will be limited by the new Constitution. Ironically,
              Madison may have even deceived himself.
            </p>
            <p>
              Returning to Federalist 9, Hamilton describes democracy as “indefensible” in any known form. However, he
              writes this in the Age of Enlightenment, and there is a sense that new understandings are enabling things
              that were impossible before. Hamilton reaches for “the science of politics,” which “has received great
              improvement.” Hamilton believes that a newly invented system of “legislative balances and checks,” will
              prevent a faction from dominating the government and undermining democracy.
            </p>

            <p className={styles.pullQuote}>
              How often has our seemingly awkward division of power saved our democracy?
            </p>
            <p>
              Hamilton’s “balances and checks” refers to the now familiar separation of powers into legislative,
              executive, and judicial branches (and then further dividing the legislative branch into a House and
              Senate). The separation of powers is often described as preventing any one branch of government from
              becoming too powerful. But the real benefit resides in preventing a single faction from easily dominating
              the government.
            </p>
            <p>
              The members of the three branches are chosen using different systems and at different times. The familiar
              two, four, and six-year terms for the House, President, and Senate prevent any temporary extreme from
              dominating the government. Further, Senators were originally appointed by the state legislatures, and the
              Constitution was not changed to force the direct election of Senators until 1913. Meanwhile, judiciary
              appointments are lifetime appointments by the President with the consent of the Senate. These alternate
              ways of selecting leaders make it difficult for any one faction to control all of the levers of power.
            </p>
            <p>
              In parliamentary systems, controlling parliament also implies control of the executive branch, which is
              everything a narrow majority needs to control every aspect of government. Scholars often criticize the
              American government for being too prone to gridlock and dysfunction, and they are correct that
              intransigent minorities have often held progress and sound governance hostage over petty issues. But how
              often has gridlock saved us from a narrow majority imposing unacceptable policy on the minority? How often
              has our seemingly awkward division of power saved our democracy?
            </p>
            <p className={styles.pullQuote}>
              At its best, American democracy has allowed our society to evolve as we continue our journey toward a more
              just society.
            </p>
            <p>
              Another crucial defense against factions was implemented shortly after the adoption of the Constitution
              during Washington’s first term by enshrining certain fundamental rights in the Constitution as the Bill of
              Rights. The Bill of Rights is commonly described as protecting individual liberties from “government
              interference,” but the government can be a tool for the controlling faction. Codifying those fundamental
              rights in the Constitution protected our democracy from a dominant faction bent on abusing the rights of
              the minority.
            </p>
            <p>
              The separation of powers and the Bill of Rights have been astonishingly effective in making American
              democracy resilient and successful. America has 234 consecutive years of democratically elected leadership
              in a country with diverse religious, cultural, and racial backgrounds. No other democracy with anything
              like American diversity comes close. At its best, American democracy has allowed our society to evolve as
              we
              continue our journey toward a more just society.
            </p>
            <p className={styles.pullQuote}>
              The polling data about the voters’ preference for bipartisan legislation is backed by the fact that since
              1969, the voters have only chosen one-party government control for 16 years.
            </p>
            <p>
              Political parties had not formed when the Constitution was written, but modern voters endorse the
              structures of the Constitution that force parties to work together. Polling has consistently shown that
              the American voter prefers bipartisan legislation, with more than 80% supporting it. Bipartisan
              legislation is seen to be broadly representative of the public good rather than legislation that
              exclusively represents one side’s views. When both parties have a stake in the outcome, they work together
              to refine and adjust it to make it successful. Alternatively, purely partisan legislation motivates the
              opposition to do everything it can to undermine and sabotage it.
            </p>
            <p>
              The polling data about the voters’ preference for bipartisan legislation is backed by the fact that since
              1969, the voters have only chosen one-party government control for 16 years. Plus, those 16 years
              were dominated by the convulsive rejection of the previous faction in power, creating only brief periods
              of one-party control. The voters are using the only tool they have – voting for a divided government – to
              force the parties to abandon their partisan dreams and work together to produce legislation that’s
              representative of the nation as a whole instead of a single political party. But divided government is a
              crude tool and often produces gridlock and bickering instead of bipartisan cooperation.
            </p>

            <h3>
              Madison’s Defense Against Factions
            </h3>
            <p>
              Where Federalist 9 discusses how the division of power will act to contain factions, Federalist 10 makes a
              different argument for how the new Constitution can succeed where all direct democracies had failed so
              spectacularly. Madison also blames factions for being the Achilles’ heel of democracy but takes a more
              nuanced approach to addressing the problem. In a gentle rebuke of Hamilton's argument, Madison admits that
              factions are a normal part of any healthy society and argues that they must be contained rather than
              repressed.
            </p>
            <p>
              In a direct democracy, where the citizens are assembled to administer the government directly, it is only
              a matter of time before some faction with a majority chooses to abuse the rights of the minority. But
              Madison says that the republic created by the new Constitution can cure the mischief of factions.
            </p>
            <p>
              Madison’s plan relies on the layer of elected representatives a republic inserts between the public and
              policy that does not exist in a direct democracy. This elected layer consists of the elected
              representatives in the executive branch, the House, the Senate, and the appointed judicial branch.
              Combined, their job in our democracy is to translate the “public views” into policy that is in the
              national interest.
            </p>
            <p>
              Here is how Madison describes the benefit of a Republic in Federalist 10:
            </p>
            <p className={styles.hardQuote}>
              The effect of the first difference is, on the one hand, to refine and enlarge the public views, by passing
              them through the medium of a chosen body of citizens, whose wisdom may best discern the true interest of
              their country, and whose patriotism and love of justice will be least likely to sacrifice it to temporary
              or partial considerations.
            </p>
            <p>
              In a direct democracy, a majority faction can do whatever it wants, even if that would violate the rights
              of the minority and cause “turbulence and contention.” But in a republic, these elected representatives
              will “refine and enlarge” the “public views” into something reflecting “the true interest of their
              country.” To decode Madison’s language, the “public views” refer to the policies the majority faction
              would have implemented in a direct democracy, and the process of “refin[ing] and enlarging” the public
              views means modifying them to consider the rights of the minority.
            </p>
            <p>
              Madison’s plan relies on these elected representatives operating as more than mere avatars of one faction
              or another. If a faction controlled a majority of the population, that faction would also control a
              proportional majority of the representatives, and those representatives would make the same catastrophic
              decisions that would destroy a direct democracy. To make better decisions, these elected representatives
              must represent more than just one faction or another. In short, they must be capable of placing country
              before party.
            </p>
            <p>
              The phrase “place country before party” is a modern invention; parties didn’t even exist in Madison’s
              time, but his phrasing is clear: “able to discern the true interest of their country” and be “least likely
              to sacrifice it to temporary or partial considerations.” These elected representatives, chosen for their
              “enlightened views and virtuous sentiments,” are tasked with avoiding the short, violent death of direct
              democracies by balancing the views of the various factions in society to produce policies that all
              factions can live with.
            </p>

            <h3>
              The Public Good
            </h3>
            <p>
              Madison uses the phrase “public good” six times in Federalist 10. He also uses “public weal” – an Old
              English term for well-being – and the “true interest of their country” to describe the ideal government
              policies. Though Madison never defines “public good,” he is clear that the public good is not the
              untempered views of any one faction. Instead, the majority's views must be “refined and enlarged” to
              accommodate the rights and views of the minority by this layer that a republic inserts between the
              factions and governance.
            </p>
            <p className={styles.pullQuote}>
              It's not guaranteed that broadly acceptable solutions exist for every problem, but if they do exist, they
              will require compromise.
            </p>
            <p>
              Madison avoids saying that the public good and a stable society require the majority to compromise with
              the minority because the word "compromise" is often used as a pejorative term. Yet, no society can exist
              peacefully without compromise, and it is the cornerstone of American democracy.
            </p>
            <p>
              No group of people agrees on everything. Even a marriage between two people who share many values requires
              constant compromise. How is a society of millions of individuals supposed to make decisions everyone can
              tolerate? Ideally, these elected representatives collaborate to find solutions that address the most vital
              needs of everyone and only ask people to yield on less important issues. When done right, such solutions
              can give each side most of what it wants without creating intolerable outcomes for anyone. Such broadly
              acceptable solutions may not always exist, but if they do exist, they will require compromise.
            </p>

            <p className={styles.pullQuote}>
              Federalist 10 never uses the word "compromise". It only appears in one of the Federalist papers and
              only in a different context. Still, a series of measures that force a majority to compromise is the
              central invention that has allowed American democracy to be as successful as it has.
            </p>
            <p>
              Critically, compromise does not mean selling out or giving up. In many cases, compromise means abiding by
              a decision but continuing to advocate for your beliefs within the democratic process. In this form,
              compromise means valuing the benefits of living in a democratic society and the chance to continue to
              advocate for your beliefs over the alternative because the alternative is violence.
            </p>
            <p>
              We proudly proclaim that we protect the rights of the minority, and every aspect of our Constitution is
              designed to protect that minority. The separation of power, elected representatives, the independent
              judiciary, the division of the legislature into the House and Senate, the Bill of Rights, and the extreme
              difficulty of modifying the Constitution are all designed to protect the minority from the majority. But
              how do you protect the minority from the majority? By forcing the majority to compromise.
            </p>
            <p className={styles.pullQuote}>
              Madison was almost certainly correct that he could not sell the new Constitution to a skeptical public by
              saying that compromise was essential to a peaceful society and that our new Constitution’s central
              principle was to force compromise between the factions. But, the time for dissembling has passed.
            </p>

            <p>
              Today, candidates proudly express their ability to work with the other side, cross the aisle, and form
              bipartisan agreements. Polling shows that 85% of voters support bipartisan solutions to vexing problems.
              All of these are just ways to avoid uttering the word compromise.
            </p>
            <p>
              We give up the right to censor our political opponents in exchange for the freedom to express ourselves.
              We give up the freedom to impose our religion on others in exchange for the right to practice our
              religion. Freedom is founded on compromise.
            </p>
            <p>
              Federalist 10 never uses the word compromise. Compromise only appears in one of the Federalist papers and
              only in a different context. Still, a series of measures that force a majority to compromise is the
              central invention that has allowed American democracy to be as successful as it has. The founders danced
              around using the term because of its negative connotations. Instead of telling people they would never get
              what they wanted, Madison said their views would be “refined and enlarged.” It’s a clever bit of
              salesmanship that was repeated with the vagueness around “public good” and “factions.” By not defining
              either, the reader can convince themselves they are not part of a faction and that their views represent
              the “true interest of their country.”
            </p>
            <p>
              Madison was almost certainly correct that he could not sell the new Constitution to a skeptical public by
              saying that compromise was essential to a peaceful society and that our new Constitution’s central
              principle was to force compromise between the factions. But, the time for dissembling has passed.
            </p>

            <h3>
              Compromise Can Be Difficult
            </h3>
            <p>
              What happens when compromise involves deeply held beliefs? What if you believe compromise would allow
              injustice to persist or multiply? It can be tempting to undermine the democratic process and impose a
              solution when the alternative is perceived as injustice.
            </p>
            <p className={styles.pullQuote}>
              Being right by some historical benchmark is irrelevant when your democracy and everything it has
              accomplished lies in ruins.
            </p>
            <p>
              The lesson our founders took from direct democracies is that violence and chaos erupted whenever the
              majority imposed its untempered will upon the minority. They knew the minority would react violently when
              their rights were not respected because the democratic process did not protect them from injustice. This
              only becomes worse when a minority somehow manages to manipulate the system and impose their will on the
              majority.
            </p>
            <p>
              I have had countless discussions with advocates for one cause or another who fervently believe that any
              compromise is morally unacceptable. These conversations are never easy because I often agree with their
              views. But being right is not enough. Wisdom cannot be imposed.
            </p>
            <p>
              It’s irrelevant if a historian two hundred years later determines that the majority was right – and who
              judges the validity of the historian, anyway? What matters is how the minority feels in the moment. Being
              right by some historical benchmark is irrelevant when your democracy and everything it has accomplished
              lies in ruins.
            </p>

            <h3>
              The Wisdom of Crowds
            </h3>
            <p>
              Choosing democracy as a form of government implicitly assumes the wisdom of the crowd. If the people
              cannot make good decisions, why would you choose democracy? Believing in democracy does not mean that you
              believe that it will always produce the right outcome. Outcomes are often flawed, and we have only to look
              around us to see many injustices – though we might all list different problems. Fortunately, right and
              wrong are not static concepts.
            </p>

            <p>
              Today, we would not want to be saddled with 19th or even 20th-century concepts of women’s rights, civil
              rights, or many other issues. We must admit that our ideas of right and wrong will always be imperfect.
              Two hundred years hence, people will likely look at our concepts of morality with as much disgust as we
              feel when we consider issues like slavery or the oppression of women and minorities in the far-too-recent
              past.
            </p>
            <p>
              Instead of asking if democracy is perfect, we should ask if our society is wiser and more just than fifty
              or one hundred years ago. The answer to those questions is an unequivocal "yes". But the process is never
              fast or smooth, and wisdom cannot be imposed.
            </p>

            <h3>
              The Purpose of Democracy
            </h3>
            <p className={styles.pullQuote}>
              Without compromise, there is only violence.
            </p>
            <p>
              The purpose of democracy is to foster the compromises that allow societies to make decisions without
              violence and evolve toward greater wisdom and justice.
            </p>
            <p>
              Notably, the purpose of democracy is not to promote any particular political agenda. It’s not up to you,
              me, or anyone else to define what policies democracy should promote. These decisions are made by society
              as a whole through the democratic process.
            </p>
            <p>
              In a democratic system where the minority believes they have meaningful input, it is in their interest to
              work within the democratic process to achieve their goals. If people in the minority believe in the
              ultimate justice of their views and they also believe in the democratic process, then society will
              eventually evolve to adopt those views. In a functioning democracy, violence is irrational.
            </p>
            <p>
              But for this to work, the democratic process must produce decisions that represent the will of the voters.
              If Attila the Hun defeats Genghis Khan, does that mean the voters really want Attila as the school board
              president? Is it enough for Attila to get a majority of the votes? Does Attila have a mandate to govern?
              The purpose of this contrived example is that – as the Greeks discovered – voting alone is not enough.
            </p>
            <p>
              A voting system that excludes people from meaningful participation through any means will eventually
              result in violence. Meaningful participation means more than voting or even electing candidates. The
              minority must be able to influence the path of government; they must be able to force the majority to
              compromise.
            </p>
            <p>
              Without compromise, there is only violence.
            </p>
          </div>
        </Article>
    )
  }
}




















