import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import VisualizationWidget from "../../../VisualizationWidget/VisualizationWidget";
import {AppMetadata} from "./App";
import {ConsensusVotingApp} from "../../../Introduction/ConsensusVotingApp";

interface Props {
  metaData: AppMetadata
}

export function Overview(props: Props) {
  // let mdash = "\u2014"
  // let lt = "<<"
  // let gt = ">>"
  return (
      <Article title={"Introduction"}
               subtitle={"There is more at stake than elections"}>
        <div className={styles.textContent}>
          <div className={styles.flyover} id={"detailsFlyover"} style={{
            display: "none",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 1,
          }}>
            <h6> Representative: <span id={"memberName"}>foo</span></h6>
            <h6> Party: <span id={"party"}>foo</span></h6>
            <h6> District: <span id={"districtName"}>foo</span></h6>
            <h6> Left/Right Ideology: <span id={"ideology"}>0</span></h6>
            <h6><span id={"representation"}> </span></h6>
          </div>

          <VisualizationWidget
              metaData={props.metaData}
              className={styles.dividedHouse}
              id={"actualHouse"}
              height={1000}
              width={1000}
              nVoters={8000}
              radius={3.5}
              render={(app: ConsensusVotingApp) => {
                app.showHouseAndVoters(1)
              }}
          />

          <p className={styles.caption}>
            This particular visualization of Congress is used repeatedly on the website. It's a clear illustration of
            the polarization and resulting dysfunction in our Congress and throughout our government. These are the
            435 U.S. House Members arranged by the ideological score of their voting records. Hover over a circle for
            information on the Representative.
          </p>
        </div>
      </Article>
  )
}




















