import * as d3 from "d3";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server"
import ScaleAndOffsets from "./ScaleAndOffsets";

class HTMLController {
  dirty = false
  htmlArea: any
  currentObject?: React.Component<any, any>
  constructor(visId: string) {
    this.htmlArea = this.getVisDiv(visId)
    this.currentObject = undefined
  }

  setNewVisDiv = (visId: string) => {
    this.htmlArea = this.getVisDiv(visId)
  }

  getVisDiv = (visId: string): d3.Selection<any, any, any, any> => {
    return d3
        .select(`#${visId}`)
        .select("#htmlArea")
        .style("display", "flex")
        .style("align-items", "center")
        .style("justify-content", "center")
  }

  set = (obj: React.Component<any, any>): void => {
    this.currentObject = obj
    this.dirty = true
  }

  clear() {
    if (this.currentObject) {
      this.currentObject = undefined
      this.dirty = true
    }
  }

  onResize = (s: ScaleAndOffsets) => {
    let w = window.innerWidth
    let h = window.innerHeight

    let px = Math.min(.6 * w, .75 * h)
    let x = w - px
    let y = (h - px) / 2
    this.htmlArea
        .attr("x", x)
        .attr("y", y)
        .attr("width", `${px}px`)
        .attr("height", `${px}px`)
    this.dirty = true
    this.render()
  }

  render = (): void =>  {
    if (!this.dirty) return
    if (this.currentObject) {
      let element: JSX.Element = this.currentObject.render() as JSX.Element
      let html = renderToStaticMarkup(element)
      this.htmlArea.html(html)
      this.dirty = false
    }
    else {
      this.htmlArea.html("")
    }
  }
}
export default HTMLController