import React from 'react'
import styles from './VotingReform101.module.scss'
import SectionTitle from '../../typography/SectionTitle'
import TopicHeader from '../TopicHeader/TopicHeader'
import ArticleIntro from "../ArticleIntro/ArticleIntro";

interface Props {

}

const VotingReform101 = (props: Props) => {
  return (
      <div className={styles.content}>
        <SectionTitle
            title="The Argument For Compromise"
            caption="Is compromise betrayal or essential to a peaceful society?"
        />
        <ul>

          <li>
            <ArticleIntro
                title="The Purpose of Democracy"
                subtitle="How do you fix something without defining its purpose?"

                link="/Democracy"
                imageUrl={"/images/Constitution.png"}
            />
          </li>

          <li>
            <ArticleIntro
                title="Our Factional Democracy"
                subtitle="When the only choice is between factions."
                link="/Factionalism"
                imageUrl={"/images/factionalism.png"}
            />
          </li>


          <li>
            <ArticleIntro
                title="The Game Theory of Elections"
                subtitle="The Leviathan destroys Madison's vision."
                link="/GameTheory"
                imageUrl={"/images/GameTheory.png"}
            />
          </li>

          <li>
            <TopicHeader
                title="The Forces Dividing Us"
                description="The factors contributing to division."
                link="/Tribalism"
            />
          </li>

          <li>
            <ArticleIntro
                title="The Global Threat"
                subtitle="All factional democracies are vulnerable.  And Doomed."
                link="/Global"
                imageUrl={"/images/fidez.png"}
            />
          </li>

          <li>
            <TopicHeader
                title="Hints of a Solution"
                description="Two Examples from California's Top-2 System point to a Solution."
                link="/CaliforniaTop2"
            />
          </li>

        </ul>


      </div>
  )
}

export default VotingReform101