export class GeometricMedian {
  median: number[] = []
  dim: number
  directionVecs = [
    [1, 0],
    [0, 1],
    [-1, 0],
    [0, -1],
  ]

  constructor(points: number[][], maxStep: number, minStep: number) {
    this.dim = points[0].length
    if (this.dim !== 2)
      return

    let bestVec = this.computeMean(points)
    let bestDistance = this.meanDistance(bestVec, points)
    let step = maxStep

    // console.log(`starting best :  ${bestVec.map(n => n.toFixed(3)).join(", ")}, ${bestDistance.toFixed(5)}`)

    let found = true
    while (found) {
      step = maxStep
      found = false
      while (step > minStep) {
        let bestTestDistance = 1e10
        let bestTestVec = Array(this.dim).fill(0)
        for (let i = 0; i < this.directionVecs.length; ++i) {
          let deltaVec = this.multiply(Array(this.dim).fill(step), this.directionVecs[i])
          let testVec = this.add(bestVec, deltaVec)
          let testDistance = this.meanDistance(testVec, points)
          if (i === 0 || testDistance < bestTestDistance) {
            bestTestDistance = testDistance
            bestTestVec = testVec
          }
        }
        if (bestTestDistance < bestDistance) {
          bestVec = bestTestVec
          bestDistance = bestTestDistance
          found = true
        } else {
          step *= .75
          // console.log(`new step: ${step}`)
        }
      }
    }
    this.median = bestVec
  }

  brutalMedianCalculation = (points: number[][]): number[] => {
    let bestVec = points[0]
    let bestDistance = this.meanDistance(bestVec, points)

    for (let i = 0; i < points.length; ++i) {
      let d = this.meanDistance(points[i], points)
      if (d < bestDistance) {
        bestDistance = d
        bestVec = points[i]
      }
    }
    return bestVec
  }


  add = (v1: number[], v2: number[]): number[] => {
    let x = Array(v1.length).fill(0)
    for (let i = 0; i < v1.length; i++) {
      x[i] = v1[i] + v2[i]
    }
    return x
  }

  multiply = (v1: number[], v2: number[]): number[] => {
    let x = Array(v1.length).fill(0)
    for (let i = 0; i < v1.length; i++) {
      x[i] = v1[i] * v2[i]
    }
    return x
  }


  computeMean = (points: number[][]): number[] => {
    let meanVec = Array(this.dim).fill(0)
    for (let i = 0; i < points.length; ++i) {
      for (let c = 0; c < this.dim; ++c) {
        meanVec[c] += points[i][c]
      }
    }
    for (let c = 0; c < this.dim; ++c) {
      meanVec[c] /= points.length
    }
    return meanVec;
  }

  meanDistance = (v: number[], points: number[][]): number => {
    let sum = 0
    for (let i = 0; i < points.length; ++i) {
      sum += this.distance(v, points[i])
    }
    return sum / points.length
  }


  distance = (v1: number[], v2: number[]): number => {
    let sum = 0
    for (let i = 0; i < this.dim; ++i) {
      let d = v1[i] - v2[i]
      sum += d * d
    }
    return Math.sqrt(sum)
  }
}