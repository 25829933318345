import React from 'react';
import {DSVRowArray} from "d3";
import styles from "./ConsensusVoting.module.scss"
import Step from "../ui/components/Step";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
import ElectionSimStoryBase from "../ElectionSimStoryBase/ElectionSimStoryBase";
import {RepresentationApp} from "./RepresentationApp";

class RepresentationStory extends ElectionSimStoryBase {

  mapAreas = [
    "mapArea1",
    "mapArea1",
    "mapArea1",
    "mapArea1",
    "mapArea1",
  ]
  visualizationAreas = [
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "mapArea1",
  ]

  createApp = (
      visDivId: string,
      dvr: DSVRowArray,
      DWNominate: DSVRowArray,
      simulationResults: DSVRowArray,
      sampleCongress: DSVRowArray,
      usTopo: any): ElectionSimApp => {
    return new RepresentationApp(this.visualizationAreas[0], dvr, DWNominate, simulationResults, sampleCongress, usTopo, 5001, 4)
  }


  render() {
    let mdash = "\u2014"
    return (
        <div className={styles.content}>
          <div className={styles.flyover} id={"detailsFlyover"} style={{
            display: "none",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 1,
          }}>
            <h6> Representative: <span id={"memberName"}>foo</span></h6>
            <h6> Party: <span id={"party"}>foo</span></h6>
            <h6> District: <span id={"districtName"}>foo</span></h6>
            <h6> Left/Right Ideology: <span id={"ideology"}>0</span></h6>
            <h6><span id={"representation"}> </span></h6>
          </div>
          <div>
            {this.renderVisArea(styles.drawing1, "drawingArea1")}
            { /* 0 */}
            <Step className={styles.stepElement} first={true}>
              <h2>Understanding Voter Representation</h2>
              <h3>An Election Between Two Candidates</h3>

              {this.generalResultTable()}

              <p>
                This shows the outcome of an election between two candidates in a balanced U.S. House of Representatives
                district. Voters are colored for the candidate that most closely matches their values. Juan has won the
                contest and is a better candidate than Bob to represent these voters. This is a foundational principle
                of democracy: Juan is a better representative of these voters here simply because the voters prefer
                him.
              </p>
              <p>
                Many people {mdash} starting with Juan and Bob {mdash} would say to look no further, but there are
                problems with this outcome.
              </p>
              <p>
                Juan, or whoever wins, will go on to represent the voters who elected him, not all of the voters in the
                district. Despite getting 48% of the vote, Bob's supporters will have zero say in the policy that Juan
                supports as a House member. It is winner take all as the Representatives are elected and then again
                at the legislative level depending on which side has a majority in the House.
              </p>
              <p>
                Like many of the visualizations on this site, this image is interactive. Dragging the candidate circles
                on the left will update the results as you drag.
              </p>
              {this.renderBalanceCaption()}
            </Step>

            { /* 4 */}
            <Step className={styles.stepElement}>
              <h3>A Closer Look at Juan</h3>
              <p>
                What if the race had been between Juan and a different candidate? Let's choose a not-so-random voter
                to become Juan's opponent and see how that works out.
              </p>
              {this.generalResultTable()}
              <p>
                Juan loses by a huge margin. Juan may be a better choice than Bob, but the voters prefer Anne to
                Juan by 2 to 1!

              </p>
              <p>
                This takes the luster off of Juan's victory. Juan is a terrible representative of these voters and can
                only win an election if his opponent is even worse.
              </p>
              <p>
                The voters prefer Anne to Juan (or Bob). That means that Anne is a better representative of these
                voters.
              </p>
              <p>
                If Anne is better than Juan, are there candidates who would beat Anne? How many are there?
              </p>
            </Step>

            { /* 5 */}
            <Step className={styles.stepElement}>
              <h3>What Does Representation Mean?</h3>
              <div style={{paddingTop: "20px", marginBottom: "20px"}}>
                {this.representationScore()}
              </div>
              <p>
                Not all voters could be candidates. But, just comparing their ideological differences, how many voters
                would beat Juan in a head-to-head race?
              </p>
              <p>
                Juan would lose to any of the darker blue voters if they were his opponent. Comparing Juan against each
                voter in the electorate as a potential opponent, Juan would win
                against {this.representationNumber().toFixed(0)}% of them. We will call that number
                Juan's <i>Representation Score</i>.
              </p>
              <p>
                Juan is a poor representative for this electorate because there are so many other potential candidates
                the voters would prefer. The representation score is based on the simple and fundamental principle that
                the candidate who receives a majority of the votes in a two candidate race is a better representative of
                the voters.
              </p>

              <p>
                Conservative candidates will have good scores in conservative districts, liberal candidates will have
                good scores in liberal districts.
              </p>

              <p>
                The background color of the Representation Score will be used on the next visualization. Drag the circle
                representing Juan around to see how that affects the score.
              </p>

            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.mapArea1, "mapArea1")}
            { /* 6 */}
            <Step first={true} className={styles.stepElement}>
              <h3>The United States House of Not Representatives</h3>
              <p>
                By stitching together data from election results, surveys, simulation, and Congressional voting records,
                it is possible to estimate the representation score for each member of Congress. It's awful. Few members
                are representative, and far too many are divisive.
              </p>

              <p>
                The first step in our election process, a primary, eliminates any candidate who doesn't represent their
                party's voters first. This ensures that members of Congress represent one party or the other and not
                all of the voters in their district.
              </p>

              <p>
                The House of Representatives is paralyzed by polarization and dysfunction, but that's not because they
                represent our values. Instead, it's paralyzed because our voting system ensures that the Members of
                the House represent their faction, not their voters.
              </p>
            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div style={{
            minHeight: "30vh"
          }}>
          </div>
        </div>
    )
  }
}

export default RepresentationStory
