import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";
import VisualizationWidget from "../../../VisualizationWidget/VisualizationWidget";
import {ConsensusVotingApp} from "../../../Introduction/ConsensusVotingApp";


interface Props {
  metaData: AppMetadata
}

export class GameTheory extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }


  render() {
    // let mdash = "\u2014"

    return (
        <Article title={"The Game Theory of Elections"}
                 subtitle={"The Leviathan Destroys Madison's Vision."}>
          <div className={styles.textContent}>
            <p>
              Why do candidates behave the way they do? When candidates choose issues to promote, bills to write, votes
              to cast, stances to take, or what to say and do in the uncountable different circumstances, why do they
              make the choices they make? Why do candidates act in ways that seem divisive to voters? Why do they
              embrace seemingly irrational conspiracy theories? Do they believe those conspiracy theories, or are they
              cynically manipulating their voters?
            </p>
            <p>
              Successful candidates behave the way they do because it works, and candidates who make different choices
              lose. They may be lucky idiots or master strategists. You can call their strategy common sense, or you
              could call it game theory.
            </p>
            <p>
              Game theory is the study of situations where the ideal strategy for one agent depends on the decisions
              made by other agents. Agents can be the players in a chess game, corporations, nations, or any other
              entity in any competitive environment. The "game" can be anything from chess to the Cold War or to any
              other contest. It is essential for studying politics.
            </p>
            <p>
              The strategy we see successful candidates using represents the end result of the evolution of the game
              theory of our political system. We know that our elections reward divisive, otherizing, and toxic behavior
              because candidates who engage in it win with disturbing frequency. Though we know that behavior works
              simply because we see it winning, we don't entirely understand why.
            </p>
            <p>
              Is our political universe exploding because cable news fragmented our concept of reality to the point
              where people from different political tribes can’t even have a discussion? Is it because social media took
              fragmentation to a whole new level where every conspiracy theorist can find a community of like-minded
              people to confirm their crazy? Is it the constellation of globalization, immigration, and automation that
              has created pressures that threaten every democracy around the world?
            </p>
            <p>
              Most disturbingly, does our conflict come from inside us instead of external circumstances? Are human
              beings inherently incapable of living in large societies without descending into strife and discord?
              Should we just expect that democracies will teeter on the brink of disaster and periodically descend into
              violence and authoritarianism? Are we doomed to tribal warfare with our ever-increasing technological
              prowess all but ensuring our eventual extinction like a pack of nuclear-armed chimpanzees?
            </p>
            <p>
              There are countless articles where different authorities have suggested each of those as the cause of
              political polarization, and they each have some level of merit. There is very little hard evidence about
              the degree to which one cause or another is to blame, and the debate over which of these is responsible
              for what degree of our problems is entirely subjective.
            </p>
            <p>
              It’s impossible to conduct an election without all of these potentially polarizing influences, but we can
              simulate elections without these factors. Simulation can tell us what happens in an ideal world where the
              population is the familiar bell curve of political opinions from left to right, and the voters make
              rational decisions about who best represents their views. What happens then?
            </p>
            <h3>
              Simulation
            </h3>
            <p>
              To explore this question, I chose to simulate elections for the United States House of Representatives.
              The House makes an ideal test case for simulation because the districts span a wide variety of political
              circumstances, and it is so very polarized. There are districts that lean to each partisan extreme and
              even a few competitive districts. This is what the U.S. House of Representatives looks like currently.

            </p>
            <VisualizationWidget
                metaData={this.props.metaData}
                className={styles.simulatedHouse}
                id={"actualHouse"}
                height={1000}
                width={1000}
                render={(app: ConsensusVotingApp) => {
                  app.showActualHouse()

                }}/>

            <p>

              And this is what simulation says would happen in an ideal world with an unpolarized population.
            </p>
            <VisualizationWidget
                metaData={this.props.metaData}
                className={styles.simulatedHouse}
                id={"simulatedHouse"}
                height={1000}
                width={1000}
                render={(app: ConsensusVotingApp) => {
                  app.showSimulatedHouse(0)
                }}/>
            <p>
              The result is both shocking and reassuring. It’s shocking because the population used for simulation is
              the familiar bell curve of voters with a strong center and few voters on the extreme. We can argue about
              whether or not our actual population is polarized or to what degree, but the population in the simulation
              is not polarized at all, and yet they elected a polarized Congress that looks remarkably like our existing
              Congress.
            </p>
            <p>

              The simulation’s simplicity is its strength. The simulation didn’t consider the impacts of our fractured
              media environment, social media, demagogic candidates, globalization, immigration, or social changes.
              Every voter voted, and there was no voter suppression or voter fraud. Everybody was well-informed. Nobody
              was tribal, bigoted, or ignorant. Big money had zero influence, and nobody could distort the issues or
              create false narratives by spending insane amounts of money. Simulation is a perfect world that can never
              be matched by reality. And yet, even in this perfect world, the voters are unable to elect a Congress that
              reflects their values.
            </p>
            <p>
              Electing polarizing leaders creates a feedback loop that will ultimately polarize the voters themselves.
              By electing partisan and extreme candidates, we legitimize their views, give them a platform to spread
              those partisan viewpoints, and empower similar voices in the media. Over time, this will create and deepen
              tribal divisions within our society. Each election produces leaders who pull our society further apart and
              enable even more divisive candidates in the next cycle. How does this end? Maybe we become horrified by
              the division within our society and stop it somehow before it’s too late. Or not.
            </p>
            <p>
              Now we get to the power of simulation because this toxic stew our election system has cooked up has only
              two ingredients: a unified electorate and the voting rule itself. There are uncountable reasons we
              might become polarized and tribal, but the voting rule alone is enough to elect polarizing leaders and
              trigger the cycle of polarization.
            </p>
            <h3>
              Bad News Is Actually Good News
            </h3>
            <p>

              This is the classic good news/bad news situation. The bad news is that the voting rule will create a cycle
              of polarization, even if it’s not what the people want. Left unchecked, this cycle of polarization,
              tribalism, and otherization will progress until our democracy is destroyed. Eventually, one side or
              the other will decide that handing over power to people they perceive to be evil is worse than undermining
              democracy. This sounds like it might be far-fetched, but a recall that a recent CNN poll showed that ~80%
              of Americans in each party think the other party would destroy America as we know it.
            </p>
            <p>
              The good news is that it means that fixing the voting rule will profoundly affect our politics and our
              society. The list of potential causes of polarization and tribalism is almost endless, and most of them
              cannot be fixed. We cannot rewrite human psychology, ensure that democracy doesn’t have to solve tough
              problems or ensure that no unscrupulous politician seeks to exploit our differences. But we can change
              the voting rule.
            </p>
            <h3>
              A Glimmer of Hope
            </h3>
            <p>
              What if instead of rewarding candidates who exploit our differences, the voting rule rewarded candidates
              for finding common ground? What if the people we elect didn’t empower extremist voices in the media? What
              if embracing conspiracy theories was political suicide? What if campaigning on fear and anger was a short
              road to retirement?
            </p>
            <p>
              If simulating elections with just these two ingredients had not produced a polarized outcome, we would
              have to look elsewhere for the cause of our dysfunction, and many of those factors are not things we can
              influence. If the critical ingredient was our inherent tribalism, social change, or economic challenges,
              then solving our political dysfunction might well be beyond us.
            </p>
            <p>
              Even though we have become polarized over the last 30 years, the polarization did not come from
              within us. It came from the election system itself.
            </p>

          </div>
        </Article>
    )
  }
}




















