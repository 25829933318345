import React from 'react';
import {csv, json} from 'd3-fetch'
import MapController from "../ui/elements/MapController";
import {DSVRowArray} from "d3";
import CombinedDistrictData from "../core/CombinedDistrictData";
import ElectionSimApp, {SimulationResults, SimulationState} from "../ElectionSimulation/ElectionSimApp";
import Scroller from "../ui/elements/Scroller";
import {CandidateResultTable, ResultWidget2, TableItem} from "../ui/elements/ResultWidget2";
import WinnerAndScore from "../ElectionSimulation/WinnerAndScore";
import {ControlState} from "../ui/elements/SimulationControls";
import VisualizationArea from "../ui/components/VisualizationArea";
import SliderWidget from "../ui/elements/SliderWidget";
import styles from "../Introduction/ConsensusVoting.module.scss";
import Candidate from "../core/Candidate";

export interface SimStoryBaseProps {
  section?: number
  progress?: number
  simulationState?: SimulationState
}


abstract class ElectionSimStoryBase extends React.Component<any, any> {
  map!: MapController
  combinedDistrictData!: CombinedDistrictData
  app!: ElectionSimApp
  props: SimStoryBaseProps
  scroller: Scroller
  loopCount: number = 0


  visualizationAreas = [
    "drawingArea1",
  ]
  mapAreas = this.visualizationAreas
  abstract createApp: (
      visDivId: string,
      dvr: DSVRowArray,
      DWNominate: DSVRowArray,
      simulationResults: DSVRowArray,
      sampleCongress: DSVRowArray,
      usTopo: any,
      nVoters: number,
      radius: number) => ElectionSimApp

  constructor(props: SimStoryBaseProps) {
    super(props);
    this.props = props
    this.scroller = new Scroller(this.onNewSection, this.onProgress)
    this.state = {
      section: 0,
      progress: 0,
      simulationState: undefined
    }
  }

  onNewSection = (section: number, progress: number) => {
    this.setState(
        {
          section: section,
          progress: progress,
          simulationState: this.state.simulationState
        })
  }
  sectionResults = (sectionName: string): SimulationResults | undefined => {
    return this.state.simulationState?.simulationResults.get(sectionName)
  }

  onCandidateEdit = (name: string, party: string, quality: number) => {
    this.app.onCandidateEdit(name, party, quality)
  }

  onProgress = (_section: number, _progress: number) => {
    // console.log(`TwoCandidateStory.onProgress ${_section}`)
  }

  componentDidUpdate() {
    this.app?.onNewSection(this.state.section, this.state.progress)
  }

  componentDidMount() {
    this.initializeStory()
    this.scroller.scroll()
  }

  initializeStory = () => {
    Promise.all([
          csv('data-5vPn3.csv'),
          csv('HS_all_members.csv'),
          csv('simulationResults.csv'),
          csv('sampleCongress.csv'),
          json('us2018.topo.json')
        ]
    ).then(([dvr, DWNominate, simulationResults, sampleCongress, usTopo]) => {
      if (dvr && DWNominate && simulationResults && sampleCongress && usTopo) {
        this.app = this.createApp(this.visualizationAreas[0], dvr, DWNominate, simulationResults, sampleCongress, usTopo, 20001, 2.5)
        this.app.setVisualizationAreas(this.visualizationAreas, this.mapAreas)
        this.app.onUpdate = (simulationState: SimulationState) => {
          let state = {...this.state, simulationState}
          this.setState(state)
        }
        let state = {
          ...this.state,
          simulationState: this.app.currentSimulationState()
        }
        this.setState(state)
      }
    })
  }

  renderBalanceCaption = (): JSX.Element => {
    return (
        <p style={{fontSize: "8pt", fontStyle: "italic", width: "100%"}} className={styles.caption}>
          This election is shown in a balanced district without party preferences. Explore
          those options in the <a href={"/SimulationTutorial"}>simulation tutorial</a> and <a
            href={"/ElectionSim"}>election simulator</a> .
        </p>
    )
  }

  generalResultTable = (sectionName: string = "", titleOpt: string = ""): JSX.Element => {
    let result = sectionName === "" ?
        this.state.simulationState?.primaryResults :
        this.sectionResults(sectionName)?.primaryResults
    if (!result) {
      return null as any as JSX.Element
    }
    let title = titleOpt ? titleOpt : "Election Results"
    let table = result.tables[0]
    table.title = title
    return (
        <div className={styles.centeredResultBox}>
          <ResultWidget2
              tableInfo={table}
          />
        </div>
    )
  }

  representationNumber = (): number => {
    if (!this.app)
      return 0
    else
      return this.app.candidates2D.bubbles[0].voterRepresentation
  }

  representationScore = (): JSX.Element => {
    if (!this.app)
      return null as any as JSX.Element
    else {
      return (<WinnerAndScore candidate={this.app.candidates2D.bubbles[0]}/>)
    }
  }

  filterIgnoredItems(items
                         :
                         TableItem[], relevantCandidates
                         :
                         Set<Candidate>
  ) {
    let className = items[0].candidate && relevantCandidates.has(items[0].candidate) ? items[0].className : "ignoredCandidate"
    return items.map(item => new TableItem(item.content, item.style, item.onSelect, className, item.candidate))
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Note that the round number is reversed here.  The table is stored in
  // display order with the final round first to be displayed at the top.
  irvRoundTable = (requestedRound: number, sectionName: string): JSX.Element => {
    let newTable = undefined
    let irvResults = this.sectionResults(sectionName)?.irvResults
    if (irvResults) {
      let maxRound = irvResults.tables.length - 1
      let round = requestedRound > maxRound ? maxRound : requestedRound
      // console.log(`irvRoundTable: table round ${round} maxRound ${maxRound}, requestedRound ${requestedRound}`, this.state.simulationState)
      let table = irvResults.tables[round]
      let party = table.rows.slice(-1)[0][0].candidate?.party
      if (!party) return null as any as JSX.Element
      let orderedCandidates = table.rows.map((r: TableItem[]) => r[0].candidate!)
      let relevantCandidates = orderedCandidates.filter((c: Candidate) => c.party === party).slice(-2)
      let newRows = table.rows.map((row: TableItem[]) => this.filterIgnoredItems(row, new Set(relevantCandidates)))
      newTable = new CandidateResultTable(table.title, table.caption, table.headings, newRows, table.className, table.id)
    }
    return (
        <div className={styles.centeredResultBox}>
          <ResultWidget2 tableInfo={newTable}/>
        </div>
    )
  }

  consensusTable = (name: string, sectionName: string = "default"): JSX.Element => {
    let table = this.resultsForCandidate(name, sectionName)
    return (
        <div className={styles.centeredResultBox}>
          <ResultWidget2 tableInfo={table}/>
        </div>
    )
  }
  resultsForCandidate = (name: string, sectionName: string): CandidateResultTable | undefined => {
    let tables = this.sectionResults(sectionName)?.allResults.tables
    if (tables) {
      let table = tables.find(t => t.id === `${name}_ConsensusResults`)
      if (table)
        table.caption = "Select a row to show voter preference for that pair of candidates."
      return table
    }
    return undefined
  }

  onLeanUpdate = (districtLean: number) => {
    this.app.onLeanUpdate(districtLean)
    this.app.stopAllTimers()
  }

  onLoyaltyUpdate = (partyPreference: number) => {
    let controlState: ControlState = {
      ...this.app.controlState, partyPreference
    }
    this.app.onControlUpdate(controlState)
    this.app.stopAllTimers()
  }

  onUncertaintyUpdate = (uncertainty: number) => {
    let controlState: ControlState = {
      ...this.app.controlState, uncertainty
    }
    this.app.onControlUpdate(controlState)
    this.app.stopAllTimers()
  }

  getPartyPreference = (): number => {
    let preference = 0
    if (this.app) preference = this.app.controlState.partyPreference
    return preference
  }

  partyLoyaltySlider = (): JSX.Element | null => {
    return this.app?.showLoyaltySlider ?
        (
            <SliderWidget
                id={"partyLoyaltySlider"}
                title={'Party Preference'}
                leftLabel={"None"}
                rightLabel={"Expected"}
                min={0}
                max={1}
                value={this.app ? this.app.controlState.partyPreference : 0}
                step={.05}
                onChange={this.onLoyaltyUpdate}
            />

        ) : null
  }

  uncertaintySlider = (): JSX.Element | null => {
    return this.app?.showUncertaintySlider ?
        (
            <SliderWidget
                id={"uncertaintySlider"}
                title={"Uncertainty"}
                leftLabel={"None"}
                rightLabel={"A Lot"}
                min={0}
                max={.5}
                value={this.app ? this.app.controlState.uncertainty : 0}
                step={.05}
                onChange={this.onUncertaintyUpdate}
            />
        ) : null
  }

  leanSlider = (): JSX.Element | null => {
    return this.app?.showLeanSlider ?
        (
            <SliderWidget
                id={"leanSlider"}
                title={"Partisan Lean of Voters"}
                leftLabel={"Liberal"}
                rightLabel={"Conservative"}
                min={-.5}
                max={.5}
                value={this.app ? this.app.lean : .5}
                step={.05}
                onChange={this.onLeanUpdate}
            />
        ) : null
  }

  renderSliders = (): JSX.Element => {
    return (
        <div id={"slidersDiv"}>
          {this.partyLoyaltySlider()}
          {this.uncertaintySlider()}
          {this.leanSlider()}
        </div>
    )
  }

  renderVisArea = (cls: string, id: string): JSX.Element => {
    return (
        <VisualizationArea
            className={cls}
            id={id}
        >
          {this.renderSliders()}
        </VisualizationArea>
    )
  }

  render() {
    return (<span> </span>)
  }
}

export default ElectionSimStoryBase
