import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class Leviathan extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }

  render() {
    // let mdash = "\u2014"

    return (
        <Article title={"A Mathematical Leviathan"}
                 subtitle={"Our society is shaped by an unseen, untamed, and poorly understood monster."}>
          <div className={styles.textContent}>

            <p className={styles.pullQuote}>
              “There are 47% of the people who will vote for the president [Obama] no matter what. [...]who are
              dependent
              upon government, who believe that they are victims. These are people who pay no income tax. [...] So, my
              job is not to worry about those people. I'll never convince them that they should take personal
              responsibility and care for their lives.”
              – Mitt Romney, May 17, 2012
            </p>
            <p>
              Romney’s gaffe was epic, but it was the product of the Leviathan that shapes our democracy.
            </p>
            <p>
              The Leviathan is a beast born from the rules that govern elections, mathematics, and human ambition. This
              beast is not Hobbes’ Leviathan. Some might call it game theory. But game theory misses the hidden nature
              of the monster lurking under the surface of our political world. We see the corrosion of our democracy,
              but we are oblivious to the mathematical tentacles shaping everything we see on the surface.
            </p>
            <p>
              Less than five months from the November election, Romney’s remarks sank – or at least severely damaged –
              his campaign for the presidency. He demonstrated a breathtaking disconnect with everyday Americans and
              even insulted many supporters. At the time, we were obsessed with the accuracy of his remarks and their
              impact on voters. CNN pilloried him. The New York Times called him “Thurston Howell Romney.” Meanwhile,
              Fox News said his only mistake would be to apologize.
            </p>
            <p>
              But the media frenzy missed the point.
            </p>
            <p>
              Four years earlier, we saw the wake of the Leviathan in Barack Obama's comments about rural Republican
              voters clinging to guns and religion. Four years later, Hillary Clinton set a new standard for
              insensitivity by describing half of Donald Trump's supporters as a "basket of deplorables."
            </p>
            <p>
              At the time, we explained each of these incidents as political gaffes. But they revealed a disconnect with
              voters on the other side that was so deep it could only occur in a political environment shaped by the
              Leviathan.
            </p>
            <p>
              Sometimes, the Leviathan is glimpsed in the accidental revelation of a candidate's inner calculus. Other
              times, it reveals itself in the broader strategy of campaigns and political parties as they seek political
              power.
            </p>
            <p>
              The video of Romney’s remarks has a Zapruder-like quality of accidentally catching a historic moment on
              film. In the same breath that Romney torpedoed his campaign, he described the forces that made his
              self-immolation inevitable.
            </p>
            <p>
              When Romney said, "So, it's not my job to worry about them," he made a statement of fact about the game
              theory of elections. The uncaring mathematics of elections dictates that candidates owe their political
              survival to their faction – the voters who will or may vote for them. The inner calculus Romney, Obama,
              and Clinton revealed originates in a world where nobody else matters. The Leviathan demands that
              candidates serve their faction alone, and that makes our democracy a factional democracy.
            </p>
            <p>
              With nine words, Romney captured the essence of the Leviathan and the consequences that will ultimately
              dwarf the outcome of any individual election.
            </p>
            <p>
              Nobody noticed.
            </p>
            <p>
              Eleven years later, we are slowly being eaten but still missing the point.
            </p>
            <p>
              Eighty percent of voters in each party think the other party would destroy America as we know it if they
              had the power. We don’t talk to each other, we don’t want our kids marrying someone in the other tribe,
              and we’re terrified of the consequences should the other party gain complete control.
            </p>
            <p>
              Consciously or not, we segregate ourselves from the other party. We live in different neighborhoods,
              attend different churches, send our kids to different schools, and watch different news. When we visit
              with a stranger, we look for the not-so-subtle markers of tribal identity. When we discover they’re from
              the other tribe, we think, “Wow, they seemed like such nice people.”
            </p>
            <p>
              Long-term friends, coworkers, and family members become persona non grata when their political views are
              discovered.
            </p>
            <p>
              We’ve abandoned the idea of “respectfully disagreeing” with people, and compromising with the other side
              has become treason to the cause.
            </p>
            <p>
              These are wounds from the beast.
            </p>
            <p>
              We’re aware of the disease, we see the polarization, and we lament the toxicity. The dysfunction
              frustrates us, the tribalism dismays us, and we search for a way out.
            </p>
            <p>
              Some blame cable news, social media, and humanity’s inherently tribal nature. Others say the problem is
              immigration, automation, and globalization. Or, maybe we all just need to be better people. But these are
              not the problem, not really.
            </p>
            <p>
              The Leviathan swims deeper, and we rarely see the beast itself. Instead, we sense its passage and feel its
              bite as its invisible mathematical engine turns small problems into big problems and big problems into
              crises. Any problem will do – immigration, automation, or globalization – the math doesn’t care. It could
              be daylight savings time. All it needs is an Us and a Them. A We and a They. Or just an Other.
            </p>
            <p>
              It's not a political stumble when politicians casually insult half of America; it's math. These stumbles
              don't come from some vacuum; they come from a political environment where the math dictates that the only
              people who matter are those who will or may vote for you. These flashes of disdain and contempt are the
              inevitable consequence of decades of disregard for people whose thoughts, feelings, and fate are
              irrelevant to a candidate's ambition.
            </p>
            <p>
              As voters, parties, candidates, or nations, we are helpless before the math of the Leviathan. Math takes
              no prisoners and grants no quarter. But math is mindless. It has no intention. It does not want. Instead,
              the math of the Leviathan is a product of the rules of elections, and therein lies hope. Different
              election rules would create different math and different outcomes for the societies they govern.
            </p>

          </div>
        </Article>
    )
  }
}




















