class StateData {
  id: number
  name: string
  abbreviation: string

  constructor(csvLine: string) {
    let f = csvLine.split(",")
    this.id = +f[2]
    this.name = f[3]
    this.abbreviation = f[4]
  }
}

class FIPSData {
  stateCsv = ["3,6,01,Alabama,AL",
    "4,9,02,Alaska,AK",
    "4,8,04,Arizona,AZ",
    "3,7,05,Arkansas,AR",
    "4,9,06,California,CA",
    "4,8,08,Colorado,CO",
    "1,1,09,Connecticut,CT",
    "3,5,10,Delaware,DE",
    "3,5,11,District of Columbia,DC",
    "3,5,12,Florida,FL",
    "3,5,13,Georgia,GA",
    "4,9,15,Hawaii,HI",
    "4,8,16,Idaho,ID",
    "2,3,17,Illinois,IL",
    "2,3,18,Indiana,IN",
    "2,4,19,Iowa,IA",
    "2,4,20,Kansas,KS",
    "3,6,21,Kentucky,KY",
    "3,7,22,Louisiana,LA",
    "1,1,23,Maine,ME",
    "3,5,24,Maryland,MD",
    "1,1,25,Massachusetts,MA",
    "2,3,26,Michigan,MI",
    "2,4,27,Minnesota,MN",
    "3,6,28,Mississippi,MS",
    "2,4,29,Missouri,MO",
    "4,8,30,Montana,MT",
    "2,4,31,Nebraska,NE",
    "4,8,32,Nevada,NV",
    "1,1,33,New Hampshire,NH",
    "1,2,34,New Jersey,NJ",
    "4,8,35,New Mexico,NM",
    "1,2,36,New York,NY",
    "3,5,37,North Carolina,NC",
    "2,4,38,North Dakota,ND",
    "2,3,39,Ohio,OH",
    "3,7,40,Oklahoma,OK",
    "4,9,41,Oregon,OR",
    "1,2,42,Pennsylvania,PA",
    "1,1,44,Rhode Island,RI",
    "3,5,45,South Carolina,SC",
    "2,4,46,South Dakota,SD",
    "3,6,47,Tennessee,TN",
    "3,7,48,Texas,TX",
    "4,8,49,Utah,UT",
    "1,1,50,Vermont,VT",
    "3,5,51,Virginia,VA",
    "4,9,53,Washington,WA",
    "3,5,54,West Virginia,WV",
    "2,3,55,Wisconsin,WI",
    "4,8,56,Wyoming,WY"]

  atLargeStates = new Set("AK,DE,MT,ND,SD,VT,WY".split(","))

  fipsByStateId = new Map<number, StateData>()
  fipsByAbbreviation = new Map<string, StateData>()
  stateData = this.stateCsv.map(csvLine => new StateData(csvLine))

  constructor() {
    this.stateData.forEach((sd: StateData) => {
      this.fipsByStateId.set(sd.id, sd)
      this.fipsByAbbreviation.set(sd.abbreviation, sd)
    })
  }

  hasAbbreviation = (abbreviation: string): boolean => {
    return this.fipsByAbbreviation.has(abbreviation)
  }

  hasGeoId = (geoId: number): boolean => {
    let stateId = Math.floor(geoId / 100)
    return this.fipsByStateId.has(stateId)
  }

  abbreviation = (fipsStateId: number): string => {
    return this.fipsByStateId.get(fipsStateId)!.abbreviation!
  }
  stateId = (abbreviation: string): number => {
    return this.fipsByAbbreviation.get(abbreviation)!.id
  }

  // For states with only one district:
  // nokken poole uses 01  (HS_116_members.csv)
  // the district voting records use "AL" (at large) (data-5vpn3.csv)
  // the geoId uses 00 for at large districts (phooey)
  // I have edited the DVR data to use 01 instead of AL so at least those are consistent
  // going from geoId to districtId is simple, anything that is districtId of 00 is really 01
  // this produces the string-id used in the Nikken data from a GeoId
  stringId = (geoId: number): string => {
    // console.log(`getting stringId for geoId ${geoId}`)
    let stateId = Math.floor(geoId / 100)
    let stateAbbreviation = this.abbreviation(stateId)
    let districtId = geoId % 100
    if (districtId === 0) districtId = 1

    // typescript/javascript does not have any form of printf style formatting?  OMFG
    let districtString: string = ""
    if (districtId < 10)
      districtString = `0${districtId}`
    else
      districtString = `${districtId}`

    return `${stateAbbreviation}-${districtString}`
  }
  // these are the "at large" states
  //  AK-AL,Don Young (R),R+9,36.6,51.3,40.8,54.8
  //  DE-AL,Lisa Blunt Rochester (D),D+6,53.2,41.8,58.6,40
  //  MT-AL,Greg Gianforte (R),R+11,35.7,56.2,41.7,55.4
  //  ND-AL,Kelly Armstrong (R),R+16,27.2,63,38.7,58.3
  //  SD-AL,Dusty Johnson (R),R+14,31.7,61.5,39.9,57.9
  //  VT-AL,Peter Welch (D),D+15,56.7,30.3,66.6,31
  //  WY-AL,Liz Cheney (R),R+25,21.9,68.2,27.8,68.6


  // this takes the string-id used in the Nokken data and returns a GeoId
  geoId = (stringId: string): number => {
    let f = stringId.split("-")
    let stateAbbreviation = f[0]
    let districtNumber = (f[1] === "01" && this.atLargeStates.has(f[0])) ? 0 : +f[1]

    let stateId = this.stateId(stateAbbreviation)
    return stateId * 100 + districtNumber
  }
}
let fipsData = new FIPSData()
export default FIPSData
export {fipsData}