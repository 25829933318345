import DisplayRectangle from "../../VoterRepresentation/DisplayRectangle";
import * as d3Drag from 'd3-drag'

class SVGSlider {

  valueMin: number
  valueMax: number
  valueRange: number
  width: number
  id: string
  parentSVG: d3.Selection<any, any, any, any>
  location: DisplayRectangle
  initialValue: number
  lineWidth: number
  leftFill: string
  rightFill: string
  leftLabel: string
  rightLabel: string
  userUpdateCB?: () => void
  dragStartCB: (v: number) => void
  dragCB: (v: number) => void
  dragEndCB: (v: number) => void
  cx: number

  leftLine?: d3.Selection<any, any, any, any> | undefined
  rightLine?: d3.Selection<any, any, any, any> | undefined
  marker?: d3.Selection<any, any, any, any> | undefined
  markerG?: d3.Selection<any, any, any, any> | undefined
  leftText?: d3.Selection<any, any, any, any> | undefined
  rightText?: d3.Selection<any, any, any, any> | undefined


  constructor(id: string,
              parentSVG: d3.Selection<any, any, any, any>,
              location: DisplayRectangle,
              valueLimits: [number, number],
              initialValue: number,
              lineWidth: number,
              leftFill: string,
              rightFill: string,
              leftLabel: string,
              rightLabel: string,
              dragStartCB: (v: number) => void = (_n) => {
              },
              dragCB: (v: number) => void = (_n) => {
              },
              dragEndCB: (v: number) => void = (_n) => {
              }) {


    this.id = id
    this.parentSVG = parentSVG
    this.location = location
    this.valueMin = valueLimits[0]
    this.valueMax = valueLimits[1]
    this.valueRange = this.valueMax - this.valueMin
    this.width = location.width
    this.initialValue = initialValue
    this.lineWidth = lineWidth
    this.leftFill = leftFill
    this.rightFill = rightFill
    this.leftLabel = leftLabel
    this.rightLabel = rightLabel
    this.dragStartCB = dragStartCB
    this.dragCB = dragCB
    this.dragEndCB = dragEndCB
    this.cx = this.width * (initialValue - this.valueMin) * this.valueRange
  }


  render() {
    this.parentSVG.selectAll(`#${this.id}`).remove()

    let g = this.parentSVG.append("g")
        .attr("id", this.id)
        .attr("transform",
            `translate(${this.location.x},${this.location.y + this.location.height / 2})`
        )
        .on("mousedown", (e: MouseEvent) => {
          // console.log("onMouseDown", e, this.location)
          // this.onLineClick(e)
        })

    this.leftLine = g.append("line")
        .attr("x1", 0)
        .attr("x2", this.width * (this.initialValue - this.valueMin) / this.valueRange)
        .attr("y1", 0)
        .attr("y2", 0)
        .style("stroke", this.leftFill)
        .style("stroke-linecap", "round")
        .style("stroke-width", this.lineWidth)


    this.rightLine = g.append("line")
        .attr("x1", this.width * (this.initialValue - this.valueMin) / this.valueRange)
        .attr("x2", this.width)
        .attr("y1", 0)
        .attr("y2", 0)
        .style("stroke", this.rightFill)
        .style("stroke-linecap", "round")
        .style("stroke-width", this.lineWidth)

    let x = this.width * (this.initialValue - this.valueMin) / this.valueRange
    this.markerG = g.append("g")
        .attr("id", "markerG")
        .attr("transform", `translate(${x}, 0)`)
        // @ts-ignore
        .call(this.dragBehavior)

    this.marker = this.markerG.append("circle")
        .attr("cx", 0)
        .attr("cy", 0)
        .attr("r", this.lineWidth)
        .style("stroke", "black")
        .style("stroke-width", 1)
        .style("fill", "white")
        .style("cursor", "grab")

    this.markerG.append("text")
        .attr("x", 0)
        .attr("y", 0)
        .text("<>")
        .attr("dy", ".25em")
        .attr("font-size", `${this.lineWidth}pt`)
        .style("text-anchor", "middle")
        .style("fill", "black")
        .style("cursor", "grab")

    this.leftText = g.append("text")
        .attr("x", -20)
        .attr("y", 0)
        .text(this.leftLabel)
        .attr("dy", ".4em")
        .attr("font-size", `${this.lineWidth}pt`)
        .style("text-anchor", "end")
        .style("fill", "black")

    this.rightText = g.append("text")
        .attr("x", this.location.width + 20)
        .attr("y", 0)
        .text(this.rightLabel)
        .attr("dy", ".4em")
        .attr("font-size", `${this.lineWidth}pt`)
        .style("text-anchor", "start")
        .style("fill", "black")


  }

  createDragBehavior = () => {
    let obj = this
    return d3Drag.drag<SVGCircleElement, SVGCircleElement>()
        .on("start", function (this: SVGCircleElement, e: DragEvent) {
          obj.onDragStart(this, e)
        })
        .on("drag", function (this: SVGCircleElement, e: DragEvent) {
          obj.onDrag(this, e)
        })
        .on("end", function (this: SVGCircleElement, e: DragEvent) {
          obj.onDragEnd(this, e)
        })
  }
  dragBehavior = this.createDragBehavior()

  clear = (): void => {
    this.parentSVG.select(`#${this.id}`).remove()
  }

  value = (): number => {
    return this.cx / this.width * this.valueRange + this.valueMin
  }
  setValue = (newValue: number): void => {
    if (newValue !== this.value()) {
      this.updateView(newValue)
      this.dragCB(this.value())
      this.dragEndCB(this.value())
    }
  }

  onLineClick(e: MouseEvent) {
    this.updateSelectedX(e.x)
  }

  onDrag = (circleNode: SVGCircleElement, e: DragEvent): void => {
    this.updateSelectedX(e.x)
  }

  updateSelectedX = (selectedX: number) => {
    if (selectedX < 0)
      selectedX = 0
    if (selectedX > this.width)
      selectedX = this.width
    let
        currentValue = selectedX / this.width * this.valueRange + this.valueMin
    this.updateView(currentValue)
  }


  onDragEnd = (circleNode: SVGCircleElement, e: DragEvent): void => {
    this.dragEndCB(this.value())
  }

  onUserUpdate = (action: () => void) => {
    this.userUpdateCB = action
  }

  onDragStart = (circleNode: SVGCircleElement, e: DragEvent): void => {
    this.dragStartCB(this.value())
    this.userUpdateCB?.()
  }


  updateView = (newValue: number): void => {
    this.cx = (newValue - this.valueMin) / this.valueRange * this.width
    this.leftLine?.attr("x2", this.cx)
    this.rightLine?.attr("x1", this.cx)
    this.markerG?.attr("transform", `translate(${this.cx}, 0)`)
    this.dragCB(newValue)
  }
}

export default SVGSlider
