import Article from "../content/Article/Article";
import styles from "../content/Article/Article.module.scss"

export function ToxicPolarization() {
  return (
      <Article title={"Toxic Polarization"}
               subtitle={"Debate is great, when does it become Toxic?"}>
        <div className={styles.textContent}>
          <p>
            Polarization and debate have long been a part of American politics. It is not my goal to eliminate
            contrasting opinions and vigorous debate; that's a healthy part of our democracy. But over the last 30
            years, the level of vitriol between the political parties has paralyzed our government, poisoned political
            discourse, and prevented us from solving any of the complex problems we face.
          </p>

          <p>
            Congress has largely ceased to function in a bipartisan way or even at all, and our political discourse has
            degenerated into little more than name-calling on cable news. While many people would agree that the
            political environment has become toxic what is the difference between healthy debate and toxic polarization?
            When does debate become toxic?
          </p>

          <p className={styles.pullQuote}>
            When conspiracies are legitimized by elected officials they amplify through the media and metastasize
            through our society.
          </p>

          <p>
            Candidates and elected politicians embrace conspiracy theories and act with total disregard for voters in
            the other party because it works. Voters in the minority party, who likely reject these theories, don't
            matter. Candidates would not behave that way if it was not a successful strategy and that strategy will
            continue to be successful until all voters have a meaningful say in the outcome of an election.
          </p>

          <p>
            It is impossible to give every voter what they want, but voters should be able to say they prefer a less
            toxic candidate from the other party and that preference must mean something. To solve this problem,
            denigrating and attacking voters of the other party must have a cost. It must be in the candidate's
            interest to represent all of the voters, not just their party's voters and not just their primary voters.
          </p>

          <p>
            The alternative is terrifying. Each election cycle we elect candidates that are more polarizing than the
            previous cycle. By electing these candidates we empower both the candidates and polarizing voices in the
            media. The cycle then repeats, and repeats, and repeats. The does not end well.
          </p>

          <p className={styles.pullQuote}>
            The election process needs to be where we find consensus, not only about which candidate to elect, but about
            which reality to reinforce through that election.
          </p>
          <p>
            For many years, other forces offset the polarizing dynamic of the election process: We had an external enemy
            in the Soviet Union and there were three, nearly identical sources of news for the vast majority of the
            population. This gave us a common foundation of facts, however flawed, that allowed us to at least have a
            debate about the issues.
          </p>
          <p>
            The rise of cable news and then social media have fractured our common understanding of reality. No matter
            how crazy and damaging a conspiracy fantasy might be, people can find an online echo chamber to reinforce
            it. This has provided a fertile ground for crazy that Q-Anon and others have exploited. When conspiracies
            are given the legitimacy by elected officials they metastasize through our society and amplify
            through the media.
          </p>

          <p>
            I believe in democracy. As individuals we are all imperfect, but democracy relies on the wisdom of the
            people at large. In the words of Lincoln: <i>You can fool all the people some of the time and some of the
            people all the time, but you cannot fool all the people all the time.</i> The election process needs to be
            where we find consensus, not only about which candidate to elect, but about which reality to reinforce
            through that election.
          </p>

        </div>
      </Article>
  )
  /*

   */
}