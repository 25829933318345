import React from 'react';
import Article from "../content/Article/Article";
import styles from "../content/Article/Article.module.scss"
import Candidate from "../../../core/Candidate";
import {Democrats, Republicans} from "../../../core/Party";
import {defaults} from "../../../core/Defaults";
import {HeadToHeadElection} from "../../../core/HeadToHeadElection";
import {Ballot} from "../../../core/Ballot";
import {DWNominateConfig} from "../../../core/ElectionConfig";
import {InstantRunoffElection} from "../../../core/InstantRunoffElection";
import BallotWidget from "../../elements/BallotWidget";


class ConsensusVoting extends React.Component<any, any> {
  candidates = [
    new Candidate("Joe", Democrats, -30, 0),
    new Candidate("Bob", Democrats, -12, 0),
    new Candidate("Maria", Republicans, 3, 0),
    new Candidate("Amy", Republicans, 16, 0),
    new Candidate("Zoe", Republicans, 42, 0),
  ]
  population = defaults.noLeanPopulation
  voters = this.population.samplePopulation(10000, 0)
  ballots = this.voters.map(v => new Ballot(v, this.candidates, DWNominateConfig))
  h2hElection = new HeadToHeadElection(this.ballots, new Set(this.candidates))
  result = this.h2hElection.result
  irvElection = new InstantRunoffElection(this.ballots, new Set(this.candidates))
  irvResult = this.irvElection.result
  mariaVJoe = this.h2hElection.pairResult(this.candidates[2], this.candidates[0])

  // mariaVotes = this.result.

  constructor(props: any) {
    super(props)
    console.log(`irvResult.winner ${this.irvResult.winner.name}`)
  }

  resultRow = (c1: Candidate, c2: Candidate): JSX.Element => {
    let [v1, v2] = this.h2hElection.pairResult(c1, c2)
    console.log(`resultRow for ${c1.name} ${c2.name}: ${v1} ${v2}`)
    let c1Style = (v1 > v2) ? styles.winner : styles.loser
    let c2Style = (v2 > v1) ? styles.winner : styles.loser
    return (
        <tr>
          <td className={c1Style}>{c1.name}</td>
          <td className={c1Style}>{v1}</td>
          <td className={c2Style}>{c2.name}</td>
          <td className={c2Style}>{v2}</td>
        </tr>

    )
  }

  resultTable = (c1: Candidate): JSX.Element => {
    console.log(`resultTable for ${c1.name}`)
    return (
        <div>
          <table>
            <tbody>
            <tr>
              <th colSpan={4}>Preference Results for {c1.name}</th>
            </tr>
            <tr>
              <th>Candidate</th>
              <th>Voters</th>
              <th>Candidate</th>
              <th>Voters</th>
            </tr>
            {this.candidates.filter(c => c !== c1).map(c2 => {
              return this.resultRow(c1, c2)
            })
            }
            </tbody>
          </table>
        </div>
    )
  }

// {this.candidates.map(c => this.resultTable(c))}
  render() {
    return (
        <Article title={"Consensus Voting"}
                 subtitle={"The candidate the voters prefer to every other candidate wins."}>
          <div className={styles.textContent}>
            <p>
              Consensus Voting is an election process based on one simple principle: The candidate the voters prefer
              to every other candidate is the "Consensus Candidate" and should win. Voters use the a standard Ranked-Choice
              ballot to rank all of the candidates in the order of the voter's preference.
            </p>
            <BallotWidget
                candidates={["Joe", "Bob", "Maria", "Amy", "Zoe"]}
                choiceOrder={["Maria", "Joe", "Bob", "Zoe", "Amy"]}
            />
            <p>
              Ranked-Choice ballots can then be used to determine which candidate the voters prefer given any two
              candidates. Let's consider Maria and Amy, if more voters have ranked Maria before Amy than Amy before
              Maria, then the voters as a whole prefer Maria to Amy.
            </p>
            <p>
              Below are the preference orders that have been inferred from three ballots like the one above. Because
              we're looking who the voters prefer between Maria and Amy, the other candidates have been faded out.
            </p>
            <div className={styles.rankingsDiv}>
              <div>
                <table>
                  <tbody>
                  <tr>
                    <th>1</th>
                    <td>Maria</td>
                  </tr>
                  <tr className={styles.faded}>
                    <th>2</th>
                    <td>Joe</td>
                  </tr>
                  <tr className={styles.faded}>
                    <th>3</th>
                    <td>Bob</td>
                  </tr>
                  <tr className={styles.faded}>
                    <th>4</th>
                    <td>Zoe</td>
                  </tr>
                  <tr>
                    <th>5</th>
                    <td>Amy</td>
                  </tr>
                  </tbody>
                  <caption className={styles.rankingsCaption}>
                    This is the preference order of the ballot above. The voter has listed Maria first so this voter
                    prefers Maria to Amy (and every other candidate).
                  </caption>
                </table>
              </div>
              <div>

                <table>
                  <tbody>
                  <tr className={styles.faded}>
                    <th>1</th>
                    <td>Joe</td>
                  </tr>
                  <tr className={styles.faded}>
                    <th>2</th>
                    <td>Bob</td>
                  </tr>
                  <tr>
                    <th>3</th>
                    <td>Maria</td>
                  </tr>
                  <tr>
                    <th>4</th>
                    <td>Amy</td>
                  </tr>
                  <tr className={styles.faded}>
                    <th>5</th>
                    <td>Zoe</td>
                  </tr>
                  </tbody>
                  <caption className={styles.rankingsCaption}>
                    Even though Maria is not listed first, she is listed before Amy so this voter prefers Maria to
                    Amy.
                  </caption>
                </table>

              </div>
              <div>

                <table>
                  <tbody>
                  <tr className={styles.faded}>
                    <th>1</th>
                    <td>Joe</td>
                  </tr>
                  <tr>
                    <th>2</th>
                    <td>Maria</td>
                  </tr>
                  <tr className={styles.faded}>
                    <th>3</th>
                    <td></td>
                  </tr>
                  <tr className={styles.faded}>
                    <th>4</th>
                    <td></td>
                  </tr>
                  <tr className={styles.faded}>
                    <th>5</th>
                    <td></td>
                  </tr>

                  </tbody>
                  <caption className={styles.rankingsCaption}>
                    This voter has listed only two candidates, Maria and Joe. Candidates ranked by the voter
                    are preferred to candidates that are not ranked, so the voter prefers Maria to Amy.
                  </caption>
                </table>
              </div>
            </div>
            <p>
              The first line in the first table below shows the voter preference between Maria and Joe. To create this
              line we went through the ballots and counted {this.mariaVJoe[0]} voters preferred Maria
              and {this.mariaVJoe[1]} preferred Joe. Since most voters prefer Maria, we can say that the voters prefer
              Maria to Joe. Maria is colored Green to highlight that she is the preferred candidate in that pairing.
            </p>

            <div className={styles.resultsDiv}>
              {this.h2hElection.result.orderedCandidates.map(c1 => {
                return this.resultTable(c1)
              })}
              <p style={{fontSize: '10pt', lineHeight: '1.2'}}><i>
                Preference tables: The number of voters that preferred each candidate for a given pairing, colored
                green/red to show which candidate a majority of the voters prefer. Each pairing is shown twice
                to make it easy to see the all of the results for a candidate.
              </i></p>
            </div>
            <p>
              In this case, Maria is preferred to each of the other candidates and is the Consensus Candidate and the
              winner. Choosing any other winner would override the preferences of the voters.
            </p>
            <p>
              It's hard to imagine how anyone could look at these ballots and think that any other candidate should
              be the winner. Could Amy be the winner? No, clearly the voters prefer both Maria and Bob to Amy.
            </p>
            <h3>A Top Five Primary</h3>
            <p>
              Overcrowded ballots are a problem for any election system. It is impossible for voters to know the
              candidates, to have a debate, or for the voters to rank all of the candidates. If there are too many
              candidates, many voters will submit an incomplete ballot and feel the entire system is too complicated.
            </p>
            <p>
              To qualify for the general election ballot, all candidates from all parties compete in an open primary.
              The top 5 candidates advance to the general election. This provides a reasonable number of candidates
              that balances the desire for inclusion with the constraints of having too large of a field.
            </p>
            <p>
              There is nothing magic about the number 5, it could be 6 or 7, but that must be balanced against the
              ability for the media and voters to vet and get to know these candidates. How do you run a debate?
              Most importantly, voters should rank deeply enough to express a preference between candidates in
              the other party.
            </p>

            <h3>The IRV Part</h3>
            <p>
              It's rare, but possible to have a tie, similar to rock/paper/scissors, where no candidate is preferred to
              every other candidate. If there is a tie, we simply use IRV with the same ballots to choose the winner.
            </p>

            <p>
              IRV, looks for a candidate with a majority of the first place votes. If no candidate has a majority of
              first place votes, IRV eliminates the candidate with the fewest first place votes and ballots which had
              counted for the eliminated candidate now count for the highest ranked active candidate on that ballot.
              This process repeats until some candidate has a majority.
            </p>
            <h3>Consensus is the solution to polarization</h3>
            <p>
              Consensus is the opposite of polarization. Consensus candidates are almost always eliminated by our
              current primary processes. With Consensus-IRV, a Top-5 primary allows good, representative candidates to
              advance to the general election. Consensus Voting then ensures that a candidate that a majority of voters
              prefer to every other candidate is the winner.
            </p>
          </div>
          <div style={{height: "20vh"}}/>
        </Article>
    )
  }
}

export default ConsensusVoting
