import React from 'react';
import {DSVRowArray} from "d3";
import styles from "./ConsensusVoting.module.scss"
import Step from "../ui/components/Step";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
import {ConsensusVotingApp} from "./ConsensusVotingApp";
// import HorizontalResultsBox from "../ElectionSimulation/HorizontalResultsBox";
import ResultsBox from "../ElectionSimulation/ResultsBox";
import ElectionSimStoryBase from "../ElectionSimStoryBase/ElectionSimStoryBase";
import BallotWidget from "../ui/elements/BallotWidget";

class ConsensusVotingStory extends ElectionSimStoryBase {

  mapAreas = [
    "drawingArea1", // 0
    "drawingArea1", // 1
    "drawingArea1", // 2
    "drawingArea1", // 3
    "drawingArea1", // 4
    "drawingArea1", // 5
    "drawingArea1", // 6
    "drawingArea3", // 7
    "drawingArea3", // 8
    "drawingArea3", // 9
    "drawingArea3", // 10
    "drawingArea3", // 11
    "drawingArea3", // 12
  ]
  visualizationAreas = [
    "drawingArea1", // 0
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",

    "drawingArea2", //5
    "drawingArea2",
    "drawingArea3",
    "drawingArea3",
    "drawingArea3",

    "drawingArea3", //10
    "drawingArea3",
    "drawingArea3",
    "drawingArea3",
    "drawingArea3",

  ]

  createApp = (
      visDivId: string,
      dvr: DSVRowArray,
      DWNominate: DSVRowArray,
      simulationResults: DSVRowArray,
      sampleCongress: DSVRowArray,
      usTopo: any): ElectionSimApp => {
    return new ConsensusVotingApp(this.visualizationAreas[0], dvr, DWNominate, simulationResults, sampleCongress, usTopo, 5001, 4)
  }


  render() {
    let mdash = "\u2014"
    return (
        <div className={styles.content}>
          <div className={styles.flyover} id={"detailsFlyover"} style={{
            display: "none",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 1,
          }}>
            <h6> Representative: <span id={"memberName"}>foo</span></h6>
            <h6> Party: <span id={"party"}>foo</span></h6>
            <h6> District: <span id={"districtName"}>foo</span></h6>
            <h6> Left/Right Ideology: <span id={"ideology"}>0</span></h6>
            <h6><span id={"representation"}> </span></h6>
          </div>
          <div>
            {this.renderVisArea(styles.drawing1, "drawingArea1")}
            <div style={{
              minHeight: "30vh"
            }}/>
            { /* 0 */}

            <Step className={styles.stepElement} first={true}>
              <h1>Consensus Voting</h1>
              <h3>Perfection Is Impossible</h3>

              <p>
                Kenneth Arrow won the Nobel Prize in Economics for proving there is no perfect way to decide an election
                with more than two candidates; they all have flaws. Two candidate races are immune from spoilers, vote
                splitting, and strategic voting problems, but who picks the candidates? You can elect anyone if you
                ensure their opponent is even worse.
              </p>
              <p>
                Though disappointing, it seems odd to expect that election systems differ from almost everything else.
                Cars, medicine, and people are all imperfect and will never be perfect. But that doesn’t stop us from
                trying to build better cars, cure diseases, or be better people. Nor should it prevent us from using a
                better election system.
              </p>
              <p>
                People often complain about the voting system because they don't like the outcome. But that's a
                subjective political judgment. There is a much more important reason to be unhappy with the voting
                system: the people we elect do not represent the values of their voters.
              </p>
              <p>
                The map on the right shows the representation score of the candidates we elect to Congress.
                Representation scores are explained in detail <a href={"/Representation"}>here</a>, but we would
                achieve more representative candidates by choosing leaders randomly instead of bothering with elections.
              </p>
              <p>
                Poor representation isn't caused by voter fraud, voter suppression, or the undue influence of outside
                groups. It is caused by an election system favors partisan candidates over candidates who represent
                the voters.
              </p>
              <p>
                Though no voting system is perfect, some are far better than others. Some are easily manipulated by
                spoiler candidates, while others invite strategic voting dilemmas for the voters. Does a voter vote
                honestly for their true preference or cynically for a candidate they despise slightly less than the
                alternative? Do they list their true second choice or try to bury a competitor?
              </p>
              <p>
                Fortunately, there is a voting system where those problems are exceedingly rare.
              </p>
            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing2, "drawingArea2")}
            { /* 1 */}
            <Step first={true} className={styles.stepElement}>
              <h3>
                Consensus Voting
              </h3>

              <p>
                This steps through a voter's experience in the same example election from the previous article, <a
                  href={"/LeviathanMath"}>The Math of the Leviathan</a>, using Consensus Voting instead of
                our traditional primary/plurality. Though this example only has three candidates, Consensus Voting can
                be applied to any number of candidates, but five or seven is probably a practical limit of the number of
                candidates a voter will familiarize themselves with.
              </p>
              <p>
                The first step in producing a more representative outcome is to get more information from the voter.
                Listing only a single candidate without any preference information limits what a voting system can
                accomplish and presents the voter with impossible strategic choices.
              </p>
              <div className={styles.centeredResultBox}>
                <BallotWidget
                    colors={["gold", "chartreuse", "violet"]}
                    choiceOrder={["Diane", "Ryan", "Rebecca"]}
                    candidates={["Diane", "Rebecca", "Ryan"]}
                    title={"Emily's Ballot"}
                />
              </div>
              <p>
                We will start with Emily, a Democrat who has felt frozen out of the political process in her
                Republican-dominated district. There are three candidates: Diane (D), Ryan (R), and Rebecca (R) shown
                as circles in the visualization.
              </p>
              <p>
                Emily lists Diane as her first choice. Crucially, the ranked choice ballot allows Emily to express a
                preference between the two Republican candidates. Emily rewards Ryan's less partisan approach
                by listing him second. Rebecca is listed last.
              </p>
            </Step>

            { /* 2 */}
            <Step className={styles.stepElement}>
              <h3>
                Counting the ballots
              </h3>
              <div className={styles.centeredResultBox}>
                <BallotWidget
                    colors={["gold", "chartreuse", "violet"]}
                    choiceOrder={["Diane", "Ryan", "Rebecca"]}
                    candidates={["Diane", "Rebecca", "Ryan"]}
                    title={"Emily's Ballot"}
                />
              </div>
              <p>
                Consensus voting compares each candidate with each of the other candidates one-on-one. The candidate who
                the voter prefer in each of their one-on-one comparisons is the winner. This sounds like it might be
                complex, but a single ranked-choice ballot from each voter tells us everything we need to know.
              </p>
              <h3>Diane vs. Rebecca</h3>
              {this.generalResultTable("DianeVRebecca", "Voter Preferences")}
              <p>
                To find the winner between these two candidates, we go through all of the ballots and count each one for
                the candidate listed higher on that ballot.
              </p>
              <p>
                We start with Emily's ballot. By listing Diane before Rebecca, Emily's ballot counts as a vote for Diane
                because the ordering shows that she would vote for Diane in a one-on-one contest with Rebecca.
                It doesn’t matter if Diane and Rebecca are first and second or second and third. They could even be 3rd
                and fifth in an election with more candidates. Emily could also have left Rebecca off of her ballot
                entirely because candidates a voter doesn't list are tied for last on that ballot.
              </p>
              <p>
                After counting all of the ballots to see which candidate each voter prefers, we see that Diane lost
                this contest in this Republican-dominated district.
              </p>
              { /* 3 */}
            </Step>
            <Step className={styles.stepElement}>
              <h3>
                Counting the ballots: Diane vs. Ryan
              </h3>

              {this.generalResultTable("DianeVRyan", "Voter Preferences")}
              <p>
                In the contest between Diane and Ryan, Emily's vote counts as a vote for Diane because Emily listed
                Diane before Ryan. Listing Ryan as her second choice does not harm Diane or help Ryan in this
                one-on-one contest. All that matters is that she listed Diane before Ryan.
              </p>
              <p>
                When all of the ballots are counted, we see that a majority of voters preferred Ryan to Diane and that
                Ryan won this one-on-one contest.
              </p>

              <p>
                Diane would have had the most votes and won a three-way plurality election, but this process illustrates
                why a plurality-based election with three candidates would have overridden majority rule and the will
                of the voters {mdash} twice!
              </p>
            </Step>

            { /* 4 */}
            <Step className={styles.stepElement}>
              <h3>
                Counting the ballots: Ryan vs. Rebecca
              </h3>
              {this.generalResultTable("RebeccaVRyan", "Voter Preferences")}
              <p>
                In the contest between Ryan and Rebecca, Emily's vote counts as a vote for Ryan. Listing Diane first
                on her ballot has no impact in this one-on-one contest. All that matters is that Emily listed Ryan
                before Rebecca.
              </p>
              <p>
                When all of the votes are counted, Ryan defeats Rebecca by almost two to one.
              </p>
            </Step>

            { /* 5 */}
            <Step className={styles.stepElement}>
              <h3>
                Full Consensus Results
              </h3>
              <div style={{minWidth: 600}} className={styles.centeredResultBox}>
                <ResultsBox
                    className={styles.sideBySideBox}
                    results={this.sectionResults("Consensus")?.h2hResults}
                    title={"Consensus Voting"}
                />
              </div>
              { /* the logical case for naming the winner */}
              <p>
                The voters prefer Ryan to each of the other candidates in this election; therefore, Ryan is the
                winner. Because Ryan beat both Rebecca and Diana, naming either of them the winner would override
                the will of the voters and violate majority rule.
              </p>
              <p>
                Rebecca would have eliminated Ryan in a Republican primary and likely won the general election in this
                Republican-leaning election. Rebecca would have claimed a mandate to govern in the name of the voters,
                but Rebecca can only win with a system that eliminates choices the voters would prefer {mdash} better
                choices {mdash} before all of the voters get a say.
              </p>
              <p>
                Primary-based elections and <a href={"/InstantRunoff"}>Instant Runoff</a> disguise their violation
                of majority rule, but that doesn’t make overriding the wishes of the voters legitimate. No matter how
                you try to justify it, naming any other candidate the winner here overrides the wishes of the voters.
              </p>
              <p>
                We don't often see candidates like Ryan in elections today because they know they have very little
                chance of winning. For the most part, they either adopt a purely factional stance or decide not to
                run. The biggest danger of systems that favor factional candidates is that non-factional candidates will
                not run.
              </p>
              <p>
                There are a few exceptions, where a candidate is able to persuade their primary voters that the only
                chance of winning is to move to the center, but even those few moderates have to navigate political
                institutions and a political ecosystem dominated by factional candidates.
              </p>
              <p>
                Consensus doesn't mean that everybody gets exactly what they want. That's impossible. Instead, consensus
                means that the voters collectively prefer one choice to each of the other possible choices.
              </p>

            </Step>
            { /* 6 */}
            <Step className={styles.stepElement}>
              { /* How it creates a Representative Democracy*/}
              <h3>
                Consensus Voting Creates a Representative Democracy
              </h3>
              <p>
                Purely factional candidates cannot win against a quality opponent in an election decided by Consensus
                Voting. To defeat Rebecca, Ryan needed support from voters on his left. To defeat Diane, Ryan needed
                support from voters on his right. To win under Consensus Voting, Ryan had to balance the needs of the
                entire electorate.
              </p>

              <p>
                Any candidate who serves only one faction and thereby alienates the opposition will lose to a candidate
                who balances the needs of the voters. Otherizing the opposition may play well with the fringe of your
                own party, but moderates in both parties reject that behavior.
              </p>

            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing3, "drawingArea3")}
            {/* 7 */}
            <Step first={true} className={styles.stepElement}>
              { /* The difference a Representative democracy makes*/}
              <h3>
                A Congress Built by Consensus
              </h3>
              <p>
                This is voter representation and Congressional ideology when Congress is elected using Consensus Voting.
              </p>
              <p>
                Voter representation is explained <a href={"/Representation"}>here</a>, but the green
                in this map of voter representation is a stark contrast with the brown map plurality-based elections
                create today. These are the leaders the voters <i>want</i> to elect.
              </p>
              <p>
                <i>{mdash} Use the blue and gray buttons under the map to display outcomes under different voting
                  rules.</i>
              </p>
              <p>
                The stark difference between the two Congresses and maps dispels any illusion that the leaders we elect
                represent our values. When given the chance, the voters chose a less partisan candidate in every single
                election under Consensus Voting. That same candidate lost every single time under primary/plurality.
              </p>
              <p>
                Primary/plurality elections often give the appearance of legitimacy by electing the best of bad
                alternatives. But that is an illusion created by a voting rule that eliminates better candidates long
                before election day.
              </p>

              <p>
                Consensus Voting allows the voters to elect the candidates they collectively prefer over any other
                possible option. Candidates know that the path to victory lies in representing the common good
                for all voters. Instead of discouraging representative candidates, Consensus Voting discourages
                division, toxicity, and factionalism.
              </p>
              <p>
                The Leviathan is the intersection of election rules, mathematics, and human ambition. It
                cannot be slain, but it can be tamed. We cannot change math or human nature, but Consensus Voting
                changes the election rules so that when candidates act in their self-interest, they act in the public
                interest.
              </p>
              <p>
                I'll close with Madison's words from Federalist 10.
              </p>
              <p className={styles.pullQuoteStory}>
                The effect of the first difference is, on the one hand, to refine and enlarge the public views, by
                passing them through the medium of a chosen body of citizens, whose wisdom may best discern the true
                interest of their country, and whose patriotism and love of justice will be least likely to sacrifice it
                to temporary or partial considerations. {mdash} James Madison, Federalist 10
              </p>
            </Step>
            {/* 8 */}
            <Step className={styles.stepElement}>
              <h3>
                Explore More
              </h3>
              <p>
                This is a deep conversation with profound implications, and this barely scratches the surface.
              </p>

              <ul style={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}>
                <li>
                  <a href={"/ElectionSim"}> The Election Simulator </a> This is where you can explore outcomes under
                  different election systems. How often does Instant Runoff-RCV produce bad results?
                  How often does Consensus Voting produce bad results?
                </li>
                <li>
                  <a href={"/IRV"}>Instant Runoff Voting</a> describes how Instant Runoff Voting (often called
                  Ranked Choice Voting) works and why it is not a solution for factional democracy or political
                  polarization.
                </li>
                <li>
                  <a href={"/Prefect"}>No Election System is Perfect</a> discusses the possibility of a three-way
                  tie where no candidate is undefeated and why it's not a problem.
                </li>
                <li>
                  <a href={"/SimulationTutorial"}>A Tutorial on Simulation</a> The science behind election simulation
                  both for the election visualization and for the district-by-district simulations of the U.S. House
                  of Representatives.
                </li>
              </ul>
            </Step>
          </div>
          <div style={{
            minHeight: "30vh"
          }}/>
        </div>
    )
  }
}

export default ConsensusVotingStory
