import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class ASimpleArrow extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }


  render() {
    // let mdash = "\u2014"
    // let gt = ">"

    return (
        <Article title={"A Simpler Version of Arrow's Impossibility"}
                 subtitle={"Arrow's impossibility"}>
          <div className={styles.textContent}>
            <h3>Under Construction!!!</h3>

            <p>
              When Kenneth Arrow published his Impossibility Theorem in 1951, it created an academic firestorm. Few
              people really understood the math, but everybody understood the implications. The overwhelming
              response in academia to Arrow's theorem was that democracy was impossible.
            </p>
            <p>
              A spoiler candidate is a candidate who changes the outcome of an election without winning it. Most people
              think of Ralph Nader in Florida-2000 as a spoiler candidate, where an uncompetitive candidate siphons
              enough vote from a candidate to change the outcome. But spoiler candidates can also be serious candidates.
              For instance, Democrats spent over $19 million supporting MAGA Republican candidates in Republican
              primaries during the 2022 midterm elections. The goal of the democrats was to engineer a general election
              matchup they could win easily. That's exactly what happened. Every MAGA candidate the Democrats helped
              win their primar went on to lose the general election to a Democrat.
            </p>
            <p>
              We will never know if the moderate Republicans the Democrats helped defeat in their primaries
              would have gone on to win the general election without Democratic help. But the Democrats certainly
              thought they might because they spent $19 million trying to engineer their general election opponent.
              And they weren't shy about it. As Abby Curran Horrell, executive director of the Democratic House Majority
              PAC, said: “House Majority PAC was founded on the mission of doing whatever it takes to secure a
              Democratic House Majority, and in 2022, that’s what we will continue to do.”
            </p>

            <p>
              Spoilers are known as <i>Irrelevant Alternatives</i> in election science and they are a serious problem.
              It would be ideal for an election system to not be vulnerable to spoilers. Unfortunately, that's
              impossible for any ordinal voting rule when there are three or more candidates. An ordinal voting rule
              is one where the voters rank the candidates in some order. The other type of election system is
              a cardinal system where the voters score candidates and those have even worse problems.
            </p>

            <h3>A Simpler Arrow</h3>
            <p>
              Assume there is an election with three candidates, A, B, and C. Further, assume that the ballots the
              voters return create a rock-paper-scissors style cycle where A defeats B who defeats C who defeats A.
              This may sound impossible but though it is highly unlikely, it is possible.
            </p>

            <ul>
              <li>Money</li>
              <li>Media</li>
              <li>Voter suppression or fraud</li>
              <li></li>
              <li></li>

            </ul>
            <p>
              In <i>cardinal voting systems</i> voters score candidates and the candidate with the highest score is
              declared the winner. Range Voting, Approval Voting, and Borda Counts are all examples of cardinal systems.
              These systems are discussed <a href={"/CardinalSystems"}>here</a>, they all suffer from strategic
              voting issues that can distort the outcome.
            </p>            <p>

          </p>
            <p>
              Arrow's theorem has been cited over 25,000 times and has been a
            </p>

            <ul>
              <li>Democracy is impossible.</li>
              <li>The theory is wrong.</li>
              <li>The theory doesn't matter.</li>
              <li>The theory demands too much of a voting system.</li>
              <li></li>

            </ul>
            <p>
              All voting systems are flawed.
              But, they are not equally flawed and the differences matter.
            </p>
            <p>Arrow was needlessly confusing</p>
            <p>Robbie's Simplified Impossibility</p>
            <p>
              This will described Tideman's Ranked Pairs as the best possible method for resolving a cycle.
            </p>
            <p>
              Kenneth Arrow didn't prove that this or that voting system was flawed, he proved that it was impossible to
              design any voting system that wasn't flawed. How could he possibly prove that a voting system he'd never
              seen was flawed? At first glance, it seems that it would be impossible to prove something he never saw
              was broken. It's not. The overwhelming response to Arrow's theorem when it was published was that
              democracy was impossible. That's an overreaction. It's more accurate to say that perfect democracy is
              impossible, and that shouldn't be a surprise to anyone.
            </p>

            <p>
              Arrow's theorem itself is extremely complex, his academic advisors in economics had to refer it to the
              math department to determine if it was correct. You can relax, I'm not going to attempt to explain Arrow's
              theorem. Instead, I'm going to present a much simpler problem that is impossible to solve.
            </p>

            <p>
              Arrow's theorem is
            </p>
          </div>
        </Article>
    )
  }
}




















