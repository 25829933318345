import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class Compromise extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }

  render() {
    // let mdash = "\u2014"

    return (
        <Article title={"Without Compromise, there is only violence."}
                 subtitle={"Compromise does not mean surrender."}>
          <div className={styles.textContent}>
            <p>
              “By a faction, I understand a number of citizens, whether amounting to a majority or a minority of the
              whole, who are united and actuated by some common impulse of passion, or of interest, adversed to the
              rights of other citizens, or to the permanent and aggregate interests of the community.” James Madison –
              Federalist 10.
            </p>
            <p>
              The missing ingredient in a functional democracy comes from the wisdom of the United States founding
              fathers, as expressed by James Madison. In Federalist 10, Madison describes early democracies as being “as
              short in their lives as they were violent in their deaths.” Madison diagnoses the problem as being caused
              by factions. Once a faction had a majority, they would inevitably impose unacceptable policies on the
              minority, and the result was a violent death for democracy.
            </p>
            <p>

              If violence is inevitable when the majority imposes its will on the minority, it would be even worse
              should the minority impose its will. How, then, is a democracy, or any large society of humans, possible
              without violence? This is the challenge that Madison and the framers of the Constitution had to solve. To
              make it even more challenging, they had to solve it without telling anyone the solution.

            </p>
            <p>
              Madison uses the phrase “public good” six times in Federalist 10, but he never defines it. It’s not an
              accident that Madison didn’t define it; Madison didn’t define public good because its real meaning is
              something he would rather not advertise. The first place he uses it is when he says, “the public good is
              disregarded in the conflicts of rival parties, and that measures are too often decided, not according to
              the rules of justice and the rights of the minor party, but by the superior force of an interested and
              overbearing majority.” Here, Madison is saying that even though a majority wants one thing, the public
              good is something else. In other words, the public good requires that the “overbearing majority”
              compromise.

            </p>
            <p>
              Madison didn’t say compromise because nobody likes compromising. By using the phrase public good Madison
              cleverly allows the reader to delude themselves into believing that the values they hold dear to their
              hearts represent the public good and they will be able to impose those ideas on society. This is, of
              course, nonsense. Madison knows that allowing any faction to impose their views on society without
              compromise will destroy the nascent democracy. But he also knows that if he admitted that every aspect of
              our government is designed to force compromise, very few people – if any – would have supported it.
              Nonetheless, our government was designed to foster the compromises necessary for any society to function
              without violence.

            </p>
            <p>
              Compromise is often used as a pejorative term, but no group of more than a single person agrees about
              everything. How is a society of millions of individuals supposed to make any decision that everyone can
              tolerate? The answer is through compromise.

            </p>
            <p>
              Even mundane decisions by small groups of friends involve compromise. But what happens when a society has
              to make a decision? What happens when the differences are profound? It may not always be possible to
              resolve such differences, but if there is a way, it is through compromise. Compromise is essential, and
              it’s time to make a bold statement about it.

            </p>
            <p>
              Without compromise, there is only violence.

            </p>
            <p>
              Critically, compromise does not mean selling out or giving up. In many cases, compromise means abiding by
              a decision but continuing to advocate for your beliefs within the democratic process. In this form,
              compromise means valuing other benefits of living in a democratic society and the chance to continue to
              advocate for your beliefs over the alternative because the alternative is violence.

            </p>
            <p>
              Madison realized this, but he also realized that he couldn’t use the term compromise. Instead, he couched
              it in elegant-sounding language: “to refine and enlarge the public views, by passing them through the
              medium of a chosen body of citizens, whose wisdom may best discern the true interest of their country, and
              whose patriotism and love of justice will be least likely to sacrifice it to temporary or partial
              considerations.” Here, the “true interest of their country” means something other than the unaltered
              wishes of the majority, those wishes have been “refined and enlarged” to balance the views of the
              minority.

            </p>
            <p>
              You have to hand it to Madison, that’s quite the sales job. Instead of telling people that they will be
              forced to compromise, even when they hold a majority, he tells them their views will be “refined and
              enlarged” to reflect “the true interest of their country.” You’d almost never know that he was actually
              saying that you’ll never get exactly what you want. In fact, I’m not sure too many people have figured
              that out today. Clever guy.

            </p>
            <p>
              In the direct democracies of the past, a faction that held a majority would see no need to compromise with
              the minority, and thus the whole “short life and violent death,” to paraphrase Madison. In Federalist 10,

            </p>
            <p>
              On its surface, Federalist 10 promises a cure to the mischief of factions which were the demise of early
              democracies, but underneath that surface, it admits that it will do so by inserting a layer between the
              factions and governance whose job is to “discern the true interest of their country.” i.e. the job of the
              legislators is to forge compromises that a faction holding a majority would never see the need for.

            </p>
            <p>
              The founders didn’t stop with their “chosen body of citizens” as a mechanism for forcing compromise, it
              permeates every aspect of our government. By dividing power and using different mechanisms to elect or
              appoint the leaders of each branch, the founders hoped to prevent a single faction from dominating the
              government, and thereby force compromise between the factions. Why are there three branches of government?
              Why is the legislative branch split between House and Senate? Why is the House on a two-year term while
              the Senate was originally appointed for six years by the states? Why does the Senate advise and consent on
              judicial nominees? The answer to all of those questions is to force compromise between the factions. Or,
              as Madison would describe it: “to refine and enlarge the public views to reflect the true interest of the
              country.”

            </p>
            <i>

              <p>


                OK, now we can combine all of this into a single statement about the purpose of democracy:

              </p>
              <p>
                The purpose of democracy is to foster compromise because, without compromise, there is only violence.

              </p>
              <p>
                The purpose of democracy is to foster compromise because, without compromise, violence is inevitable.


              </p>
              <p>
                The failure of early democracies was because they did not promote compromise

              </p>
              <p>
                The critical contribution of the American founding fathers was a realization that successful democracy
                depends on compromise.

              </p>
              <p>
                It is easier to accept a setback when you believe the system is fair because you value democracy more
                and
                believe you will ultimately prevail in a fair system.

              </p>
              <p>
                The path to justice may not always be direct. There may be times when a society lacks the collective
                wisdom to do the right thing and must learn lessons the hard way. But if you believe in the long-term
                wisdom of the electorate, if you believe in democracy, then justice will ultimately prevail if the
                system
                is fair.

              </p>
              <p>
                imposing the correct solution on an unwilling society is fraught with danger. Is it worth risking all of
                the democratic progress and undermining democracy to win some issue? Or is it better to use the
                democratic
                process to continue to advocate for your beliefs? Would the attempt to impose your values even succeed?
                Assuming you are “right,” might you actually create a backlash that delays the process of education and
                enlightenment by attempting to impose your views on an unwilling process

              </p>
              <p>
                These two principles are the underlying principle guiding this site

              </p>
            </i>
            <p>
              If you asked Americans what quintessential quality defines America, most people would respond, “Freedom.”
              I think that’s wrong; it should be “compromise.” Absolute freedom is an illusion for any member of a
              society with more than a single person. Peaceful coexistence means compromise with your fellow countrymen,
              and the art of finding the compromises that help most people be as happy – and as free – as possible is
              essential to a peaceful society. When American democracy performs well, it is because it does exactly
              that. When we stop finding compromises, we teeter on the brink of the disaster that has claimed so many
              democracies.
            </p>


          </div>
        </Article>
    )
  }
}




















