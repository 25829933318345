import {ConsensusVotingApp} from "../Introduction/ConsensusVotingApp";
import {Candidate2D} from "../ui/elements/Candidates2D";
import Candidate from "../core/Candidate";
import {Democrats, Republicans} from "../core/Party";
import * as d3 from 'd3';

export class FirstPlaceFallacyApp extends ConsensusVotingApp {

  createJuan = () => new Candidate2D(new Candidate("Juan", Republicans, 0, 0, [.75, .75]), "cyan", 10)
  createBob = () => new Candidate2D(new Candidate("Bob", Democrats, 0, 0, [-.70, -.55]), "lightseagreen", 10)
  createAnne = () => new Candidate2D(new Candidate("Anne", Republicans, 0, .01, [.07, -.10]), "gold", 10)

  showGeneral2 = (_progress: number) => {


    let candidates = [
      this.createAnne(),
      this.createBob(),
    ]
    this.displayOutcome(candidates)
    let gcSet = new Set(candidates.map(c => c.candidate))
    this.colorVotersByPreference(gcSet, `Voter Preference between ${candidates[0].name} and ${candidates[1].name}`)
    this.applyVoterColors = () => {
      this.colorVotersByPreference(gcSet, `Voter Preference between ${candidates[0].name} and ${candidates[1].name}`)
    }
    this.highlightResult("generalResult")
    this.clearInstructions()
    this.addTextInstruction(990, 900, "Drag the candidate circles to try different scenarios.")
  }

  showGeneral3 = (_progress: number) => {
    let candidates = [
      this.createAnne(),
      this.createBob(),
      this.createJuan(),
    ]
    this.displayOutcome(candidates)
    this.colorVotersByIRVRound(0)
    this.highlightIRVRound(0)
    d3.timeout(() => {this.highlightIRVRound(0)}, 100)
    this.clearInstructions()
    this.addTextInstruction(990, 900, "Drag the candidate circles to try different scenarios.")
  }

  displayOutcome = (candidates: Candidate2D[]) => {
    this.setLoyalty(0)
    this.resetVoterOpacity(.9)
    this.setNewCandidates(candidates)
    this.layoutCandidatesAndVoters()
    this.clearInstructions()
  }

  newSectionMethods = [
    this.showVoters2D,
    this.showGeneral2,
    this.showGeneral3,
    this.showGeneral3,
  ]
  progressMethods = []
}