class Party {
  name: string
  shortName: string
  // the adjustment for a voter of this party for a candidate from that party
  adjustments: Map<string, number>
  color: string

  constructor(name: string, shortName: string, adjustments: Map<string, number>, code: number) {
    this.name = name
    this.shortName = shortName
    this.adjustments = adjustments

    if (name === "Republican")
      this.color = 'red'
    else if (name === 'Democratic')
      this.color = 'blue'
    else if (name === 'Independent')
      this.color = 'purple'
    else
      this.color = partyColors[code % partyColors.length]
  }

  adjustmentFor = (otherParty: string): number => {
    if (this.adjustments.has(otherParty))
      return this.adjustments.get(otherParty)!
    else
      return 0
  }
}

let partyColors = [
    "orange",
  "#e6194b"
  , "#3cb44b"
  , "#ffe119"
  , "#4363d8"
  , "#f58231"
  , "#911eb4"
  , "#46f0f0"
  , "#f032e6"
  , "#bcf60c"
  , "#fabebe"
  , "#008080"
  , "#e6beff"
  , "#9a6324"
  , "#800000"
  //, "#aaffc3"
  , "#808000"
  //, "#ffd8b1"
  , "#000075"
  , "#808080"
  , "#000000"]


let republicanAdjustments = new Map(
    [
      ['QAnon', 1.0],
      ['Republican', 1.0],
      ['Independent', .5],
      ['Democratic', 0],
    ]
)
let democraticAdjustments = new Map(
    [
      ['QAnon', -.5],
      ['Republican', 0],
      ['Independent', .5],
      ['Democratic', 1.0],
    ]
)
let independentAdjustments = new Map(
    [
      ['QAnon', 0],
      ['Republican', 0],
      ['Independent', .5],
      ['Democratic', 0],
    ]
)
let qAnonAdjustments = new Map(
    [
      ['QAnon', 1.2],
      ['Republican', 1.2],
      ['Independent', .5],
      ['Democratic', 0],
    ]
)

let Republicans = new Party("Republican", "rep", republicanAdjustments, 200)
let Democrats = new Party("Democratic", "dem", democraticAdjustments, 100)
let Independents = new Party("Independent", "ind", independentAdjustments, 328)
let QAnon = new Party("QAnon", "qAnon", qAnonAdjustments, 1001)
let NoParty = new Party("NoParty", "none", independentAdjustments, 0)

let partyForShortName: { [shortName: string]: Party} = {
  "rep": Republicans,
  "ind": Independents,
  "dem": Democrats,
  "qAnon": QAnon,
}

let partyMap: { [id: number]: string } = {
  0: "NoParty",
  1: "Federalist",
  13: "Democrat-Republican",
  22: "Adams",
  26: "Anti Masonic",
  29: "Whig",
  37: "Constitutional Unionist",
  44: "Nullifier",
  46: "States Rights",
  100: "Democrat",
  108: "Anti-Lecompton Democrat",
  112: "Conservative",
  114: "Readjuster",
  117: "Readjuster Democrat",
  200: "Republican",
  203: "Unconditional Unionist",
  206: "Unionist",
  208: "Liberal Republican",
  213: "Progressive Republican",
  300: "Free Soil",
  310: "American",
  326: "National Greenbacker",
  328: "Independent",
  329: "Ind. Democrat",
  331: "Ind. Republican",
  340: "Populist",
  347: "Prohibitionist",
  354: "Silver Republican",
  355: "Union",
  356: "Union Labor",
  370: "Progressive",
  380: "Socialist",
  402: "Liberal",
  403: "Law and Order",
  522: "American Labor",
  523: "American Labor",
  537: "Farmer-Labor",
  555: "Jackson",
  603: "Ind. Whig",
  1060: "Silver",
  1111: "Liberty",
  1116: "Conservative Republican",
  1275: "Anti-Jackson",
  1346: "Jackson Republican",
  3333: "Opposition",
  3334: "Opposition",
  4000: "Anti-Administration",
  4444: "Union",
  5000: "Pro-Administration",
  6000: "Crawford Federalist",
  7000: "Jackson Federalist",
  7777: "Crawford Republican",
  8000: "Adams-Clay Federalist",
  8888: "Adams-Clay Republican",
  1001: "QAnon"
}


let partyForCode = (code: number): Party => {
  if (code === 100) return Democrats
  else if (code === 200) return Republicans
  else {
    return new Party(partyMap[code], partyMap[code], independentAdjustments, code)
  }
}

export {Party}
export {Republicans}
export {Democrats}
export {Independents}
export {NoParty}
export {QAnon}
export {partyForCode}
export {partyForShortName}
export {partyColors}
