import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class Cardinal extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }


  render() {
    let mdash = "\u2014"

    return (
        <Article title={"Range Voting, Approval Voting, and Borda Counts"}
                 subtitle={"\"My System Is Only Intended for Honest Men.\""}>
          <div className={styles.textContent}>
            <h3>Cardinal Voting Systems</h3>
            <p>
              There are two families of voting systems: ordinal and cardinal. Ordinal means ordering, and voters rank
              the candidates in their preference order in these systems. Ordinal methods are the most commonly used;
              both Consensus Voting and Instant Runoff are ordinal voting systems.
            </p>
            <p>
              Cardinal refers to the mathematical term cardinal and means counting numbers. In cardinal systems, the
              voter assigns scores to candidates, and the candidate with the highest total score wins. These seem like a
              great way to do things, but there are problems.
            </p>
            <p>
              Cardinal voting rules have a long history, and starting at the beginning will help to explain both
              the appeal of cardinal voting systems and their problems.
            </p>
            <p>
              In 1770, Jean-Charles de Borda delivered a paper to the French Academy of Sciences describing a problem
              with voting and his proposed solution. His problem was with spoiler candidates, and his solution was the
              birth of the science of elections.
            </p>
            <p>
              A / B / C: 1 (1 voter prefers A, then B, then C)
            </p>
            <p>
              A / C / B: 7 (7 voters prefer A, then C, then B)
            </p>
            <p>
              B / C / A: 7
            </p>
            <p>
              C / B / A: 6
            </p>
            <p>
              In Borda’s formulation, four different orderings of the candidates have been cast by 21 voters. Line 1
              refers to a ballot where a single voter has listed A, then B, then C. Line 2 describes seven voters who
              have
              listed A, then C, then B, and so on.
            </p>
            <p>
              Eight voters (combining lines 1 and 2) have listed A as their first choice, and A wins a plurality
              election by having the most votes. That may seem fair, but there is a problem. There are eight ballots on
              which A is listed before C, but 13 on which C is listed before A. That means C would easily win a
              one-on-one contest with A by 13 to 8! Not only that, applying the same logic to A and B, B would also
              defeat A by 13 to 8. Yet A won.
            </p>
            <p>
              Borda’s logic demonstrated that the commonly used practice of declaring the candidate who gets the most
              votes to be the winner can’t be right. But what alternative is there?
            </p>
            <p>
              In Borda’s example, C would win if the race were between C and A or C and B. But because both B and C are
              in the race, A wins.
            </p>
            <p>
              C beat both of the other candidates in a head-to-head comparison and by substantial margins. Borda saw
              that if C defeats A, it’s wrong for A to win merely because B – a spoiler candidate – enters the race.
            </p>
            <p>
              To resolve the problem, Borda proposed a point system to determine a winner that represents the will of
              the voters. In a three-candidate race, a voter's first choice gets two points, their second choice one
              point, and their last choice zero points. The winner is the candidate with the most points.
            </p>
            <p>
              Applying Borda’s method:
            </p>
            <p>
              A: 8 * 2 + 0 * 1 = 16 points.
            </p>
            <p>
              B: 7 * 2 + 7 * 1 = 21 points
            </p>
            <p>
              C: 6 * 2 + 14 * 1= 26 points
            </p>
            <p>
              Borda’s method became known as a Borda Count, and by this method, C wins. A, the plurality winner, is
              last. Borda’s method appeared to solve the problem by producing a result that better represents the
              voters’ wishes.
            </p>
            <p>
              It seems like a great idea, and the NBA uses it for All-Star voting, but the method is deeply flawed
              because voters, like candidates, can behave strategically. In Borda’s example, C wins the race, but part
              of the reason C wins is that voters who prefer B list C as their second choice. If B’s voters thought
              strategically, they might employ a strategy called “bullet voting” and would list B as their first choice
              and no second or third choice. Then the result would be:
            </p>
            <p>
              A: 8 * 2 + 0 * 1 = 16 points.
            </p>
            <p>
              B: 7 * 2 + 7 * 1 = 21 points
            </p>
            <p>
              C: 6 * 2 + 7 * 1 = 19 points
            </p>
            <p>
              Now, B wins because B’s voters voted strategically. Of course, C’s voters and A’s voters can also choose
              to
              vote strategically. When everybody votes strategically, the result is:
            </p>
            <p>
              A: 8 * 2 + 0 * 1 = 16 points
            </p>
            <p>
              B: 7 * 2 + 0 * 1 = 14 points
            </p>
            <p>
              C: 6 * 2 + 0 * 1 = 12 points
            </p>
            <p>
              Once you consider that many people will likely vote strategically, you get the same broken plurality
              results we started with.
            </p>
            <p>
              The NBA system works, incidentally, because the ballots are public and by invitation only. Any participant
              using strategic voting to help their favorite would be “uninvited” from future participation.
            </p>
            <p>
              In our partisan elections, I cannot imagine that many voters would list a candidate from the other party
              on their ballot in a Borda Count because that would help them defeat a candidate from their own party.
              When bullet voting is widespread, it is impossible to have confidence that an election outcome reflects
              the will of the voters. Even a few bullet voters can skew the outcome of an election.
            </p>
            <p>
              Borda knew that his system was vulnerable to strategic voting, and when the problem was pointed out to
              him, he had a response loaded and ready to fire: “My system is only intended for honest men.” It’s an
              almost comical response from an Enlightenment-era scientist but also a reflection of his times: experience
              with democratic voting systems was limited, and voting in one’s self-interest instead of the public
              interest was considered dishonest.
            </p>
            <p>
              Yet, as James Madison observed in Federalist 51: “If men were angels, no government would be necessary.”
              To take it a step further: With no need for government, what need would there be for voting?
            </p>
            <p>
              Angels or not, it’s not dishonest to use your vote to influence the outcome of an election or to think
              strategically about how to vote, and election scientists describe voting strategically as being insincere,
              not dishonest. But no voting system should ever force the voter to play some sort of strategy game based
              on imperfect polling information to make their vote matter. It should always be in a voter’s self-interest
              to cast a sincere ballot reflecting their true preferences.
            </p>

            <h3>Range Voting</h3>

            <p>
              In a Borda count with five candidates, voters are limited to giving one candidate four points, then
              another three, and so on. That's arbitrary and may not reflect the voters' actual opinion of the
              candidates.
              What if a voter really likes two of the candidates and hates all of the rest? Range voting solves this
              problem by allowing the voter to give any amount of points to any candidate. On a zero to ten scale, a
              voter can give any candidate any number of points between zero and ten. For instance, a voter could give
              two candidates 10 points and the rest all zero. Range Voting allows the voter to be more expressive on the
              ballot, which is generally a good thing.
            </p>

            <p>
              The problem is that Range Voting {mdash} like all cardinal systems {mdash} suffers from the same strategic
              voting problem as a Borda count.
            </p>

            <p>
              Consider an election in a district that leans 55/45 Democratic with two Democratic candidates (Diane and
              Debbie) and one Republican candidate (Robin). The two Democratic candidates are competitive and believe
              that the strongest Democrat will win this Democratic-leaning district.
            </p>

            <p>
              The Democratic-leaning voters are evenly split between Diane and Debbie. And though Diane's supporters
              prefer Debbie to Robin, they want to show that they think Diane is the better candidate. So, they give
              Diane 10 points, Debbie 5, and Robin 0.
            </p>
            <p>
              Similarly, Debbie's supporters want to express that they prefer Debbie to Diane, so they give Debbie 10,
              Diane 5, and Robin 0. Meanwhile, Robin's Republican supporters all award Robin 10 and zero to both
              Democrats. Let's see how this works out with 1001 voters {mdash} I'm avoiding a tie.
            </p>
            <table>
              <tbody>
              <tr>
                <th colSpan={5}>Range Voting Points</th>
              </tr>
              <tr>
                <th>Preferred Candidate</th>
                <th>Number of voters</th>
                <th>Debbie</th>
                <th>Diane</th>
                <th>Robin</th>
              </tr>
              <tr>
                <th>Debbie</th>
                <td>276</td>
                <td>10</td>
                <td>5</td>
                <td>0</td>
              </tr>
              <tr>
                <th>Diane</th>
                <td>275</td>
                <td>5</td>
                <td>10</td>
                <td>0</td>
              </tr>
              <tr>
                <th>Robin</th>
                <td>450</td>
                <td>0</td>
                <td>0</td>
                <td>10</td>
              </tr>
              <tr>
                <th>Totals</th>
                <td>1000</td>
                <td>4135</td>
                <td>4130</td>
                <td>4500</td>
              </tr>
              </tbody>
            </table>
            <p>
              The result is that Robin wins the elections even though Democratic-leaning voters outnumbered Republicans
              by 55% to 45%. The point is that vote splitting still exists, though it might be more accurate to call it
              vote dilution. Any time there are two similar candidates {mdash}from the same party or faction {mdash}
              their support will be diluted as their supporters try to distinguish between the two candidates. They
              can give 10 and 5 points, as shown here, or 10 and 9 points. It can {mdash} and does {mdash} make
              the difference in a close race.
            </p>
            <p>
              This creates an incentive for parties or factions to put forward only a single candidate. This is the same
              problem that led to partisan primaries. Even if official primaries are prohibited, there can be significant factional
              pressure for candidates to drop out of the race or face backlash. This quickly deteriorates into a system
              where the voters' choices are limited to ones approved by a faction, and the outcome doesn't reflect the
              voter's wishes.
            </p>
            <p>
              Also, you may notice that 551 voters gave Debbie more points than Robin, while only 450 gave Robin more
              points. So, Range voting overrode majority rule. This is bad, but it gets worse. What happens when voters
              start thinking strategically?
            </p>
            <h3>Strategic Thinking and Range Voting</h3>
            <p>
              Let's add a little more color to the example. Presume that Debbie is a far-left Democrat, and Republican
              voters (e.g., Robin's supporters) find her offensive. Diane, on the other hand, has taken some flak from
              the
              left of the Democratic party for taking more moderate positions, disowning the most extreme positions in
              her own party, and reaching out to Republican voters. This has cost Diane support with Democratic voters,
              but she is hoping that Republican voters will reward her reasonableness and outreach.
            </p>
            <p>
              Let's presume Robin's supporters respond by awarding Diane an average of 3 points each. This is the new
              result.
            </p>
            <table>
              <tbody>
              <tr>
                <th colSpan={5}>Range Voting Points</th>
              </tr>
              <tr>
                <th>Preferred Candidate</th>
                <th>Number of voters</th>
                <th>Debbie</th>
                <th>Diane</th>
                <th>Robin</th>
              </tr>
              <tr>
                <th>Debbie</th>
                <td>301</td>
                <td>10</td>
                <td>5</td>
                <td>0</td>
              </tr>
              <tr>
                <th>Diane</th>
                <td>250</td>
                <td>5</td>
                <td>10</td>
                <td>0</td>
              </tr>
              <tr>
                <th>Robin</th>
                <td>450</td>
                <td>0</td>
                <td>3</td>
                <td>10</td>
              </tr>
              <tr>
                <th>Totals</th>
                <td></td>
                <td>4260</td>
                <td>5355</td>
                <td>4500</td>
              </tr>
              </tbody>
            </table>
            <p>
              At first glance, this seems like a great result, and it is exactly the outcome that Consensus Voting would
              have generated. Diane, a Democrat, has been rewarded for being less divisive and paying attention to all
              of the voters. Diane's less extreme positions and outreach to Republican voters cost some Democratic votes
              but helped her win.
            </p>
            <p>
              The problem is that Republican voters quickly realize that if they had given Robin 10 and Diane 0, then
              Robin would have won, and Republican voters would have gotten a better outcome {mdash} at least from their
              perspective. That seems like it would be the last time Republican voters would ever give any points to a
              Democrat in this district. Assuming it ever happens at all, which I doubt.
            </p>
            <p>
              Without receiving points from the opposition, Diane's strategy of moderation costs her support within her
              own faction, and she gets nothing in return.
            </p>
            <p>
              Personally, I cannot imagine a significant number of voters in either party awarding points to the
              opposition when they know those points can cause their own party to lose the election. I also despair at
              the idea that the voting system would reward voters who are dishonest about their values on their ballots
              with a better outcome. What does that say about the integrity of the election system?
            </p>

            <h3>Approval Voting</h3>
            <p>
              Approval voting is just like range voting except that instead of any number between 0 and 10, the range is
              limited to zero (disapprove) and one (approve). Just like a voter can give multiple candidates a 10 in
              Range
              Voting, a voter can approve of multiple candidates in approval voting. Limiting the scores a voter can
              give to candidates does not make approval voting better than Range Voting. If anything, it makes it worse.
            </p>
            <p>
              In the example above, Diane's supporters have to give Debbie either a 1, meaning no distinction between
              Debbie and Diane, or a zero (no distinction between Debbie and Robin). Even a few voters who try
              to create a distinction between their party's candidates would throw the race to the opposition.
            </p>
            <p>
              Worse yet, to reward a moderate candidate from the other party, a voter must elevate them to be on the
              same level as their preferred candidate within their own party. I may be cynical, but I do not see that
              happening on a statistically significant scale.
            </p>

            <h3>Cardinal Voting In an Ideal World With a Magic Device</h3>
            <p>
              Let's pretend for a moment that we have a magic device that senses every voter's honest score for each
              candidate. Just stepping into the voting booth would allow the device to record the voter's honest
              score for each candidate. For completeness, let's make the even more farfetched assumption that all
              voters believe in the integrity of this magic device. Strategic voting would be impossible, and the
              winner would seem to be the best possible candidate.
            </p>
            <p>
              This device may sound farfetched, but an AI examination of a person's online life and viewing habits can
              be startlingly accurate. There aren't a lot of people who watch Fox News who voted for Joe Biden.
              You wouldn't even have to go to the voting booth.
            </p>
            <p>
              Except for one thing: <i>You cannot offset one man's sorrow with another man's joy.</i>
            </p>
            <p>
              You cannot measure sorrow in pounds or joy in feet and inches. How do you evaluate the impact of one
              candidate or another on voters, even if they were trying to be honest? What if a voter feels that two
              candidates are "meh" and the magic device records each candidate as a 5, while another voter has the same
              feeling about the candidates but finds some tiny difference, and the device records 10 and 0? Should
              voters be rewarded for the extremity of their views?
            </p>
            <p>
              Economists use the word utility to describe the value of a particular policy, option, or candidate to a
              person, but each individual’s utility is subjective, and they cannot be added or offset. Money seems to be
              a constant, but does $100 mean the same to a poor person as to a rich person? How does one person’s
              quality of life compare with another’s? These questions are impossible to answer. Economists often express
              this impossibility by saying, "you cannot make interpersonal comparisons of utility.”
            </p>
            <p>
              Cardinal voting systems don't work, even with magic.
            </p>
          </div>
        </Article>
    )
  }
}




















