import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class Factionalism extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }

  render() {
    let mdash = "\u2014"

    return (
        <Article title={"Our Factional Democracy"}
                 subtitle={"The people should be sovereign, not the factions"}>
          <div className={styles.textContent}>

            <p className={styles.hardQuote}>
              By a faction, I understand a number of citizens, whether amounting to a majority or a minority of the
              whole, who are united and actuated by some common impulse of passion, or of interest, adversed to the
              rights of other citizens, or to the permanent and aggregate interests of the community. {mdash} James Madison,
              Federalist 10
            </p>
            <h3>
              GTO Politics, Politics that is Game Theory Optimal
            </h3>
            <p>
              Every competitive endeavor has strategies that are more and less effective. Skilled competitors know which
              strategies are better and win different situations. For people who study game theory, the ideal
              strategy is called Game Theory Optimal or GTO. Players may want to do something creative or
              otherwise rewarding, but in any competitive game, players must either play the GTO strategy or
              lose to another player who does. It does not matter if the game is poker, chess, or politics.
            </p>
            <p>
              When Mitt Romney said, “It’s not my job to worry about them,” he was making a factual statement about the
              game theory of elections in America. The opinions of voters committed to voting for Obama have almost no
              possible effect on whether Romney wins or loses the election. Any candidate must focus on the voters who
              will or may vote for them and not on those who are committed to voting against them. If attacking and
              otherizing opposition voters generates enthusiasm, turnout, and donations from the base, then that’s
              exactly what a candidate must do, even when it divides our society.

            </p>
            <p>
              Barack Obama was attempting to express compassion for voters neglected by Washington for decades when he
              described rural Republicans as “clinging to guns and religion.” But his comments revealed a deep
              disconnect with those voters. His blindness to how they would interpret that comment can only occur in a
              political universe where those voters are irrelevant to candidates on Obama’s side of the political world.

            </p>
            <div className={styles.centered}>
              <img className={styles.floatingImage} alt={"deplorable"} src={"images/HillaryDeplorables.png"}/>
            </div>
            <p>
              Hillary Clinton was standing at a podium showing her “Stronger Together” campaign slogan when she called
              half of the Republican Party a “basket of deplorables.” Every Republican voter felt personally attacked by
              Clinton. It could not possibly have been more ironic. Again, this comment can only happen in a world where
              the thoughts, feelings, and fate of voters in the other party are irrelevant to a candidate’s ambition.
            </p>
            <p>
              We blame candidates for their divisiveness, but they are playing the game by the rules we established and
              perpetuate. These examples are the byproduct of an election system where the optimal strategy is to ignore
              or even denigrate voters in the opposition party. Trump’s voters rewarded him with money and turnout for
              his pandering to them and attacks on everyone else. Should we blame Trump? Should we blame a set of rules
              that made that strategy successful? Or should we blame ourselves for allowing the rules that rewarded his
              behavior?

            </p>
            <p>
              Unlike poker or Go, the GTO strategy for politics is disturbingly simple – and destructive. About 85% of
              elections in America are decided in primaries of one flavor or another, and the optimal strategy is
              obvious: keep your primary voters happy. Since 1949, there have been 15,660 regular elections to the
              United States House of Representatives. In that period, only one non-incumbent Independent candidate was
              elected: Bernie Sanders. A few incumbents have transitioned to Independent and won reelection, and the
              United States Senate has had a few Independents. But, the path to power is almost exclusively through one
              of the major parties.

            </p>
            <p>
              One inescapable fact of all democratic processes is that voters not needed for a candidate’s election or
              reelection do not matter. Even voters in the candidate’s party may be irrelevant when the general election
              is not in doubt if they don’t play a role in the primary. Candidates must always place the priorities of
              voters who control their political destiny first.

            </p>
            <h3>
              Factional Democracy
            </h3>
            <p>
              In a factional democracy, most elected officials owe their primary allegiance to their faction, and
              placing country over party creates grave political risk. In such a democracy, the factions choose their
              candidates and policies, and voters can choose between candidates representing one faction or another in
              most elections. Democracies are not factional because of some flaw in either the candidates or the voters.
              Instead, they are factional because the voting rules allow no alternative.

            </p>
            <p>
              By that definition, America – and almost all other democracies – are factional democracies.
            </p>
            <p>
              Describing a democracy as factional may seem superfluous. Why bother defining a democracy as factional?
              How could any democracy be any other way? We take it for granted that when a candidate says “my voters,”
              they mean those who voted for them, not those who voted against them. It’s like defining water as being
              wet. Factionalism is so baked into our political thinking that we cannot imagine a democracy where the
              leaders are not factional.
            </p>
            <h3>
              Madison’s Intent
            </h3>
            <p>
              Factional democracies can be successful, but they can also fail. Moreover, factional democracy is not what
              the authors of the United States Constitution intended.
            </p>
            <p className={styles.hardQuote}>
              From this view of the subject it may be concluded that a pure democracy, by which I mean a society
              consisting of a small number of citizens, who assemble and administer the government in person, can admit
              of no cure for the mischiefs of faction. {mdash} James Madison, Federalist 10
            </p>
            <p>
              The Federalist papers were written by James Madison, Alexander Hamilton, and John Jay to convince a
              skeptical public of the wisdom of adopting the newly written Constitution. They contain invaluable insight
              into the thinking of the authors of the Constitution.
            </p>
            <p>
              Federalist 10 is considered to be the most important Federalist paper because James Madison describes how
              the demise of direct democracy and his solution. Madison's issue with direct democracy is not so much that
              they are direct, but that direct democracy is inherently vulnerable to factions. Madison’s premise is that
              it is impossible to prevent a faction that controls all the levers of power from abusing that power. “…
              measures are too often decided, not according to the rules of justice and the rights of the minor party,
              but by the superior force of an interested and overbearing majority.”
            </p>

            <p>
              It makes little difference whether the controlling faction is a majority of citizens in a direct democracy
              or their elected avatars in a republic. The result is the same: a controlling faction can govern without
              the consent of the minority. Why should the dominant faction collaborate or compromise with the minority?
              What barriers exist when one faction controls all the levers of power?

            </p>
            <p>
              Madison was not optimistic about the outcome when a faction gained control of a direct democracy.
              Hence it is that such democracies have ever been spectacles of turbulence and contention; [...] and have
              in general been as short in their lives as they have been violent in their deaths. {mdash} James Madison,
              Federalist 10
              American democracy is more resilient than direct democracy or most parliamentary democracies because of
              the separation of powers and the protections built into the Constitution. But it is still possible for a
              single faction to dominate all branches of government, and the Constitution is only as strong as the
              people we elect to defend it.
            </p>
            <h3>
              The Great Sorting
            </h3>
            <p>
              For all of Madison and Hamilton’s doomsaying, factional democracies do not explode instantly. Hamilton
              describes “felicity,” “rays of glory,” and “dazzling … brilliancy.” Sadly, these compliments are preceded,
              followed, and intermixed with words like “short-lived,” “transient,” and “fleeting.” But a historian's
              perspective differs from that of the people living in the moment, and Hamilton’s “fleeting” may be decades
              or even a lifetime.

            </p>
            <p>
              When a factional democracy works best, the elected representatives of the factions collaborate to find
              solutions that address everyone’s most vital needs and advance the public good. In these periods,
              factional democracy can create the dazzling brilliance of which Hamilton spoke.

            </p>
            <p>
              American democracy experienced just such a period of dazzling brilliance in the 1950s, aided by the
              unifying effect of WWII and the Cold War and by the fact that neither party met Madison's definition of a
              faction. Madison describes the members of a faction as being ".. united and actuated by some common
              impulse of passion, or of interest, adverse to the rights of other citizens, ..." In the 1950s, the
              political parties were far from united by a common political ideology.

            </p>
            <p>
              This quote predates the 1950s but is too good to pass up. In 1923, Senator William Borah of Idaho defined
              the requirements of being a Republican for an Idaho paper:

            </p>
            <p>
              "Any man who can carry a Republican primary is a Republican. He might believe in free trade, in
              unconditional membership in the League of Nations, in States' rights and in every policy that the
              Democratic Party ever advocated; yet, if he carried his Republican primary, he would be a Republican. He
              might go to the other extreme and believe in the communistic state, in the dictatorship of the
              proletariat, in the abolition of private property and in the extermination of the bourgeoisie; yet, if he
              carried his Republican primary, he would still be a Republican."

            </p>
            <p>
              Borah’s definition of a political party is about as far as it’s possible to get from Madison’s definition
              of a faction “united by a common cause or principle."

            </p>
            <p className={styles.pullQuote}>
              “It would be a great tragedy if we had our two major political parties divide on what we would call a
              conservative-liberal line,” Nixon said. ”I think one of the attributes of our political system has been
              that
              we have avoided generally violent swings in Administrations from one extreme to the other. And the reason
              we have avoided that is that in both parties, there has been room for a broad spectrum of opinion.”
            </p>
            <p>
              This lack of ideological purity made compromising with the opposing party and ideological opponents
              possible. The result was a flood of bipartisan legislation with broad electoral support that advanced the
              public good. The Interstate Highway system, the Civil Rights Act of 1957, the Peace Corps, the expansion
              of Social Security, the Housing Act of 1954, and the Atomic Energy Act of 1954 were all products of this
              era of bipartisan cooperation, and they are just the highlights.

            </p>
            <p>
              Surprisingly, the political establishment saw the lack of polarization and the abundance of compromise as
              a problem. In 1950, the American Political Science Association released a ninety-eight-page paper pleading
              for a more polarized political system. The criticism was that each party contained too much diversity of
              opinion and was too willing to work with the other party. It is exquisitely ironic that this complaint
              came in an era we look back upon as a pinnacle of American democracy.

            </p>
            <p>
              In 1959, then-Vice President and candidate Richard Nixon spoke out against polarization. “It would be a
              great tragedy if we had our two major political parties divide on what we would call a
              conservative-liberal line,” Nixon said. ”I think one of the attributes of our political system has been
              that
              we have avoided generally violent swings in Administrations from one extreme to the other. And the reason
              we have avoided that is that in both parties, there has been room for a broad spectrum of opinion.”
              Imagine a 21st-century candidate saying anything like that.

            </p>
            <p>
              Nixon wasn’t alone. John F. Kennedy, his opponent in the 1960 presidential election, echoed Nixon’s
              comments when he warned that “The country was already split vertically, between sections, races, and
              ethnic groups,” so it would be “dangerous to split it horizontally, too, between liberals and
              conservatives.”

            </p>
            <p className={styles.pullQuote}>
              Candidates from both parties could compromise on many issues without inviting a primary challenge.
            </p>
            <p>
              There may be many reasons for this, but the lack of party orthodoxy was essential to the compromises that
              allowed the political progress of the era. Neither party was even close to ideologically uniform. On the
              Democratic side, Southern conservatives clung to the increasingly liberal Democratic party as a legacy of
              the Civil War. At the same time, there were liberal Republicans, particularly on social issues: Barry
              Goldwater’s wife was a founder of the Arizona branch of Planned Parenthood.

            </p>
            <p>
              Candidates still had to represent the voters that put them in office to the exclusion of other voters.
              That meant supporting Jim Crow racial segregation and discrimination policies in the South. But that, like
              other regional issues, wasn't the policy of either party, and there was no party orthodoxy. Candidates
              from both parties could compromise on many issues without inviting a primary challenge.

            </p>
            <p>
              That all began to change with the passage of the Civil Rights Act of 1964 and the Voting Rights Act of
              1965. President Lyndon Johnson’s famous – and possibly apocryphal – comment to an aide after signing the
              Civil Rights Act, “There goes the South for a generation,” was an understatement. Civil rights had been a
              bipartisan issue; Republicans supported the measures at least as much as Democrats, but Republican
              presidential candidates Barry Goldwater and later Richard Nixon saw a political opportunity and embarked
              on their notorious and fateful “Southern Strategy,” courting Southern white voters by appealing to their
              racial grievances. Unwittingly, they were innovating game theory optimal politics, all too willing to
              leverage factionalism for electoral advantage.

            </p>
            <p>
              Over the next fifty years, the parties sorted themselves on issue after issue, with each side adopting the
              positions that we can all recognize at a glance today, starting with hot-button issues like abortion and
              gun rights, extending to less obviously ideological matters like energy use, vaccines, and environmental
              protection, and even reaching foreign policy and America’s interests abroad, tossing aside the quaint
              World War II-era adage that “politics stops at the water’s edge.” Today, any candidate who deviates from
              either party’s core principles invites a primary challenge.

            </p>
            <p className={styles.pullQuote}>
              A peaceful power transfer requires the side yielding power to value democracy more than they fear their
              opponents. How could you give power to an opposition you believe is evil or will undermine democracy?
            </p>
            <p>
              Our democracy teeters on the brink of authoritarianism because the divide between the two parties has
              grown so large that they cannot govern effectively. Compromise happens through brinksmanship when it
              happens at all, and effective governance has become all but impossible.

            </p>
            <p>
              A peaceful power transfer requires the side yielding power to value democracy more than they fear their
              opponents. How can you yield power to an opposition you believe is evil or will undermine democracy? Do
              you then become the problem? Undermining confidence in the democratic process is a slippery slope.
            </p>
            <p>
              Eighty percent of voters in each party believe the other party would destroy America as we know it if they
              had the power. Is that enough justification to retain power by undermining democracy? If not, what is?
              Factions have brought American democracy to the brink of disaster.
            </p>
            <h3>
              Madison’s Other Defense Against Factions
            </h3>

            <p className={styles.hardQuote}>
              The two great points of difference between a democracy and a republic are: first, the delegation of the
              government, in the latter, to a small number of citizens elected by the rest; secondly, the greater number
              of citizens, and greater sphere of country, over which the latter may be extended. {mdash} James Madison, Federalist 10
            </p>
            <p>
              Madison in Federalist 10 and Hamilton in Federalist 9 could not possibly have been more critical of direct
              democracy in Federalist 9 and 10. Direct democracy, they say, is indefensible; it exists, Hamilton
              says, <i>in a state of perpetual vibration between the extremes of tyranny and anarchy</i>, and
              democracies, Madison says, are as <i>short in their lives as they are violent in their deaths</i>.
              Hamilton's and Madison's additional critiques display considerable linguistic creativity and can't all be
              listed here. After all of that, Madison argues that a Republic can limit the excesses of factions because
              of <i>two great points of difference</i>.
            </p>
            <p>
              In addition to Madison's <i>two great points of difference</i>, there are other defenses against tyranny
              in
              American democracy, the separation of powers and the Constitutional protections of the minority could
              conceivably exist in a direct democracy as well. The entirety of Madison's case for a republic to succeed
              where direct democracy failed rests on these two great points of difference.
            </p>
            <p>
              Taking the second point first because it is less compelling and briefer to explain; it is that the new
              Republic will be larger and more diverse. Madison argues that this diversity of interests will make it
              more difficult for a faction to dominate the government. There may have been more validity to the
              diversity of interests argument when Federalist 10 was written, but the modern reality is that democracy
              can be threatened by a faction.
            </p>
            <p>
              Madison also suggests that the leaders we elect will be more qualified because we have a larger pool of
              potential candidates and more voters will make better choices. To his credit, we have elected leaders of
              amazing talent, but experience has shown that we also have a larger pool of idiots and demagogues.
            </p>
            <p className={styles.hardQuote}>
              The effect of the first difference is, on the one hand, to refine and enlarge the public views, by passing
              them through the medium of a chosen body of citizens, whose wisdom may best discern the true interest of
              their country, and whose patriotism and love of justice will be least likely to sacrifice it to temporary
              or partial considerations. {mdash} James Madison, Federalist 10
            </p>
            <p>

              The first point is more compelling. It is that a Republic is governed by elected representatives instead
              of directly by the people. These elected representatives, Madison argues, will insulate governance from
              being dominated by a faction. Their <i>love of justice</i> contrasts with direct democracy’s tendency to
              decide matters contrary to the requirements of justice. Madison expects the representative’s ability to
              discern the true interests of their country to protect the country from the overbearing majority who would
              prevail in a direct democracy. These public-spirited elected representatives are the essential protection
              of government institutions from the overbearing majority.
            </p>
            <p className={styles.hardQuote}>
              Under such a regulation, it may well happen that the public voice, pronounced by the representatives of
              the people, will be more consonant to the public good than if pronounced by the people themselves,
              convened for the purpose. {mdash} James Madison, Federalist 10
            </p>
            <p>
              Madison describes the leaders we elect as translating the majority's views into policy that will be
              more reflective of the public good. If these representatives are beholden to a faction that
              controls their political destiny, how can they do anything but exactly what the majority faction
              demands? Madison repeats the necessity of insulating public policy from factional domination.
            </p>
            <p className={styles.pullQuote}>
              Will a Hamilton 2,000 years in the future reflect on our 200 years of democracy as a fleeting period of
              brilliance and chaos before we descend into tyranny?
            </p>
            <p>
              Scholars have dismissed Madison’s description of elected representatives as either naive or willfully
              blind to the realities of elections. Given our current political reality, Madison’s requirement for
              leaders to be above the factional fray seems unrealistic, and it is hard not to laugh at Madison’s
              description of the enlightened views and virtuous sentiments of the people we elect. Our leaders are
              mostly avatars of their various factions, certainly not all enlightened and virtuous, and yet American
              democracy has survived.
            </p>
            <p>
              But survival and thriving are different things. We might survive, but we will not thrive amidst the
              frequent periods of bitter infighting, dysfunction, gridlock, and even threat of civil war. Furthermore,
              what is fleeting? Will a Hamilton 2,000 years in the future reflect on our 250 years of democracy as a
              fleeting period of brilliance and chaos before we descended into tyranny?
            </p>
            <h3>
              
            </h3>
            <p>
              Madison’s idea of the elected leaders insulating governance from factional domination was not
              necessarily naive. Madison might have thought that voters could elect the candidates they preferred.
              You might think we do that today, but our election process is structured to make it almost impossible for
              voters to elect the candidates they truly prefer.
            </p>
            <p>
              Before going further, it’s essential to understand that opinions are diverse within any group, party, or
              faction. In the modern context, if you took all of the Republican members of the U.S. House and put them
              on an island without opposition, they would immediately divide into bitterly opposed factions. If you put
              those factions on their own islands, they would divide again. This division would repeat until every
              member was standing on their own island. There is a similar spectrum of opinion with Democrats or any
              other political group.
            </p>
            <p>

              Given that each party contains a spectrum of voters consider an election in a U.S. House district that
              leans to the Republican party by 55% to 45% with two candidates. Typically, such an election would feature
              candidates from each party, but in this instance, assume that both candidates are Republicans and
              everybody is voting because this is a general election, not a primary.
            </p>
            <p>

              Rebecca represents the Republican party orthodoxy and is at the ideological center of the Republican
              party. Ryan is a more moderate Republican and seeks to balance the views of Republican and
              Democratic voters in the district.
            </p>
            <div className={styles.centered}>
              <img className={styles.floatingImage} alt={"MadisonsVision"} src={"images/MadisonsVision.png"}/>
              <p className={styles.caption}>
                This image shows voter preference in a Republican-leaning district between two Republican candidates.
                Voters are colored for the candidate whose ideological views are closer to theirs and are
                most likely to vote for. Rebecca represents a candidate near the center of the Republican party. Ryan is
                positioned to be a better representative of all voters and easily defeats his more partisan opponent.
              </p>
            </div>
            <p>
              Assume the 55% of voters who are Republican votes split 35% to 20% in favor of the Rebecca.
              But, the Ryan would also get nearly all of the Democratic votes for a total of 20 + 45 = 65%. The result
              is almost a two-to-one victory for Ryan.
            </p>
            <p>
              An election with a decisive partisan lean will almost always elect a candidate from the dominant party.
              The general election is a formality; the critical questions are: Which candidate from the dominant party?
              And who makes that decision?
            </p>
            <p className={styles.pullQuote}>
              If Madison was naive about factional representatives, it was not because the voters preferred them.
            </p>
            <p>
              If all of the voters participate in the decision, then any purely factional candidate loses to a
              candidate from the same party who balances the needs of all voters. These non-factional leaders would
              create the world Madison envisioned, where the people we elect are responsive to the entire spectrum of
              voters they represent. These leaders would be rewarded for placing country before party. But that’s now
              how our elections work.
            </p>
            <p>

              It’s almost impossible for candidates like Ryan to survive a partisan primary. The rules are so biased
              against them that they rarely choose to run. Even when they do run, they have difficulty amassing the
              funding, staff, volunteers, and media coverage required to mount a successful political campaign because
              everybody knows they have little chance of victory.
            </p>
            <p>

              The way our elections work allows factional forces to subvert the intent of elections and freeze minority
              party voters out of meaningful participation in the democratic process. In our modern political world, the
              parties choose their candidates in primaries or caucuses, and most general elections are a formality. Even
              in competitive elections the voters only have a choice of factional avatars. The result is today’s
              factional democracy and all of its problems.
            </p>
            <p>
              If Madison was naive about factional representatives, it was not because the voters preferred them.
              Rather, he failed to anticipate how thoroughly factional forces would subvert the democratic process to
              prevent the voters from electing candidates they prefer. Or perhaps he understood the danger and thought
              the voters would not tolerate having their democracy stolen.
            </p>
            <p>
              If we are concerned about partisan zealots destroying our democracy, we need an election process doesn't
              force us to elect them.
            </p>
          </div>
        </Article>
    )
  }
}




















