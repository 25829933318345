import * as d3 from 'd3'

class Scroller {

  onNewSection: (sectionNumber: number, progress: number) => void
  onProgress: (sectionNumber: number, progress: number) => void

  constructor(
      onNewSection: (sectionNumber: number, progress: number) => void,
      onProgress: (sectionNumber: number, progress: number) => void) {
    this.onNewSection = onNewSection
    this.onProgress = onProgress
    this.dispatch.on("active", this.onNewSection)
    this.dispatch.on("progress", this.onProgress)
    this.containerStart = 0
    this.currentIndex = -1
  }

  dispatch = d3.dispatch('active', 'progress');
  sectionPositions: Array<number> = []
  nSections = 0
  currentIndex = -1
  containerStart = 0;
  sections = d3.selectAll('.step')


// Binds the position function to the scroll event, and the resize function to the resize event. What these functions do are detailed below.
  scroll() {
    this.sections = d3.selectAll('.step')
    d3.select('#scrollerParent')
        .on("scroll", this.position)
        .on("resize", this.resize)

    window.addEventListener("scroll", this.position)
    window.addEventListener("resize", this.resize)
    // d3.select(window)
    //     .on('scroll', this.position)
    //     .on('resize', this.resize)
    this.resize();
    let timerCallback = () => {
      this.position();
    }
    d3.timeout(timerCallback, 100)
  }

// The resize function determines where each of the .step elements are on the page,
// relative to the top of the first element. It saves the co-ordinates of
// these elements in an array called sectionPositions
//
// There is an issue with this being called before the page is fully rendered and
// the resulting positions being inaccurate. There is also a problem with elements
// changing in size and causing problems. It should be called by position() to ensure
// that the positions accurately reflect the current screen content, but there is still
// a problem with screen content changing size due to a step resizing results tables
// when the candidate mix changes.
//
// I think the solution there is to narrow the scope of the table updates, but that's
// going to take some effort.

  resize = () => {
    let scrollY = window.scrollY
    this.sectionPositions = [0];
    this.sections.each((d: any, i: any, nodes: any) => {
      let top = nodes[i].getBoundingClientRect().top + scrollY;
      this.sectionPositions.push(top)
      if (i === this.sections.size() - 1) {
        let bottom = nodes[i].getBoundingClientRect().bottom + scrollY;
        this.sectionPositions.push(bottom)
      }
    });
    this.nSections = this.sectionPositions.length
    // this.sectionPositions.forEach((v: number, i: number) => { console.log(`section ${i} top ${v}`)})
  }

  fade = () => {
    let nSections = this.sections.size()
    let x = Array<number>(nSections).fill(0)
    for (let i = 0; i < nSections; i++)
      x[i] = i
    let numberedSteps = this.sections.data(x)
    numberedSteps.filter(d => d === this.currentIndex)
        .transition("fade-step")
        .duration(500)
        .style("opacity", 1)

    numberedSteps.filter(d => d < this.currentIndex)
        .transition("fade-step")
        .duration(1000)
        .style("opacity", .20)

    numberedSteps.filter(d => d > this.currentIndex)
        .transition("fade-step")
        .duration(1000)
        .style("opacity", .20)

  }

//The position function determines where the user is on the page (using window.pageYOffset),
// and uses that to determine which section of text should currently be in view. It then
// uses D3’s dispatching tools to signal the 'progress' event, which will be used in the
// main script, passing along the current section index so that the script knows which
// stage of the animation/visualisation should be showing.

  position_old = () => {
    let pos = Math.max(0, window.scrollY - this.containerStart)
    let sectionIndex = Math.max(0, d3.bisect(this.sectionPositions, pos) - 1)
    sectionIndex = Math.min(this.sections.size() - 1, sectionIndex)
    // console.log(`sectionIndex ${sectionIndex} sectionPos: ${this.sectionPositions[sectionIndex]} pos ${pos} h ${window.innerHeight}`)
    while (sectionIndex > 0 && sectionIndex < this.nSections - 1 && this.sectionPositions[sectionIndex + 1] > pos + window.innerHeight) {
      // console.log(`decrement! sectionIndex ${sectionIndex} sectionPos: ${this.sectionPositions[sectionIndex]} pos ${pos} h ${window.innerHeight}`)
      sectionIndex -= 1
    }

    let sectionStart = this.sectionPositions[sectionIndex]
    let sectionEnd = this.sectionPositions[sectionIndex + 1]
    let progress = (pos - sectionStart) / (sectionEnd - sectionStart) || 0


    if (this.currentIndex !== sectionIndex) {
      this.dispatch.call('active', this, sectionIndex, progress);
      this.currentIndex = sectionIndex;
      // this.fade()
    }
    this.dispatch.call('progress', this, this.currentIndex, progress)
  }

  position = () => {
    this.resize()
    let sectionIndex = 0
    let progress = 0
    let pos = Math.max(0, window.scrollY) + window.innerHeight / 2
    // console.log(`Scroller.position ${window.scrollY} ${window.innerHeight} ${pos}`)
    for (let i = 0; i < this.sections.size(); i++) {
      let startVis = this.sectionPositions[i + 1]
      let endVis = this.sectionPositions[i + 2]
      if (pos > startVis) {
        sectionIndex = i
        progress = (pos < endVis) ? (pos - startVis) / (endVis - startVis) : 1.0
      }
    }
    if (this.currentIndex !== sectionIndex) {
      this.dispatch.call('active', this, sectionIndex, progress);
      this.currentIndex = sectionIndex;
      this.fade()
    }
    this.dispatch.call('progress', this, this.currentIndex, progress)
  }
}

export default Scroller