import React from 'react'
import styles from './VotingReform101.module.scss'
import SectionTitle from '../../typography/SectionTitle'
import TopicHeader from '../TopicHeader/TopicHeader'
import ArticleIntro from "../ArticleIntro/ArticleIntro";

interface Props {

}

const MoreStuffRight = (props: Props) => {
  return (
      <div className={styles.content}>
        <SectionTitle title="Blog Articles" caption="Articles on various topics"/>
        <ul>


          <li>
            <ArticleIntro
                title="The First Place Vote Argument"
                subtitle="Instant Runoff places special emphasis on first place votes, but are they really so special?"
                link="/FirstPlaceFallacy"
                imageUrl={"/images/FirstPlaceFallacy.png"}
            />
          </li>


        </ul>
        <SectionTitle title="About" caption="What is Better Choices"/>
        <ul>

          <li>
            <TopicHeader
                title="About the Author"
                description="Background on the Author"
                link="/About"
            />
          </li>

        </ul>
      </div>
  )
}

export default MoreStuffRight