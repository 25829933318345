import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class Perfect extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }


  render() {
    let mdash = "\u2014"
    let gt = ">"

    return (
        <Article title={"No Voting System Is Perfect."}
                 subtitle={"Like anything, perfection is a impossible."}>
          <div className={styles.textContent}>
            <p>
              All voting rules have flaws, but they are not equally flawed. Some {mdash} like our primary based
              voting rule {mdash} are easily and systematically manipulated, while others are almost impossible to
              manipulate. Consensus Voting has been mathematically proven by Partha Dasgupta and Eric Maskin to
              mathematically dominate all other voting rules. That means that no voting rule ever produces a result
              that is better represents the voter's values. Consensus Voting is the best.
            </p>
            <p>
              I'm using two different terms here, election system and voting rule. By an election system I mean the
              combination of the Top-5 qualifier to choose candidates for the general and the general election held with
              Consensus Voting. By a voting rule, I mean the type of ballot and the way it is interpreted in the general
              election.
            </p>
            <p>
              Though it is the best, Arrow proved that all systems are flawed and the flaw in Consensus Voting comes
              from the possibility that the voters will return a set of ballots that create a rock-paper-scissors
              style cycle. It may seem impossible, but though it is highly unlikely, the voters can return a set
              of ballots where the voters prefer A to B, B to C, and C to A. For brevity, we write this as
              A {gt} B {gt} C {gt} A. So, who is the winner?
            </p>

            <h3>All Voting Systems Have Cycles</h3>
            <p>
              Consensus Voting doesn't create the cycle, it reveals its existence. If a cycle existed in a plurality
              election, whoever got the most votes would be winner and nobody would ever know there was a cycle. If
              primaries were used, one of the candidates would be eliminated in the primary. <i>Every</i> voting system
              must deal with cycles. Most voting rules ignore cycles but that doesn't mean they don't exist.
            </p>

            <h3>Cycles are Extremely Rare</h3>
            <p>
              There has been an exhaustive search of elections conducted with ranked choice ballots, and no governmental
              election has ever demonstrated a cycle. Academic research, including some of my own, estimates that a
              cycle might happen once every thousand elections. For a four year office, that's once every four thousand
              years. Even that requires at least three candidates of almost equal quality (money, campaigning talent,
              etc) so they may be even more rare.
            </p>

            <h3>Cycles Don't Matter Much</h3>

            <p>
              The article on Representation Scores showed that a candidate who is a better representative of the voters
              will defeat a candidate who is less representative. To create the A {gt} B {gt} C {gt} A cycle, all of the
              candidates have to be about the same level of representation. Further, if they aren't all extremely good
              representatives of the voters, any one of them could move toward the center of the electorate and easily
              win.
            </p>
            <p>
              Since a cycle is only likely among three candidates of equal quality who are all near the center of the
              electorate, any one of the candidates in the cycle would be an acceptable winner. The voting rule has
              already accomplished its goal of shaping the candidates to be representatives of all of the people.
              Given this, Eric Maskin has suggested choosing the winner randomly among the candidates involved in the
              cycle.
            </p>

            <h3>Cycles Must Be Resolved</h3>
            <p>
              Even though they are rare and any candidate will do, cycles must be resolved. Nicolaus Tideman discovered
              the mathematically best way to resolve cycles, but even he says that we shouldn't get too hung up on how
              to resolve them. I agree, I'm going to present two ways to resolve cycles. One may be more practical given
              the political environment while the other is more mathematically rigorous.
            </p>

            <h3>When Instant Runoff Is Already in Use</h3>
            <p>
              When a jurisdiction is already using <a href={"/IRV"}>Instant Runoff</a>, insert a Consensus Check before
              running the existing IRV algorithm. The Consensus Check determines if there is a Consensus Winner and
              elects them when they exist. This is not mathematically ideal, but it has been well studied and has the
              is a small change to existing statutes. It is also conceptually easy to present as a small
              change that ensures that the voting rule does not override the wishes of the voters.
            </p>

            <p>
              One way of looking at this is that inserting the Consensus Check makes a minor change to Instant Runoff
              (IRV) and fixes an unlikely corner case where IRV would otherwise violate majority rule and override
              the wishes of the voters. It may sometimes be expedient to leave the discussion at that, but the
              dynamics of Consensus Voting and Instant Runoff are profoundly different. A Consensus Check would
              elect the Consensus Candidate 99.9% of the time and fundamentally alter the strategy for winning.
            </p>

            <h3>The Mathematically Best Way</h3>

            <p>
              When the voters tell us that they prefer A {gt} B, B {gt} C, and C {gt} A, they have given us a set of
              three facts that are logically inconsistent. To choose a winner we have to override one of these facts
              that the voters have given us. But which one? You've probably already guessed the answer, but the margin
              of victory in these contest can be described as the confidence the voters have in the fact. i.e. if A
              defeats B by 10,000 votes and C defeats A by 10 votes, it would be fair to say that we have more
              confidence in A {gt} B than we do in C {gt} A.
            </p>

            <p>
              For a cycle with three candidates, the answer is obvious: ignore the weakest defeat in the cycle. But it's
              important to state the rule in a way that is mathematically complete and handles every possible problem
              with any number of candidates. So, I'll state Tideman's Ranked Pairs in its full complexity.
            </p>

            <p>
              Take all of the one-on-one results and sort them in the order of margin of victory with the largest margin
              of victory first as the "provisional results". Then, accept the results one-by-one as long as they don't
              conflict with previously accepted results by creating a cycle. A result which does create a cycle is
              discarded and ignored. The winner is the undefeated candidate.
            </p>

            <table>
              <tbody>
              <tr>
                <th colSpan={2}>Provisional Results</th>

              </tr>
              <tr>
                <th>Result</th>
                <th>Margin of Victory</th>
              </tr>

              <tr>
                <td>A defeats B</td>
                <td>10,000</td>
              </tr>
              <tr>
                <td>B defeats C</td>
                <td>100</td>
              </tr>

              <tr>
                <td>C defeats A</td>
                <td>10</td>
              </tr>
              </tbody>
            </table>

            <p>
              With these results, Tideman's Rank Pairs would accept A {gt} B and B {gt} C. But accepting C {gt} A would
              create a cycle so that result is ignored. A is undefeated in the accepted results and the winner. These
              results deliberately create a cycle, but nine hundred and ninety nine times out of a thousand, no result
              will be discarded.
            </p>


            <p>
              Ranked Pairs has the advantage of a having consistent strategy whether or not a cycle exists. Candidates
              should always try to be preferred by the voters over each of their opponents. This removes any
              incentive for a candidate to encourage strategic voting to create a cycle because the method of decision
              doesn't shift to a different method where a candidate may have an advantage.
            </p>

            <h3>Cycles are Over-Hyped</h3>

            <p>
              When Kenneth Arrow published his Impossibility Theorem in Social Choice and Individual Values in 1951, it
              was an academic earthquake, fundamentally changing our perception of economics and politics. Arrow would
              win the Nobel Prize in economics for his theorem and over 25,000 academic papers cite Arrow's work. It is
              the foundation of modern public choice theory.
            </p>

            <p>
              The overwhelming reaction to it was that Arrow had proven that democracy is impossible, but that is an
              exaggeration. At its core, Arrow's theorem says that there is no perfect way to resolve a cycle. That's
              true, but there are some very good ways to resolve a cycle and democracy is far from impossible.
            </p>

            <p>
              Given the deep flaws in our primary system and other election systems, using cycles as a reason to
              dismiss Consensus Voting is specious at best. First of all, cycles are an artifact of the voters'
              preferences, not the voting rule and they can exist in any voting rule. Other voting systems are
              blissfully unaware of the existence of cycles because they don't even attempt to abide by majority
              rule.
            </p>

            <p>
              It's far better to abide by majority rule when the voters prefer one candidate to each of the other
              candidates, and logically resolve a cycle in the rare instance where one exists. Abiding by majority
              rule rewards candidates who balance the needs of the electorate
            </p>

          </div>
        </Article>
    )
  }
}




















