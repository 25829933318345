import Bubble from "./Bubble";
import Candidate from "../../core/Candidate";
import { Party } from "../../core/Party";
import {rangesAndGradients} from "./RangesAndGradients";

class CandidateBubble extends Bubble {
  candidate: Candidate
  width = 30
  nameAreaHeight = 15
  lineWidth = 10
  height = () => {return 2 * this.width + this.nameAreaHeight}
  yOffset = () => {return -(this.height() + this.lineWidth / 2) }
  xOffset = () => { return -this.width / 2 }
  xStickOffset = () => -this.width
  name: string
  ideology: number
  party: Party
  active = true
  halo = false
  voterRepresentation = 80
  representationColor = (): string => {
    return rangesAndGradients.electorateRepresentationColorScale(this.voterRepresentation)
  }

  constructor(candidate: Candidate) {
    super();
    this.candidate = candidate
    this.name = candidate.name
    this.ideology = candidate.ideology
    this.party = candidate.party
  }
  ivec = (): number[] => {
    return this.candidate.ivec
  }
}
export default CandidateBubble