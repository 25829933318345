import React from 'react';
import MapController from "../ui/elements/MapController";
import CombinedDistrictData from "../core/CombinedDistrictData";
import VisualizationArea from "../ui/components/VisualizationArea";
import {ConsensusVotingApp} from "../Introduction/ConsensusVotingApp";
import {AppMetadata} from "../ui/components/App/App";
import {SimulationState} from "../ElectionSimulation/ElectionSimApp";


export interface VisualizationWidgetProps {
  metaData: AppMetadata
  id: string
  className: string
  render: (app: ConsensusVotingApp) => void
  width?: number
  height?: number
  onUpdate?: (s: SimulationState) => void
  nVoters?: number
  radius?: number
}


class VisualizationWidget extends React.Component<any, any> {
  map!: MapController
  combinedDistrictData!: CombinedDistrictData
  app!: ConsensusVotingApp
  resizeObserver = new ResizeObserver(entries => {
    this.app?.onResize(true)
  })



  componentDidMount() {
    let md = this.props.metaData
    this.app = new ConsensusVotingApp(this.props.id,
        md.dvr,
        md.DWNominate,
        md.simulationResults,
        md.sampleCongress,
        md.usTopo,
        this.props.nVoters ?? 201, this.props.radius ?? 2.5)
    this.app.setVisualizationArea(this.props.id)
    this.app.setMapArea(this.props.id)
    this.app.setScaling(this.props.width ?? 1000, this.props.height ?? 1000)
    this.props.render(this.app)
    // this.app.showConsensusHouse(0)
    this.app.render()
    this.app.onUpdate = this.props.onUpdate
    this.props.onUpdate && this.props.onUpdate(this.app.cachedSimulationState)
    this.app.onResize(true)
    this.resizeObserver.observe(document.getElementById(this.props.id)!)
  }

  render() {
    return (
        <VisualizationArea
            id={this.props.id}
            className={this.props.className}
        />
    )
  }
}

export default VisualizationWidget
