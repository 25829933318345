import React from "react";
import styles from "./WinnerAndScore.module.scss";
import {Candidate2D} from "../ui/elements/Candidates2D";

interface Props {
  candidate: Candidate2D | undefined
}

class WinnerAndScore extends React.Component<any, any> {
  foo: string

  constructor(props: Props) {
    super(props);
    this.foo = "bar"
  }

  render = (): JSX.Element => {
    let candidate = this.props.candidate
    if (!candidate)
      return null as any as JSX.Element

    return (
        <div className={styles.winnerAndScore}>
          <div className={styles.candidateNameAndCaption} style={{
            background: candidate.candidateColor,
          }}>
            <div className={styles.representationCaption}>
              Winner
            </div>
            <div className={styles.candidateName}>
              {candidate.name}
            </div>
          </div>
          <div className={styles.representationDiv} style={{
            background: candidate.representationColor(),
            color: candidate.voterRepresentation > 40 ? "black" : 'white'
          }}>

            <div className={styles.representationCaption}>
              Representation
            </div>
            <div className={styles.representationNumber}>
              {candidate.voterRepresentation.toFixed(0)}
            </div>
          </div>
        </div>
    )
  }
}

export default WinnerAndScore