import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class CaliforniaTop2 extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }

  render() {
    // let mdash = "\u2014"

    return (
        <Article title={"Hints of a Solution"}
                 subtitle={"Two Stories from California's Top-2 System Point to a Solution"}>
          <div className={styles.textContent}>
            <p>
              Who should represent a 60/40 district? Should the "60" be able to decide the outcome with no input from
              the "40." Should the "40" be frozen out of the democratic process?
            </p>
            <p>
              These two examples from California illustrate what happens when all of the voters participate
              equally in deciding who should represent them. California uses a Top-2 primary system where all
              candidates from all parties participate in an open primary. These elections often follow a fairly
              traditional pattern where Democrats and Republicans vote for candidates from their respective parties in
              the primary, and one candidate from each party advances the general election. But not always.
            </p>
            <h3>
              Susan Rubio's Story
            </h3>
            <p>
              Sometimes, California's Top-2 system produces elections where the final two candidates are from
              the same party. While many people criticize these elections, they often produce results that do a much
              better job of representing all of the voters than the traditional “D vs. R” elections we are all familiar
              with. One such Democrat vs Democrat election was set up on June 5, 2018, when Susan Rubio and Mike Eng
              qualified for the fall general election for California State Senate Seat 22.
            </p>
            <p>
              Mike Eng dominated the Top-2 primary, securing 45.3% of the vote, far ahead of Susan Rubio’s 26.4%. The
              heavily favored Eng had the full-throated support of the Democratic party establishment: 55 labor
              organizations, 14 public safety groups, 20 current and retired Democratic State Senators, nine United
              States House Members (including his wife, Judy Chu), and the State Democratic Party. It’s difficult to
              imagine a better-qualified candidate – from the perspective of Democratic voters.

            </p>
            <p>
              Susan Rubio, a Democrat, reached out to Republican voters in the district.
            </p>
            <p>
              Even before the June 5, 2018 primary, Susan Rubio worked to establish herself as a moderate Democratic
              candidate, not beholden to the more progressive Democratic party line. In an interview during the
              campaign, Rubio said that she represented "the middle ground voters are desperately seeking," while her
              opponent represented the party establishment.

            </p>
            <p>
              As Rubio described her strategy, “I have been very successful in getting Republican women groups in
              particular to be supportive. Even though I am a Democrat and a very active Democrat, I have always
              cultivated relationships with Republican groups. These are groups who feel heavily marginalized and
              disenfranchised because of how Democratic the district is.”

            </p>
            <p>
              With the support of moderate Democrats and Republican voters, Rubio won the fall election 52.3% to 48.7%.

            </p>
            <p className={styles.pullQuote}>
              Something very unusual happened in these two elections: nearly everyone voted for Rubio in at least one of
              them.
            </p>
            <p>

              A heavily Democratic district will almost always elect a Democrat; the question is, which Democrat? About
              85% of elections in the United States have a dominant party, and these races follow a pattern. The
              factions
              choose their candidates, and the candidate from the dominant party wins. This election was unusual because
              California’s Top-2 system allowed two Democrats to advance to the general election, and the entire
              electorate had a say in who would represent them.
            </p>
            <p>

              Every election features countless issues, but Rubio’s moderation and outreach to voters in the opposition
              party was critical to her victory. Though Rubio treated the concerns of Republican voters with respect,
              she did not abandon her loyalty to democratic voters.
            </p>
            <p>

              Four years later, Rubio’s loyalty to the Democratic base in her district paid off when she faced a
              Republican, Vincent Tsai, in the general election. This time, Democratic voters backed Rubio, and she
              easily defeated Tsai 58.5% to 41.5%.
            </p>
            <p>
              The game theory of elections dictates that candidates serve only the voters who vote for them and not the
              voters who vote against them. Normally, that means that candidates serve their party and maybe a few
              moderates. Often, they only care about their primary voters. But something unusual happened in these two
              elections.
            </p>
            <p>
              First, Rubio needed the support of Republican voters to defeat a liberal Democrat. Four years later, Rubio
              needed the support of Democratic voters to defeat a Republican opponent. Nearly everyone voted for Rubio
              in at least one of these two elections. Rubio accomplished this by balancing the needs of the entire
              electorate, not just one part or the other.
            </p>
            <h3>

              Chad Mayes' Story
            </h3>
            <p>

              Chad Mayes’ story is even more interesting. Chad Mayes was the leader of the Republican party in
              California in 2017. On July 17 of that year, Mayes joined seven other Republicans to extend a
              controversial piece of environmental legislation. Republican hardliners began calling for Mayes’ ouster
              almost before the vote was cast, and Mayes resigned as the leader of the Republican party in the
              California Assembly on Aug. 24, 2017.
            </p>
            <p>
              In the next election, on June 15, 2018, Mayes faced two Republican challengers in the 2018 qualifier. He
              finished second to Deni Mazingo (D) but still qualified for the general election. Once in the general,
              Mayes comfortably defeated Mazingo 55.3% to 44.7% with Republican support.
            </p>
            <p className={styles.pullQuote}>
              Mayes and Rubio are better representatives of their voters because the voters chose them over partisan
              alternatives from either side. Unfortunately, they are the exception rather than the rule.
            </p>
            <p>
              Mayes left the Republican party in December 2019 and ran as an Independent in 2020. The March 3 Top-2
              primary was tight, but Mayes emerged with a slim plurality of 35.0% over Andrew Kotyuk (R) with 33.5% and
              Deni Mayes (D) at 31.5%.
            </p>
            <p>
              In the general election, Democratic voters overwhelmingly voted for Mayes, the former head of the
              California Republican party, to help him defeat Republican Andrew Kotyuk by 55.6% to 44.4%.
            </p>
            <p>
              Like Rubio, almost every voter voted for Mayes in one of the two elections. In 2018, Mayes won with
              Republican support. Then Mayes won with Democratic support against a Republican in 2020.
            </p>
            <p>
              Mayes and Rubio are better representatives of their voters because the voters chose them over partisan
              alternatives from either side. Unfortunately, they are the exception rather than the rule because breaking
              through the partisan lock on politics takes exceptional circumstances, even with California’s Top-2
              system. Rubio qualified for the general and then won her first election only because there was no
              Republican candidate. Mayes began his tenure as a Republican and was even the leader of the GOP in the
              California Assembly before he became an Independent, but Mayes was the only independent in the Assembly.

            </p>
            <p>
              Small groups of partisan primary voters decide most elections in America. The general election outcome is
              often a formality determined long before election day by the state's demographics or the way the district
              lines were drawn. These two elections are examples of what happens when the voters are allowed a choice
              between partisan orthodoxy and a candidate representing all of the voters.

            </p>
          </div>
        </Article>
    )
  }
}




















