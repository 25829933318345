import * as React from 'react';

import Navbar from '../navigation/Navbar'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {VotingRequirements} from "./VotingRequirements";
import {ToxicPolarization} from "./ToxicPolarization";
import {OtherVotingSystems} from "./OtherVotingSystems";
import ConsensusVoting from "./ConsensusVoting";
import ConsensusVotingStory from "../../../Introduction/ConsensusVotingStory";
import {Ties} from "./Ties";
import ElectionSimStory from "../../../ElectionSimulation/ElectionSimStory";
import IRV2DStory from "../../../IRVStory/IRV2DStory";
import {Top5} from "./Top5";
import {PartOne} from "./PartOne";
import {About} from "./About";
import {ConsensusHistory} from "./ConsensusHistory";
import SimulationTutorialStory from "../../../SimulationTutorial/SimulationTutorialStory";
import FrontPage from "../content/FrontPage/FrontPage";
import FirstPlaceFallacyStory from "../../../FirstPlaceFallacy/FirstPlaceFallacyStory";
import {csv, json} from 'd3-fetch'
import {DSVRowArray} from "d3";
import ReactGA from "react-ga"
import {FiftyOne} from "./FiftyOne";
import {Overview} from "./Overview";
import {OldOverview} from "./OldOverview";
import IS2 from "../../../Introduction/IS2";
import RepresentationStory from '../../../Introduction/RepresentationStory';
import {Madison} from "./Madison";
import {Gerrymander} from "../../../GerrymanderStory/GerrymanderStory";
import {Leviathan} from "./Leviathan";
import {Democracy} from "./Democracy";
import {Compromise} from "./Compromise";
import {Factionalism} from "./Factionalism";
import {GameTheory} from "./GameTheory";
import {Tribalism} from "./Tribalism";
import {Global} from "./Global";
import {Perfect} from "./Perfect";
import {DefiningReality} from "./DefiningReality";
import {Top2Hope} from "./Top2Hope";
import {ProportionalFactionalism} from "./ProportionalFactionalism";
import {RestoringDemocracy} from "./RestoringDemocracy";
import {Conclusions} from "./Conclusions";
import ConsensusVotingStoryOld from "../../../Introduction/ConsensusVotingStoryOld";
import LeviathanMathStory from "../../../Introduction/LeviathanMathStory";
import {ShapingPolitics} from "./ShapingPolitics";
import {ProportionalRepresentation} from "./ProportionalRepresentation";
import {Cardinal} from "./Cardinal";
import {ASimpleArrow} from "./ASimpleArrow";
import FosteringCompromiseStory from "../../../Introduction/FosteringCompromiseStory";
import Footer from "../content/Footer/Footer";
import {CaliforniaTop2} from "./CaliforniaTop2";
import ConsensusVotingStory3 from "../../../Introduction/ConsensusVotingStory3";
import RepresentativeCongressStory from "../../../RepresentativeCongressImages/RepresentativeCongressStory";

// import createBrowserHistory from 'history/createBrowserHistory';


export interface AppMetadata {
  dvr: DSVRowArray
  DWNominate: DSVRowArray
  simulationResults: DSVRowArray
  sampleCongress: DSVRowArray
  usTopo: any
}

class App extends React.Component<any, any> {
  metaData?: AppMetadata
  initialized: boolean = false


  constructor(props: {}) {
    super(props)
    Promise.all([
          csv('data-5vPn3.csv'),
          csv('HS_all_members.csv'),
          csv('simulationResults.csv'),
          csv('sampleCongress.csv'),
          json('us2018.topo.json')
        ]
    ).then(([dvr, DWNominate, simulationResults, sampleCongress, usTopo]) => {
      if (dvr && DWNominate && simulationResults && sampleCongress && usTopo) {
        this.metaData = {
          dvr,
          DWNominate,
          simulationResults,
          sampleCongress,
          usTopo,
        }
        console.log("metaData has been loaded")
        this.initialized = true
        this.setState(this.metaData)
      }
    })
  }

  componentDidMount = () => {
    console.log("calling ReactGA.initialize with UA-222111254-1")
    ReactGA.initialize("UA-222111254-1")
  }

  render = () => {
    console.log(`calling ReactGA.pageView with ${window.location.pathname} ${window.location.search}`)
    ReactGA.pageview(window.location.pathname + window.location.search)
    return (
        <div className="App">
          <Navbar
              link={"/"}
          />
          <Router>
            <Switch>

              <Route path={"/FirstPlaceFallacy"}>
                <FirstPlaceFallacyStory section={0} progress={0}/>
              </Route>

              <Route path={"/ProportionalRepresentation"}>
                <ProportionalRepresentation section={0} progress={0}/>
              </Route>

              <Route path={"/Cardinal"}>
                <Cardinal section={0} progress={0}/>
              </Route>

              <Route path={"/ASimpleArrow"}>
                <ASimpleArrow section={0} progress={0}/>
              </Route>

              <Route path="/ConsensusVoting">
                <ConsensusVotingStory3 section={0} progress={0}/>
              </Route>

              <Route path="/ConsensusVoting2">
                <ConsensusVotingStory section={0} progress={0}/>
              </Route>

              <Route path="/LeviathanMath">
                <LeviathanMathStory section={0} progress={0}/>
              </Route>
              <Route path="/ConsensusVotingOld">
                <ConsensusVotingStoryOld section={0} progress={0}/>
              </Route>

              <Route path="/Representation">
                <RepresentationStory section={0} progress={0}/>
              </Route>

              <Route path="/IS2">
                <IS2 section={0} progress={0}/>
              </Route>
              <Route path="/IRV">
                <IRV2DStory section={0} progress={0}/>
              </Route>

              <Route path="/Ties">
                <Ties/>
              </Route>

              <Route path="/Top5">
                <Top5/>
              </Route>

              <Route path={"/RepresentativeCongressImages"}>
                    <RepresentativeCongressStory/>
              </Route>

              <Route path={"/WhyVotingReform"}>
                {this.initialized ? (
                    <PartOne
                        metaData={this.metaData!}
                    />
                ) : null
                }
              </Route>

              <Route path={"/Overview"}>
                {this.initialized ? (
                    <Overview
                        metaData={this.metaData!}
                    />
                ) : null
                }
              </Route>

              <Route path={"/OldOverview"}>
                {this.initialized ? (
                    <OldOverview
                        metaData={this.metaData!}
                    />
                ) : null
                }
              </Route>

              <Route path={"/FiftyOne"}>
                {this.initialized ? (
                    <FiftyOne
                        metaData={this.metaData!}
                    />
                ) : null
                }
              </Route>

              <Route path="/ElectionSim">
                <ElectionSimStory/>
              </Route>

              <Route path="/Leviathan">
                {this.initialized ? (
                    <Leviathan metaData={this.metaData!}/>
                ) : null
                }
              </Route>

              <Route path="/Democracy">
                {this.initialized ? (
                    <Democracy metaData={this.metaData!}/>
                ) : null
                }
              </Route>

              <Route path="/Compromise">
                {this.initialized ? (
                    <Compromise metaData={this.metaData!}/>
                ) : null
                }
              </Route>

              <Route path="/GameTheory">
                {
                  this.initialized ? (
                      <GameTheory metaData={this.metaData!}/>
                  ) : null
                }
              </Route>

              <Route path="/DefiningReality">
                {
                  this.initialized ? (
                      <DefiningReality metaData={this.metaData!}/>
                  ) : null
                }
              </Route>

              <Route path="/ShapingPolitics">
                {
                  this.initialized ? (
                      <ShapingPolitics metaData={this.metaData!}/>
                  ) : null
                }
              </Route>

              <Route path="/Tribalism">
                {
                  this.initialized ? (
                      <Tribalism metaData={this.metaData!}/>
                  ) : null
                }
              </Route>

              <Route path="/Conclusions">
                {
                  this.initialized ? (
                      <Conclusions metaData={this.metaData!}/>
                  ) : null
                }
              </Route>

              <Route path="/Top2Hope">
                {
                  this.initialized ? (
                      <Top2Hope metaData={this.metaData!}/>
                  ) : null
                }
              </Route>

              <Route path="/ProportionalFactionalism">
                {
                  this.initialized ? (
                      <ProportionalFactionalism metaData={this.metaData!}/>
                  ) : null
                }
              </Route>

              <Route path="/RestoringDemocracy">
                {
                  this.initialized ? (
                      <RestoringDemocracy metaData={this.metaData!}/>
                  ) : null
                }
              </Route>

              <Route path="/Perfect">
                {this.initialized ? (
                    <Perfect metaData={this.metaData!}/>
                ) : null
                }
              </Route>

              <Route path="/Global">
                {this.initialized ? (
                    <Global metaData={this.metaData!}/>
                ) : null
                }
              </Route>

              <Route path="/Factionalism">
                {this.initialized ? (
                    <Factionalism metaData={this.metaData!}/>
                ) : null
                }
              </Route>

              <Route path="/CaliforniaTop2">
                {this.initialized ? (
                    <CaliforniaTop2 metaData={this.metaData!}/>
                ) : null
                }
              </Route>

              <Route path="/Madison">
                {this.initialized ? (
                    <Madison metaData={this.metaData!}/>
                ) : null
                }
              </Route>

              <Route path="/Gerrymandering">
                {this.initialized ? (
                    <Gerrymander metaData={this.metaData!}/>
                ) : null
                }
              </Route>

              <Route path={"/ToxicPolarization"}>
                <ToxicPolarization/>
              </Route>

              <Route path={"/SimulationTutorial"}>
                <SimulationTutorialStory section={0} progress={0}/>
              </Route>

              <Route path={"/PrinciplesOfReform"}>
                <VotingRequirements/>
              </Route>

              <Route path="/ConsensusVoting">
                <ConsensusVoting/>
              </Route>

              <Route path="/FosteringCompromise">
                <FosteringCompromiseStory/>
              </Route>

              <Route path="/ConsensusDynamics">
                <ConsensusVoting/>
              </Route>

              <Route path="/ConsensusHistory">
                <ConsensusHistory/>
              </Route>

              <Route path="/OtherVotingSystems">
                <OtherVotingSystems/>
              </Route>

              <Route path={"/About"}>
                <About/>
              </Route>

              <Route path="/">
                <FrontPage/>
              </Route>
            </Switch>
          </Router>
          <Footer/>
        </div>
    );
  }
}

export default App;
