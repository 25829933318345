import React from "react";
import styles from "./Step.module.scss"

interface Props {
  title?: string
  className?: string
  children?: JSX.Element | JSX.Element[]
  first?: boolean
  end?: boolean
  id?: string
  next?: string
}


const Step = (props: Props) => {
  let firstStep = props.first ? styles.firstStep : ""
  let title = props.title ?
      (
          <div style = {{
            borderBottom: "1px solid black",
            marginBottom: "20px",
          }
          }>
            <h1>{props.title}</h1>
          </div>
      ): ""

  return (
      <div className={`${styles.step} ${firstStep} step ${props.className}`}>
        <div className={`${styles.stepInner} ${props.className}`}>
          {title}
          {props.children}
        </div>
      </div>
  )
}

export default Step