import Trajectory from "./Trajectory";
import Point from "./Point";
import {random} from "../../core/Random";

class NormalSequencer extends Trajectory {
  offset: number
  variation: number
  trajectory: Trajectory
  random = random
  duration: number

  constructor(trajectory: Trajectory, offset: number, variation: number, duration: number = 3000) {
    super();
    this.trajectory = trajectory
    this.offset = offset
    this.variation = variation
    this.duration = duration
  }

  path = (p0: Point, p1: Point): (t: number) => Point => {

    let d = this.random.normal() * this.variation + this.offset
    while (d > 1 || d < 0)
      d = this.random.normal() * this.variation + this.offset

    let originalPath = this.trajectory.path(p0, p1)
    return (t: number): Point => {
      return originalPath(Math.max(t - d, 0))
    }
  }
}

export default NormalSequencer