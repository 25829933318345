import Layout from "./Layout";
import * as d3 from "d3"
import Bubble from "./Bubble";
import Trajectory from "./Trajectory";

class LayoutByScale extends Layout {
  extractor: <T extends Bubble> (b: T) => number
  route: <T extends Bubble> (b: T) => Trajectory
  scale: d3.ScaleLinear<number, number>
  yFloor: number

  constructor(extractor: <T extends Bubble> (b: T) => number,
              route: <T extends Bubble>(b: T) => Trajectory,
              scale: d3.ScaleLinear<number, number>, yFloor: number) {
    super();
    this.extractor = extractor
    this.route = route
    this.scale = scale
    this.yFloor = yFloor
  }

  reset = () => {}

  setTargetLocation<T extends Bubble>(b: T) {
    let v = this.extractor(b)
    b.tx = this.scale(v)
    b.ty = this.yFloor
  }
}

export default LayoutByScale