import {Party} from "../../core/Party";
import Bubble from "./Bubble";
import {Ballot} from "../../core/Ballot";
import Member from "../../core/Member";

class MemberBubble extends Bubble {
  radius: number
  party: Party
  ideology: number
  candidateIndex: number = -1
  ballot!: Ballot
  irvRound = 0
  name: string
  body: string
  member: Member


  constructor(member: Member, radius: number) {
    super()
    this.radius = radius
    this.color =  "darkgray"
    this.member = member
    this.party = member.party
    this.ideology = member.ideology
    this.name = member.name
    this.body = member.body
  }
  ivec = (): number[] => {
    return [this.member.ideology, 0]
  }
}

export default MemberBubble