import React from 'react'
import styles from './FrontPage.module.scss'
import Title from "../../App/Title";
import HorizontalRule from "../../layout/HorizontalRule/HorizontalRule";
import DualPane from "../../layout/DualPane/DualPane";
import VotingReform101 from "../VotingReform101/VotingReform101";
import ConsensusTopics from "../IRVTopics/ConsensusTopics";
import MainArticle from "../../layout/MainArticle/MainArticle";
import MoreStuffRight from "../VotingReform101/MoreStuffRight";
import MoreStuffLeft from "../VotingReform101/MoreStuffLeft";

interface Props {

}

const FrontPage = (props: Props) => {
  return (
      <div className={styles.content}>
        <Title/>
        <HorizontalRule/>
        <MainArticle
            title={"A Mathematical Leviathan"}
            caption={"Society is shaped by an unseen, untamed, and poorly understood monster."}
            details={
                "The unforgiving mathematics of elections rewards and punishes different behavior. " +
                "We often blame political leaders for playing the game of politics to win. " +
                "Instead, we should blame the rules. "
            }
            link={"/Leviathan"}
            image1Url={"images/house.png"}
        />
        <HorizontalRule/>
        <DualPane
            leftHandSide={<VotingReform101/>}
            rightHandSide={<ConsensusTopics/>}
        />
        <HorizontalRule/>
        <DualPane
            leftHandSide={<MoreStuffLeft/>}
            rightHandSide={<MoreStuffRight/>}
        />
      </div>
  )
}
export default FrontPage