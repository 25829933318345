import {Party} from "../../core/Party"

abstract class Bubble {
  x = -50
  y = -50
  tx = 0
  ty = 0
  cx = 0
  cy = 0
  opacity = .9
  color = 'violet'
  abstract party: Party
  abstract ideology: number
  abstract ivec: () => number[]
}

export default Bubble