import Layout from "./Layout";
import Random from "../../core/Random";
import Bubble from "./Bubble";
import {directRoute} from "./LayoutAnimator";
import Trajectory from "./Trajectory";

class LayoutByIdeology2D extends Layout {
  xScale: d3.ScaleLinear<number, number, any>
  yScale: d3.ScaleLinear<number, number, any>
  rand = new Random()
  route: <T extends Bubble>(b: T) => Trajectory

  constructor(
      xScale: d3.ScaleLinear<number, number, any>,
      yScale: d3.ScaleLinear<number, number, any>,
      route: <T extends Bubble>(b: T) => Trajectory = directRoute,
      duration = 3000) {
    super();
    this.duration = duration
    this.xScale = xScale
    this.yScale = yScale
    this.route = route
  }

  reset = () => {
  }

  setTargetLocation<T extends Bubble>(b: T) {
    b.tx = this.xScale(b.ivec()[0])
    b.ty = this.yScale(b.ivec()[1])
  }
}

export default LayoutByIdeology2D
