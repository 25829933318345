import Controller from "./Controller";
import {LayoutAnimator} from "./LayoutAnimator";
import blockTimer from "../../core/BlockTimer";

abstract class AnimatedController extends Controller {
  abstract renderFrame: (pctComplete: number) => void
  abstract animator: LayoutAnimator | undefined

  render = () => {
    blockTimer(() => {
      this._render()
    }, "AnimatedController.render()")
  }


  protected _render = () => {
    // console.log(`voters.render: ${this.dirty}`)
    if (!this.dirty) return
    this.axes.forEach(axis => axis.render())
    this.applyUpdate()
    this.applyColorAndOpacity()

    this.animator?.stop()
    this.animator = new LayoutAnimator(
        "voters",
        this.bubbles,
        this.layout,
        this.renderFrame
    )
    // this.dump10Voters("Before Animate Call")
    this.animator.animate()
    this.dirty = false
    // this.renderFrame(0)
    return
  }
}

export default AnimatedController