import {Candidate2D} from "../ui/elements/Candidates2D";
import {DSVRowArray} from "d3";
import Bubble from "../ui/elements/Bubble";
import SVGButton from "../ui/elements/SVGButton";
import {ConsensusVotingApp} from "./ConsensusVotingApp";

// [Log] candidate Anne 0.070, 0.189
// [Log] candidate Sue -0.351, -0.137
// [Log] candidate Maria 0.550, 0.300
// [Log] candidate Juan 0.750, 0.750
// [Log] candidate Bob -0.700, -0.950

export class RepresentationApp extends ConsensusVotingApp {
  actualButton?: SVGButton
  primaryButton?: SVGButton
  consensusButton?: SVGButton
  lean = .0

  constructor(visDivId: string,
              dvr: DSVRowArray,
              DWNominate: DSVRowArray,
              simulationResults: DSVRowArray,
              sampleCongress: DSVRowArray,
              usTopo: any,
              nVoters: number,
              radius: number) {
    super(visDivId, dvr, DWNominate, simulationResults, sampleCongress, usTopo, nVoters, radius)
    this.candidates2D.setColor((b: Bubble) => (b as Candidate2D).candidateColor, "candidateColor")
  }


  newSectionMethods = [
    this.showGeneralOutcome,
    this.showAlternateOutcome,
    this.showAllVoterOutcomes,
    this.showActualHouse,
  ]

  progressMethods = [
    this.progressBasic, // 0
  ]

}