import React from 'react'
import styles from './TopicHeader.module.scss'

interface Props {
  title: string
  description: string
  link: string
}

const TopicHeader = (props: Props) => {
  return (
    <div className={styles.mainBox}>
    <a href={props.link}>
      <div className={styles.content}>
        <p className={styles.title}>{props.title}</p>
        <p className={styles.description}>{props.description}</p>
      </div>
    </a>
    </div>
  )
}

export default TopicHeader