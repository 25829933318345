import React from 'react';
import {DSVRowArray} from "d3";
import styles from "./ConsensusVoting.module.scss"
import Step from "../ui/components/Step";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
import ElectionSimStoryBase from "../ElectionSimStoryBase/ElectionSimStoryBase";
import {LeviathanMathApp} from "./LeviathanMathApp";
import ResultsBox from "../ElectionSimulation/ResultsBox";

class LeviathanMathStory extends ElectionSimStoryBase {

  mapAreas = [
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",

    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",

    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
  ]
  visualizationAreas = [
    "drawingArea1", // 0
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",

    "drawingArea1", //5
    "drawingArea1",
    "drawingArea2",
    "drawingArea3",
    "drawingArea4",

    "drawingArea5", //10
    "drawingArea5",
    "drawingArea5",
    "drawingArea5",
    "drawingArea5",

  ]

  createApp = (
      _visDivId: string,
      dvr: DSVRowArray,
      DWNominate: DSVRowArray,
      simulationResults: DSVRowArray,
      sampleCongress: DSVRowArray,
      usTopo: any): ElectionSimApp => {
    return new LeviathanMathApp(this.visualizationAreas[0], dvr, DWNominate, simulationResults, sampleCongress, usTopo, 5001, 4)
  }
  simState = () => {
    // console.log("simState this.state:  ", this.state)
    if (this.state.simulationState)
      return this.state.simulationState
    else
      return this.state
  }

  render() {
    let mdash = "\u2014"
    return (
        <div className={styles.content}>
          <div>
            {this.renderVisArea(styles.drawing1, "drawingArea1")}

            <div className={styles.spacerDiv} />
            { /* 0 */}
            <Step className={styles.stepElement} first={true}>
              <h3>The Math of the Leviathan</h3>
              <p>
                The mathematics of elections may seem daunting, but the basic concepts are common sense ideas that we’re
                all familiar with. I’m going to work through a hypothetical example to provide a framework for these
                concepts and how the mathematics of elections shapes our politics and our society.
              </p>
              <p>
                Elections for the United States House of Representatives are a great test case for an election system;
                there are 435 districts that span almost every conceivable election scenario we face in the United
                States. It’s important to get the House right and if an election system can do that, it can
                probably work well almost everywhere.
              </p>
            </Step>

            {/* 1 */}
            <Step className={styles.stepElement}>
              <h3>
                The Voters
              </h3>
              <p>
                These dots represent the voters in a balanced district. They are arranged according to the ideological
                tendencies of each voter. The axes here represent the ideology of the voters, with each voters’ position
                on the x-axis corresponding to their views on fiscal policy, from liberal on the left to conservative on
                the right. Similarly, the voters’ y-positions correspond to their views on social issues from liberal at
                the bottom to conservative at the top.
              </p>
              <p>
                No political party is a monolith. There are moderate and even liberal Republicans as well as
                moderate and conservative Democrats. Independents are diverse and not necessarily centrists, though
                they may be unlikely to vote for a candidate at odds with their views.
              </p>
            </Step>

            { /* 2 */}
            <Step className={styles.stepElement}>
              <h3>A Partisan Lean</h3>
              <p>
                For this example, the voters have been shifted 10 points more conservative on both fiscal and social
                issues. House seats have all been gerrymandered to some degree, and a 10-point margin is common because
                it’s enough of an advantage to be considered safe without “wasting” votes the majority could use to win
                another district. I talk about gerrymandering more in this article.
              </p>
              <p>
                The voters are colored for their liberal-conservative position: blue in the lower left and red in the
                upper right.
              </p>
            </Step>

            { /* 3 */}
            <Step className={styles.stepElement}>
              <h3>The Candidates</h3>
              <p>
                Our hypothetical election has three serious candidates Ryan, Rebecca, and Diane. They are represented
                as purple, green, and orange circles respectively in the visual. There are two Republican candidates
                vying for a U.S. House seat that favors a Republican candidate. Diane is a Democrat who believes she
                can win this open House seat despite the Republican tilt to the district.
              </p>
              <p>
                Quality candidates and the money to run a competitive campaign are both related to opportunity. If a
                candidate’s political views don’t give them a good chance to win, they have trouble raising money,
                getting media attention, or attracting quality campaign staff. High-quality candidates may also be
                reluctant to contest an election they don’t think they can win.
              </p>
              <p>
                Because I’m trying to explore what ideologies have an advantage, I’m assuming all of the candidates are
                at about the same level of quality and money. The candidates who win under these circumstances would be
                even more likely to win in actual elections because they enjoy the benefits of being viable.
              </p>
            </Step>

            { /* 4 */}
            <Step className={styles.stepElement}>
              <h3>Voter Preferences</h3>
              <ResultsBox
                  title={"Plurality Results"}
                  results={this.sectionResults("voterPreferences")?.pluralityResults}
              />
              <p>
                For candidates of approximately equal quality – experience, campaigning skills, money – voters tend to
                prefer the candidate who shares their values more closely. This method of modeling voter behavior is
                called a spatial model of voter preference and election scientists use this to understand how voting
                rules affect outcomes.
              </p>
            </Step>

            { /* 5 */}
            <Step className={styles.stepElement}>
              <h3>Uncertainty</h3>
              <ResultsBox
                  title={"Plurality Results"}
                  results={this.sectionResults("uncertainty")?.pluralityResults}
              />
              <p>
                In reality, each voter consumes different news media, sees different ads, is influenced by their peers
                in different ways, and has a different opinion of the quality of each candidate. The sharp divisions
                with every voter choosing one candidate or another depending on which side of the line they stand on are
                not realistic. Instead, there is uncertainty about how an individual voter will choose. Uncertainty is a
                part of elections and is used in all simulations but it makes interpreting the visualizations more
                difficult. The remaining visualizations show the preferences without uncertainty and can be interpreted
                as showing which candidate voters are most likely to choose.
              </p>
            </Step>

            { /* 6 */}
            <Step className={styles.stepElement}>
              <h3>
                Primaries
              </h3>
              <ResultsBox
                  title={"Election With Primary Results"}
                  results={this.sectionResults("primaries")?.primaryResults}
                  caption={"Select an election to show result."}
              />
              <p>
                You may have noticed that the Democratic candidate was leading in these visualizations of an election in
                a Republican-leaning district. That’s because this election has been presented as a plurality without
                primaries.
              </p>
              <p>
                In order to avoid “splitting the vote,” parties choose a single candidate to represent them.
                This was first done in party caucuses but those were abandoned in most states after being criticized as
                undemocratic and potentially corrupt – choosing candidates in the proverbial smoke-filled room. Now,
                parties use a primary system to choose a candidate in most states.
              </p>

              <p>
                Though primaries solve one problem, they create another. The political party of the winner for most
                elections in the United States is never in doubt, that was determined when the district lines were
                drawn or by the permanent gerrymander of the state. The primary is the only election that matters.
              </p>

              <p>
                This crucial decision determines the makeup of over 88% of U.S. House seats and 70% of Senate seats and
                it is made solely by the members of one faction or another. Even when the party of the winner isn't
                predetermined, the general election voters can only choose between candidates approved by one faction
                or the other.
              </p>

              <p>
                This forces most candidates to be little more than avatars of their faction. If primary voters reward
                divisive behavior {mdash} they often do {mdash} then candidates who don't show sufficient loyalty will
                lose to candidates who do. Of course, any hint of accommodation with the voters in the minority party
                that they supposedly represent will be punished by primary voters.
              </p>

            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing2, "drawingArea2")}
            { /* 7 */}
            <Step first={true} className={styles.stepElement}>
              <h3>
                A Factional Democracy
              </h3>
              <p>
                Repeat the primary process for all 435 districts in the result is the divided and unrepresentative
                democracy we have today. The winners are divided into their factions at either end of the political
                spectrum with no one in the political middle.
              </p>
              <p>
                These candidates don't even represent our values. The map on the right shows each district colored by
                the <a href={"/RepresentationScore"}> representation score</a>. The blue and red circles represent
                the members of the U.S. House of Representatives arranged by their left-right ideology. The ideology
                is calculated by analyzing their voting records.
              </p>
              <p>
                When we elect polarizing candidates, we empower polarizing voices in the media, and that
                has an effect on the voters opinions about members of the other party. This creates a cycle of fear,
                anger, and resentment that divides friends and families and pulls voters farther and farther apart.
              </p>

            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing3, "drawingArea3")}
            { /* 7 */}
            <Step first={true} className={styles.stepElement}>
              <h3>
                The Toxicity of Factionalism
              </h3>
              <p>
                When elections reward candidates who otherize voters in the other party, they divide our nation and
                damage our democracy. As the parties have sorted themselves into progressively more extreme liberal
                vs. conservative camps, they have completely abandoned the center.
              </p>
              <p>
                When we elect polarizing candidates, we empower polarizing voices in the media, and that
                has an effect on the voters opinions about members of the other party. This creates a cycle of fear,
                anger, and resentment that divides friends and families and pulls voters farther and farther apart.
              </p>
            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing4, "drawingArea4")}
            { /* 8 */}
            <Step first={true} className={styles.stepElement}>
              <h3>
                The Leviathan
              </h3>
              <p>
                This process of factionalization isn’t some theoretical construct, it’s happening today. These images
                from the PEW Research Center show the corrosive effect of our flawed election system on the people.
                Every time our election system rewards divisive candidates it legitimizes their extreme viewpoints and
                further divides our nation. This process of otherization fuels authoritarian forces that insist that
                authoritarianism is justified to prevent the other side from obtaining power.
              </p>
              <p>
                How many more cycles will it take before one side or the other demands that their leaders undermine
                democracy to prevent the other side from destroying America? If this sounds far-fetched to you, it’s
                not. An NBC poll showed that 80% of Americans in each party think the other party will destroy America
                as we know it.
              </p>
              <p>
                This is the Leviathan that stalks every democracy. The Leviathan is the intersection of human ambition
                and mathematics, but math is impersonal. The Leviathan fosters factionalism because the rules of
                elections
                make reward polarizing candidates, not because the Leviathan wants to destroy our society, and there
                lies hope. Different rules would create different math and different outcomes for our politics and
                society.
              </p>
            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing5, "drawingArea5")}
            { /* 9 */}
            <Step first={true} className={styles.stepElement}>
              <h3>
                Majority Rule
              </h3>
              <ResultsBox
                  title={"Rebecca vs Ryan"}
                  results={this.sectionResults("majorityRule")?.pluralityResults}
                  caption={"A one-on-one comparison between Anne and Juan."}
              />
              <p>
                At first glance, it seems that Rebecca won the election and therefore the voters wanted this polarizing
                outcome, but that's not the case. If we look at a one-on-one comparison between Rebecca and Ryan,
                the voters preferred Ryan to Rebecca by almost two to one. The voters didn't want this polarized
                outcome. Instead, the voters wanted a more moderate outcome but the voting system overrode their wishes
                by eliminating the more representative Ryan in the Republican primary.
              </p>
              <p>
                Overriding the voters is at the heart of the problems with our democracy. Rebecca's general election
                victory over Diane gives her a veneer of legitimacy, but doesn't change the fact that the voters
                preferred a less partisan candidate.
              </p>
              <p>
                A district that favors Republicans will almost always elect a Republican. But, the question is which
                Republican? And who makes that decision? If that decision is made in a primary by Republican-leaning
                voters only, that eliminates more representative candidates and creates a factional democracy where the
                candidates we elect answer only to whichever party dominates the district. Meanwhile, the minority in
                each district is frozen out of meaningful participation in the democratic process and is vulnerable to
                being denigrated, otherized, and abused.
              </p>
              <p>
                You can elect almost any candidate by controlling the choices. Russia, China, and other authoritarian
                regimes around the world engineer the outcome they want by manipulating the choices available to their
                voters. In the United States an oligarchy of extremism exploits a flawed election process to limit
                voters' choices to an avatar of one faction or another. Instead of following majority rule and allowing
                voters to elect the candidates they want, our system is more like "factional rule" and that creates our
                factional democracy with all of its problems.
              </p>
              <p>
                We regularly dismiss democracies where the state controls the choices as being sham democracies. But our
                choices are controlled also. How much does it matter whether our choices are controlled by the state or
                by factional interests? Our election process elects one candidate when the voters clearly preferred
                another. How can any election process that overrides the will of the people claim legitimacy? Is that
                democracy?
              </p>
              <p>
                Regardless of whether an election system that overrides the voters' wishes qualifies as a democracy,
                the result is toxic divisiveness that will eventually extinguish whatever vestiges of democracy we have
                now.
              </p>
              <p>
                The good news is that polarization isn't inherent in democracy and it doesn't come from some deep
                flaw within us. Instead, polarization is created by a flawed election system. And that can be fixed.
              </p>
            </Step>
            <div style={{
              minHeight: "30vh"
            }}>
            </div>
          </div>
        </div>
    )
  }
}

export default LeviathanMathStory
