import {RenderItem} from "./RenderItem";

export class TextItem extends RenderItem {
  text: string
  id: string
  parentSVG: d3.Selection<any, any, any, any>
  textColor: string
  fontSize: string
  textAnchor: string
  x: number
  y: number

  constructor(text: string,
              id: string,
              parentSVG: d3.Selection<any, any, any, any>,
              x: number,
              y: number,
              textColor: string,
              textAnchor: string,
              fontSize: string) {
    super()

    this.id = id
    this.text = text
    this.parentSVG = parentSVG
    this.x = x
    this.y = y
    this.textColor = textColor
    this.textAnchor = textAnchor
    this.fontSize = fontSize
  }

  clear = () => {
    this.parentSVG.selectAll(`#${this.id}`).remove()
  }

  render = (): void => {
    this.clear()

    this.parentSVG.append("text")
        .attr("id", this.id)
        .attr("x", this.x)
        .attr("y", this.y)
        .style("fill", this.textColor)
        .style("text-anchor", this.textAnchor)
        .style("alignment-baseline", "middle")
        .style("cursor", "default")
        .style("font-size", this.fontSize)
        .text(this.text)
  }
}