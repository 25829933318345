import CandidateBubble from "./CandidateBubble";
import * as d3Drag from 'd3-drag'
import * as d3 from "d3";
import Controller from "./Controller";
import RangesAndGradients from "./RangesAndGradients";
import Bubble from "./Bubble";
import Candidate from "../../core/Candidate";

class CandidateController extends Controller {
  width = 15
  nameAreaHeight = 15
  height = 2 * this.width + this.nameAreaHeight
  bubbles: Array<CandidateBubble>
  rangesAndGradients = new RangesAndGradients()
  controllerName = "candidates"


  constructor() {
    super()
    this.bubbles = []
  }

  noUpdate = (_cb: CandidateBubble) => {
  }
  onUpdate: (cb: CandidateBubble) => void = this.noUpdate

  createDragBehavior = () => {
    let obj = this
    return d3Drag.drag<SVGGElement, CandidateBubble>()
        .on("drag", function (this: SVGGElement, e: DragEvent, c: CandidateBubble) {
          obj.drag(this, e, c)
        })
  }

  dragBehavior = this.createDragBehavior()


  drag = (g: SVGGElement, e: DragEvent, c: CandidateBubble): void => {
    // @ts-ignore
    c.cx = c.cx + e.dx
    d3.select<SVGGElement, CandidateBubble>(g)
        // .attr("transform", (c: CandidateBubble) => `translate(700,100)`)
        .attr("transform", (c: CandidateBubble) => `translate(${c.cx}, ${c.cy})`)
    let ideology = this.rangesAndGradients.ideologyScale.invert(c.cx)
    c.candidate.ivec[0] = ideology
    c.candidate.ideology = ideology
    c.ideology = ideology
    this.onUpdate(c)
  }

  candidateBubbles = (): Array<CandidateBubble> => {
    return this.bubbles as Array<CandidateBubble>
  }
  candidates = (): Array<Candidate> => {
    return (this.bubbles as Array<CandidateBubble>).map(cb => cb.candidate)
  }

  clearCandidates = (svg: d3.Selection<any, any, any, any>) => {
    svg.selectAll(".candidate-g").remove()
    this.onUpdate = this.noUpdate
  }

  render = (svg: d3.Selection<any, any, any, any>, progress: number): void => {
    if (!this.dirty) return
    this.dirty = false

    progress = Math.min(1, 1.5 * progress)
    let path = "M69.75766,45.999L50.50034,34.39465V30.61041a8.667,8.667,0,1,0-1,0V34.395L30.24253,45.999a0.49984,0.49984,0,1,0,.51563.85645l18.74219-11.2934V55.63525a0.47837,0.47837,0,0,0,.01874.0929L40.85239,86.06006a0.5,0.5,0,0,0,.34375.61816,0.47877,0.47877,0,0,0,.1377.01953,0.50009,0.50009,0,0,0,.48047-0.3623l8.186-28.64972,8.186,28.64972a0.49923,0.49923,0,0,0,.48047.3623,0.48356,0.48356,0,0,0,.1377-0.01953,0.499,0.499,0,0,0,.34277-0.61816L50.4816,55.72815a0.47837,0.47837,0,0,0,.01874-0.0929V35.56238L69.242,46.85547a0.495,0.495,0,0,0,.25781.07178A0.4997,0.4997,0,0,0,69.75766,45.999ZM42.33335,21.96875a7.66675,7.66675,0,1,1,7.667,7.667A7.67533,7.67533,0,0,1,42.33335,21.96875Z"

    this.axes.forEach(axis => axis.render())
    this.applyLayout()
    this.applyColorAndOpacity()
    this.applyUpdate()


    let u = svg.selectAll(".candidate-g").data(this.bubbles)


    let enteringG = u.enter()
        .append("g")
        .classed("candidate-g", true)
        .call(this.dragBehavior)

    enteringG.append("rect")
        .classed("candidate", true)
        .attr("width", (c: CandidateBubble) => c.width)
        .attr("height", (c) => c.height())
        .style("fill", c => c.color)
        .attr("transform", c => `translate(${c.xOffset()}, ${c.yOffset()})`)

    let pathDim = 100
    enteringG.append("path")
        .style("fill", "white")
        .style("stroke-width", 10)
        .attr("d", path)
        .attr("transform", (c) => `translate(${c.xStickOffset()}, ${c.yOffset() + 1}) scale(${c.width * 2 / pathDim})`)

    enteringG.append("text")
        .classed("candidate-name", true)
        .attr("id", c => c.name)
        .attr("x", 0)
        .attr("y", c => -c.lineWidth / 2 - c.nameAreaHeight - 3)
        .attr("dy", "1em")
        .text(c => c.name)
        .attr("font-size", "10pt")
        .attr("text-anchor", "middle")
        .attr("fill", "white")

    enteringG.merge(u as d3.Selection<any, any, any, any>)
        .transition()
        .ease(d3.easeLinear)
        .attr("transform", c => {
          this.layout.updateCxCy(c, progress)
          return `translate(${c.cx}, ${c.cy})`
        })
        .style("opacity", cb => cb.cy > 0 ? cb.opacity : 0)

    u.style("opacity", (b) => b.cy > 0 ? b.opacity : 0)

    u.filter(cb => cb.halo)
        .selectAll("rect")
        .style("stroke", "gold")
        .style("stroke-width", 5)
        .style("stroke-opacity", 1)
        .style("fill", ((c: Bubble) => c.color) as any)

    u.filter(cb => !cb.halo)
        .selectAll("rect")
        .style("stroke", "gold")
        .style("stroke-width", 0)
        .style("stroke-opacity", 0)
        .style("fill", ((c: Bubble) => c.color) as any)

    svg.selectAll<SVGGElement, CandidateBubble>(".candidate-g")
        .call(this.dragBehavior)

    u.exit().remove()
  }
}

export default CandidateController