import Point from "./Point";
import Trajectory from "./Trajectory";

class ParabolicTrajectory extends Trajectory {
  height: number
  constructor(height: number) {
    super()
    this.height = height
  }
  path = (p0: Point, p1: Point): (t: number) => Point => {
    if (p0.manhattenDistance(p1) < 2)
      return (t: number): Point  => { return p1 }

    let deltaX = p1.x - p0.x
    let deltaY = p1.y - p0.y
    let a = 4 * deltaY - 8 * this.height
    let v0 = 2 * this.height - a / 4

    let p = (t: number): Point => {
      if (t >= 1) return p1
      else {
        let x = p0.x + deltaX * t
        let y = p0.y + .5 * a * t * t + v0 * t
        return new Point(x, y)
      }
    }
    return p
  }
}
export default ParabolicTrajectory