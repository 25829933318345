import Article from "../content/Article/Article";
import styles from "./ConsensusHistory.module.scss";

export function ConsensusHistory() {
  return (
      <Article title={"History of Consensus Voting"}
               subtitle={"A new name for an old concept"}>
        <div className={styles.textContent}>


        </div>
      </Article>
  )
}