// import {DSVRowArray} from "d3";
import {Democrats, Republicans} from "../core/Party";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
import ImageController from "../ui/elements/ImageController";
// import imageController from "../ui/elements/ImageController";
import {Candidate2D} from "../ui/elements/Candidates2D";
import Candidate from "../core/Candidate";

export class FosteringCompromiseApp extends ElectionSimApp {

  // constructor(visDivId: string,
  //             dvr: DSVRowArray,
  //             DWNominate: DSVRowArray,
  //             simulationResults: DSVRowArray,
  //             sampleCongress: DSVRowArray,
  //             usTopo: any,
  //             nVoters: number,
  //             radius: number) {
  //   super(visDivId, dvr, DWNominate, simulationResults, sampleCongress, usTopo, nVoters, radius)
  //
  // }

  imageController = new ImageController(this.svg, "image1")

  createJuanM = () => new Candidate2D(new Candidate("Juan", Republicans, 0, 0, [.18, .15]), "cyan", 10)
  createAnneM = () => new Candidate2D(new Candidate("Anne", Republicans, 0, 0.01, [.06, .07]), "gold", 10)
  createMariaM = () => new Candidate2D(new Candidate("Maria", Republicans, 0, 0.005, [.06, .29]), "chartreuse", 10)
  createBobM = () => new Candidate2D(new Candidate("Bob", Democrats, 0, 0, [-.20, -.25]), "lightseagreen", 10)
  createSueM = () => new Candidate2D(new Candidate("Sue", Democrats, 0, 0.015, [-.30, -.04]), "violet", 10)


  createCandidatesM = () => {
    return [
      this.createJuanM(),
      this.createAnneM(),
      this.createMariaM(),
      this.createBobM(),
      this.createSueM(),
    ]
  }

  createJuanO = () => new Candidate2D(new Candidate("Juan Original", Republicans, 0, 0, [.75, .75]), "cyan", 10)

  qualityBonusIndex = 0
  cycleCandidateQuality = () => {
    let candidates = this.createCandidatesM()
    candidates[this.qualityBonusIndex % candidates.length].candidate.quality = .1
    this.qualityBonusIndex += 1
    this.h2hVoterSelect("", "", candidates)
    this.stopAllTimers() // h2hVoterSelect started a timer!
    this.voters.clearAxes()
    this.stopAllTimers()
    this.addTimer("cycleCandidateQuality", this.cycleCandidateQuality, 5000)
  }

  newSectionMethods = [
    // 0
    (_p: number): void => {
      this.setActiveSimulation("TwoCandidates")
      this.showGeneralOutcome()
    },

    // 1
    (_p: number): void => {
      this.setActiveSimulation("Opportunity")
      let candidates = [
        this.createJuan(),
        this.createBob(),
        this.createMaria()
      ]
      this.h2hVoterSelect("Maria", "Juan", candidates)
      this.voters.clearAxes()
    },

    // 2
    (_p: number): void => {
      this.setActiveSimulation("MoreOpportunity")
      let candidates = [
        this.createJuan(),
        this.createBob(),
        this.createMaria(),
        this.createAnne(),
        this.createSue()
      ]
      this.h2hVoterSelect("Anne", "Juan", candidates)
      this.voters.clearAxes()
    },

    // 3
    (_p: number): void => {
      this.setActiveSimulation("Reevaluation")
      this.setUncertainty(0)
      this.h2hVoterSelect("Anne", "Juan", this.createCandidatesM())
      this.voters.clearAxes()
    },
    // 4
    (_p: number): void => {
      this.setActiveSimulation("MoreReevaluation")
      this.setUncertainty(.1)
      this.onLeanUpdate(0)
      this.h2hVoterSelect("Anne", "Juan", this.createCandidatesM())
      this.voters.clearAxes()
    },

    // 5
    (_p: number): void => {
      this.setActiveSimulation("Understanding")
      this.setUncertainty(.1)
      this.onLeanUpdate(-.14)
      this.h2hVoterSelect("", "", this.createCandidatesM())
      this.voters.clearAxes()
    },
    // 6
    (_p: number): void => {
      this.setActiveSimulation("Quality")
      this.setUncertainty(.1)
      this.onLeanUpdate(.0)
      this.voters.clearAxes()
      this.timerCount = 4
      this.cycleCandidateQuality()
    },
    // 7
    (_p: number): void => {
      this.setActiveSimulation("Leadership")
      this.setUncertainty(.1)
      this.onLeanUpdate(.30)
      this.h2hVoterSelect("", "", this.createCandidatesM())
      this.voters.clearAxes()
    },
    // 8
    this.showConsensusHouse,
    // 9
    this.showConsensusHouse,
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
  ]

  progressMethods = [
    this.progressBasic, // 0
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,

    this.progressBasic, // 5
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,

    this.progressBasic, // 10
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,

    this.progressBasic, // 15
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,

  ]

}