import React from 'react';
import styles from './CandidateControls.module.scss'
import SliderWidget from "./SliderWidget";


interface Props {
  name: string
  party: string
  quality: number
  changeCallback: (name: string, party: string, quality: number) => void
}

class CandidateControls extends React.Component<any, any> {
  controlsOpen = false;
  changeCallback: (name: string, party: string, quality: number) => void

  constructor(props: Props) {
    super(props)
    console.log("SimulationControls.constructor: props = ", props)
    this.changeCallback = props.changeCallback
  }

  partyUpdate = () => {
    let s = document.getElementById("partyAffiliation")
    //@ts-ignore
    let party = s.value
    this.changeCallback(this.props.name, party, this.props.quality)
  }

  qualityUpdate = (quality: number) => {
    console.log(`new quality ${quality}`)
    this.changeCallback(this.props.name, this.props.party, quality)
  }


  render = () => {
    // console.log("\t\t\tCandidateControls.render: ", this.props)
    return (
        <div id='editCandidate' className={styles.editCandidate + " editCandidate"}>
          <table>
            <tbody>
            <tr>
              <td colSpan={2} className={styles.editCandidateName}>
                {this.props.name}
              </td>
            </tr>
            <tr>
              <td>
                <span className={styles.editInputLabel}>Party Affiliation:</span>
              </td>
              <td>
                <select onChange={this.partyUpdate} value={this.props.party} name={"partyAffiliation"}
                        id={"partyAffiliation"}>
                  <option value={"dem"}>Democratic</option>
                  <option value={"rep"}>Republican</option>
                  <option value={"ind"}>Independent</option>
                </select>
              </td>

            </tr>
            </tbody>
          </table>
          <SliderWidget
              id={"candidateQuality"}
              title={"Quality"}
              leftLabel={"Low"}
              rightLabel={"High"}
              min={-.4}
              max={.4}
              step={.01}
              value={this.props.quality}
              onChange={this.qualityUpdate}
          />
        </div>
    )
  }
}

export default CandidateControls