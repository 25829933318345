import {DSVRowArray} from "d3";
import Bubble from "../ui/elements/Bubble";
import VoterBubble from "../ui/elements/VoterBubble";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
import HistogramLayout from "../ui/elements/HistogramLayout";
import {parabolicRoute} from "../ui/elements/LayoutAnimator";
import OffscreenLayout from "../ui/elements/OffscreenLayout";
import DisplayRectangle from "../VoterRepresentation/DisplayRectangle";
import CandidateBubble from "../ui/elements/CandidateBubble";
import Candidate from "../core/Candidate";
import {Democrats, Republicans} from "../core/Party";

export class RepresentativeCongressApp extends ElectionSimApp {


  constructor(visDivId: string,
              dvr: DSVRowArray,
              DWNominate: DSVRowArray,
              simulationResults: DSVRowArray,
              sampleCongress: DSVRowArray,
              usTopo: any,
              nVoters: number,
              radius: number) {
    super(visDivId, dvr, DWNominate, simulationResults, sampleCongress, usTopo, nVoters, radius)


    this.clearMap()
  }


  newSectionMethods = [
    () => {
      this.clearMembers()
      this.clearVoters(0)
      this.voters1D.clearAxes()
      this.voters1D.setColor((b: Bubble) => (b as VoterBubble).party.color, "colorByParty")
      this.voters1D.setLayout(this.splitLayout())
      this.voters1D.addAxis(this.createNumericalIdeologyAxis(300, `IndependentAxis`, "Independent Voter Ideology in Standard Deviations"))
      this.voters1D.addAxis(this.createNumericalIdeologyAxis(500, `DemocraticAxis`, "Democratic-Leaning Voter Ideology in Standard Deviations"))
      this.voters1D.addAxis(this.createNumericalIdeologyAxis(700, `RepublicanAxis`, "Republican-leaning Voter Ideology in Standard Deviations"))
    },
    () => {
      let yValue = 900
      this.voters1D.clearAxes()
      this.voters1D.setColor((b: Bubble) => (b as VoterBubble).party.color, "colorByParty")
      this.voters1D.sortByParty()
      this.voters1D.setLayout(new HistogramLayout(this.bubbleIdeology, parabolicRoute, yValue, this.ranges.histogramScale, this.voters1D.radius - .5, 3000, true))
      this.voters1D.addAxis(this.createNumericalIdeologyAxis(yValue, `VoterAxis`, "Combined Voter Ideology in Standard Deviations"))
      this.clearMembers(0)
    },
    () => {
      this.clearVoters(0)
      this.voters1D.clearAxes()
      this.voters1D.setLayout(new OffscreenLayout())
      this.memberController.layoutByNominate(this.svg, 900, 3000)
      // this.memberController.clearAxes()
      // this.memberController.clearLabel()
      // this.memberController.addAxis(this.createNumericalIdeologyAxis(900, `MemberAxis`, "Actual U.S. House Members by DWNominate Dim1"))
    },

    () => {
      this.clearVoters(0)
      this.voters1D.clearAxes()
      this.voters1D.setLayout(new OffscreenLayout())
      this.clearMap()
      this.memberController.layoutBySampleIdeology(this.svg, "primary", 900, 2000, "United States House of Representatives Using Primaries")
      this.memberController.clearAxes()
      this.memberController.clearLabel()
      this.memberController.addAxis(this.createNumericalIdeologyAxis(900, `MemberAxis`, "Simulated U.S. House Members by Ideology (Primary/Plurality - PopulationSigma)"))
    },

    () => {
      this.clearVoters(0)
      this.voters1D.clearAxes()
      this.voters1D.setLayout(new OffscreenLayout())
      this.clearMap()
      this.memberController.layoutBySampleIdeology(this.svg, "instantRunoff", 900, 2000, "Simulated United States House of Representatives (Minimax - PopulationSigma)")
      this.memberController.clearAxes()
      this.memberController.clearLabel()
      this.memberController.addAxis(this.createNumericalIdeologyAxis(900, `MemberAxis`, "Simulated U.S. House Members by Ideology (populationSigma)"))

    },

    () => {
      this.clearVoters(0)
      this.voters1D.clearAxes()
      this.voters1D.setLayout(new OffscreenLayout())
      this.clearMap()
      this.memberController.layoutBySampleIdeology(this.svg, "headToHead", 900, 2000, "Simulated United States House of Representatives (Minimax - PopulationSigma)")
      this.memberController.clearAxes()
      this.memberController.clearLabel()
      this.memberController.addAxis(this.createNumericalIdeologyAxis(900, `MemberAxis`, "Simulated U.S. House Members by Ideology (populationSigma)"))
    },

    () => {
      this.clearVoters()
      this.clearVoters1D()
      this.map.display(new DisplayRectangle(20, 50, 900, 600))
      this.selectActual()
      this.clearMembers()
    },

    () => {
      this.clearVoters()
      this.clearVoters1D()
      this.map.display(new DisplayRectangle(20, 50, 900, 600))
      this.selectPrimary()
      this.clearMembers()
    },

    () => {
      this.clearVoters()
      this.clearVoters1D()
      this.map.display(new DisplayRectangle(20, 50, 900, 600))
      this.selectConsensus("Simulated U.S. House Member Representation Using Minimax")
      this.clearMembers()
    },


    () => {
      // this.setNewCandidates(this.fiveCandidateAll())
      this.candidates.bubbles = [
        new CandidateBubble(new Candidate("Sue", Democrats, -20, 0)),
        new CandidateBubble(new Candidate("John", Republicans, 16, 0)),
        new CandidateBubble(new Candidate("Matt", Republicans, 37, 0)),
      ]
      this.irvResult = this.runIRVElection(this.candidates.candidates())
      this.setBarLayout(this.candidates.bubbles.map(c => c.candidate), parabolicRoute)
    },
    () => { },
    () => { },
  ]

  progressMethods = []
}

export default RepresentativeCongressApp