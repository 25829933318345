import React from 'react';
import styles from "../../ui/components/content/Article/Article.module.scss"

interface Props {
  candidates: string[]
  choiceOrder: string[]
  activeCandidates?: string[]
  caption?: string
  title?: string
  colors?: string[]
}

class BallotWidget extends React.Component<any, any> {
  candidates: string[]
  choiceOrder: string[]
  activeCandidates: string[]
  title?: string
  caption?: string
  colors?: string[]

  rowHeaders = [
    (<th key={1}>1st Choice</th>),
    (<th key={2}>2nd Choice</th>),
    (<th key={3}>3rd Choice</th>),
    (<th key={4}>4th Choice</th>),
    (<th key={5}>5th Choice</th>),
    (<th key={6}>6th Choice</th>),
    (<th key={7}>7th Choice</th>),
    (<th key={8}>8th Choice</th>),
    (<th key={9}>9th Choice</th>),
    (<th key={10}>10th Choice</th>)
  ]

  // eslint-disable-next-line
  constructor(props: Props) {
    super(props)
    this.candidates = props.candidates
    this.choiceOrder = props.choiceOrder
    this.activeCandidates = props.activeCandidates ? props.activeCandidates : props.candidates
    this.title = props.title
    this.caption = props.caption
    this.colors = props.colors
  }

  candidateRow = (candidateName: string, row: number, faded: boolean): JSX.Element => {
    let choiceIndex = this.props.choiceOrder.indexOf(candidateName)
    let tdElements = this.candidates.map((c, i) => {
      if (this.choiceOrder.length > i && i === choiceIndex)
        return (<td key={i}>{'\u2B24'}</td>) // filled
      else
        return (<td key={i}>{'\u25EF'}</td>) // empty
    })

    let fadedClass = (faded) ? " " + styles.faded : ""
    return (
        <tr className={styles.centered + fadedClass}
            key={row}
            style={{
              background: this.colors ? this.colors[row] : 'white'
            }}
        >
          <th key={0}
              style={{
                background: this.colors ? this.colors[row] : 'white'
              }}
          >{candidateName}</th>
          {tdElements}
        </tr>
    )
  }

  candidateIsFaded = (c: string): boolean => {
    return !this.activeCandidates.includes(c)
  }


  renderBallot = (): JSX.Element => {
    return (
        <div>
          <table style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <tbody>
            <tr key={-3}>
              <th key={0} colSpan={this.candidates.length + 1}>{this.title}</th>
            </tr>
            <tr key={-2}>
              <th key={0}>Candidate</th>
              {this.candidates.map((c, i) => this.rowHeaders[i])}
            </tr>
            {this.candidates.map((c, i) => this.candidateRow(c, i, this.candidateIsFaded(c)))}
            </tbody>
            <caption>{this.caption}</caption>
          </table>
        </div>
    )
  }

  render() {
    return this.renderBallot()
  }
}

export default BallotWidget