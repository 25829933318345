import {DSVRowArray} from "d3";
import {Republicans} from "../core/Party";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
import Bubble from "../ui/elements/Bubble";

// [Log] candidate Anne 0.070, 0.189
// [Log] candidate Sue -0.351, -0.137
// [Log] candidate Maria 0.550, 0.300
// [Log] candidate Juan 0.750, 0.750
// [Log] candidate Bob -0.700, -0.950

export class ConsensusVotingApp extends ElectionSimApp {

  constructor(visDivId: string,
              dvr: DSVRowArray,
              DWNominate: DSVRowArray,
              simulationResults: DSVRowArray,
              sampleCongress: DSVRowArray,
              usTopo: any,
              nVoters: number,
              radius: number) {
    super(visDivId, dvr, DWNominate, simulationResults, sampleCongress, usTopo, nVoters, radius)

    this.clearMap()
  }


  newSectionMethods = [
    // 0
    (_p: number): void => {
      this.clearMap();
      this.showActualHouse();
    },
    //1
    (_p: number): void => {
      this.onLeanUpdate(.2)
      this.setNewCandidates(this.threeCandidateDRR())
      this.layoutCandidatesAndVoters(.6)
      this.voters.setColor(this.colorBubbleByIdeology, "colorByIdeology")
    },
    // 2
    (_p: number): void => {
      this.setActiveSimulation("DianeVRebecca")
      this.voters.opacityFcn = (b: Bubble) => {return .8}
      this.displayOutcome([this.createDiane(), this.createRebecca()])
    },

    // 3
    (_p: number): void => {
      this.setActiveSimulation("DianeVRyan")
      this.displayOutcome([this.createDiane(), this.createRyan()])
    },

    // 4
    (_p: number): void => {
      this.setActiveSimulation("RebeccaVRyan")
      this.displayOutcome([this.createRebecca(), this.createRyan()])
    },

    // 5
    (_p: number): void => {
      this.setActiveSimulation("Consensus")
      this.setNewCandidates(this.threeCandidateDRR())
      this.h2hVoterSelect("Ryan", "Rebecca", this.threeCandidateDRR())
    },

    // 6
    (_p: number): void => {
      this.setNewCandidates(this.threeCandidateDRR())
      this.h2hVoterSelect("Ryan", "Rebecca", this.threeCandidateDRR())
    },
    // 7
    this.showConsensusHouse,

    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },

    this.showHouse,
    this.showHouseAndVoters,
    this.showVoters2D,
    this.showGeneralOutcome,
    (_p: number) => { this.showPrimaryResults(Republicans) },

    // 5
    (_p: number) => { this.h2hVoterSelectAll(this.Juan().name, this.Anne().name) },
    this.showActualHouse,
    this.showRCVBallotDefault,
    (_p: number) => {
      this.htmlController.clear()
      this.h2hVoterSelectAll(this.Juan().name, this.Bob().name)
    },
    (_p: number) => { this.h2hVoterSelectAll(this.Maria().name, this.Juan().name) },
    // 10

    (_p: number) => { this.h2hVoterSelectAll(this.Sue().name, this.Juan().name) },
    (_p: number) => { this.h2hVoterSelectAll(this.Anne().name, this.Juan().name) },
    (_p: number) => {
      this.clearMembers(1000)
      this.clearMap(1000)
      this.h2hVoterSelectAll(this.Anne().name, this.Juan().name)
      this.colorVotersByIRVRound(0)
    },

    this.showConsensusHouse,
    this.showConsensusHouse,
    // 15
  ]

  progressMethods = [
    this.progressBasic, // 0
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,

    this.progressBasic, // 5
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,

    this.progressBasic, // 10
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,

    this.progressBasic, // 15
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,

  ]

}