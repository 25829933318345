import Article from "../ui/components/content/Article/Article";
import styles from "./Gerrymander.module.scss";
import VisualizationWidget from "../VisualizationWidget/VisualizationWidget";
import {AppMetadata} from "../ui/components/App/App";
import {ConsensusVotingApp} from "../Introduction/ConsensusVotingApp";
import React from "react";
import {SimulationState} from "../ElectionSimulation/ElectionSimApp";
import HouseFlyover from "../ui/components/content/HouseFlyover/HouseFlyover";
import MemberBubble from "../ui/elements/MemberBubble";

interface Props {
  metaData: AppMetadata
}

export class Gerrymander extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }

  render() {
    // let mdash = "\u2014"

    return (
        <Article title={"Consensus Voting Limits the Power of Gerrymandering"}
                 subtitle={"There is another way to limit the corrosive influence of gerrymandering."}>
          <div className={styles.textContent}>
            <HouseFlyover className={styles.flyover}/>
            <div className={"step"}>
              <p>
                Any discussion about the American political system has to include gerrymandering. Gerrymandering
                exacerbates almost every problem in American politics and is nearly impossible to prevent. Somebody has
                to draw the boundary lines; who is to say what is and is not fair? What's an objective measure of
                fairness? When does fair become unfair?
              </p>
              <p>
                The problem is impossible to define objectively, much less eliminate.
              </p>
              <p>
                Gerrymandering typically shuffles district lines to create districts where the dominant party wins by
                small but safe margins. At the same time, gerrymandering packs voters for the minority party into
                districts where they win by huge margins.
              </p>
              <p>
                Gerrymandering and all of its problems are all powered by wasted votes. Once a party has a safe
                majority, any additional voters make no difference and can be used to win another seat by redrawing the
                lines. The dominant party can elect whomever they want, and voters in the minority are frozen out of the
                democratic process.
              </p>
              <div style={{
                minHeight: "30px",
              }}>
              </div>
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}>

                <img className={styles.floatingImage}
                     style={{
                       width: "60vw",
                       height: "60vw"
                     }}
                     alt={"partisan lean vs. ideology"}
                     src={"images/lean_vs_ideology.png"}/>
                <p className={styles.caption}>
                  This shows the ideology of a representative and the partisan lean of the district they represent for
                  the 113th through 116th United States House of Representatives. The ideology of the representative is
                  from their DWNominate scores (Poole, https://voteview.com). Partisan lean is taken from the Cooke
                  Political Report.
                </p>
                <p className={styles.caption}>
                  The four graphs are divided by the type of primary. The four types shown are: combined,
                  closed, open, and Top-2 primary races.
                </p>

              </div>

              <p>
                This graph shows the relationship between the partisan lean of the district and the ideology of the
                candidate elected to represent the voters. In an ideal world, the people we elect would match the
                partisan lean of the district they represent, but that's not how it works. Some of the most partisan
                members of Congress come from fairly closely divided districts.
              </p>
              <p>
                Efforts to reduce the impact of gerrymandering have focused on creating fair maps, mostly through court
                challenges and the creation of non-partisan redistricting commissions. Non-partisan commissions have had
                some success, but the conditions for creating a non-partisan commission are often lacking where
                gerrymandering is at its worst. The courts are rightly reluctant to step into a political brawl without
                clear, objective definitions for what is and is not acceptable.
              </p>
              <p>
                Meanwhile, the voters are making it ever harder to create balanced districts and easier to gerrymander
                by sorting themselves geographically. Cities and urban areas have become Democratic strongholds, while
                Republican voters are dispersed in rural areas.
              </p>
              <p>
                Gerrymandering describes drawing lines for state and federal offices, but the real problems are
                uncompetitive elections, disenfranchised voters, and the toxic polarization created by the way those
                lines are drawn. Does it matter if those disenfranchised voters were created by a redistricting process
                or the natural gerrymander and demographics of the states? If wasted votes and disenfranchised voters
                are bad for democracy in a U.S. House district, aren't they at least as bad in a U.S. Senate election?
                What about governorships and other statewide offices? These are problems that cannot be solved by
                drawing different lines.
              </p>
              <p>
                It seems like an insoluble problem, but there is a fundamentally different way to approach it. What if
                the voting system didn't waste votes?
              </p>
              <p>
                Any voting system that wastes votes is fundamentally broken. Maybe we should fix that.
              </p>
              <h3>Consensus Voting and Gerrymandering</h3>

              <p>
                Consensus Voting limits gerrymandering simply because it never wastes votes, no voter is ever frozen out
                of the process and every voter matters. The favored ideology for a candidate shifts as the partisan lean
                of a district shifts. The so-called "safe" districts created by the gerrymandering process would favor
                moderate candidates that have to balance the needs of the 45% of voters in the minority.
              </p>
              <p>
                As the district becomes more partisan, the favored candidate also becomes more partisan. No single voter
                controls the outcome of any election, but every voter on either side shifts the balance a tiny amount.
                Every vote is meaningful, and no vote is ever "wasted."
              </p>
              <p>
                Without the fuel of wasted votes, gerrymandering doesn't make sense. Yes, a party could increase the
                absolute number of seats they nominally hold, but those seats would then have to be held by moderate
                candidates who are beholden to their voters and not party orthodoxy. As voters are shuffled in or out
                districts with a larger majority, those seats must also become more moderate. These moderate candidates
                cannot win election or reelection if they support an overly partisan agenda.
              </p>


              <div style={{
                minHeight: '5vh'
              }}/>
              <div className={styles.consensusDiv}>
                <VisualizationWidget
                    metaData={this.props.metaData}
                    className={styles.dividedHouse}
                    id={"actualHouse"}
                    height={1000}
                    width={1000}
                    nVoters={80}
                    radius={3.5}
                    render={(app: ConsensusVotingApp) => {
                      app.showConsensusHouse(0)
                    }
                    }
                    onUpdate={this.onUpdate}
                />
              </div>

              <div className={styles.caption}>
                <p>
                  This graphic is interactive: use the buttons to show outcomes under different voting methodologies.
                </p>
                <p>
                  <a href={"/Representation"}>Representation</a> and ideological breakdown for the United States House
                  of Representatives under the current gerrymandered districts. Three versions are shown:
                </p>
                <ul>
                  <li>
                    Actual Current - The ideology of each representative's voting record and estimates of voter
                    representation.
                  </li>
                  <li>
                    Simulated Current - The expected ideology and representation are based on the simulation of the
                    primary-based election process.
                  </li>
                  <li>
                    Consensus Voting - The expected ideology and representation are based on simulation of Consensus
                    Voting.
                  </li>

                </ul>
                <p>
                  The simulations use identical voters, voter preferences, and candidates. The only difference is the
                  method used to interpret the preferences.
                </p>
              </div>

              <h3>The Policy Tipping Point</h3>

              <p>
                A party with a majority can't pass any legislation they want. They are limited by the least partisan
                member of their caucus required to form a majority. Senate Democrats were a perfect example in 2022:
                they could not pass anything Joe Manchin did not support. It did not matter how badly Bernie Sanders or
                Elizabeth Warren wanted something; it was not going anywhere without Joe Manchin's support. Manchin
                was the policy tipping point.
              </p>

              <p>
                If a legislature has one hundred seats and a party has fifty-one partisans and four moderates, the
                tipping point is partisan and they can pass legislation without needing support from moderates.
                Under Consensus voting, they might be able to use gerrymandering to control the same number of seats but
                would have something like 20 partisans and 35 moderates of one degree or another. They still control 55
                seats but now they need support from moderates to pass legislation. They may also find they have a lot
                in common with moderates from the other party.
              </p>

              <p>
                Remember also, that these "moderates" are not moderates in the sense we use today. Today, a moderate
                represents the moderate voters of one party, but not voters in the other party. Moderates may be
                conscientious about voters in the minority in their district, but those voters don't have much if any
                say in the political future of a candidate.
              </p>

              <p>
                Under Consensus Voting the support of voters in the "minority" is essential to defeating other
                candidates from their own party. i.e. a Republican candidate needs the support of Democratic voters to
                defeat other Republicans. This creates a fundamentally different calculus when it comes to how win
                elections. Party orthodoxy and obeisance to the party elite become political suicide under
                Consensus Voting.
              </p>

              <p>
                Gerrymandering cannot be used to implement an extreme partisan agenda under Consensus Voting.
              </p>

              <div className={styles.consensusDiv}>
                <VisualizationWidget
                    metaData={this.props.metaData}
                    className={styles.simulatedHouse}
                    id={"consensusHouse"}
                    height={1000}
                    width={1000}
                    nVoters={80}
                    radius={3.5}
                    render={(app: ConsensusVotingApp) => {
                      app.showHouseVsVoters((mb: MemberBubble) => {
                        return app.combinedDistrictData.sampleIdeology("headToHead", mb.member.district)
                      })
                      app.memberController.simulatedLayout = true
                    }}/>
              </div>

              <p className={styles.caption}>
                This shows the partisan lean and ideological distribution of the United States House of Representatives
                when the elections are simulated using Consensus Voting instead. The details of Consensus Voting are
                explained <a href={"/ConsensusVoting"}>here</a>.
              </p>

              <p>
                Consensus Voting produces a representative Congress that must represent all of the voters, even with our
                current gerrymandered districts. Will it be worth it, or even advantageous, to gerrymander? Probably,
                but not to the same degree or with the same impact as today. Further, Consensus Voting fixes the
                problems created by uncompetitive elections that cannot be fixed by changing maps: Senate seats,
                governorships, statewide offices, and geographic sorting.
              </p>

              <p>
                Finally, Consensus Voting accomplishes all of this by simply allowing all of the voters to participate
                in the decisions that matter.
              </p>

            </div>
          </div>
        </Article>
    )
  }
}




















