import Layout from "./Layout";
import Bubble from "./Bubble";
import Point from "./Point";
import Trajectory from "./Trajectory";

class BarChartLayout extends Layout {
  classifier: <T extends Bubble>(b: T ) => number
  route: <T extends Bubble>(b: T ) => Trajectory

  nClasses: number
  x0: number
  y0: number
  barWidth: number
  spacing: number
  radius: number
  diameter: number
  axisLength: number
  counts: Array<number>
  bottomLeft: Point

  reset = () => {
    this.counts = Array<number>(this.nClasses).fill(0)
  }

  constructor( classifier: <T extends Bubble>(b: T ) => number,
               route: <T extends Bubble>(b: T) => Trajectory,
               nClasses: number,
               bottomLeft: Point,
               barWidth: number,
               spacing: number,
               radius: number) {
    super();
    this.classifier = classifier
    this.route = route
    this.nClasses = nClasses
    this.bottomLeft = bottomLeft
    this.x0 = bottomLeft.x
    this.y0 = bottomLeft.y
    this.barWidth = barWidth
    this.spacing = spacing
    this.radius = radius
    this.counts = Array<number>(this.nClasses).fill(0)
    this.diameter = 2 * radius
    this.axisLength = 2 * radius * (barWidth * nClasses + spacing * (nClasses - 1))
  }
  barStart = (classId: number) => {
    return (this.barWidth + this.spacing) * this.diameter * classId
  }
  position<T extends Bubble>(item: T): Point {
    let i = this.classifier(item)
    if (i < 0 || i >= this.nClasses) {
      console.log(`BarChartLayout: bad class ${i}`)
      return new Point(this.x0, this.y0)
    }
    let x = this.barStart(i) + this.counts[i] % this.barWidth * this.diameter + this.radius + this.x0
    let y = - (Math.floor(this.counts[i] / this.barWidth)* this.diameter + this.radius) + this.y0
    this.counts[i] += 1
    return new Point(x,y)
  }
  setTargetLocation: (b: Bubble) => void = (b: Bubble) => {
    let p = this.position(b)
    b.tx = p.x
    b.ty = p.y
  }
}


export {BarChartLayout}