import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class RestoringDemocracy extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }

  render() {
    // let mdash = "\u2014"

    return (
        <Article title={"Restoring Democracy"}
                 subtitle={"Wresting control of our democracy from extremists would allow it to fulfill it's promise."}>
          <div className={styles.textContent}>
            <p className={styles.pullQuote}>
              [<i>Referring to the larger Republic created by the Consitution</i>] Does the advantage consist in the
              substitution of representatives whose enlightened views and virtuous
              sentiments render them superior to local prejudices and schemes of injustice? -- James Madison, Federalist
              10
            </p>

            <p>
              Madison's words in Federalist 10 may seem naive to a modern ear that has been jaded by 234 years of
              factional democracy, but perhaps he thought democracy meant majority rule. In any case, Madison's argument
              that a republic can survive where direct democracy failed is based on the concept that the people we
              elect will "place the national interest ahead of temporary or partial considerations." i.e. that they can
              place country before party.
            </p>
            <p>
              But instead of the enlightened and virtuous people envisioned by Madison our election system rewards
              division and punishes any candidate who attempts to place country before party. The result is a government
              that is out of balance with a paralyzed legislative branch, a politicised court system compensating for
              legislative paralysis, and an executive attempting to govern by executive order without a functioning
              legislature.
            </p>
            <p>
              Consensus Voting would solve many of the problems plaguing our government and society. I've talked in some
              detail about a few issues but I'd like to quickly mention a few things on how it would rebalance our
              government.
            </p>
            <h3>
              The Senate
            </h3>
            <p>
              The Senate not a democratic institution. There is no justification to give a Wyoming resident 68 times the
              voting power of a California resident. Worse yet, those Senators from Wyoming, Rhode Island, and Delaware
              don't even represent all of their residents, instead they only represent their faction within their
              states. Consensus Voting would at least force those Senators to represent all of the residents and reduce
              the outsized sway that extremist forces wield through the combination of a flawed election system and
              undemocratic institution.
            </p>
            <p>
              Reining in the more partisan members of the Senate and enabling members to compromise without risking
              their political future would create a more functional body where the fillibuster rule wouldn't be so
              paralyzing. It would also lower the temperature of cabinet, judicial, and Supreme Court nominations.
            </p>
            <h3>The Supreme Court</h3>
            <p>
              The Supreme Court has taken on outsized importance because of legislative paralysis. Instead of updating
              laws written 50 years ago for entirely different circumstances we are endlessly legislating how to apply
              laws to situations they were never intended for. Electing leaders who are more representative of the
              voters by obeying majority rule would create a Congress and Senate that are capable of finding the
              compromises required to update these laws and take the issues away from the court.
            </p>

            <h3>Campaign Finance</h3>

            <p>
              We often decry the amount of money spent on American elections, but the fate and economic direction of the
              largest economy in the world is balanced on a knife edge every four years. Trillions of dollars hang in
              the balance and every corpration, every company, every individual will be affected one way or the other by
              the outcome. It would be corporate malfeasance not to attempt to influence the outcome.
            </p>


            <p>
              Often companies and individuals don't bother spending money to promote the cuase they actually care about,
              that would be a lost cause. Instead they choose sides on divisive social issues to promote whichever
              faction will act in their economic interest. The billions spent preaching fear and anger every election
              cycle amplifies the vitriol and poisons our society.
            </p>

            <p>
              Consensus voting lowers the temperature by taking all of the more extreme options off of the table.
              Instead of choosing be warring factions, voters will be choosing between candidates with different visions
              of how to balance the views of the entire electorate. The changes will be more subtle and drive by the
              evolution of the voters as a whole and the candidate's ability to present their differing visions
              for the future.
            </p>

            <p>
              The lower temperature means that the changes brought on by each election won't demand the huge outlays of
              cash that are so common today. People and corporations will still spend money to influence elections, but
              maybe they'll spend less because the consquences are smaller.
            </p>

          </div>
        </Article>
    )
  }
}




















