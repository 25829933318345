import * as d3 from "d3"
import {BaseType} from "d3"
import Scroller from "../ui/elements/Scroller";
import VoterController from "../ui/elements/VoterController";
import HistogramLayout from "../ui/elements/HistogramLayout";
import Bubble from "../ui/elements/Bubble";
import VoterBubble from "../ui/elements/VoterBubble";
import CandidateController from "../ui/elements/CandidateController";
import CandidateBubble from "../ui/elements/CandidateBubble";
import Candidate from "../core/Candidate";
import {Democrats, Independents, Republicans} from "../core/Party";
import CategoryAxis from "../ui/elements/CategoryAxis";
import Point from "../ui/elements/Point";
import OffscreenLayout from "../ui/elements/OffscreenLayout";
import MultiLayout from "../ui/elements/MultiLayout";
import RangesAndGradients from "../ui/elements/RangesAndGradients";
import Layout from "../ui/elements/Layout";
import {DWNominateConfig, ElectionConfig} from "../core/ElectionConfig";
import {Ballot} from "../core/Ballot";
import {InstantRunoffElection, IRVResult} from "../core/InstantRunoffElection";
import {BarChartLayout} from "../ui/elements/BarChartLayout";
import {PluralityElection, PluralityResult} from "../core/PluralityElection";
import ScaleAndOffsets from "../ui/elements/ScaleAndOffsets";
import Random, {random} from "../core/Random";
import LayoutByScale from "../ui/elements/LayoutByScale";
import {directRoute, immediateDirectRoute, parabolicRoute} from "../ui/elements/LayoutAnimator";
import {BarChartAxis} from "../ui/elements/BarChartAxis";
import Trajectory from "../ui/elements/Trajectory";
import CombinedPopulation from "../core/CombinedPopulation";
import HTMLController from "../ui/elements/HTMLController";
import MemberController from "../ui/elements/MemberController";
import CombinedDistrictData from "../core/CombinedDistrictData";
import blockTimer from "../core/BlockTimer";
import MapController from "../ui/elements/MapController";
import Candidates2D from "../ui/elements/Candidates2D";
import {RenderItem} from "../ui/elements/RenderItem";
import ReactGA from 'react-ga'
import NumericAxis from "../ui/elements/NumericAxis";


class CanvasInfo {
  svgParent!: d3.Selection<any, any, any, any>
  canvasNode!: HTMLCanvasElement
  canvas!: d3.Selection<any, any, any, any>
  context!: CanvasRenderingContext2D
  dpr = window.devicePixelRatio

  constructor(svgParent: d3.Selection<any, any, any, any>, canvasNode: HTMLCanvasElement, canvas: d3.Selection<any, any, any, any>, context: CanvasRenderingContext2D) {
    this.svgParent = svgParent

    this.canvasNode = canvasNode
    this.canvas = canvas
    this.context = context
  }


  onResize = (s: ScaleAndOffsets) => {
    let cWidth = s.scaledWidth
    let cHeight = s.scaledHeight
    if (s.width > s.height) {
      cWidth = s.scaledHeight / s.height * s.width
    } else if (s.height > s.width) {
      cHeight = s.scaledWidth / s.width * s.height
    }

    let dprWidth = Math.trunc(cWidth) * this.dpr
    let dprHeight = Math.trunc(cHeight) * this.dpr
    let scale = 1
    /////////////////////////////////////////////////////////////////
    // the iphone has limits on the canvasArea, so this reduces the
    // scale to keep it in bounds without affecting anything else.
    // I suspect that I could mess with the ScaleAndOffsets and
    // avoid this hack, but I'm not sure of the full implications
    // of that.

    if (this.dpr === 3 && dprWidth * dprHeight > 4096 * 4096) {
      scale = 2 / 3
      dprWidth *= scale
      dprHeight *= scale
    }
    this.canvas
        .attr("width", dprWidth)
        .attr("height", dprHeight)
    this.context.transform(scale, 0, 0, scale, 0, 0)
    this.context.scale(this.dpr, this.dpr)
    this.context.translate(s.xOffset / s.scale, s.yOffset / s.scale)
  }
}

abstract class StoryBase {
  svgParent!: d3.Selection<any, any, any, any>
  svg!: d3.Selection<any, any, any, any>
  visDivId: string
  voters: VoterController
  map?: MapController
  candidates: CandidateController
  candidates2D?: Candidates2D
  ranges = new RangesAndGradients()
  voterScale = d3.scaleLinear([-3.3, 3.3], [10, 990])
  candidateScale = d3.scaleLinear([-3.3, 3.3], [10, 990])
  filterRepublicans = (b: Bubble) => b.party === Republicans
  filterDemocrats = (b: Bubble) => b.party === Democrats
  filterIndependents = (b: Bubble) => b.party === Independents
  currentSection = -1
  previousSection = -1
  lastProgress = 0
  irvResult!: IRVResult
  pluralityResult!: PluralityResult
  abstract newSectionMethods: Array<(progress: number) => void>
  progressMethods: Array<(progress: number) => void> = []
  rPrimaryResult!: PluralityResult
  dPrimaryResult!: PluralityResult
  timers: Array<[string, d3.Timer]> = []
  timerCount: number = 0
  htmlController: HTMLController
  scaleAndOffsets!: ScaleAndOffsets
  memberController!: MemberController
  h2hMemberController?: MemberController
  renderItems: RenderItem[] = []
  scroller?: Scroller
  drawingAreaWidth: number = 1000
  drawingAreaHeight: number = 1000

  protected visualizationAreas: string[] = []
  protected currentVisArea: string = ""
  protected mapAreas: string[] = []
  protected currentMapArea: string = ""


  /////////////////////////
  // these are all initialized in createCanvas
  dpr = window.devicePixelRatio
  voterCanvas: CanvasInfo
  voterCanvas1D: CanvasInfo
  // voterCanvas2: CanvasInfo
  memberCanvas1: CanvasInfo
  memberCanvas2: CanvasInfo
  visDiv: d3.Selection<any, any, any, any>

  constructor(visDivId: string, population: CombinedPopulation, nVoters: number = 2000) {
    this.visDivId = visDivId
    this.voterCanvas = this.createCanvas("voters")
    this.voterCanvas1D = this.createCanvas("voters1D")
    this.memberCanvas1 = this.createCanvas("members1")
    this.memberCanvas2 = this.createCanvas("members2")

    this.visDiv = d3.select(`#${this.visDivId}`)
    this.svgParent = this.visDiv.select("#svgArea")
    this.svg = this.svgParent.select("#drawingG")

    this.setupScroller()
    this.htmlController = new HTMLController(this.visDivId)


    this.voters = new VoterController(nVoters,
        this.voterCanvas.context,
        population,
        5)

    this.voters.startOffscreen()
    this.candidates = new CandidateController()
    this.candidates.bubbles = [
      new CandidateBubble(new Candidate("Sue", Democrats, -20, 0)),
      new CandidateBubble(new Candidate("John", Republicans, 16, 0)),
      new CandidateBubble(new Candidate("Matt", Republicans, 37, 0)),
    ]

    this.irvResult = this.runIRVElection(this.candidates.candidates())
    window.addEventListener('resize', () => this.onResize(true))
    this.onResize(false)
  }

  renderRenderItems = () => {
    this.renderItems.forEach(item => item.render())
  }
  addRenderItem = (item: RenderItem) => {
    this.clearRenderItem(item.id)
    this.renderItems.push(item)
  }

  clearRenderItem = (id: string) => {
    let toClear = this.renderItems.filter((item) => item.id === id)
    this.renderItems = this.renderItems.filter((item) => item.id !== id)
    toClear.forEach((item) => item.clear())
  }

  setVisualizationAreas = (newVisAreas: string[], newMapAreas: string[]) => {
    this.visualizationAreas = newVisAreas
    this.mapAreas = newMapAreas
  }

  createH2HMemberController = (combinedDistrictData: CombinedDistrictData): MemberController => {
    let mc = new MemberController(this.svg, 117, 8, combinedDistrictData)
    mc.setH2HMembers()
    return mc
  }
  createMemberController = (combinedDistrictData: CombinedDistrictData): MemberController => {
    return new MemberController(this.svg, 117, 8, combinedDistrictData)
  }
  reset_candidates = () => {
    this.candidates.setUpdate((_b: Bubble) => {
    })
    this.candidates.bubbles.forEach((b: Bubble) => {
      let cb = b as CandidateBubble
      cb.opacity = 1.0
      cb.halo = false
      cb.active = true
    })
    this.candidates.dirty = true
  }

  stopTimer = (timerName: string) => {
    this.timers.filter(([n, t]) => n === timerName).forEach(([_n, t]) => t.stop())
    this.timers = this.timers.filter(([n, t]) => n !== timerName)
  }
  stopAllTimers = () => {
    this.timers.forEach(([n, _t]) => console.log(`Stopping timer ${n}`))
    this.timers.forEach(([_n, t]) => t.stop())
    this.timers = []
  }

  addTimer = (name: string, thunk: () => void, millis: number) => {
    if (this.timerCount > 0) {
      this.timerCount -= 1
      console.log(`Pushing timer for ${name} timerCount ${this.timerCount}`)
      this.timers.push([name, d3.timeout(thunk, millis)])
    }
  }

  render = (): void => {
  }

  bubbleIdeology = (b: Bubble): number => {
    return b.ideology
  }

  showTwoCandidateRace = (activeCandidates: Array<Candidate>,
                          centralCandidate: Candidate,
                          activeVoters?: Set<Bubble>,
                          inactiveLayout?: Layout
  ): void => {
    random.setSeed("Consensus")


    let barRoute = (activeVoters) ? immediateDirectRoute(1000) : directRoute
    let barLayout = this.createBarLayout((b: Bubble) => (b as VoterBubble).candidateIndex,
        barRoute,
        3, 20, 20, 500, 900)

    let layout = activeVoters ?
        new MultiLayout(
            [
              [(b: Bubble) => activeVoters!.has(b), barLayout],
              [(_b: Bubble) => true, inactiveLayout!]
            ]) :
        barLayout


    this.pluralityResult = this.runPluralityElection(this.voters.bubbles, activeCandidates)

    this.candidates.bubbles.forEach((b: Bubble) => {
      let cb = b as CandidateBubble
      cb.opacity = activeCandidates.findIndex(c => c === cb.candidate) >= 0 ? 1.0 : .2
      cb.halo = false
      cb.active = true
    })
    this.candidates.dirty = true

    let indexCandidates: Array<Candidate>
    let dummyCandidate = new Candidate("", Independents, 0, 0)
    if (activeCandidates[0] === centralCandidate)
      indexCandidates = [dummyCandidate, activeCandidates[0], activeCandidates[1]]
    else
      indexCandidates = [activeCandidates[0], activeCandidates[1], dummyCandidate]
    this.setCandidateIndex(this.voters.bubbles, new Set(activeCandidates), indexCandidates)

    let axis = new BarChartAxis("two-candidate-results", this.svg, barLayout, indexCandidates.map(c => c.name), "Voter Preference Results")
    this.voters.clearAxes()
    this.voters.addAxis(axis)
    this.voters.setLayout(layout, "general-bars")
  }

  showIRVRound = (round: number, route: <T extends Bubble>(b: T) => Trajectory = parabolicRoute) => {
    this.voters.setIRVRounds(this.irvResult, round)
    this.voters.sortByParty()
    let axisCandidates = this.candidates.bubbles.map(c => c.candidate)
    let activeCandidates = new Set(this.irvResult.rounds[round].orderedCandidates)
    this.candidates.setUpdate((b: Bubble) => {
      let cb = b as CandidateBubble
      cb.opacity = activeCandidates.has(cb.candidate) ? 1 : .2
    })
    this.candidates.dirty = true
    this.setCandidateIndex(this.voters.bubbles, activeCandidates, axisCandidates)


    let candidates = this.candidates.bubbles.map(c => c.candidate)
    this.setBarLayout(candidates, route, `Instant Runoff Round ${round + 1}`)
    this.markLastPlace(round)

    let markResults = () => {
      if (round === this.irvResult.rounds.length - 1) {
        let winnerIdx = this.candidates.candidates().indexOf(this.irvResult.winner)
        this.setHalo(winnerIdx)
      } else {
        this.resetHalo()
      }
      this.candidates.render(this.svg, 1)
    }
    this.timerCount = 1
    this.addTimer("markResults", markResults, 3000)
  }

  setBarLayout = (candidates: Array<Candidate>, route: <T extends Bubble>(b: T) => Trajectory = parabolicRoute, title = "") => {
    let bars = this.createSingleBarLayout(route)
    this.voters.setUpdate((b: Bubble) => b.opacity = .9)
    this.voters.clearAxes()
    this.voters.addAxis(new BarChartAxis("bar-axis", this.svg, bars, candidates.map(c => c.name), title))
    this.voters.setLayout(bars, "bars!!")
  }

  splitLayout(): Layout {
    return new MultiLayout(
        [
          [this.filterRepublicans, new HistogramLayout(this.bubbleIdeology, directRoute, 700, this.ranges.histogramScale, this.voters.radius - .5)],
          [this.filterIndependents, new HistogramLayout(this.bubbleIdeology, directRoute, 300, this.ranges.histogramScale, this.voters.radius - .5)],
          [this.filterDemocrats, new HistogramLayout(this.bubbleIdeology, directRoute, 500, this.ranges.histogramScale, this.voters.radius - .5)]
        ]
    )
  }

  selectAndCreateCanvas = (visId: string, canvasId: string): CanvasInfo => {
    let vis = d3.select(`#${visId}`)
    let canvas = vis.select(`#${canvasId}`)
    return this.createCanvasFromSelection(canvas)
  }

  createCanvasFromSelection = (canvas: d3.Selection<BaseType, unknown, HTMLElement, any>): CanvasInfo => {
    let canvasNode = canvas.node() as HTMLCanvasElement
    let context = canvasNode.getContext("2d") as CanvasRenderingContext2D
    context.scale(this.dpr, this.dpr)
    return new CanvasInfo(this.svgParent, canvasNode, canvas, context)
  }

  createCanvas = (name: string) => {
    let canvas = d3.select(`#${this.visDivId}`).select(`#${name}`)
    let canvasNode = canvas.node() as HTMLCanvasElement
    let context = canvasNode.getContext("2d") as CanvasRenderingContext2D
    context.scale(this.dpr, this.dpr)
    return new CanvasInfo(this.svgParent, canvasNode, canvas, context)
  }

  setupScroller = () => {
    this.scroller?.scroll()
  }

  setScaling = (newWidth: number, newHeight: number) => {
    this.drawingAreaWidth = newWidth
    this.drawingAreaHeight = newHeight
    this.onResize(false)
  }

  onResize = (render: boolean) => {
    let s = new ScaleAndOffsets(this.svgParent, this.drawingAreaWidth, this.drawingAreaHeight)
    this.scaleAndOffsets = s
    let transform = `translate(${s.xOffset}, ${s.yOffset}) scale(${s.scale.toFixed(5)})`
    this.svg.attr("transform", transform)
    let mapOuterG = d3.selectAll("#mapOuterG")
    // console.log(`performing resize:  visDivId is ${this.visDivId} mapOuterG size is ${mapOuterG.size()}`)
    mapOuterG.attr("transform", transform)


    this.voterCanvas.onResize(s)
    this.voterCanvas1D.onResize(s)
    this.memberCanvas1.onResize(s)
    this.memberCanvas2.onResize(s)
    // this.checkPhoneRotation()

    if (this.memberController) this.memberController.dirty = true
    if (this.h2hMemberController) this.h2hMemberController.dirty = true
    this.voters.dirty = true
    this.candidates.dirty = true
    if (render)
      this.render()

  }
  setVisualizationArea = (visId: string): void => {
    // console.log(`StoryBase.setVisualizationAreai(${visId}`)
    this.visDivId = visId
    let vis = d3.select(`#${visId}`)
    let svgParent = vis.select("#svgArea")
    let drawingG = svgParent.select("#drawingG")

    this.svgParent = svgParent
    this.svg = drawingG
    this.memberCanvas1 = this.selectAndCreateCanvas(visId, "members1")
    this.memberCanvas2 = this.selectAndCreateCanvas(visId, "members2")
    this.voterCanvas = this.selectAndCreateCanvas(visId, "voters")
    this.voterCanvas1D = this.selectAndCreateCanvas(visId, "voters1D")
    this.htmlController.setNewVisDiv(visId)

    this.memberController.setNewSVG(drawingG)
    this.h2hMemberController?.setNewSVG(drawingG)
    this.memberController.setNewCanvas(this.memberCanvas1.context, visId)
    this.h2hMemberController?.setNewCanvas(this.memberCanvas2.context, visId)
    this.voters.setNewCanvas(this.voterCanvas.context, visId)

    this.candidates2D?.setSVG(this.svg)
    this.onResize(false)
  }

  setMapArea = (section: number): void => {
    if (this.map && section < this.mapAreas.length) {
      let mapId = this.mapAreas[section]
      if (mapId !== this.currentMapArea) {
        let vis = d3.select(`#${mapId}`)
        // console.log(`setting map area to from ${this.currentMapArea} => ${mapId}`)
        this.map.setNewParent(vis)
        this.currentMapArea = mapId
      }
    }
  }

  updateVisArea = (section: number): void => {
    this.setMapArea(section)
    if (section < this.visualizationAreas.length) {
      let newVisArea = this.visualizationAreas[section]
      if (this.currentVisArea !== newVisArea) {
        // console.log(`Setting new vis area: ${this.currentVisArea} => ${newVisArea}`)
        this.setVisualizationArea(newVisArea)
        this.currentVisArea = newVisArea
      }
    } else {
      // console.log("past last visualization area")
    }
  }

  onNewSection = (section: number, progress: number) => {
    // console.log(`onNewSection: ${section} ${progress}`)
    this.lastProgress = 0
    // this.updateProgressHint(section, progress)
    if (section === this.currentSection) return
    ReactGA.pageview(`${window.location.pathname}-${section}`)
    let direction = (section - this.currentSection > 0) ? 1 : -1
    while (this.currentSection !== section) {
      this.previousSection = this.currentSection
      this.currentSection += direction
      // console.log(`loop  of onNewSection: target: ${section} this.currentSection ${this.currentSection} ${direction}`)
      if (this.currentSection >= 0 && this.currentSection < this.newSectionMethods.length) {
        blockTimer(() => {
          this.stopAllTimers()
          this.updateVisArea(this.currentSection)
          this.newSectionMethods[this.currentSection](progress)
        }, `newSectionMethods ${this.currentSection}`)
      }
    }
    blockTimer(() => {
      this.render()
    }, "StoryBase.render()")
    // this.checkPhoneRotation()
  }

  resetOpacity = () => {
    this.candidates.setUpdate((b: Bubble) => {
      b.opacity = 1.0
    })
  }


  markLastPlace = (round: number) => {
    let result = this.irvResult.rounds[round]
    let candidates = this.candidates.bubbles.map(b => b.candidate)
    let last = result.orderedCandidates[result.orderedCandidates.length - 1]
    let lastIndex = candidates.indexOf(last)

    let update = (b: Bubble): number => {
      let v = b as VoterBubble
      if (v.candidateIndex === lastIndex) return .2
      else return .9
    }
    this.voters.setOpacity(update)
  }

  runSinglePrimary = (bubbles: Array<VoterBubble>, candidates: Array<Candidate>): PluralityResult => {
    let result = this.runPluralityElection(bubbles, candidates)
    let activeCandidates = new Set(candidates)
    bubbles.forEach(vb => {
      vb.primaryIndex = vb.ballot.preferredIndex(activeCandidates, candidates)
    })
    return result
  }

  runPrimaries = () => {
    this.setPrimaryVoters()
    let rVoters = this.voters.bubbles.filter(vb => vb.primary === Republicans)
    let dVoters = this.voters.bubbles.filter(vb => vb.primary === Democrats)
    let rCandidates = this.candidates.bubbles.filter(cb => cb.party === Republicans).map(cb => cb.candidate)
    let dCandidates = this.candidates.bubbles.filter(cb => cb.party === Democrats).map(cb => cb.candidate)
    this.rPrimaryResult = this.runSinglePrimary(rVoters, rCandidates)
    this.dPrimaryResult = this.runSinglePrimary(dVoters, dCandidates)
  }

  setPrimaryVoters = () => {
    let r = new Random("Consensus")
    let partyPrimaryRate = .5
    let independentPrimaryRate = .3
    this.voters.bubbles.forEach(vb => {
      vb.primary = undefined
      if (vb.party === Republicans && r.nextFloat() < partyPrimaryRate)
        vb.primary = Republicans
      else if (vb.party === Democrats && r.nextFloat() < partyPrimaryRate)
        vb.primary = Democrats
      else if (vb.party === Independents) {
        if (r.nextFloat() < independentPrimaryRate) {
          if (r.nextBoolean())
            vb.primary = Republicans
          else
            vb.primary = Democrats
        }
      }
    })
  }

  runPluralityElection = (voters: Array<VoterBubble>, candidates: Array<Candidate>): PluralityResult => {
    let ballots = voters.map(vb => vb.ballot)
    let x = new PluralityElection(ballots, new Set(candidates))
    return x.result as PluralityResult
  }


  setVoterBallots = (candidates: Candidate[], config: ElectionConfig = DWNominateConfig): void => {
    blockTimer(() => {
          random.setSeed("Consensus")
          this.voters.bubbles.forEach(vb => vb.ballot = new Ballot(vb.voter, candidates, config))
        },
        "setBallots"
    )
  }

  runIRVElection = (candidates: Candidate[], config: ElectionConfig = DWNominateConfig): IRVResult => {
    this.setVoterBallots(candidates, config)
    return this.computeIRVResult(candidates)
  }

  computeIRVResult = (candidates: Candidate[]): IRVResult => {
    return blockTimer(() => {
      let ballots = this.voters.bubbles.map(vb => vb.ballot)
      let x = new InstantRunoffElection(ballots, new Set(candidates))
      return x.result as IRVResult
    }, "computeIRVResult")
  }

  setCandidateIndex(bubbles
                        :
                        Array<VoterBubble>, activeCandidates
                        :
                        Set<Candidate>, indexCandidates
                        :
                        Array<Candidate>
  ) {
    bubbles.forEach((v) => {
      v.candidateIndex = v.ballot.preferredIndex(activeCandidates, indexCandidates)
    })
  }

  checkPhoneRotation() {
    let w = window.innerWidth
    let h = window.innerHeight
    if (w < 500 && w < h) {
      let u = this.svg.selectAll(".phoneText").data([0])
      let entering = u.enter()
          .append("text")
          .classed("phoneText", true)
          .attr('x', 200)
          .attr('y', -40)
          .style("fill", "black")
          .style("font-size", "30")
          .attr("font-family", "Arial")

      entering.merge(u as d3.Selection<any, any, any, any>)
          .text("Rotate your phone for a better experience.")
    } else {
      this.svg.selectAll(".phoneText").remove()
    }
  }

  updateProgressHint(section
                         :
                         number, progress
                         :
                         number
  ) {
    let u = this.svg.selectAll(".progress-text").data([0])

    let entering = u.enter()
        .append("text")
        .classed("progress-text", true)
        .attr('x', "10")
        .attr('y', "1000")
        .style("fill", "black")
        .style("font-size", "14")
        .attr("font-family", "Arial")

    entering.merge(u as d3.Selection<any, any, any, any>)
        .text(`section ${section} progress ${progress.toFixed(3)}`)
  }

  onProgress = (section: number, progress: number) => {
    if (section > 0 && section < this.progressMethods.length) {
      this.progressMethods[section](progress)
    } else {
    }
    this.lastProgress = progress
  }

  clearMap = (duration: number = 1000) => {
    this.map?.clear()
  }

  clearMembers = (duration: number = 1000) => {
    this.memberController.moveOffscreen(duration)
    this.memberController.clearSlider()
  }

  clearVoters = (duration: number = 1000) => {
    this.voters.setLayout(new OffscreenLayout(-1000, -900, 0, 1000, duration), "clearVoters")
    this.voters.clearAxes()
  }

  layoutCandidatesByIdeology = (yValue: number): void => {
    let activeLayout = new LayoutByScale((b: Bubble) => (b as CandidateBubble).ideology,
        directRoute,
        this.candidateScale,
        yValue)
    let inactiveLayout = new OffscreenLayout()
    let candidateLayout = new MultiLayout([
      [(b: Bubble) => (b as CandidateBubble).active, activeLayout],
      [(b: Bubble) => !(b as CandidateBubble).active, inactiveLayout],
    ])
    this.candidates.setLayout(candidateLayout, "Candidates by scale")
    this.candidates.addAxis(this.createIdeologyAxis(yValue, "candidateAxis", "Candidates by Partisanship"))
    this.candidates.setColor(this.colorBubbleByIdeology, "colorByIdeology")
    this.candidates.render(this.svg, 1)
  }
  colorBubbleByIdeology = (b: Bubble): string => {
    return this.ranges.colorByIdeology(b.ideology)
  }

  layoutVotersByIdeology = (yValue: number): void => {
    this.voters.clearAxes()
    this.voters.setColor(this.colorBubbleByIdeology, "votersByIdeology")
    this.voters.setOpacity((_b: Bubble) => .9)
    this.voters.setLayout(
        new HistogramLayout(this.bubbleIdeology, directRoute,
            yValue, this.voterScale, this.voters.radius - 1, 1000, true),
        "voters histogram Layout")
    this.voters.addAxis(this.createIdeologyAxis(yValue, "voterAxis", "Voters by Ideology"))
  }

  clearCandidates = (duration: number = 0) => {
    this.candidates.clearAxes()
    this.candidates.setLayout(new OffscreenLayout(-1000, -1100, 0, 1000, duration), "clearCandidates")
  }


  createNumericalIdeologyAxis = (y: number, id: string, title: string): NumericAxis => {
    // let categories = ["Hyper-Partisan", "Partisan", "Non-Partisan", "Partisan ", "Hyper-Partisan "]
    return new NumericAxis(id, this.svg, this.ranges.histogramScale, [-2, -1, 0, 1, 2], new Point(10, y), 980, title)
  }

  createIdeologyAxis = (y: number, id: string, title: string): CategoryAxis => {
    // let categories = ["Hyper-Partisan", "Partisan", "Non-Partisan", "Partisan ", "Hyper-Partisan "]
    let categories = ["Very Liberal", "Liberal", "Moderate", "Conservative", "Very Conservative"]
    return new CategoryAxis(id, this.svg, categories, new Point(10, y), 980, title)
  }

  createEastWestAxis = (y: number, id: string, title: string = ""): CategoryAxis => {
    let categories = ["", "", "", "", ""]
    return new CategoryAxis(id, this.svg, categories, new Point(10, y), 980, title)
  }

  createSingleBarLayout = (route: <T extends Bubble>(b: T) => Trajectory = parabolicRoute): BarChartLayout => {
    let nCandidates = this.candidates.bubbles.length
    let barSpacing = 20
    let barWidth = 20

    if (nCandidates === 2) {
      barSpacing = 30
      barWidth = 30
    }
    if (nCandidates === 3) {
      barSpacing = 20
      barWidth = 20
    }
    if (nCandidates === 4) {
      barWidth = 20
      barSpacing = 8
    }
    if (nCandidates === 5) {
      barWidth = 20
      barSpacing = 5
    }
    return this.createBarLayout((b: Bubble) => (b as VoterBubble).candidateIndex, route,
        this.candidates.bubbles.length, barWidth, barSpacing, 500, 900)
  }

  createBarLayout = (classifier: (b: Bubble) => number,
                     route: <T extends Bubble>(b: T) => Trajectory,
                     nCandidates: number,
                     barWidth: number,
                     spacing: number,
                     xCenter: number, y: number): BarChartLayout => {

    let radius = this.voters.radius - 1
    let axisLength = (barWidth * nCandidates + spacing * (nCandidates - 1)) * radius * 2
    let bottomLeft = new Point(xCenter - axisLength / 2, y)
    return new BarChartLayout(classifier,
        route,
        nCandidates,
        bottomLeft,
        barWidth,
        spacing,
        radius)
  }

  resetHalo = () => {
    this.candidates.bubbles.forEach((b: Bubble) => {
      let cb = b as CandidateBubble
      cb.halo = false
    })
    this.candidates.dirty = true
  }
  setHalo = (index: number) => {
    this.candidates.bubbles.forEach((b: Bubble, i: number) => {
      let cb = b as CandidateBubble
      cb.halo = i === index;
    })
    this.candidates.dirty = true
  }
}

export default StoryBase