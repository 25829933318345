import * as d3 from 'd3'
import Point from './Point'
import SVGAxis from './SVGAxis'

class NumericAxis extends SVGAxis {
  id: string
  svg: d3.Selection<any, any, any, any>
  scale: d3.ScaleLinear<number, number>
  tics: Array<number>
  width: number
  topLeft: Point
  dirty: boolean = true
  title: string
  vertical: boolean

  constructor(id: string, svg: d3.Selection<any, any, any, any>,
              scale: d3.ScaleLinear<number, number>,
              tics: Array<number>,
              topLeft: Point,
              width: number,
              title: string,
              vertical: boolean = false) {
    super()
    this.id = id
    this.svg = svg
    this.scale = scale
    this.tics = tics

    this.width = width
    this.topLeft = topLeft
    this.title = title
    this.vertical = vertical
  }

  clear = () => {
    let name = `${this.id}-g`
    // console.log(`clearing axis ${name}`)
    d3.selectAll("#" + name).remove()
  }



  render = () => {
    let name = `${this.id}-g`
    if (!this.dirty) return
    this.clear()

    const axis = d3.axisBottom(this.scale)
        .tickValues(this.tics)

    let axisG = this.svg.append("g")
        .attr("id", name)
        .style("font-size", "14pt")
        .attr("transform", `translate(${this.topLeft.x}, ${this.topLeft.y})`)
        .call(axis)



    this.dirty = false
    axisG.append("text")
        .attr("text", this.title)

    axisG.append("text")
        .attr("id", "x-label")
        .attr("text-anchor", "middle")
        .attr("y", 40)
        .attr("x", this.width / 2)
        .attr("dy", ".75em")
        .attr("fill", "black")
        .attr("font-size", "16pt")
        .text(this.title)
  }
}

export default NumericAxis