import {Population} from "../../core/CombinedPopulation";
import VoterBubble from "./VoterBubble";
import Random from "../../core/Random";
import * as d3 from 'd3';
import HistogramLayout from "./HistogramLayout";
import Bubble from "./Bubble";
import Layout from "./Layout";
import {IRVResult} from "../../core/InstantRunoffElection";
import {directRoute, LayoutAnimator} from "./LayoutAnimator";
import AnimatedController from "./AnimatedController";
import blockTimer from "../../core/BlockTimer";

class VoterController extends AnimatedController {
  nVoters: number
  population: Population
  bubbles: Array<VoterBubble>
  random: Random
  width = 1000
  height = 1000
  radius = 5
  layout: Layout
  controllerName = "voters"
  dpr: number
  canvasContext: CanvasRenderingContext2D
  animator!: LayoutAnimator | undefined

  constructor(nVoters: number,
              voterContext: CanvasRenderingContext2D,
              population: Population, radius: number) {
    super()

    /////////////////////////////////////////////////////////////////////////////////////
    // create canvas rendering object for faster rendering.
    this.canvasContext = voterContext
    this.radius = radius

    this.dpr = window.devicePixelRatio

    ////////////////////////////////////////////////////////////////////////////////


    this.nVoters = nVoters
    this.population = population
    this.bubbles = []
    this.random = new Random()
    let scale = d3.scaleLinear([-100, 100], [0, this.width])
    this.layout = new HistogramLayout((b: Bubble) => (b as VoterBubble).voter.ideology, directRoute, 600, scale, this.radius - 1)

    let voters = this.population.samplePopulation(nVoters, 0)
    this.bubbles = voters.map(v => new VoterBubble(v, this.radius))

    this.sortByIdeology()
    this.initOffScreen()
    // this.dump10Voters("VoterController.constructor")
  }

  dump10Voters = (tag: string) => {
    this.bubbles.slice(0, 10).forEach(b => {
      console.log(`${tag}: render bubble ${b.x} ${b.y} ${b.tx} ${b.ty}`)
    })
  }


  renderFrame = (_pctComplete: number): void => {
    this.renderStart()
    // pctComplete = Math.min(1.0, pctComplete)
    // this.bubbles.forEach(b => b.updateOpacity(pctComplete))
    let context = this.canvasContext;
    this.bubbles.forEach(
        b => {
          context.beginPath()
          context.globalAlpha = b.opacity
          context.fillStyle = b.color
          context.arc(b.x, b.y, b.radius, 0, 2 * Math.PI)
          context.fill()
        }
    )
    this.renderFinish()
  }


  startOffscreen = () => {
    this.bubbles.forEach((b) => {
      b.tx = this.random.nextInt(this.width)
      b.ty = this.random.nextInt(200) * -1
    })
  }

  sortByParty = () => {
    this.bubbles.sort((a, b) => {
          if (a.voter.party !== b.voter.party)
            return a.voter.party.name < b.voter.party.name ? -1 : 1
          else
            return a.ideology - b.ideology
        }
    )
  }

  setIRVRounds = (irvResult: IRVResult, round: number) => {
    this.bubbles.forEach(b => b.irvRound = 0)
    irvResult.rounds.forEach(
        (pluralityResult, roundIndex) => {
          if (roundIndex > 0 && roundIndex <= round) {
            let currentCandidates = new Set(pluralityResult.orderedCandidates)
            let previousCandidates = new Set(irvResult.rounds[roundIndex - 1].orderedCandidates)
            this.bubbles.forEach((b: VoterBubble) => {
              let current = b.ballot.pickFrom(currentCandidates)
              let previous = b.ballot.pickFrom(previousCandidates)
              if (current && previous && current !== previous)
                b.irvRound = roundIndex
            })

          }
        }
    )
  }


  sortByIdeology = () => {
    this.bubbles.sort((a, b) => {
          return a.ideology - b.ideology
        }
    )
  }

  initOffScreen = () => {
    this.bubbles.forEach((b) => {
      b.x = this.random.nextInt(this.width)
      b.y = -900 + this.random.nextInt(200) * -1
    })
  }

  render = () => {
    // console.log("voters.render")
    blockTimer(() => {

      // console.log(`voters.render: ${this.dirty}`)
      if (!this.dirty) return
      this.axes.forEach(axis => axis.render())
      this.applyUpdate()


      // console.log("VoterController: starting animation...")
      if (this.animator) this.animator.stop()
      this.applyColorAndOpacity()
      this.animator = new LayoutAnimator(
          "voters",
          this.bubbles,
          this.layout,
          this.renderFrame
      )
      // this.dump10Voters("Before Animate Call")
      this.animator.animate()
      this.dirty = false
    }, "voters.render")
  }

  renderSVG = (svg: d3.Selection<any, any, any, any>, progress: number, transition: number = 1000) => {

    console.log(`VoterController.render:  progress ${progress}`)

    let sUpdate = svg.selectAll(".voter").data(this.bubbles)
    let sEnter = sUpdate.enter().append("circle")
        .classed("voter", true)
        .attr("x", (b) => b.x)
        .attr("y", (b) => b.y)
        .attr("tx", (b) => b.tx)
        .attr("ty", (b) => b.ty)
        .attr("cx", (b) => b.x)
        .attr("cy", (b) => b.y)
        .attr("r", (b) => b.radius)
        .style("fill", (b) => b.color)
        .style("opacity", .9)

    // this.bubbles.forEach(b => {
    //       this.layout.updateCxCy(b, progress)
    //     }
    // )
    sEnter.merge(sUpdate as d3.Selection<any, any, any, any>)
        .transition("voter-position")
        .ease(d3.easeLinear)
        .duration(2000)
        .attr("cx", (b) => b.tx)
        .attr("cy", (b) => b.ty)
    // .style("fill", (b) => b.color)
    // .style("opacity", (b) => b.opacity)

    sEnter.merge(sUpdate as d3.Selection<any, any, any, any>)
        .transition("voter-color")
        .ease(d3.easeLinear)
        .duration(transition)
        .style("fill", (b) => b.color)
        .style("opacity", (b) => b.opacity)

    // sEnter.merge(sUpdate as d3.Selection<any, any, any, any>)
    // .transition()
    // .ease(d3.easeLinear)
    // .duration(1000)

    sUpdate.exit().remove()
  }
}

export default VoterController