import React from 'react'

interface Props {
  className?: string
}

const ArticleFooter = (props: Props) => {
  return (
      <div style={{
        minHeight: "30vh",
        borderTop: "1px solid black",
        marginTop: "5vh",
        marginLeft: "15%",
        marginRight: "15%",
      }}>

      </div>
  )
}

export default ArticleFooter