// import {DSVRowArray} from "d3";
import {Democrats, Republicans} from "../core/Party";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
import ImageController from "../ui/elements/ImageController";
// import imageController from "../ui/elements/ImageController";
import Point from "../ui/elements/Point";
import Bubble from "../ui/elements/Bubble";
import {Candidate2D} from "../ui/elements/Candidates2D";
import Candidate from "../core/Candidate";

// [Log] candidate Anne 0.070, 0.189
// [Log] candidate Sue -0.351, -0.137
// [Log] candidate Maria 0.550, 0.300
// [Log] candidate Juan 0.750, 0.750
// [Log] candidate Bob -0.700, -0.950

export class LeviathanMathApp extends ElectionSimApp {

  // constructor(visDivId: string,
  //             dvr: DSVRowArray,
  //             DWNominate: DSVRowArray,
  //             simulationResults: DSVRowArray,
  //             sampleCongress: DSVRowArray,
  //             usTopo: any,
  //             nVoters: number,
  //             radius: number) {
  //   super(visDivId, dvr, DWNominate, simulationResults, sampleCongress, usTopo, nVoters, radius)
  //
  // }

  imageController = new ImageController(this.svg, "image1")

  createDiane = () => new Candidate2D(new Candidate("Diane", Democrats, 0, 0.01, [-.60, -.45]), "gold", 10)
  createRyan = () => new Candidate2D(new Candidate("Ryan", Republicans, 0, 0.015, [+.35, +.22]), "violet", 10)
  createRebecca = () => new Candidate2D(new Candidate("Rebecca", Republicans, 0, 0.005, [.75, .75]), "chartreuse", 10)

  newSectionMethods = [
    this.showHouseAndVoters,
    // 0
    // (_p: number): void => {
    //   this.clearVoters()
    //   this.clearGraphTitle()
    //   this.imageController.setSVG(this.svg)
    //   this.imageController.clear()
    //   this.imageController.addImage("House", "images/house.png", new Point(20, 20), 900, 900)
    //   this.addRenderItem(this.imageController)
    // },
    //1
    (_p: number): void => {
      this.clearRenderItem(this.imageController.id)
      this.onLeanUpdate(0)
      this.showVoters2D(_p)
    },
    //2
    (_p: number): void => {
      this.onLeanUpdate(.2)
      this.showVoters2D(_p)
      this.resetVoterOpacity(.8)
    },
    //3
    (_p: number): void => {
      this.setNewCandidates(this.threeCandidateDRR())
      this.layoutCandidatesAndVoters()
      this.voters.setOpacity((b: Bubble) => .5)
      this.voters.setColor(this.colorBubbleByIdeology, "colorByIdeology")
    },
    // 4
    (_p: number): void => {
      this.setActiveSimulation("voterPreferences")
      this.resetVoterOpacity(.8)
      this.setUncertainty(0)
      this.displayOutcome(this.threeCandidateDRR())
    },
    // 5
    (_p: number): void => {
      this.setActiveSimulation("uncertainty")
      this.setUncertainty(.1)
      this.displayOutcome(this.threeCandidateDRR())
    },
    // 6
    (_p: number): void => {
      this.setActiveSimulation("primaries")
      this.setUncertainty(0)
      this.showPrimaryResults(Republicans, this.threeCandidateDRR())
    },
    (_p: number): void => {
      this.showActualHouse()
    },
    // 7
    (_p: number): void => {
      this.clearRenderItem(this.imageController.id)
      this.candidates2D.clear(0)
      this.showHouse(0)
      this.timerCount = 100
      this.cycleHousePolarization(0)
    },
    //8
    (_p: number): void => {
      this.clearVoters()
      this.clearMembers(0)
      this.imageController.setSVG(this.svg)
      this.imageController.clear()
      this.imageController.addImage("House", "images/PEW-2017.png", new Point(20, 20), 900, 900)
      this.addRenderItem(this.imageController)
    },
    //9
    (_p: number): void => {
    this.setActiveSimulation("majorityRule")
      this.clearRenderItem(this.imageController.id)
      this.setUncertainty(0)
      let RR = this.threeCandidateDRR().slice(-2)
      this.displayOutcome(RR)
    },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
    (_p: number): void => { },
  ]

  progressMethods = [
    this.progressBasic, // 0
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,

    this.progressBasic, // 5
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,

    this.progressBasic, // 10
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,

    this.progressBasic, // 15
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,
    this.progressBasic,

  ]

}