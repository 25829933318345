import {RenderItem} from "./RenderItem";

class SVGButton extends RenderItem {
  active: boolean
  label: string
  width: number
  height: number;
  id: string
  parentSVG: d3.Selection<any, any, any, any>
  clickCB: () => void
  line?: d3.Selection<any, any, any, any> | undefined
  background: string
  inactiveBackground: string
  textColor: string
  inactiveTextColor: string
  fontSize: string
  x: number
  y: number
  button?: d3.Selection<any, any, any, any>

  constructor(label: string,
              id: string,
              parentSVG: d3.Selection<any, any, any, any>,
              active: boolean,
              x: number,
              y: number,
              height: number,
              width: number,
              background: string,
              textColor: string,
              inactiveBackground: string,
              inactiveTextColor: string,
              fontSize: string,
              clickCB: () => void) {
    super()

    this.id = id
    this.label = label
    this.parentSVG = parentSVG
    this.active = active;
    this.x = x
    this.y = y
    this.height = height
    this.width = width
    this.background = background
    this.inactiveBackground = inactiveBackground
    this.textColor = textColor
    this.inactiveTextColor = inactiveTextColor
    this.fontSize = fontSize
    this.clickCB = clickCB
  }

  activate = (): void => {
    // console.log(`activating ${this.label}`)
    if (!this.active) {
      this.active = true
      this.updateColors()
    }
  }

  deactivate = (): void => {
    // console.log(`deactivating ${this.label}`)
    if (this.active) {
      this.active = false
      this.updateColors()
    }
  }
  updateColors = (): void => {
    this.button?.selectAll("line")
        .style("stroke", this.getBackgroundColor())
    this.button?.selectAll("text")
        .style("stroke", this.getTextColor())
  }

  clear = () => {
    this.parentSVG.selectAll(`#${this.id}`).remove()
  }

  getTextColor = (): string => {
    return this.active ? this.textColor : this.inactiveTextColor
  }
  getBackgroundColor = (): string => {
    return this.active ? this.background : this.inactiveBackground
  }

  render = (): void => {
    this.clear()

    let g = this.parentSVG.append("g")
        .attr("class", "svgButton")
        .attr("id", this.id)
        .attr("transform", `translate(${this.x}, ${this.y})`)
        .style("cursor", "default")
        .on("click", this.clickCB)

    g.append("line")
        .attr("x1", 0)
        .attr("x2", this.width)
        .attr("y1", 0)
        .attr("y2", 0)
        .style("border", "1px solid black")
        .style("stroke", this.getBackgroundColor())
        .style("stroke-linecap", "round")
        .style("stroke-width", this.height)
        .style("cursor", "default")

    g.append("text")
        .attr("x", this.width / 2)
        .attr("y", 0)
        .style("fill", this.getTextColor())
        .style("text-anchor", "middle")
        .style("alignment-baseline", "middle")
        .style("cursor", "default")
        .text(this.label)

    this.button = g
  }
}

export default SVGButton
