class Point {
  x: number
  y: number

  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }

  manhattenDistance = (that: Point): number => {
    return Math.abs(this.x - that.x) + Math.abs(this.y - that.y)
  }
}

export default Point