class ElectionConfig {
  partyLoyalty: number
  uncertainty: number

  constructor(partyLoyalty: number, uncertainty: number) {
    this.partyLoyalty = partyLoyalty
    this.uncertainty = uncertainty
  }
}

let DWNominateConfig = new ElectionConfig(1, .4)
let UnitConfig = new ElectionConfig(0, 0)

export {DWNominateConfig, UnitConfig, ElectionConfig}
