import React, {CSSProperties} from 'react';
import styles from "../../ui/components/content/Article/Article.module.scss"
import Candidate from "../../core/Candidate";


class TableItem {
  content: string
  style: CSSProperties
  onSelect: () => void
  className: string
  candidate?: Candidate

  constructor(content: string, style: CSSProperties, onSelect: () => void = () => { }, className: string, candidate?: Candidate) {
    this.content = content
    this.style = style
    this.onSelect = onSelect
    this.className = className
    this.candidate = candidate
  }
}

class CandidateResultTable {
  title: string
  caption: string
  headings: TableItem[]
  rows: TableItem[][]
  className: string
  id: string

  constructor(title: string, caption: string, headings: TableItem[], rows: TableItem[][], className: string, id: string) {
    this.title = title
    this.caption = caption
    this.headings = headings
    this.rows = rows
    this.className = className
    this.id = id
  }
}

interface Props {
  tableInfo?: CandidateResultTable
  id?: number
}

class ResultWidget2 extends React.Component<any, any> {
  props: Props

  // eslint-disable-next-line
  constructor(props: Props) {
    super(props)
    this.props = props
  }

  resultRow = (row: TableItem[], trIdx: number): JSX.Element | null => {
    if (row.length === 0)
      return null
    let items = row.map((ti, tdIdx) => (
        <td key={trIdx * 100 + tdIdx}
            className={styles.winner + " " + ti.className}
            style={ti.style}
            onMouseDown={ti.onSelect}>
          {ti.content}
        </td>
    ))
    return (
        <tr key={trIdx}>
          {items}
        </tr>
    )
  }


  render = (): JSX.Element | null => {
    if (!this.props.tableInfo)
      return null
    let titleSelect = () => {}
    if (this.props.tableInfo.rows.length > 0)
      titleSelect = this.props.tableInfo.rows[0][0].onSelect

    return (
        <div style={{
          background: "white",
        }}>

          <table key={this.props.id || 0}
                 id={this.props.tableInfo.id}
                 className={this.props.tableInfo.className}
                 style={{
                   background: "white",
                   width: "98%",
                   margin: "1%",
                   whiteSpace: "nowrap",
                 }}
          >
            <tbody>
            <tr key={-2}>
              <th key={0}
                  colSpan={4}
                  onMouseDown={titleSelect}
              >{this.props.tableInfo.title}</th>
            </tr>
            {this.resultRow(this.props.tableInfo.headings, -1)}
            {this.props.tableInfo.rows.map((row, idx) => this.resultRow(row, idx))}
            </tbody>
            <caption className={styles.rankingsCaption}>
              {this.props.tableInfo.caption}
            </caption>
          </table>
        </div>
    )
  }
}

export {ResultWidget2, CandidateResultTable, TableItem}