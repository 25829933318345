import Election from "./Election";
import {Ballot, ElectionResult} from "./Ballot";
import Candidate from "./Candidate";


class PluralityResult extends ElectionResult {
  voteTotals: Array<[Candidate, number] >
  voteMap: Map<Candidate, number>
  constructor(winners: Array<Candidate>, voteTotals: Array<[Candidate, number] >) {
    super(winners)
    this.voteTotals = voteTotals
    this.voteMap = new Map<Candidate, number>(voteTotals)
  }
}

class PluralityElection extends Election {
  voteTotals: Array<[Candidate, number]> = []
  sortedCandidates: Array<Candidate>
  result: ElectionResult

  constructor(ballots: Array<Ballot>, candidates: Set<Candidate>) {
    super(ballots, candidates);
    let totals = new Map<Candidate, number>()
    candidates.forEach(c => totals.set(c, 0))

    ballots.forEach(b => {
      let c = b.scores.filter(cs => candidates.has(cs.candidate))[0].candidate
      totals.set(c, (totals.get(c) || 0) + 1)
    })
    // insane: reversing <key, value> pair to be <value, key> in forEach?
    totals.forEach((v, k) => this.voteTotals.push([k, v]))
    this.voteTotals.sort((a, b) => b[1] - a[1])
    this.sortedCandidates = this.voteTotals.map(a => a[0])
    this.result = new PluralityResult(this.sortedCandidates, this.voteTotals)
  }
}

export {PluralityElection}
export {PluralityResult}
