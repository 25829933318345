import React from 'react';
import {DSVRowArray} from "d3";
import styles from "./ConsensusVoting.module.scss"
import Step from "../ui/components/Step";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
import ElectionSimStoryBase from "../ElectionSimStoryBase/ElectionSimStoryBase";
import ResultsBox from "../ElectionSimulation/ResultsBox";
import {FosteringCompromiseApp} from "./FosteringCompromiseApp";

class FosteringCompromiseStory extends ElectionSimStoryBase {

  mapAreas = [
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",

    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea2",
    "drawingArea2",

    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
  ]
  visualizationAreas = [
    "drawingArea1", // 0
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",

    "drawingArea1", //5
    "drawingArea1",
    "drawingArea1",
    "drawingArea2",
    "drawingArea2",

    "drawingArea2", //10
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",

  ]

  createApp = (
      _visDivId: string,
      dvr: DSVRowArray,
      DWNominate: DSVRowArray,
      simulationResults: DSVRowArray,
      sampleCongress: DSVRowArray,
      usTopo: any): ElectionSimApp => {
    return new FosteringCompromiseApp(this.visualizationAreas[0], dvr, DWNominate, simulationResults, sampleCongress, usTopo, 5001, 4)
  }
  simState = () => {
    // console.log("simState this.state:  ", this.state)
    if (this.state.simulationState)
      return this.state.simulationState
    else
      return this.state
  }

  render() {
    // let mdash = "\u2014"
    return (
        <div className={styles.content}>
          <div>
            {this.renderVisArea(styles.drawing1, "drawingArea1")}


            { /* 0 */}
            <Step className={styles.stepElement} first={true}>
              <h3>Strategy When Elections are Held With Consensus Voting</h3>
              <p>
                This walks through some situations that illustrate how candidate strategy would evolve in elections
                held with Consensus Voting.
              </p>
              <p>
                The first situation presumes two traditional partisan candidates have declared their intention to
                contest the election. These candidates represent traditional candidates that would have a good chance of
                winning a traditional primary/plurality election.
              </p>
              <div className={styles.centeredResultBox}>
                <ResultsBox
                    className={styles.singleResultBox}
                    results={this.sectionResults("TwoCandidates")?.pluralityResults}
                    title={"Consensus Voting, early polling"}
                />
              </div>
              <p>
                Early polling suggests that the election would be close between these two candidates, but election day
                is a long way off, and other candidates may choose to enter.
              </p>
            </Step>

            { /* 1 */}
            <Step>
              <h3>A Third Candidate Senses Opportunity</h3>
              <p>
                Maria understands that there is a spectrum of opinion within her party and not every Republican supports
                the more extreme positions that often prevail in primaries. Her strategy is to avoid embracing the more
                inflammatory language used by Juan and reach out to voters in the opposition.  With the support of
                moderate Republicans and Democrats, she feels she can easily defeat Juan.
              </p>
              <p>
                At the same time, by moving closer to the center of the electorate than her Democratic opponent, she
                feels that she can capture the center, defeat Bob,  and win the election.
              </p>

              <p>
                Early polling suggests that Maria is right. She would defeat both Bob and Juan one-on-one.
              </p>


              <div className={styles.centeredResultBox}>
                <ResultsBox
                    className={styles.singleResultBox}
                    results={this.sectionResults("Opportunity")?.h2hResults}
                    title={"Consensus Voting, early polling"}
                    caption={"Select a comparison to halt animation."}
                />
              </div>

              <p>
                This visualization doesn't display the cross-hair axes showing the exact center of the electorate
                because nobody, including the candidates, knows where that is. Every candidate has their idea of
                where it might be based on polling and intuition, but those are imprecise at best.
              </p>
            </Step>


            { /* 2 */}
            <Step className={styles.stepElement}>
              <h3>Other Candidates Join the Fray</h3>
              <p>
                Maria is not the only candidate to anticipate that Consensus Voting has significantly altered the
                political landscape. Sue and Anne each enter the race with their own ideas of what the voters want
                and how to balance the views of a diverse electorate.
              </p>
              <div className={styles.centeredResultBox}>
                <ResultsBox
                    className={styles.singleResultBox}
                    results={this.sectionResults("MoreOpportunity")?.h2hResults}
                    title={"Consensus Voting, early polling"}
                    caption={"Select a comparison to halt animation."}
                />
              </div>
            </Step>

            { /* 3 */}
            <Step className={styles.stepElement}>
              <h3>Bob and Juan Reevaluate Their Strategy</h3>

              <p>
                Upon further reflection, Juan and Bob declare that they were misunderstood, and their divisive language
                was
                taken out of context. They didn't mean to disparage the voters in the other party. Further, their
                previous rigid adherence to party orthodoxy was a product of a system that gave them no alternative.
              </p>

              <div className={styles.centeredResultBox}>
                <ResultsBox
                    className={styles.singleResultBox}
                    results={this.sectionResults("Reevaluation")?.h2hResults}
                    title={"Consensus Voting, early polling"}
                    caption={"Select a comparison to halt animation."}
                />
              </div>

              <p>
                They each begin to present much more nuanced policy goals that balance the needs of all of the voters.
                Their true goal is to deliver meaningful improvements to the lives of all voters, not just
                one faction or another.
              </p>
              <p>
                Not every issue is a zero sum game. There are solutions to many political issues which give each side
                the things that are most important to them while compromising on less critical issues. Consensus Voting
                will reward candidates who excel at this difficult task.
              </p>

              <p>
                The other candidates also refine their strategy as they evaluate the dynamics of the election.
              </p>

            </Step>

            { /* 4 */}
            <Step className={styles.stepElement}>
              <h3>Who wins a race under Consensus Voting?</h3>
              <p>
                Any of these candidates would be a good representative of the voters, and it's up to the voters to
                decide
                which one is best. The broad categories of things that will determine the winner are understanding the
                electorate, candidate quality, and leadership.
              </p>
              <p>
                Simulation can tell us a lot about the broader trends that will be favored or rejected, but it can't
                say which candidates will understand the electorate best, connect well with voters, or
                demonstrate the leadership needed to change voters' opinions and move the center.
              </p>
              <p>
                Simulation can illustrate how those scenarios might play out.
              </p>
              <div className={styles.centeredResultBox}>
                <ResultsBox
                    className={styles.singleResultBox}
                    results={this.sectionResults("MoreReevaluation")?.h2hResults}
                    title={"Consensus Voting, early polling"}
                    caption={"Select a comparison to halt animation."}
                />
              </div>
            </Step>

            { /* 5 */}
            <Step className={styles.stepElement}>
              <h3>Understanding the Electorate</h3>
              <p>
                When candidates delude themselves about the views of the electorate, they are going to lose unless
                all other candidates are even more out of step with the people they are trying to represent.
              </p>
              <p>
                In theory, the candidates are competing to represent the views of the electorate in
                a representative democracy. It should come as no surprise that representing the views of the
                electorate requires that the candidate know what those views are.
              </p>
              <p>
                Here, Sue has done a better job of understanding the electorate.
              </p>
              <div className={styles.centeredResultBox}>
                <ResultsBox
                    className={styles.singleResultBox}
                    results={this.sectionResults("Understanding")?.h2hResults}
                    title={"Consensus Voting, early polling"}
                    caption={"Select a comparison to halt animation."}
                />
              </div>
            </Step>

            { /* 6 */}
            <Step className={styles.stepElement}>
              <h3>Candidate Quality</h3>
              <p>
                Voters balance three things when selecting a candidate: Party affiliation, policy affinity, and
                candidate quality. Depending on the voter and the candidates, any one of those may dominate the
                decision,
                but when all of the candidates have similar policy positions, candidate quality plays a decisive
                role.
              </p>
              <p>
                Candidate quality refers to all of the things that cause a voter to choose one candidate over another
                other than policy or party affiliation. A voter could make their decision based on anything, but the
                most common things are:
              </p>
              <ul>
                <li>The candidate's ability to connect with the voters.</li>
                <li>Perceived shared experience with the candidate.</li>
                <li>The integrity of the candidate.</li>
                <li>Experience, military service, track record in politics.</li>
                <li>Money.</li>
              </ul>
              <p>
                This cycles through each candidate and shows how they could win if they emerge as connecting better
                with the voters.
              </p>
              <div className={styles.centeredResultBox}>
                <ResultsBox
                    className={styles.singleResultBox}
                    results={this.sectionResults("Quality")?.h2hResults}
                    title={"Consensus Voting, early polling"}
                    caption={"Select a comparison to halt animation."}
                />
              </div>
            </Step>

            { /* 7 */}
            <Step className={styles.stepElement}>
              <h3>Leadership</h3>
              <p>
                Imposing unpopular policy on an unwilling electorate is neither democracy nor leadership. That
                ultimately becomes tyranny, and tyranny is violence.
              </p>
              <p>
                Leadership is the ability of a candidate to connect with the electorate, inspire their passion, and
                persuade them with the wisdom and justice of their arguments.
              </p>
              <p>
                A candidate may well perfectly measure the exact center of the electorate only to find that another
                candidate has inspired the voters to change their views.
              </p>
              <div className={styles.centeredResultBox}>
                <ResultsBox
                    className={styles.singleResultBox}
                    results={this.sectionResults("Leadership")?.h2hResults}
                    title={"Consensus Voting, early polling"}
                    caption={"Select a comparison to halt animation."}
                />
              </div>

            </Step>
          </div>
          <div className={styles.spacerDiv}/>

          <div>
            {this.renderVisArea(styles.drawing2, "drawingArea2")}
            { /* 8 */}
            <Step first={true} className={styles.stepElement}>
              <h3>Fostering Compromise</h3>
              <p>
                When this process is repeated across all 435 U.S. House districts, the ideology of Congress matches
                the ideology of the people they are elected to serve. These Members are as diverse as the districts
                they represent, and each of them represents a spectrum of voters.
              </p>
              <p>
                Our primary/plurality process takes a step away from compromise every time a district with a mix of
                voters elects a candidate who is bound to a single faction and unable to compromise without risking
                their political future.
              </p>
              <p>
                Consensus Voting changes that. Each election is itself a compromise that balances the views of the
                electorate to find a candidate the electorate collectively prefers. Consensus Voting takes a step
                toward compromise at the ballot box by election candidates who are themselves a compromise. These
                candidates are then free to place country before party as they represent the unique needs of all
                of their voters in government.
              </p>
            </Step>

            { /* 9 */}
            <Step className={styles.stepElement}>
              <h3>The Value of Compromise</h3>

              <p>
                There is a reason James Madison never mentioned political compromise when he designed a government based
                on the concept. Nobody likes to compromise. That has not changed in the ensuing 200 years.
              </p>
              <p>
                When I present Consensus Voting to people, there is often an "Aha!" moment where they understand what
                majority rule really means. This is often followed by a "But I'll never be able to elect ....," or the
                realization that their cherished policy ideas can't be implemented without broad support. Their reaction
                is often to retreat toward voting systems they believe would allow them to implement their policies.
                Even when those systems violate majority rule.
              </p>
              <p>
                I point out that implementing policy the electorate opposes is neither likely to be successful nor
                democratic. The political backlash from such attempts can be extreme, and it is too easy for opponents
                to undermine anything unpopular.
              </p>
              <p>
                They dream of a world where they are able to implement policies that are important to them even
                when they are unpopular, but it's equally likely that the opposition could manipulate the choices voters
                have to implement their own partisan agenda. Neither should be allowed. It may take more time than they
                would like, but any policy implemented with widespread support is more likely to be successful.
              </p>
              <p>
                Instead, I argue that though the voters are by no means perfect, democracy is based on the idea that
                there is wisdom in the crowd. If you believe in that wisdom and the justice of your ideas, then you
                must believe that the path to success for your beliefs is through persuasion instead of imposition.
                In the long term, a democratic system where the results balance the values of all voters allows that
                wisdom to surface and flourish.
              </p>
              <p className={styles.pullQuote}>
                “You can fool some of the people all of the time and all of the people some of the time, but you cannot
                fool all of the people all of the time” – Abraham Lincoln.
              </p>
            </Step>
            <div style={{
              minHeight: "30vh"
            }}>
            </div>
          </div>
        </div>
    )
  }
}

export default FosteringCompromiseStory
