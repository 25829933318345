import * as d3 from 'd3'
import Point from './Point'
import SVGAxis from "./SVGAxis";

class CategoryAxis extends SVGAxis {
  id: string
  svg: d3.Selection<any, any, any, any>
  categories: Array<string>
  width: number
  topLeft: Point
  dirty: boolean = true
  title: string
  vertical: boolean

  constructor(id: string, svg: d3.Selection<any, any, any, any>, categories: Array<string>, topLeft: Point, width: number, title: string, vertical: boolean = false) {
    super()
    this.id = id
    this.svg = svg
    this.categories = categories
    this.width = width
    this.topLeft = topLeft
    this.title = title
    this.vertical = vertical
  }

  clear = () => {
    let name = `${this.id}-g`
    // console.log(`clearing axis ${name}`)
    d3.selectAll("#" + name).remove()
  }


  render = () => {
    let name = `${this.id}-g`
    // console.log(`rendering axis ${name} ${this.dirty}`)
    if (!this.dirty) return
    this.clear()
    let axisG = this.svg.append("g")
        .attr("id", name)
        .style("font-size", "30")
        .attr("transform", `translate(${this.topLeft.x}, ${this.topLeft.y})`)

    let scale = d3.scaleBand()
        .range([0, this.width])
        .domain(this.categories)

    if (this.vertical) {
      axisG.call(d3.axisRight(scale).tickSize(0))
          .selectAll("text")
          .style("text-anchor", "middle")
          .attr("dx", "-.8em")
          .attr("dy", "-.5em")
          .attr("transform", "rotate(-90)");

    }
    else {
        axisG.call(d3.axisBottom(scale).tickSize(0))
    }

    this.dirty = false
    axisG.append("text")
        .attr("text", this.title)

    axisG.append("text")
        .attr("id", "x-label")
        .attr("text-anchor", "middle")
        .attr("y", 40)
        .attr("x", this.width / 2)
        .attr("dy", ".75em")
        .attr("fill", "black")
        .attr("font-size", "16pt")
        .text(this.title)
  }
}

export default CategoryAxis