import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class ShapingPolitics extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }

  render() {
    // let mdash = "\u2014"

    return (
        <Article title={"Shaping Politics"}
                 subtitle={"Election rules dictate our political reality, is this what we want?"}>
          <div className={styles.textContent}>
            <p>
              When a 45/55 district elects a candidate, that candidate knows that their political survival depends on
              satisfying their primary voters. They represent only the 55%, the 45% are completely frozen out of the
              democratic process. Their party may win other districts and give them some indirect say but even that is
              limited by the makeup of the legislative body. Being in the minority of the U.S. House of Representatives
              does not confer a lot of political power.
            </p>
            <p>
              The separation of powers can force the two opposing factions to work together, but that ability to forge
              compromises is limited. If leaders in a factional democracy place country before party by entering into a
              compromise their faction opposes, they risk being defeated in their next primary for factional disloyalty.
              Stepping away from compromise at the ballot box by electing partisan purists makes forging the compromises
              necessary for a peaceful society all but impossible, even when the people would not reject compromise.
            </p>
            <p>
              The University of Maryland School of Public Policy conducted 35 different surveys, contacting more than
              90,000 survey respondents from 2016 to 2020 to study polarization and try to determine whether it is
              possible to find common ground on polarizing issues. This was an attempt to peer underneath the
              markers of tribal identity that characterize our polarized society and understand if the political issues
              that divide us can be reconciled; to discover if we can find solutions that we can all agree to.
            </p>
            <p>
              This project tried to solve this problem by putting the respondents in the shoes of a
              policymaker by briefing them on the issues and the arguments for and against each proposal. Finally, they
              were asked for their recommendations.
            </p>
            <p>
              The topics covered almost every contentious political issue we face today: social security, medicare,
              federal budget, healthcare, immigration, poverty, environment, energy, police reform, trade, nuclear
              weapons, NATO, international aid, and net neutrality. In each of these areas, the participants in the
              study were able to come to an agreement with a majority supporting a proposal. Further, most of the time,
              a majority of both parties supported the proposal, but always with both parties at least tolerating it.
            </p>
            <p>
              It’s both stunning and, all too predictable that the American people can agree on solutions – or
              at least progress – on every issue we face while our politicians remain unable to make similar progress.
              On every issue, at least one faction feels that its political ends are better served by deadlock than
              progress.
            </p>
            <h3>
              Stepping Towards Compromise
            </h3>
            <p>
              But what if elections took a step toward compromise instead of away from it? Consensus Voting elects
              candidates who represent a spectrum of voters and to win reelection, they must balance the views of
              that spectrum. They may lean conservative or liberal to match their district, but they will be rewarded
              at the ballot box for finding compromise.
            </p>
            <p>
              Without compromise, there is only violence. By stepping toward compromise at the ballot box, Consensus
              Voting makes the second step of implementing policy that balance the needs of the entire electorate
              essential for political survival. The mathematics of the Leviathan work to reward candidates who serve
              the public good as defined by all voters.
            </p>

          </div>
        </Article>
    )
  }
}




















