import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class ProportionalFactionalism extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }

  render() {
    // let mdash = "\u2014"

    return (
        <Article title={"Proportional Representation (of Factions)"}
                 subtitle={"Proportional representation recreates the problems of direct democracy."}>
          <div className={styles.textContent}>
            <p>Proportional Representation systems recreate the problems of direct democracy.</p>
            <p>Can improve on ?</p>
            <p>Who represents the public good?</p>
            <p>History is littered with the corpses of proportional representation schemes</p>
            <p>They work great, until they don't.</p>
            <p>
              Someone in the minority (a Texas Democrat, California Republican) has zero
              influence on policy even when they are a meaningful portion of the electorate.
            </p>
          </div>
        </Article>
    )
  }
}




















