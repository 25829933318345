import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class ProportionalRepresentation extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }


  render() {
    // let mdash = "\u2014"
    // let gt = ">"

    return (
        <Article title={"Proportional Representation"}
                 subtitle={"Institutionalized factionalism recreates the problems of direct democracy."}>
          <div className={styles.textContent}>
            <h3>Under Construction</h3>
            <p>
              This topic is not complete, but there is some discussion in <a href={"Global"}>The Global Threat.</a>
            </p>
          </div>
        </Article>
    )
  }
}




















