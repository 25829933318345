import React from 'react'
import styles from './VotingReform101.module.scss'
import SectionTitle from '../../typography/SectionTitle'
import TopicHeader from '../TopicHeader/TopicHeader'
import ArticleIntro from "../ArticleIntro/ArticleIntro";

interface Props {

}

const MoreStuffLeft = (props: Props) => {
  return (
      <div className={styles.content}>
        <SectionTitle
            title="Voting Is Not Simple"
            caption="The wrong change can make things worse."
        />
        <ul>

          <li>
            <ArticleIntro
                title="Election Visualization"
                subtitle="Interactive exploration of election outcomes under Consensus Voting, Instant Runoff, and
                Primaries."

                link="/ElectionSim"
                imageUrl={"/images/electionSim.png"}
            />
          </li>

          <li>
            <ArticleIntro
                title="Instant Runoff Voting"
                subtitle="Lots of complexity to create Instant Primaries."
                link="/IRV"
                imageUrl={"/images/IRV3.png"}
            />
          </li>

          <li>
            <TopicHeader
                title="Range Voting, Approval Voting, and Borda Counts"
                description={"\"My system is only intended for honest men.\""}
                link="/Cardinal"
            />
          </li>

          <li>
            <TopicHeader
                title="Proportional Representation"
                description="Institutionalized Factionalism recreates the demise of direct democracy."
                link="/ProportionalRepresentation"
            />
          </li>


          <li>
            <TopicHeader
                title="No Voting System Is Perfect."
                description="Like every problem, there is no perfect solution."
                link="/Perfect"
            />
          </li>

          <li>
            <TopicHeader
                title="An Example of Majority Rule"
                description="An example from California's Top-2 system points the way."
                link="/Top2Hope"
            />
          </li>

        </ul>

        <SectionTitle title="Technical Details" caption="Understanding the science"/>
        <ul>

          <li>
            <ArticleIntro
                title="Understanding Voter Representation"
                subtitle="What is voter representation and how is it measured?"
                link="/Representation"
                imageUrl={"/images/representation.png"}
            />
          </li>
          <li>
            <TopicHeader
                title="Simulation Tutorial"
                description="A tutorial on election simulation."
                link="/SimulationTutorial"
            />
          </li>

          <li>
            <ArticleIntro
                title="A Top-5 Primary"
                subtitle="What is a Top-5 primary and why should we use it?"
                link="/Top5"
                imageUrl={"/images/top5.png"}
            />
          </li>
        </ul>
      </div>
  )
}

export default MoreStuffLeft