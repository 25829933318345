import {Party} from "./Party";
import {ElectionConfig} from "./ElectionConfig";
import Candidate from "./Candidate";
import {random} from "./Random";

class Voter {
  ideology: number
  party: Party
  ivec: number[]

  constructor(ivec: number[], party: Party) {
    this.ivec = ivec
    this.ideology = ivec[0]
    this.party = party

  }

  // euclidean distance between two vectors.
  distance = (ivec: number[]): number => {
    // performance shortcut
    if (this.ivec.length === 1)
      return Math.abs(ivec[0] - this.ivec[0])

    // full euclidean distance.
    let ssd = 0
    this.ivec.forEach((v, i) => {
      let d = v - ivec[i]
      ssd += d * d
    })
    return Math.sqrt(ssd)
  }

  score = (candidate: Candidate, config: ElectionConfig): number => {
    let s = -this.distance(candidate.ivec)
    // add a bonus for same party (30, typically) or 15 if an independent.  These can also be viewed as a penalty
    // (lack of bonus) for a party mismatch.
    s += this.party.adjustmentFor(candidate.party.name) * config.partyLoyalty
    s += candidate.quality
    s += random.normal() * config.uncertainty
    return s
  }
}
export {Voter}
