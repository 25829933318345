import {ConsensusVotingApp} from "../Introduction/ConsensusVotingApp";
import {Party, Republicans} from "../core/Party";
import {PluralityResult} from "../core/PluralityElection";
import Bubble from "../ui/elements/Bubble";
import Candidate from "../core/Candidate";
import VoterBubble from "../ui/elements/VoterBubble";
import {Candidate2D} from "../ui/elements/Candidates2D";

export class IRV2DApp extends ConsensusVotingApp {
  irvTimerDelay = 2000

  showIRVRaceDefault = (_progress: number = 1) => {
    this.showIRVRaceCandidates(this.fiveCandidateAll())
  }

  createMaria = () => new Candidate2D(new Candidate("Maria", Republicans, 0, 0.005, [.55, .2]), "chartreuse", 10)

  fiveCandidateAll = () => [
    this.createAnne(),
    this.createBob(),
    this.createJuan(),
    this.createMaria(),
    this.createSue(),
  ]

  showPartyPreferenceLoop = (_progress: number) => {
    this.showIRVRaceDefault(_progress)
    this.timerCount = 5
    this.onPartyPreferenceTimer(0)
  }

  onIRVTimer = (round: number) => {
    this.colorVotersByIRVRound(round)
    let nextRound = (round + 1) % this.irvResult.rounds.length
    this.addTimer("resultsTimer", () => {this.onIRVTimer(nextRound)}, this.irvTimerDelay)
  }

  setVoterParticipantOpacity = (irvRound: number) => {
    let round: PluralityResult = this.irvResult.rounds[irvRound]
    let party: Party = round.orderedCandidates.slice(-1)[0].party
    let activeCandidates = new Set(round.orderedCandidates)
    let choiceCandidates = new Set(round.orderedCandidates.filter((c) => c.party === party).slice(-2))

    var firstActiveChoice: Candidate
    this.voters.setOpacity((v: Bubble) => {
      let ballot = (v as VoterBubble).ballot
      // find the first candidate on the ballot that's still active
      ballot.scores.every(s => {
        if (activeCandidates.has(s.candidate)) {
          firstActiveChoice = s.candidate
          return false
        }
        return true
      })
      return choiceCandidates.has(firstActiveChoice) ? .8 : .1
    })
  }

  displayIRVVoterParticipation = (round: number) => {

    this.selectIRVTable(round)
    this.colorVotersByIRVRound(round)
    this.setVoterParticipantOpacity(round)
    this.candidates2D.onUpdate = (cb: Candidate2D) => {
      this.processCandidateUpdate([cb])
      this.setVoterParticipantOpacity(round)
      this.voters.applyColorAndOpacity()
      this.voters.renderFrame(1)
    }
  }
  partyLoyalty = .5

  newSectionMethods = [
    // 0
    () => {
      this.showVoters2D(0)
    },

    // 1
    () => {
      this.setActiveSimulation("spoiler")
      this.setLoyalty(this.partyLoyalty)
      this.setUncertainty(.2)
      this.showIRVRaceCandidates(this.threeCandidateSpoiler())
      this.onLeanUpdate(0)
    },

    // 2
    () => {
      this.setActiveSimulation("local")
      this.setLoyalty(this.partyLoyalty)
      this.setUncertainty(0)
      this.onLeanUpdate(-.75)
      this.showIRVRaceCandidates(this.threeCandidateDDR())
    },

    // 3
    () => {
      this.setActiveSimulation("competitiveLocal")
      this.setLoyalty(this.partyLoyalty)
      this.setUncertainty(0)
      this.onLeanUpdate(-.35)
      this.showIRVRaceCandidates(this.threeCandidateDDR())
    },

    // 4
    () => {
      this.setActiveSimulation("competitiveLocal")
      this.setLoyalty(this.partyLoyalty)
      this.setUncertainty(0)
      this.onLeanUpdate(-.35)
      this.displayOutcome(this.threeCandidateDDR().slice(0, 2))
    },

    // 5
    () => {
      this.setActiveSimulation("Details")
      this.setLoyalty(this.partyLoyalty)
      this.onLeanUpdate(0)
      this.showIRVRaceDefault()
      this.timerCount = 4
      this.onIRVTimer(0)
    },

    // 6
    () => {
      this.setActiveSimulation("irvRound1")
      this.setLoyalty(this.partyLoyalty)
      this.setNewCandidates(this.fiveCandidateAll())
      this.displayIRVVoterParticipation(0)
    },
    // 7
    () => {
      this.setActiveSimulation("irvRound2")
      this.setLoyalty(this.partyLoyalty)
      this.setNewCandidates(this.fiveCandidateAll())
      this.displayIRVVoterParticipation(1)
    },
    // 8
    () => {
      this.setActiveSimulation("irvRound3")
      this.setLoyalty(this.partyLoyalty)
      this.setNewCandidates(this.fiveCandidateAll())
      this.displayIRVVoterParticipation(2)
    },
    // 9
    () => {
      this.setActiveSimulation("final")
      this.setLoyalty(this.partyLoyalty)
      this.setUncertainty(0)
      this.showIRVRaceDefault()
      this.stopAllTimers()
      this.selectIRVTable(3)
    },
    // 10
    () => {
      this.setActiveSimulation("impossible")
      this.setLoyalty(this.partyLoyalty)
      this.onLeanUpdate(0)
      this.showIRVRaceDefault()
    },
    // ?
    () => { },
    // ?
    () => { },
    () => { },
    () => { },
  ]

  progressMethods = []
}

export default IRV2DApp