import React from 'react';
import {DSVRowArray} from "d3";
import styles from "./ConsensusVoting.module.scss"
import Step from "../ui/components/Step";
import ElectionSimApp from "../ElectionSimulation/ElectionSimApp";
import {ConsensusVotingApp} from "./ConsensusVotingApp";
import HorizontalResultsBox from "../ElectionSimulation/HorizontalResultsBox";
import ResultsBox from "../ElectionSimulation/ResultsBox";
import ElectionSimStoryBase from "../ElectionSimStoryBase/ElectionSimStoryBase";

class IS2 extends ElectionSimStoryBase {

  mapAreas = [
    "mapArea1",
    "mapArea1",
    "mapArea1",
    "mapArea1",
    "mapArea1",

    "mapArea1",
    "mapArea1",
    "mapArea1",
    "mapArea1",
    "mapArea1",

    "drawingArea3",
    "drawingArea3",
    "drawingArea3",
    "drawingArea4",
    "drawingArea4",

    "drawingArea4",
    "drawingArea4",
    "drawingArea4",
    "drawingArea6",
  ]
  visualizationAreas = [
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",
    "drawingArea1",

    "drawingArea1",
    "mapArea1",
    "drawingArea2",
    "drawingArea2",
    "drawingArea2",

    "drawingArea3",
    "drawingArea4",
    "drawingArea5",
    "drawingArea5",
    "drawingArea5",

    "drawingArea5",
    "drawingArea5",
    "drawingArea5",
    "drawingArea6",
  ]

  createApp = (
      visDivId: string,
      dvr: DSVRowArray,
      DWNominate: DSVRowArray,
      simulationResults: DSVRowArray,
      sampleCongress: DSVRowArray,
      usTopo: any): ElectionSimApp => {
    return new ConsensusVotingApp(this.visualizationAreas[0], dvr, DWNominate, simulationResults, sampleCongress, usTopo, 5001, 4)
  }


  render() {
    let mdash = "\u2014"
    return (
        <div className={styles.content}>
          <div className={styles.flyover} id={"detailsFlyover"} style={{
            display: "none",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 1,
          }}>
            <h6> Representative: <span id={"memberName"}>foo</span></h6>
            <h6> Party: <span id={"party"}>foo</span></h6>
            <h6> District: <span id={"districtName"}>foo</span></h6>
            <h6> Left/Right Ideology: <span id={"ideology"}>0</span></h6>
            <h6><span id={"representation"}> </span></h6>
          </div>
          <div>
            {this.renderVisArea(styles.drawing1, "drawingArea1")}
            { /* 0 */}

            <div className={"step"}> </div>
            <Step className={styles.stepElement} first={true}>
             <h3> This is broken </h3>
              <p>
                There's a striking mismatch here.  The first time I saw this data was from a PEW study on political
                typology and I was stunned. I had thought that since the politicians were polarized the people must
                be also.  That's not true, I've since our confirmed the voter ideology data from several sources,
                including my election process
              </p>
            </Step>

            { /* 2 */}
            <Step className={styles.stepElement}>
              <h3>Voter Representation</h3>
              <p>
                This expands the view of voter opinion into two dimensions: fiscal and social. Republicans are primarily
                in the upper right and Democrats in the lower left. This group of voters is balanced, with an even mix
                of Democrats and Republicans.
              </p>
              <p>
                Voters views on social and fiscal issues are <i>correlated</i>, meaning that if they are fiscally
                conservative
                they are more likely to be socially conservative. The same also applies a fiscally liberal
                voter {mdash} they are
                more likely to be socially liberal.
              </p>
              <p>
                The voters are colored for their left-right position: blue in the lower-left and red in the upper-right.
              </p>

            </Step>

            { /* 3 */}
            <Step className={styles.stepElement}>
              <h3>An Election Between Two Candidates</h3>

              {this.generalResultTable()}

              <p>
                This shows the outcome of an election between two candidates in a balanced district. Voters are
                colored for the candidate that most closely matches their values. Juan has won the contest and is a
                better candidate than Bob to represent these voters. This is a foundational principle of democracy: Juan
                is a better representative of these voters here simply because the voters prefer him.
              </p>
              <p>
                Many people {mdash} starting with Juan and Bob {mdash} would say to look no further, but there are
                problems with this outcome.
              </p>
              <p>
                Juan, or whoever wins, will go on to represent the voters who elected him, not all of the voters in the
                district. Despite getting 48% of the vote, Bob's supporters will have zero say in the policy that Juan
                supports as a House member. It is winner take all as the Representatives are elected and then again
                at the legislative level depending on which side has a majority in the House.
              </p>
              <p>
                Like many of the visualizations on this site, this image is interactive. Dragging the candidate circles
                on the left will update the results as you drag.
              </p>
              {this.renderBalanceCaption()}
            </Step>

            { /* 4 */}
            <Step className={styles.stepElement}>
              <h3>A Closer Look at Juan</h3>
              <p>
                What if the race had been between Juan and a different candidate? Let's choose a not-so-random voter
                to become Juan's opponent and see how that works out.
              </p>
              {this.generalResultTable()}
              <p>
                Juan loses by a huge margin. Juan may be a better choice than Bob, but the voters prefer Anne to
                Juan by 2 to 1! This takes the luster off of Juan's victory. Juan is a terrible representative
                of these voters, and can only win against an even worse opponent.
              </p>
              <p>
                The voters prefer Anne to Juan (or Bob, as we shall see later) because Anne does a better job of
                representing the needs of all of the voters than Juan does. If Anne were to represent the district
                at the legislative level she would advocate policy that represented all of the voters in this
                district.
              </p>
            </Step>

            { /* 5 */}
            <Step className={styles.stepElement}>
              <h3>What Does Representation Mean?</h3>
              <div style={{paddingTop: "20px", marginBottom: "20px"}}>
                {this.representationScore()}
              </div>
              <p>
                Not all voters could be candidates. But, just comparing their ideological differences, how many voters
                would beat Juan in a head-to-head race?
              </p>
              <p>
                Juan would lose to any of the darker blue voters if they were his opponent. Comparing Juan against each
                voter as a potential opponent, Juan would win against {this.representationNumber().toFixed(0)}%
                of them. We will call that number Juan's <i>Representation Score</i>.
              </p>
              <p>
                Juan is a poor representative for this electorate because there are so many other potential candidates
                the voters would prefer. The representation score is based on the simple and fundamental principle that
                the candidate who receives a majority of the votes in a two candidate race is the winner.
              </p>

              <p>
                Conservative candidates will have good scores in conservative districts, liberal candidates will have
                good scores in liberal districts. Voters <i>want</i> candidates with higher representation scores
                because candidates with higher scores will almost always win.
              </p>

              <p>
                The background color of the Representation Score will be used on the next visualization. Drag the circle
                representing Juan around to see how that affects the score.
              </p>

            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.mapArea1, "mapArea1")}
            { /* 6 */}
            <Step first={true} className={styles.stepElement}>
              <h3>Voter Representation in America Today is Terrible</h3>
              <p>
                By stitching together data from election results, surveys, simulation, and Congressional voting records,
                it is possible to estimate the representation score for each member of Congress. It's awful. Few members
                are representative, and far too many are divisive.
              </p>

              <p>
                When we elect divisive members, we do more than just elect people to Congress. We promote their divisive
                reality. These toxic views are given legitimacy when they are repeated and promoted by elected leaders.
                Meanwhile, the common values we all share are ignored.
              </p>

              <p>
                Since voters would elect more representative candidates if they could, why don't they?
              </p>

            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing2, "drawingArea2")}
            { /* 7 */}
            <Step first={true} className={styles.stepElement}>
              <h3>Eliminating Better Choices.</h3>
              <p>
                Voters elect unrepresentative candidates, because better candidates are eliminated before all of the
                voters have a chance to participate. Looking back at the contest between Juan, Anne, and Bob,
                you may assume that Anne was not in the race. She was, but she was eliminated in the Republican
                primary.
              </p>
              <p>
                There were 5 candidates in this race and Juan loses by between 20% and 30% to every candidate but
                Bob, his general election opponent.
              </p>
              <p>
                This table shows the voter preferences {mdash} the outcome of a two candidate race {mdash} between Juan
                and each of the other candidates. Click on a row to show the breakdown in each contest. Juan is an
                absurdly bad choice to represent these voters and about typical for America.
              </p>
              {this.consensusTable("Juan")}
            </Step>
            { /* 8 */}
            <Step className={styles.stepElement}>
              <h3>GTO Politics</h3>
              <p>
                Like every successful politician Juan plays <a href={"/Overview"}>Game Theory Optimal Politics</a>. Juan
                knows that if he can win his primary, the general election will be a formality. Divisive tactics are
                extremely successful amongst this small group of voters and help power Juan to victory in the Republican
                primary.
              </p>
              <p>
                This means that Juan's election and reelection depend on this small group of voters, he will represent
                them to the exclusion of the other 70% of the electorate in his district. This strategy is employed
                over and over again across the country filling Congress with Representatives who don't represent anybody
                but the small group of voters they depend on for their seat.
              </p>
              <p>
                This is the root of polarization. When candidates are controlled by uncompromising factions within
                their own party it is impossible to find the compromises necessary to produce policy balances the needs
                of the broader electorate.
              </p>
              <div className={styles.horizontalResults}>
                <HorizontalResultsBox
                    title={"Election Results"}
                    results={this.state.simulationState?.primaryResults}
                />
              </div>
            </Step>


            { /* 9 */}
            <Step className={styles.stepElement}>
              <h3>Bob Helps Juan.</h3>
              <p>
                Sue would easily defeat Juan, but fortunately for Juan, he has some help from Bob.
              </p>
              <p>
                Bob eliminates Sue in the Democratic primary. Sue would easily defeat either Juan or Bob if all of
                the voters get a say, but Bob wins the primary because it's dominated by a single tribe.
              </p>
              <p>
                The parties have this bizarre cooperation where each relies on the other to be partisan enough
                to make their own more partisan goals acceptable.  Each side operates under the delusion that they
                are the centrists and the other party is the extreme one while both are being pulled away from the
                center by their own activists.
              </p>
              <p>
                The result is a government dominated by partisans of one stripe or another who represent only the
                partisan voters that elected them. Their election and reelection depends on satisfying these
                uncompromising voters and the result is the polarization and dysfunction we see today.
              </p>

              <p>
                This simulation includes some more representative candidates to demonstrate that they should win but
                don't. The bigger problem is that candidates, donors, and the media all play GTO politics. They know
                that less divisive candidates are at a disadvantage. The result is good candidates who represent all of
                the voters rarely run. When they do run, it's hard for a doomed effort to attract the donors,
                volunteers, and media coverage that fuel a campaign.
              </p>
              <div className={styles.horizontalResults}>
                <HorizontalResultsBox
                    title={"Election Results"}
                    results={this.state.simulationState?.primaryResults}
                />
              </div>
            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing3, "drawingArea3")}
            { /* 10 */}
            <Step first={true} className={styles.stepElement}>
              <h3>
                Unrepresentative Results
              </h3>
              <p>
                The map and dots show the results of simulating the current election process for the United States House
                of Representatives. The simulation assumes the unified population shown at the beginning, and doesn't
                consider social media, cable news, or individual politicians. You can use the buttons on the right
                to compare the simulated and actual results.
              </p>

              <p>
                Candidates are always going to play Game Theory Optimal (GTO) politics, or simply lose to candidates
                who do. The problem is that under our current system, GTO means being divisive, toxic, inflexible, and
                utterly beholden to their primary voters.
              </p>

              <p>
                We need to change the system so that GTO means representing all of the voters, not just their party's
                voters and not just their primary voters.
              </p>

            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing4, "drawingArea4")}
            { /* 11 */}
            <Step first={true} className={styles.stepElement}>
              <h3>A Better System</h3>
              <p>
                We start with a general election with the same <a href="/TopFive">five candidates</a> shown in the
                primary-based example. The first step to use a Ranked Choice Ballot to allow every voter to express
                their opinion about every candidate.
              </p>
              <p>
                Consensus Voting finds the Consensus Candidate to determine the winner. To find the Consensus Candidate,
                each pair of candidates is compared one-on-one. A voter is said to prefer a candidate if they rank them
                higher on their ballot. When comparing Anne and Juan, a voter who ranked Anne second and Juan fourth,
                prefers Anne to Juan. If more voters prefer Anne to Juan than vice-versa, Anne wins that comparison.
                The candidate who wins all of their comparisons is the Consensus Candidate and the winner.
              </p>
              <p>
                We will follow a hypothetical voter {mdash} Emily {mdash} and how her ballot is counted in this same
                example race to explain how this works in more detail.
              </p>
              <p>
                To set the stage: Emily is a Democrat who is frustrated by divisive politics from both parties. Juan has
                represented Emily's district for several terms and Emily finds him divisive and partisan. Emily is
                hopeful that Sue or Anne might change things. Emily's ballot is shown on the right.
              </p>
            </Step>
          </div>

          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing5, "drawingArea5")}
            { /* 12 */}
            <Step first={true} className={styles.stepElement}>
              <h3>Emily's ballot and Juan</h3>
              <p>
                We will look at the results for each candidate and how Emily's ballot factored into those results.
              </p>
              <p>
                Emily ranked Juan last. In each comparison for Juan, Emily has ranked a his opponent higher on her
                ballot so it counts for his opponent. For example, Emily ranked Anne 3rd and Juan 5th, so Emily's
                ballot counts for Anne in the contest between Anne and Juan.
              </p>
              <p>
                Juan loses to every candidate but Bob when every voter's opinion matters.
              </p>
              {this.consensusTable("Juan")}
            </Step>

            { /* 13 */}
            <Step className={styles.stepElement}>
              <h3>Emily's ballot and Bob</h3>
              <p>
                Emily ranked Bob second on her ballot. Emily's ballot counted as a vote for Bob in his comparison with
                every candidate but Sue.
              </p>
              <p>
                Bob was able to win the Democratic primary, but he loses badly to every candidate when all of the
                voters get a say.
              </p>
              {this.consensusTable("Bob")}
            </Step>
            { /* 14 */}
            <Step className={styles.stepElement}>
              <h3>Emily's ballot and Maria</h3>
              <p>
                Emily ranked Maria fourth on her ballot. Emily's ballot counts as a vote for Maria in Maria's comparison
                with Juan, but against Maria in Maria's comparisons with each of the other candidates.
              </p>
              <p>
                Maria easily defeats both Bob and Juan when they are compared head-to-head.
              </p>
              {this.consensusTable("Maria")}
            </Step>
            { /* 15 */}
            <Step className={styles.stepElement}>
              <h3>Emily's ballot and Sue</h3>
              <p>
                Sue was Emily's first choice. Emily's ballot counted as a vote for Sue in each of Sue's comparisons.
              </p>
              <p>
                Listing second, third and fourth choices did not harm Sue in any way. Emily's ballot still counts
                as a vote for Sue in every comparison.
              </p>
              {this.consensusTable("Sue")}
            </Step>
            { /* 16 */}
            <Step className={styles.stepElement}>
              <h3>Emily's Ballot and Anne</h3>
              <p>
                Anne was Emily's third choice. Emily listed members of her own party first and second, and listed Anne
                third as a less divisive alternative from the other party.
              </p>
              <p>
                The voters prefer Anne to each of the other candidates in the race. Anne is the Consensus Candidate
                and the winner. Choosing any other candidate with these ballots would override the clearly expressed
                wishes of the voters.
              </p>
              <p>
                It is the voters like Emily that allow Anne to prevail when every voter matters. These third place votes
                reward Anne for not being divisive, for standing up to the more extreme elements of both parties, and
                for representing all of the voters.
              </p>
              <p>
                To win, Anne needed support from voters to her left against Maria and Juan. She also needed support from
                voters to her right against Sue and Bob. Anne needs to do the best job of balancing the needs of all of
                the voters to win. In other words she must represent all of the voters in the electorate.
              </p>
              {this.consensusTable("Anne")}

            </Step>
            { /* 18 */}
            <Step className={styles.stepElement}>
              <h3>Consensus Results</h3>
              <p>
                This shows the Consensus Voting results in a more compact form.
              </p>

              <p>
                I've include the Instant Runoff-RCV results using the same ballots for comparison. Representative
                candidates cannot win under Instant Runoff if partisan candidates {mdash} Juan and Bob {mdash} play GTO
                politics.  By this, I mean that it's always possible for partisan candidates to bracket more representative
                candidates and settle the contest between them.
              </p>

              <p>
                Adjust the candidates. Play with difference scenarios. Try the full <a href={"/ElectionSim"}>Election
                Simulator </a> to explore outcomes under Consensus, Instant Runoff, and Primaries.
              </p>

              <div className={styles.sideBySide}>
                <ResultsBox
                    results={this.state.simulationState?.h2hResults}
                    title={"Consensus Voting"}
                />
                <div style={{minWidth: "2%"}}></div>
                <ResultsBox
                    results={this.state.simulationState?.irvResults}
                    title={"Instant Runoff-RCV"}
                />
              </div>
            </Step>
          </div>
          <div className={styles.spacerDiv}/>
          <div>
            {this.renderVisArea(styles.drawing6, "drawingArea6")}
            { /* 19 */}
            <Step first={true} className={styles.stepElement}>
              <h3>A Representative House of Representatives</h3>

              <p>
                This is a simulation of the U.S. House of Representatives under Consensus Voting. The only difference
                between this simulation and the simulation using primaries is how the voter preferences are interpreted.
                These two simulations use the same candidates and the same voter preferences, yet the outcomes are
                dramatically different. Primaries produce a dysfunctional Congress that does not represent the voters,
                while Consensus Voting elects a representative Congress with those same candidates and voters.
              </p>

              <p>
                These ideological scores may seem the same but they are not. A conservative under primaries is not the
                same as a conservative under Consensus Voting. Under Consensus Voting each of these Members represents all of
                the voters in their district, not some narrow slice of their voters. This makes compromise fundamentally
                easier, compromise can't be shut down by some small faction even for a conservative representative.
                Consensus Voting makes placing country over party not only possible, but essential.
              </p>

              <p>
                Simulation shows that the polarization in our political leaders does not reflect a polarized society. If
                we simply elect the candidates the voters prefer, we would have a functional government capable of
                dealing the challenging problems we face.
              </p>

              <p>
                Going all the way back to Hamilton and Madison, the underlying presumption of representative democracy
                is that elections produce representative results. Our democracy is failing because our elections fail
                to produce representative results. We must fix this.
              </p>

            </Step>

            <Step className={styles.stepElement}>
              <h3>Explore More</h3>
              <p>
                This is a deep conversation with profound implications and I have barely scratched the surface here.
              </p>

              <ul style={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}>
                <li>
                  <a href={"/ElectionSim"}> The Election Simulator </a> This is where you can explore outcomes under
                  different election systems. How often does Instant Runoff-RCV produce bad results?
                  How often does Consensus Voting produce bad results?
                </li>
                <li>
                  <a href={"/IRV2D"}>Instant Runoff Voting</a> describe how Instant Runoff Voting works and why is not
                  the best solution for political polarization.
                </li>
                <li>
                  <a href={"/Ties"}>Ties in Consensus Voting </a> discusses the possibility of a tie, where no candidate
                  beats every other candidate. Consensus Voting is known to election science as Condorcet voting and
                  there has been endless debate about the possibility of a rock/paper/scissors style cycle where
                  no candidate is undefeated. This explains how that is handled and why it's not a problem.
                </li>
                <li>
                  <a href={"/SimulationTutorial"}>A Tutorial on Simulation</a> The science behind election simulation
                  both for the election visualization and for the district-by-district simulations of the U.S. House of
                  Representatives.
                </li>
                <li>
                  Approximately one million more ideas and thoughts... This, like our democracy itself, is work in
                  progress.
                </li>
              </ul>
            </Step>
            <div style={{
              minHeight: "30vh"
            }}>
            </div>
          </div>
        </div>
    )
  }
}

export default IS2
