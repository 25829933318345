import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import {AppMetadata} from "./App";
import React from "react";
import {SimulationState} from "../../../ElectionSimulation/ElectionSimApp";


interface Props {
  metaData: AppMetadata
}

export class Conclusions extends React.Component<any, any> {
  simulationState?: SimulationState = undefined

  constructor(props: Props) {
    super(props)
    this.setState({
      simulationState: undefined
    })
  }

  onUpdate = (simulationState: SimulationState) => {
    let state = {...this.state, simulationState}
    this.setState(state)
  }

  render() {
    // let mdash = "\u2014"

    return (
        <Article title={"Conclusions"}
                 subtitle={"A few final thoughts."}>
          <div className={styles.textContent}>
            <p>
              After every election cycle, the victor declares a mandate to govern but that's a wild exaggeration. At
              most, the victor exceeded 50% by a tiny margin, if the exceeded it at all. Mostly, these "victories" are
              really just a rejection of the side that power instead of an endorsement of the victor. The most
              common choice by the voters is to reject the factional aspirations of both sides by engineering gridlock
              and divided government in a vain attempt to force compromise.
            </p>
            <p>
              The result is a government that varies between gridlock and unwinding any actions taken by the previous
              administration. Long-term forward progress on either social or economic issues is continuously setback by
              the inconsistent policy. Allies cannot rely on us, and foes feast on our inconsistency. We are constantly
              at war with ourselves.
            </p>
            <p>
              Each camp in this factional war is more concerned about scoring political points than acting for the
              public good. Instead of finding ways to improve all of our lives and advance as a society, leaders are
              obsessed with devising political strategies to obtain or retain power. Instead of being judged by their
              ability to safeguard and advance national interests, they are only weighed against their ability to
              demonize the opposition.
            </p>
            <p>
              After Barack Obama's 2008 victory, the Republican caucus decided that they could not let the
              him succeed. Knowing that Obama was "really popular", they made a token show of working with the
              administration when their real goal was to do everything in their power to deny him reelection. Joe Biden
              claimed that Republicans decided to withhold all cooperation before Obama took office or proposed any
              new legislation. This seems like it might be an exaggeration, but in 2010 McConnell himself said that
              their number one goal was to deny Obama a second term. Further, after retiring, Senator George Voinovich
              went on the record to tell Michael Grunwald the real marching orders of the Republicans in Congress: “If
              he was for it, we had to be against it,... He wanted everyone to hold the fort. All he cared about was
              making sure Obama could never have a clean victory.”
            </p>
            <p>
              This sort of strategic politics isn't limited to Republicans. In the 2022 midterm elections Democrats
              placed ads attacking David Valadao for voting <i>with Democrats</i> to impeach Donald Trump. They were
              hoping to engineer a weaker general election opponent, but how can they possible expect Valadao or any
              Republican to ever work with them again?
            </p>
            <p>
              It's a wonder we haven't destroyed ourselves in this endless squabble, much less made the progress we
              have.
            </p>
            <p>
              There is a temptation to be angry with the political class for placing political ambition and their
              factional interests before the national interest. But you might as well be mad at water for flowing
              downhill. Just as water flows downhill, politicians will seek power by following whatever strategy
              works best. We can't make water flow uphill, but we can change the contours of the land so that when
              water flows downhill, it nourishes our crops, powers our industry, and feeds the thirst of our cities.
            </p>
            <p>
              For politicians, the contours of the land are the rules of elections and those can be altered so that when
              politicians act in their self-interest, they act in the public interest. This sounds like it might be a
              massive political engineering project with a million arcane rules, but it comes down to one simple
              thing: <i>Obey majority rule</i>.
            </p>
            <p>
              In Yuval Hariri's Sapiens, homo sapiens were smaller and weaker than their Neanderthal cousins.
              But Neanderthal tribes topped out at about 150 individuals while the sapiens lived in groups of up to 1500
              people which spelled doom for the Neanderthals.
            </p>
            <p>
              Homo sapiens went on to astonishing success and developed agriculture, writing, money, cities, science,
              and art. But our societies have always been stalked by violence. There are periods of stunning success
              where disaster seems inconceivable, always followed by staggering violence and destruction. Societies are
              either crushed by their neighbors or riven by factional violence when not united by external threats. In
              either case, violence is inevitable.
            </p>
            <p>
              What if we could live together without violence? What could we accomplish?
            </p>
          </div>
        </Article>
    )
  }
}




















