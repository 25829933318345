import Article from "../content/Article/Article";
import styles from "./PartOne.module.scss";
import VisualizationWidget from "../../../VisualizationWidget/VisualizationWidget";
import {AppMetadata} from "./App";
import {ConsensusVotingApp} from "../../../Introduction/ConsensusVotingApp";
import {TextItem} from "../../elements/TextItem";

interface Props {
  metaData: AppMetadata
}

export function PartOne(props: Props) {
  let mdash = "\u2014"
  return (
      <Article title={"Why Voting Reform?"}
               subtitle={"Why is voting reform essential"}>
        <div className={styles.textContent}>
          <div className={styles.flyover} id={"detailsFlyover"} style={{
            display: "none",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 1,
          }}>
            <h6> Representative: <span id={"memberName"}>foo</span></h6>
            <h6> Party: <span id={"party"}>foo</span></h6>
            <h6> District: <span id={"districtName"}>foo</span></h6>
            <h6> Left/Right Ideology: <span id={"ideology"}>0</span></h6>
            <h6><span id={"representation"}> </span></h6>
          </div>

          <VisualizationWidget
              metaData={props.metaData}
              className={styles.dividedHouse}
              id={"actualHouse"}
              height={600}
              width={1000}
              render={(app: ConsensusVotingApp) => {
                app.displayHouse(400, 1000)
                app.addRenderItem(new TextItem("The United States House of Representatives, Arranged by Ideology",
                    "house-title", app.svg, 500, 50, "black", "middle", "22pt"))
                app.addRenderItem(new TextItem("Drag the slider to show ideology for different Congresses.",
                    "slider-instructions", app.svg, 500, 550, "black", "middle", "14pt"))
              }}
          />

          <p>
            There's a lot of stuff that needs fixing, why pick voting reform?
          </p>

          <p>
            The one thing nearly everyone agrees on is that polarization is bad. Even in a completely divided political
            world, each side does agree that the divide itself is a problem. Political debate has become toxic and
            unproductive as the two sides inhabit different media bubbles and are unable to even agree on basic facts.
            Debate is a fundamental aspect of democracy? What makes it toxic? Why is it toxic today and not 50 years
            ago? We need to understand the answers to these questions before we can set about solving the problem.
          </p>

          <p>
            Everybody has their own definition of what makes debate toxic. My definition of toxic is when debate
            and rhetoric cross the line between disagreeing with voters in the opposition and disparaging or attacking
            voters. I'm talking about voters and not candidates, candidates may be pretty mean to each
            other and while I don't like that, it's not the same as attacking the voters. Attacking the voters {mdash}
            the people a candidate is supposedly going to represent {mdash} means that the candidate isn't going to
            represent all of their voters. That should never be a successful campaign strategy.
          </p>


          <p>
            You would think that a candidate attacking their own voters would be political suicide, but it's not in our
            system. The first hint of a problem is that when candidates talk about "their voters" they really mean
            just voters that voted for them, and not all of the voters they represent. Most general elections in the
            United States are formalities, with the real decision made in a primary. Voters who prefer the opposition
            party have almost little influence in these elections and there's no penalty for disparaging them. More
            often than not such an attack is a winning strategy.
          </p>

          <p>
            This isn't how things work, but imagine a general election two Republicans in a district that leans toward
            Republicans. In this example, the two candidates have similar policy positions but one candidate disparages
            and insults the
            other party's voters while the other respects but disagrees with voters in the other party. If all of the
            voters participate in the choice between these two candidates, the one who disagrees instead of disparages
            would win. There would be a penalty for divisiveness.
          </p>
          <p>
            The problem is that's not the way our system works. That less divisive candidate is eliminated in a primary
            where divisive tactics are rewarded instead of punished. This is the root of our polarization problem. Our
            election process rewards divisiveness because voters from one party can't express a meaningful preference
            for a less divisive candidate from the other party. In our winner take all system, you're either in the
            majority, or you're not.
          </p>

          <h3>51%</h3>

          <p>
            There are a hundred reasons why polarization is bad, and you've almost certainly heard most of them. I
            thought I'd mention a few here that you might not have considered.
          </p>

          <p>
            American government excels at dysfunction but function is hardly better. When one party does manage to
            control Congress and the White House, they behave as though 51% is 100%. Elections that are decided by
            narrow margins reflect a closely divided electorate. when the margins flip from 51/49% to 49/51% policy
            shifts dramatically as the winning side claims a non-existent mandate for their partisan agenda. Nothing
            could be further from the truth. That shift from 51% to 49% is more about the center of the population
            recoiling from partisan overreach; the party out of power was merely momentarily less bad than the party in
            power. The voters' reward is either paralysis or another dose of partisan overreach. There is no option for
            compromise and progress on issues where there is broad agreement on both the problem and the solution.
          </p>

          <p>
            The ship of state lurches left and right after each election cycle as the center of the population rejects
            from the partisan agendas of each party. The result is dysfunctional and wasteful. Business cannot make the
            long term investments necessary to be competitive in the global economy because they do not have a stable
            policy environment. Social programs and education need decades of steady, thoughtful, investment to help
            people become contributing members of society. Partners and allies need to know that the United States is a
            reliable leader, trading partner, and military ally. We are currently failing at all of that.
          </p>

          <p>
            Governing as though 51% is 100% is so ingrained that we don't question it. We accept that a few hundred
            votes in Florida in the 2000 presidential election would cause the nation to choose dramatically different
            paths. It is a winner take all system where the party in power governs solely for themselves, the party out
            of power gets nothing. The consequence of the system is that each party implements policy that is
            more extreme than is supported by the electorate.
          </p>

          <p>
            This pattern of implementing policy that is not supported by the electorate drives the oscillation between
            the parties and undermines the very goals of the partisans. More consistent, incremental policy changes that
            are supported by the electorate and not unwound and undermined at the next election cycle might be more
            effective for partisans on both sides of the political divide.
          </p>

          <h3>Alternative Facts</h3>

          <p>
            The rise of cable news and then social media have fractured our common understanding of reality. No matter
            how crazy and damaging a conspiracy fantasy might be, people can find an online echo chamber to reinforce
            it. This has provided a fertile ground for an industry of crazy that Q-Anon and others have exploited. When
            elected officials legitimize conspiracy theories they amplify through the media and metastasize through our
            society.
          </p>

          <p>
            Elections do more than choose legislators, they also choose which reality to reinforce through that
            election. Because the broader electorate has little influence on the decisions that matter in most
            elections, a bare majority of primary voters can legitimize the most crazy conspiracy theories. When elected
            officials legitimize conspiracy theories they amplify through the media and metastasize through
            our society.
          </p>

          <p>
            Coupled with a fractured media environment, our election process enables each side to live entirely within
            their own bubble. Candidates who don't drink the "partisan reality kool-aid" of their party don't survive
            their primary. Elected leaders and their more partisan followers inhabit separate realities and no longer
            share a common basis of facts needed for meaningful debate.
          </p>

          <p>
            Different areas of the country are going to view things differently and their representatives should reflect
            the views of their constituents. But, they should represent the views of <i>all</i> of their constituents
            not just a narrow slice of primary voters.
          </p>

          <h3>Democracy in Peril</h3>

          <p>
            When I started working on voting reform, I did so because I was concerned about the future of our democracy.
            The cycle of partisanship was growing worse each election cycle. As we elected leaders that were more
            polarized than our society these leaders pulled society farther apart. Then, the next election cycle
            produced even even more polarizing leaders. Though I was believed that this would ultimately lead to
            disaster, I chose to focus on other issues because I did not want to seem too alarmist.
          </p>

          <p>
            When I talked to people about the problem, I chose to focus on polarization and dysfunction. I described the
            pointless arguments on cable news, the complete lack of progress on important issues where there is broad
            agreement on the solution, and the fact that many voters are effectively frozen out of the election process.
            I did not mention my real fear, that our democracy could fail. That time has passed.
          </p>

          <p>
            Though dysfunction is still crippling our government, it has become far more apparent that democracy itself
            is in deep peril. Not only are we unable to solve the many problems that we need to face, toxic polarization
            is threatening to undermine our democracy.
          </p>

          <p>
            In <i>The Two Party Doom Loop</i> Lee Drutman has pulled together an excellent chronology of the breakdown
            of our political system. He traces the problem to the sorting of the parties that happened between 1960 and
            2010. At the start of that period there were significant numbers of liberal Republicans and conservative
            Democrats that allowed the parties to find compromise and govern effectively. By the end of the period there
            was zero overlap between the parties in Congress, though the voters still have significant overlap. The
            interactive graphic above shows the evolution of polarization.
          </p>

          <p>
            In "How Democracies Die", Levitsky and Ziblatt point out that the danger isn't a military coup but a series
            of steps that erode the institutions that make our democracy strong. I agree, recent examples of democracies
            failing in Russia, Venezuela, Turkey, and Hungary all start with a democratically elected leader, not a
            military coup. That erosion has already begin in the United States.
          </p>


          <p>
            Democracy relies on the most improbable event: the peaceful transfer of power. Every four or eight years we
            come together as a nation and transfer power to a new president, mostly of the opposition party. This
            remarkable accomplishment relies on politicians from each party who trust and respect their opponents. This
            has all been easier because of the ideological overlap between the parties, but that overlap has vanished at
            the elected level (though not among voters). As the level of toxic polarization escalates the transfer of
            power seems ever more uncertain.
          </p>

          <p>
            As I explain in the introduction to <a href={"/ConsensusVoting"}>Consensus Voting</a>, this partisan polarization
            does not originate with a polarized society. Polarization it is the product of an election system that
            favors divisiveness. These divisive candidates who poison our political discourse are not even the
            candidates the voters want. These polarizing, divisive candidates are only electable because partisan forces
            have created an election system where candidates preferred by the broader electorate are eliminated in the
            primary process.
          </p>

          <p>
            Even though we didn't start with a polarized society, the influence of divisive leaders is having a
            corrosive effect on our national cohesion. Wave after wave of divisive leadership in a self-reinforcing
            cycle with cable news and social media have created separate bubbles of reality heading off in different
            directions. It is becoming harder and harder for Americans from different parties to even talk to each
            other.
          </p>

          <p>
            The glimmer of hope is that we can change all of this by cutting it off at the source. The source of this
            divisiveness is an election process that favors divisive candidates who represent just a slice of their
            own voters. Instead, we need an election process that favors candidates who represent all of their voters.
          </p>

          <h2>Polarization Is Bad, Our Democracy Is in Danger, but What Do We Do About It?</h2>

          <p style={{
            marginTop: "50px",
          }}
             className={styles.pullQuote}>
            <i>You can fool all the people some of the time and some of the people all the time, but you cannot fool all
              the people all the time. – Abraham Lincoln</i>
          </p>

          <p style={{
            fontStyle: "italic",
            width: "80%",
            textAlign: "center",
            marginBottom: "50px",
          }}>
            Our democracy is being destroyed by unrepresentative partisan zealots that the voters don't even want, so
            maybe we should stop electing them.
          </p>

          <p>
            Most people are aware of polarization as a bad thing, it's mentioned frequently in the media and never as a
            positive force. The question is what causes polarization? and How do we fix it? The list of suspects is
            huge: a fundamentally polarized society, individual politicians, Facebook, cable news, Twitter, QAnon, voter
            suppression, voter fraud, and Russian bots to name a few. Those probably all contribute, but the root cause
            is the election process itself.
          </p>

          <p>
            Our election process produces polarized outcomes from an un-polarized electorate, even when a unified
            population makes well informed and rational decisions. How do I know this? I built a simulation engine that
            simulates elections using different voting systems. The simulation engine starts with a diverse set of voter
            opinions spread out in a standard bell curve and simulated voters make well informed, rational decisions
            choosing candidates that most closely align with their values and preferring to stay within their party.
            Using those assumptions, simulation predicts a polarized government remarkably similar to what we see today.
          </p>
          <VisualizationWidget
              metaData={props.metaData}
              className={styles.simulatedHouse}
              id={"simulatedHouse"}
              height={1000}
              width={1000}
              render={(app: ConsensusVotingApp) => {
                app.showSimulatedHouse(0)
              }}
          />
          <p>
            Simulation doesn't consider Facebook, Twitter, cable new or any of the other often suggested causes. It
            just assumes that we use a primary process to select candidates for a general election and then choose one
            of the general election candidates as the winner.
          </p>

          <p>
            Some may {"\u2014"} and a few have {"\u2014"} argued that these assumptions are too optimistic. They may
            well be optimistic, but if a well informed, unified population can't elect a representative government, then
            what chance do we have in the real world? If things don't work in the lab, they'll never work in the real
            world.
          </p>

          <p>
            Our current election process rewards divisiveness. Candidates able to win by appealing to one segment of the
            electorate while vilifying not only their opponents, but their voters as well. There is no penalty for
            divisiveness because voters in the minority party don't have a meaningful say in the election. Most
            elections in the United States are decided in the primary, 87% of U.S. House races are non-competitive and
            decided in the primary.
          </p>

          <p>
            Lincoln's wisdom ultimately relies on "all of the people". When the process excludes large groups of voters
            from meaningful participation in elections we lose that wisdom and balance. The critical decisions are made
            in party primaries with a small slice of the electorate and the general election is a formality. We need
            "all of the people" to participate in all of the decisions that matter to make the results represent all of
            the people.
          </p>

          <p>
            To solve these problems voters must have the ability to elect candidates who represent all of their voters,
            not just their party's voters.
          </p>
          <p>
            This requires three things:
          </p>

          <ul className={styles.stepsList}>
            <li>
              Have a general election with better choices.
            </li>
            <li>
              Allow voters to express an opinion about each of the candidates.
            </li>
            <li>
              Elect the candidate the voters prefer.
            </li>
          </ul>


          <h3>First, Reform Must Be Purposeful.</h3>
          <p>
            Those sound like simple things {"\u2014"} they are not. There are a thousand ways to perform each of these
            steps, and most of them won't save our democracy. This is not a time for "Ready, Fire, Aim". Reform must be
            purposeful and meaningful or it will fail and our democracy with it. If we engineered airplanes like we have
            engineered our democracy, everybody would die. It's time to change that.
          </p>

          <p>
            Reforming our democracy is harder than designing an airplane. Aircraft design doesn't have to deal with
            people in power who've engineered the system to keep them in power. Most politicians and voters will benefit
            from an election process that is more representative, but some people empowered by the current system will
            fight to undermine real reform.
          </p>

          <p>
            There is a great deal of dissatisfaction with politics today. 61% of Americans say that "significant changes
            are needed in the design and structure of American government" according to a 2018 PEW poll. Statements
            about polarization and dysfunction that used to be confined to a few wonks are now mainstream. There's so
            much frustration that you're reading this article, but we must be careful. An ineffective reform will
            consume critical time and energy while undermining electoral reform as a solution.
          </p>

          <h3>Give the Voters Better Choices</h3>
          <p>
            A general election should have multiple options from each party so that all of the voters can have a say in
            the outcome. Separate primaries before the general election exclude the broader electorate from the critical
            decisions in our binary political universe. In a district where one party dominates, all of the voters need
            to participate in the decision of which candidate from the dominant party will represent the voters. Voters
            must have the opportunity to express a preference for a less divisive candidate from the opposition party.
          </p>

          <p>
            Katherine Gehl has proposed a Top-5 primary where all candidates from all parties participate and the top
            five candidates advance to the general election. The objective is to provide enough candidates that voters
            have some real choices, but to limit the number of candidates to a reasonable number so a voter can rank
            candidates from their own party and then less divisive candidates outside their party. Depending on the
            race, five or even seven candidates is a good compromise between having more choices and managing the
            complexity of an election with too many candidates.
          </p>

          <h3>Allow the Voters to Express Their Choices </h3>

          <p>
            There are multiple ways for a voter to express an opinion. None are perfect, but there is a vast difference
            between the good ones and the bad ones.
          </p>

          <p>
            The system we use today: plurality, where each voter votes for a single candidate, is among the worst
            possible ways to interpret the ballot. First, the voter isn't able to provide much information, the only
            thing a voter can indicate is their first choice is and often not even that.
          </p>

          <p>
            Second, plurality is easily manipulated with spoiler candidates who split the vote. Voters are often forced
            to vote strategically for a candidate who isn't their first choice based on guesses about which candidates
            are competitive. The winner is most often a poor representative of the voters' values, even if there are
            good choices running.
          </p>

          <p>
            A ranked choice ballot allows the voter to rank the candidates in their order of preference. With the right
            method for interpreting that ballot, ranked choice voting limits the ability to manipulate the outcome with
            spoiler candidates and allows voters to cast an honest ballot listing candidates from their own party and
            then less divisive candidates from the other party.
          </p>

          <h3>Listen to the Voters' Choices</h3>

          <p>
            Having gone to all of the trouble to ask voters for their opinion on all of the candidates, the ballot
            counting method should actually use that opinion. <a href={"/ConsensusVoting"}>Consensus
            Voting</a> uses every ranking on every ballot to determine a winner.
          </p>
          <p>
            Given two candidates, a voter 'prefers' whichever candidate is listed higher on their ballot even neither
            candidate is at the top of their ballot. Each pair of candidates is compared and the candidate who defeats
            every other candidate is the Consensus Candidate and the winner.
          </p>

          <p>
            Every voter gets an equal say in each of these comparisons. A candidate who disparages the opposition will
            lose to a candidate from their own party who merely disagrees with the opposition. By giving all of the
            voters a say, Consensus Voting rewards candidates who represent all of their voters, however imperfectly, and
            punishes candidates who represent only a narrow slice of the electorate.
          </p>

          <h2>A Representative Government</h2>

          <p>
            Consensus Voting works by doing what the voters want. If the voters prefer a candidate to every other candidate
            then that candidate <i>must</i> be the winner. This simple principle has a profound impact on our politics.
            The most important change is that candidates will know that disparaging opposition voters will cause them to
            lose to another candidate from their own party who merely disagrees with the opposition. This will
            fundamentally change the tone of our politics in ways that other potential reforms will not.
          </p>

          <p>
            Every state, every district in the United States contains a spectrum of voters. Some electorates are
            liberal, some are conservative but all contain voters of every stripe. Winning under Consensus Voting requires
            candidates to represent all of their voters; this takes a step toward consensus. The winners will be as
            diverse as their districts, but none will be zealots devoted solely to the wishes of some narrow slice of
            the electorate.
          </p>

          <p>
            These are the same gerrymandered districts, the only difference is the voting system gives every voter
            a say in the outcome of an election. Primary voters will not be able to hold members hostage to a partisan
            agenda. Compromise will be possible, it may even be necessary.
          </p>
          <VisualizationWidget
              metaData={props.metaData}
              className={styles.consensusCongress}
              id={"ConsensusCongress"}
              render={(app: ConsensusVotingApp) => {
                app.showConsensusHouse(0)
              }}
          />

          <p>
            A functional U.S. House is only the beginning. The Senate would also no longer be held hostage by a few
            partisans on either side. The profound imbalance of power in the Senate between large and small states will
            be mitigated when senators represent all of their voters and not just their primary voters. A functional
            legislative branch would reduce the burden on the Supreme Court, provide objective oversight of
            the executive branch, and reduce the reliance on executive orders. In short, our government could begin
            to function as it was designed.
          </p>

          <p>
            Our habit of governing as though 51% is 100% would end under Consensus Voting, and it should. 51% is not a
            mandate for an extreme partisan agenda, it's a mandate for more nuanced agenda. If you want to pursue a more
            ambitious agenda, then make the case, persuade the voters. When you persuade enough voters it's not just a
            partisan agenda it's an everyone agenda and not only can it happen, but it won't be repealed and undermined
            going forward.
          </p>
          <p>
            If you can't persuade the voters, then well, this is a democracy after all. I've actually heard people in
            voting reform discussions complain that under Consensus Voting they wouldn't be able to pass policy that the
            voters don't support. They seem immune to the irony.
          </p>

          <p>
            When we transfer power today, it's between polar opposites who represent opposite sides of the electorate.
            It's easy to paint the opposition as evil and justify undermining democracy to retain power. Under
            Consensus Voting each side would represent all of the voters, transferring power would only be a change between
            the old voter consensus and the new voter consensus.
          </p>

          <p>

            Representing all of the voters means not embracing conspiracy theories. Today, we've seen a number of
            candidates embrace conspiracy theories popular among their primary voters but rejected by the broader
            electorate. They are able to win election because they are in a safe district the only important decision
            is made in the primary. Under Consensus Voting those candidates would lose to a similar candidate from their
            own party who rejected the conspiracy theory because the broader electorate isn't frozen out of the election
            process.

          </p>

          <p className={styles.pullQuote}>

            <i>You can fool all the people some of the time and some of the people all the time, but you cannot fool all
              the people all the time.</i>
          </p>

          <p>
            We can not and should not attempt to censor anyone. The election process itself chooses which candidates to
            elect and consequently which realities to endorse and amplify. When we have a process which favors
            divisiveness, we get the conspiracy theories we have today. To solve this problem, we have to return to the
            Lincoln quote, and remember that we need "all of the people" to be able to participate in the decisions
            that matter.
          </p>
          <p>
            There is no Truth with a capital 'T' in politics, but at least we can insist that all of the people get to
            participate in what reality we are going to promote.
          </p>


          <p>
            There is a cost for candidates who embrace toxic behavior and fringe reality when every voter has a say. A
            divisive candidate will find it difficult to compete with a less divisive candidate from their own party.
            Truth may be an elusive concept, but at least we can give every voter a say in helping to define it.
          </p>

          <p>
            Election reform won't solve the laundry list of challenges we face, but it will make it possible to begin
            to solve them.
          </p>


        </div>
        <div>

          <div className={styles.pullQuote}>
            <p>
              "The alternate domination of one faction over another, sharpened by the spirit of revenge natural to party
              dissension, which in different ages and countries has perpetrated the most horrid enormities, is itself a
              frightful despotism."
            </p>
            <p>
              George Washington's farewell address
            </p>
          </div>

          <p>
            Washington's farewell address contains a shockingly accurate description of the partisan tit-for-tat that
            has been happening for the last two decades. Tit-for-tat sounds like a harmless elementary school problem,
            but while the mentality is assuredly elementary school, the consequences are not. Washington goes on to
            suggest
            that the cycle of partisan revenge ends when one party (faction) seizes power.
          </p>

          <p><i>
            There's a ton of stuff on factions that's easy to cite. It was perceived as being a fatal flaw in democracy
            that prevented it from being a workable form of government. What would prevent a majority from ignoring or
            oppressing the minority? Every prior democracy had failed on this single issue, ours does not seem immune.
          </i></p>
        </div>
      </Article>
  )
}