import CandidateBubble from "./CandidateBubble";
import Candidate from "../../core/Candidate";
import AnimatedController from "./AnimatedController";
import {immediateDirectRoute, LayoutAnimator} from "./LayoutAnimator";
import OffscreenLayout from "./OffscreenLayout";
import * as d3 from 'd3'
import {ScaleLinear} from 'd3'
import * as d3Drag from 'd3-drag'
import LayoutByIdeology2D from "./LayoutByIdeology2D";
import {rangesAndGradients} from "./RangesAndGradients";
import VoteTotal from "./VoteTotal";
import ReactGA from 'react-ga'

// import {ScaleLinear} from 'd3-scale'


let candidateSerialNumber = 0

class Candidate2D extends CandidateBubble {
  ideology = 0
  opacity = .2
  candidateColor: string = 'red'
  radius: number
  distanceFromMedian: number = 0
  uniqueId = 0
  OnDblClick = (c: Candidate2D, e: MouseEvent) => {}

  distance = (ivec: number[]): number => {
    let sum = 0
    this.candidate.ivec.forEach((x, i) => sum += (x - ivec[i]) * (x - ivec[i]))
    return Math.sqrt(sum)
  }

  constructor(candidate: Candidate, color: string, radius: number) {
    super(candidate);
    this.candidateColor = color
    this.radius = radius
    this.uniqueId = candidateSerialNumber + 1
    candidateSerialNumber += 1
  }

  ivec = (): number[] => {
    return this.candidate.ivec
  }
}

class Candidates2D extends AnimatedController {
  radius: number = 2
  bubbles: Array<Candidate2D>
  animator: LayoutAnimator
  svg: d3.Selection<any, any, any, any>
  xScale2d: ScaleLinear<number, number> = rangesAndGradients.xScale2d
  yScale2d: ScaleLinear<number, number> = rangesAndGradients.yScale2d
  totals: VoteTotal[] = []
  dragReported = false;
  controllerName = "Candidates2D"

  candidate?: Candidate2D
  onUpdate: (cb: Candidate2D) => void = (_cb: Candidate2D) => {
  }


  setSVG = (svg: d3.Selection<any, any, any, any>) => {
    this.svg = svg
  }

  createDragBehavior = () => {
    console.log("createDragBehavior")
    let obj = this
    return d3Drag.drag<SVGGElement, Candidate2D>()
        .on("drag", function (this: SVGGElement, e: DragEvent, c: Candidate2D) {
          obj.drag(this, e, c)
        })
  }

  dragBehavior = this.createDragBehavior()

  setNewCandidates = (newCandidates: Candidate2D[]) => {
    newCandidates.forEach(nc => {
      this.bubbles.forEach(oc => {
        if (oc.name === nc.name && oc.candidateColor === nc.candidateColor) {
          nc.x = oc.x
          nc.y = oc.y
        }
      })
    })
    this.bubbles = newCandidates
    this.dirty = true
    this.svg.selectAll(".candidateCircle").remove()
  }
  candidates = (): Candidate[] => {
    return this.bubbles.map(b => b.candidate)
  }

  setActiveCandidates = (activeCandidates: Set<Candidate>, inactiveOpacity: number = .3) => {
    this.bubbles.forEach((cb: Candidate2D) => {
      cb.active = activeCandidates.has(cb.candidate)
      // cb.opacity = cb.active ? 1 : inactiveOpacity
    })
    this.applyColorAndOpacity()
    this.renderFrame(1.0)
  }

  drag = (gElement: SVGGElement, e: DragEvent, c: Candidate2D) => {

    let x = e.x
    let y = e.y
    c.x = x
    c.y = y

    let i1 = this.xScale2d.invert(x)
    let i2 = this.yScale2d.invert(y)
    c.candidate.ivec = [i1, i2]
    c.tx = x
    c.ty = y

    // console.log(`candidateDrag ${x} ${y} ${i1} ${i2} ${c.name} ${c.uniqueId}`, e)

    d3.select(gElement)
        .attr("transform", `translate(${x}, ${y})`)

    this.renderFrame(1)
    if (!this.dragReported) {
      ReactGA.pageview(`${window.location.pathname}-candidateDrag`)
      this.dragReported = true
    }
    this.onUpdate(c)
  }

  constructor(svg: d3.Selection<any, any, any, any>) {
    super()
    this.svg = svg
    this.bubbles = []
    this.animator = new LayoutAnimator("candidates2D", this.bubbles, new OffscreenLayout(), this.renderFrame)
  }

  setLayout2D = (duration: number): void => {
    this.setLayout(new LayoutByIdeology2D(this.xScale2d, this.yScale2d, immediateDirectRoute(duration)), "2D")
  }
  clear = (duration: number) => {
    this.clearAxes()
    this.moveOffscreen(duration)
  }
  moveOffscreen = (duration: number): void => {
    this.setLayout(new OffscreenLayout(-1000, -1000, 0, 1000, duration), "2D")
  }

  clearVoteTotals = () => {
    this.svg.selectAll(".voteTotal").remove()
  }


  renderFrame = (_pctComplete: number) => {
    this.renderFrameSVG2()
  }

  onCandidateSelect = (candidate: Candidate2D) => {

  }


  renderFrameSVG2 = (): void => {
    this.svg.selectAll(".candidateCircle").data(this.bubbles)
        .join(
// @ts-ignore
            enter => {
              let g = enter.append("g")
                  .classed("candidateCircle", true)
                  .attr("transform", (b) => `translate(${b.x}, ${b.y})`)
                  .call(this.dragBehavior)
                  .on("dblclick", (event, candidate: Candidate2D) => {this.onCandidateSelect(candidate)})

              if (g.size()) {
                console.log(`adding candidate circles: ${g.size()}`)
                g.append("circle")
                    .attr("cx", 0)
                    .attr("cy", 0)
                    .attr("r", (b) => b.radius)
                    .style("fill", (b) => b.color)
                    .style("stroke", 'black')
                    .style("stroke-width", 2)
                    .style("cursor", "grab")

                g.append("text")
                    .attr("class", "candidateName")
                    .attr("id", (b) => b.name)
                    .attr("x", 0)
                    .attr("y", (b) => b.radius + 10)
                    .attr("dy", "1em")
                    .style("font-size", "24pt")
                    .style("text-anchor", "middle")
                    .style("fill", "black")
                    .text((b) => b.name)
              }
            },
            update => {
              update
                  .attr("transform", (b) => `translate(${b.x}, ${b.y})`)
                  .style("opacity", (b) => b.opacity)
            },
            exit => {
              exit.each(b => {
                console.log(`removing: `, b)
              }).remove()
            }
        )
  }
}

export default Candidates2D
export {Candidate2D}