import Layout from "./Layout";
import Random from "../../core/Random";
import Bubble from "./Bubble";
import {directRoute} from "./LayoutAnimator";
import Trajectory from "./Trajectory";
import MemberBubble from "./MemberBubble";
import CombinedDistrictData from "../../core/CombinedDistrictData";

class LayoutMembersVsVoters extends Layout {
  xScale: d3.ScaleLinear<number, number, any>
  yScale: d3.ScaleLinear<number, number, any>
  rand = new Random()
  route: <T extends Bubble>(b: T) => Trajectory
  cdd: CombinedDistrictData
  extractor: (b: MemberBubble) => number

  constructor(
      extractor: (b: MemberBubble) => number,
      xScale: d3.ScaleLinear<number, number, any>,
      yScale: d3.ScaleLinear<number, number, any>,
      cdd: CombinedDistrictData,
      route: <T extends Bubble>(b: T) => Trajectory = directRoute,
      duration = 1000) {
    super();
    this.extractor = extractor
    this.cdd = cdd
    this.duration = duration
    this.xScale = xScale
    this.yScale = yScale
    this.route = route
  }

  reset = () => {
  }

  setTargetLocation<T extends Bubble>(b: T) {
    let mb = (b as any as MemberBubble)
    let dvr = this.cdd.dvrForName(mb.member.district)
    let y_raw = +dvr.observedLean
    b.tx = this.xScale(this.extractor(mb))
    b.ty = this.yScale(-y_raw)
    // console.log(`${mb.member.district} observedLean: ${y_raw} b.tx = ${b.tx} b.ty = ${b.ty}`)
  }
}

export default LayoutMembersVsVoters
