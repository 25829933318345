import React from "react";
import {ElectionData} from "./ElectionSimApp";
import {CandidateResultTable, ResultWidget2} from "../ui/elements/ResultWidget2";
import styles from "./ResultsBox.module.scss";
import WinnerAndScore from "./WinnerAndScore";

interface Props {
  title: string
  results: ElectionData | undefined
  caption?: string
  className?: string
}

class ResultsBox extends React.Component<any, any> {
  foo: string
  props: Props

  constructor(props: Props) {
    super(props);
    this.props = props
    this.foo = "bar"
    // console.log("ResultsBox", props.results)
  }

  caption = (): JSX.Element => {
    if (!this.props.caption) return null as any as JSX.Element
    return (
        <p className={styles.caption}
           style={{
             paddingTop: "0",
             marginTop: "0",
             fontSize: "8pt",
             fontStyle: "italic"
           }}>
          {this.props.caption}
        </p>
    )
  }

  render = (): JSX.Element => {
    let ed = this.props.results
    if (!ed)
      return (
          <div className={styles.resultBox}>
            <div className={styles.titleDiv}>
              {this.props.title}
            </div>
          </div>
      )

    return (
        <div className={styles.resultBox + " " + this.props.className ?? ""}
             style={{
             }}>
          <div style={{width: "100%"}}>
            <div className={styles.titleDiv}>
              {this.props.title}
            </div>
            <div className={styles.blackBorder}>
              <WinnerAndScore
                  candidate={this.props.results?.winner}
              />
              {
                ed.tables.map((resultTable: CandidateResultTable, index: number) =>
                    (<ResultWidget2 tableInfo={resultTable} key={index}/>))
              }
              {this.caption()}
            </div>
          </div>
        </div>
    )
  }
}

export default ResultsBox