import Article from "../content/Article/Article";
import styles from "../content/Article/Article.module.scss"

// import styles from "./VotingRequirements.module.scss"

export function Ties() {
  return (
      <Article title={"Ties in Consensus Voting"}
               subtitle={"Ties are rare but must be addressed."}>
        <div className={styles.textContent}>

          <p>
            Consensus Voting is based on the principle that a candidate that the voters prefer to each of the other
            candidates is the Consensus Candidate and the winner. That's clear, but what if no candidate is preferred to
            each of the other candidates? What if Joe beats Mary, who beats Sue, who beats Joe? This has never been seen
            in any examination of ranked-choice ballots used in a governmental election, but it is possible and
            must be dealt with.
          </p>

          <p>
            The brief response is that in that case, Instant Runoff is used to determine the winner. This makes the
            check for a Consensus Candidate a preliminary that will resolve the outcome 99.9% of the time and 0.1% of
            the time it will be the existing Instant Runoff result.
          </p>

          <h3>Much more detail</h3>
          <p>
            The more detailed response is that there are two ways a tie might happen: In response to voters' honest
            preferences and when candidates and voters attempt to manipulate the outcome. It's appropriate to examine
            each of these individually.
          </p>
          <h3>Natural Ties</h3>
          <p>
            If you've read the article on voter representation, you may remember that the winning candidate in a two
            candidate race has a higher representation score. For there to be a tie among three candidates requires that
            all three candidates have about the same representation score, otherwise it's impossible for the third
            candidate in the tie to beat the first. Also, the representations scores likely have to be excellent because
            otherwise any one candidate, or even a fourth candidate could win by moving to the center. </p>
          <p>
            If a natural tie does occur, it means that there are at least three candidates that are very close to the
            ideal candidate. It really doesn't matter which candidate in the tie wins, any would be an excellent
            representative of the voters. </p>
          <p>
            You should try using the election simulator to create a tie. </p>

          <h3>Creating a Tie to Manipulate the Outcome</h3>
          <p>
            I'm going to present the simplest form of manipulation I know of, but it's still complex and that's one
            reason that manipulation is unlikely. The second reason is that voters tend to mostly submit honest ballots
            that reflect their real intent. Today, voters could get better outcomes by voting for less divisive
            candidates
            in the primary of the dominant party but they don't do that in sufficient numbers to make a difference. You
            will have to form your own opinions on how likely this scenario is.
          </p>
          <p>
            Consider an election with three candidates in a district that leans toward Republicans: Sue, a Democrat,
            John, a less divisive less partisan Republican and Matt a more partisan Republican. Matt knows that John is
            very likely to be the Consensus Candidate and the winner but that he (Matt) would defeat Sue if John were
            not in the race. </p>
          <p>
            Matt thinks that he would win if the race is settled by Instant Runoff. So he decides to try to try to
            create a tie by asking his voters change their ballots.
          </p>

          <p className={styles.pullQuote}>
            The idea that exactly one
            candidate's voters are going to play 3D chess in the voting booth to create a 3-way tie is ludicrous.
          </p>

          <p>
            Matt tells his voters to list Sue as their second choice even though they would likely prefer John. Matt's
            goal is to take votes away from John so that John doesn't defeat Sue and create a tie.
          </p>

          <p> If things ended here, that might work out for Matt. </p>

          <p>
            The problem is that John and John's supporters don't like this at all. From their perspective, Matt is
            trying to undermine the integrity of the election. John's supporters who would otherwise have listed Matt as
            their second choice switch to Sue in retaliation for Matt's underhanded tactics.
          </p>

          <p>
            Sue and her supporters just kinda say thanks, because they now win the election.
          </p>

          <p>
            I don't have a way to measure how likely it is to get a voter on one edge of the political spectrum to skip
            over more compatible choices and list someone on the opposite edge as their second choice. I don't think
            it's that likely because of the way this worked out.
          </p>

          <p>
            This is all too complicated, particularly for the voters. If voters were willing to do this type of thing
            then they could get better results today by voting for less divisive candidates in the opposition primary
            in many states. At least then they could still vote for their own party in the general election. In
            practice, voters don't even do that in numbers sufficient to make a difference. The idea that exactly one
            candidate's voters are going to play 3D chess in the voting booth to create a 3-way tie is ludicrous.
          </p>

          <p>
            Ultimately, Consensus voting rewards voters who vote honestly and that's what's most important. Voters who
            voted irrationally (Matt's voters) got a bad outcome and that's what we want. Maybe next time they won't
            attempt to undermine the election process.
          </p>

          <h3>Conclusions</h3>
          <p>
            There's a certain irony when a supporter of Instant Runoff attempts to say that Consensus Voting shouldn't
            be used because there is the possibility of a tie {"\u2014"} This would supposedly be a problem because the race
            would be decided by ... Instant Runoff? And this is a problem because of what?
          </p>

          <p>
            There are arguably better ways to resolve ties. Tideman's Rank Pairs is considered to be the superior
            solution by most. Another, very good solution, is Minimax where the winner is the candidate who's largest
            defeat is smallest. The amount of academic debate about these solutions implies that the tie resolution
            methodology is more important than it really is. In practice, for an office that's elected every 4 years it
            might be used once every 4,000 years. Even then, many of the resolution methods will come to the same
            conclusion so the tie resolution may change the outcome one time every 40,000 years.
          </p>

          <p>
            The differences are small enough and ties are rare enough that I've chosen Instant Runoff as the resolution
            method (this is known as Condorcet-Hare in election science literature) because it's an easy modification to
            existing voting rules: Check for a Consensus Candidate first.
          </p>

          <p>
            Finally, if a tie did occur, I would celebrate because it would mean that there were several candidates who
            were all close to the ideal representative for the voters.
          </p>
        </div>
      </Article>
  )
}

// <a className={styles.inlineLink} href='/VotingSystems' > preferences</a>
