import Layout from "./Layout";
import Random from "../../core/Random";
import Bubble from "./Bubble";
import {slowDirectRoute} from "./LayoutAnimator";

class OffscreenLayout extends Layout {
  xMin: number
  xMax: number
  yMin: number
  yMax: number
  rand = new Random()

  constructor(yMin = -1100, yMax = -1000, xMin = 0, xMax = 1000, duration: number = 3000) {
    super();
    this.xMin = xMin
    this.xMax = xMax

    this.yMin = yMin
    this.yMax = yMax
    this.duration = duration
  }

  route = slowDirectRoute

  reset = () => {

  }

  setTargetLocation<T extends Bubble>(b: T) {
    b.tx = this.rand.intRange(this.xMin, this.xMax)
    b.ty = this.rand.intRange(this.yMin, this.yMax)
  }
}

export default OffscreenLayout
