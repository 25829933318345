import {ConsensusVotingApp} from "../Introduction/ConsensusVotingApp";

export class SimulationTutorialApp extends ConsensusVotingApp {
  irvTimerDelay = 2000


  showPartyPreferenceLoop = (_progress: number) => {
    let newCandidates = this.fiveCandidateAll()
    newCandidates[2].candidate.ivec = [.82, .84]
    this.showIRVRaceCandidates(newCandidates)
    this.timerCount = 5
    this.onPartyPreferenceTimer(0)
  }

  // onIRVTimer = (round: number) => {
  //   this.stopTimer("resultsTimer")
  //   this.colorVotersByIRVRound(round)
  //   let nextRound = (round + 1) % this.irvResult.rounds.length
  //   this.addTimer("resultsTimer", () => {this.onIRVTimer(nextRound)}, this.irvTimerDelay)
  // }

  showGeneralTutorial = (_progress: number) => {
    this.setLoyalty(0)
    this.showGeneralOutcome()
    this.clearInstructions()
  }

  showGeneralTutorial2 = (_progress: number) => {
    this.showGeneralOutcome()
    this.timerCount = 5
    this.onPartyPreferenceTimer(0)
    this.clearInstructions()
  }

  newSectionMethods = [
    this.showGeneralTutorial,
    this.showGeneralTutorial2,
    this.showPartyPreferenceLoop,
    this.showPartyPreferenceLoop,

    this.showGeneralTutorial,
    this.showGeneralTutorial,
    this.showGeneralTutorial,
    this.showGeneralTutorial,
    this.showGeneralTutorial,
    this.showGeneralTutorial,
    this.showGeneralTutorial,
    this.showGeneralTutorial,
    this.showGeneralTutorial,
    this.showGeneralTutorial,
    this.showGeneralTutorial,
    this.showGeneralTutorial,
  ]

  progressMethods = []
}

export default SimulationTutorialApp