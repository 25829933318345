import {Party} from "./Party";
import {rangesAndGradients} from "../ui/elements/RangesAndGradients";

class Member {
  name: string
  party: Party
  ideology: number
  nominate: number
  body: string
  district: string
  constructor(name: string, party: Party, ideology: number, body: string, district: string) {
    this.name= name
    this.party = party
    this.ideology = ideology
    this.nominate = ideology / rangesAndGradients.dwNominateScale
    this.body = body
    this.district = district
  }
}

export default Member