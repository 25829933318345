import seedrandom from 'seedrandom';
// import Prando from 'prando'

class Random {
  seed: string
  generator = seedrandom("Consensus")

  // generator: Prando

  constructor(seed: string = "Consensus") {
    this.seed = seed
    this.generator = seedrandom(seed)
    // this.generator = new Prando(seed)
  }

  setSeed = (seed: string) => {
    random = new Random(seed)
  }

  nextFloat = (): number => {
    return this.generator()
    // return Math.random()
    // return this.generator.next()
  }

  nextNormal = (): number => {
    return this.normal()
  }
  // uses the Box-Muller transform
  normal = (): number => {
    let u = 0, v = 0;
    while (u === 0) u = this.nextFloat(); //Converting [0,1) to (0,1)
    while (v === 0) v = this.nextFloat();
    return Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
  }
  nextBoolean = (): boolean => {
    return this.nextInt(2) === 1
  }
  nextInt = (max: number): number => {
    return this.intRange(0, max)
  }
  // return range from [min, max)
  intRange = (min: number, max: number): number => {
    return Math.floor(this.nextFloat() * (max - min)) + min
  }
}

let random = new Random()
export default Random
export {random}