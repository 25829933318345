import Article from "../content/Article/Article";
import styles from "./FiftyOne.module.scss";
import {AppMetadata} from "./App";
import {MemberFlyover} from "./MemberFlyover";
import {ConsensusVotingApp} from "../../../Introduction/ConsensusVotingApp";
import VisualizationWidget from "../../../VisualizationWidget/VisualizationWidget";
import MemberBubble from "../../elements/MemberBubble";
import Bubble from "../../elements/Bubble";

interface Props {
  metaData: AppMetadata
}

export function FiftyOne(props: Props) {
  let mdash = "\u2014"
  return (
      <Article title={"51%"}
               subtitle={"In America, the Winner Takes All"}>
        <div className={styles.textContent}>
          <MemberFlyover/>

          <VisualizationWidget
              metaData={props.metaData}
              className={styles.simulatedHouse}
              id={"leanVsPolicyIntro"}
              height={1000}
              width={1000}
              render={(app: ConsensusVotingApp) => {
                app.clearVoters(0)
                app.showLeanVsPolicyLines(false)
                // app.showHouseVsVoters((mb: MemberBubble) => {
                //   return mb.member.ideology
                // })
              }}/>


          <p className={styles.caption}>
            Policy vs voter choices. This shows policy on the vertical axis and vote share on the horizontal axis.
            There is a large discontinuity at balanced vote share. Whichever side has a majority will move policy
            significantly in their direction.
          </p>


          <h2>What Does Winning an Election Mean?</h2>


          <p>
            When one side or the other in our democracy gets power, it's winner take all {mdash} loser gets nothing.
            Whichever side wins governs without any concern for the wishes of the losing side and the losing side gets
            no say in who the winner is. This is how it's always been and traces all the way back to the founding
            fathers' fears of factionalism destroying our democracy. The polarization we are experiencing today
            justifies their concern.
          </p>


          <p>
            The American voter is like a person stuck in a shower where the demented controls will only produce water
            that is either boiling or freezing. As voters frantically attempt to adjust the temperature to something
            more reasonable they get either no change or too much change. There is no warm setting on our political
            shower, we can't simply step out, and the extremes are only getting worse.
          </p>

          <p>
            We accept this situation because there really hasn't been an alternative, but the consequences are serious
            and getting worse. 51% is shorthand for a tiny majority; a situation where the electorate is evenly
            balanced and the outcome is more random than a true measure of the will of the electorate. Whichever side
            wins declares they have a mandate from the voters and governs as though they got 100% of the vote, ignoring
            the wishes of voters who lost. This flip in policy makes our democracy unstable, hampers our ability to
            govern effectively, raises the stakes in elections to ridiculous heights, and isn't even what the voters
            want.
          </p>

          <VisualizationWidget
              metaData={props.metaData}
              className={styles.simulatedHouse}
              id={"simulatedHouse"}
              height={1000}
              width={1000}
              render={(app: ConsensusVotingApp) => {
                // app.showLeanVsPolicyLines()
                app.showHouseVsVoters((mb: MemberBubble) => {
                  return mb.member.ideology
                })
              }}/>

          <p className={styles.caption}>
            The 435 members of Congress, the left-right position is the partisan lean of the district they represent
            from Cooke Political Report. Their vertical position is the left-right partisanship score. There are many
            moderate districts (near the center left-right) and zero moderate members (near the center vertically).
          </p>

          <p>
            The policy shift at the governmental level described in the first graph is the result of the same thing
            happening at each level of our democracy. This graph shows the Members of the United States House of
            Representatives, there are no members in the "happy medium". The pattern of a massive change in policy as
            control shifts from one side to the other happens when one side or the other gets control. Again there
            is no choice between boiling and freezing.
          </p>

          <h3>The Path to Polarization</h3>

          <p>
            For years, the assumption of political scientists was that a closely divided electorate would result in
            moderate policy. Anthony Downs published some highly influential works in 1957 that promoted the idea that
            the two party system would tend toward moderation as the parties compete for the voters in the middle. It
            seemed like a reasonable assumption in 1957. The differences between the parties were much smaller
            and so was the consequent policy dislocation. The parties also found it easier to compromise with a party
            that was fundamentally closer.
          </p>

          <p>
            Unfortunately, that theory has been thoroughly discredited by the events of the last 30 years.
            The dislocation in policy has become so enormous that the consequences are undermining our democracy.
            The temperatures in our metaphorical shower have become progressively more extreme.
          </p>


          <h3>Divided Government</h3>

          <p>
            In a desperate attempt to find some temperature between boiling and freezing Americans have responded by
            dividing the government between the parties. Over the past 50 years, 40 of them have had divided government
            and this was effective while the parties had sufficient overlap to compromise. While hardly ideal this was
            the best mechanism available and, for a time, it limited partisan overreach.
          </p>

          <p>
            Since 2008, divided government has meant paralyzed government. Referring to President Obama in 2009, Ohio
            Senator George Voinovich explained “If he was for it, we had to be against it.” The idea that the parties
            would work together to identify common ground and work for the benefit of the nation as a whole has been
            relegated to a naive fairytale. Each party wants to deny the other party of any hint of success and will
            gladly prioritize partisan gains over the welfare of the nation. Each justifies obstruction by asserting
            that it's more important for the nation for their party to be in control.
          </p>

          <p>
            Never a good solution, divided government has become a disaster. The only interesting thing is that as bad
            as dysfunction is, the American voter <i>still</i> prefers it to the partisan agenda of either party.
          </p>


          <h3>An Unstable Democracy</h3>

          <p>
            This discontinuity does more than just inflict a partisan agenda on an electorate that would prefer
            something else, it makes our democracy unstable. First, the big swing in policy between the two potential
            outcomes makes our democracy unpredictable. Each close election represents a point of inflection where the
            outcome isn't known and the consequences are huge.
          </p>

          <p>
            Unpredictable may be OK for some things, but it's a huge problem for a government. Governments need to
            be predictable allies and trading partners. Businesses need predictable policy to make long term
            investments and individuals need to know their lives won't be disrupted by unexpected shifts in policy.
            Policy can and should evolve over time as the public support it relies on evolves.
          </p>

          <p>
            This discontinuity is a magnet for malfeasance. The differences between one outcome and the other are so
            profound that it cannot help but to attract efforts to influence the outcome. Corporate interests, political
            activists, and foreign actors are all compelled to invest. The most powerful nation on earth approaches
            a fork in the road every 2 years and a major decision every 4 years. The consequences of which path is taken
            will affect every person on the planet. The temptation, even obligation, to try to influence the tiny number
            of voters required to engineer a favorable outcome is overwhelming.
          </p>

          <p>
            We wonder at the staggering amounts of money spent on modern election campaigns. We shouldn't. The amounts
            are staggering because the stakes are staggering. If you want to reduce the ridiculous influence of money on
            elections, then lower the stakes.
          </p>

          <p>
            When an outcome is decided, the huge differences in resultant policy create incentive to attempt to
            undermine the legitimacy of the election. This is key in terms of invigorating the losing side to fight
            harder the next time around, but it undermines confidence in our democracy.
          </p>

          <h3>Just Plain Wrong</h3>
          <p>
            Wrong is a risky word to use in a political discussion. Mostly it's used to state an opinion for which
            there isn't really a right or a wrong, just an opinion. But, in this case this case, 'wrong' means not
            what the voters want. Our election system system eliminates choices the voters would prefer at every
            level of the process. The result is a national policy that has a huge gap and all of the problems
            that come from that.
          </p>

          <p>
            If voters were able to elect the candidates they actually prefer, that gap would not exist. Our democracy
            would be stable, we could diminish the impact of money on politics, and ensure the transfer of power.
          </p>

          <VisualizationWidget
              metaData={props.metaData}
              className={styles.simulatedHouse}
              id={"consensusHouse"}
              height={1000}
              width={1000}
              render={(app: ConsensusVotingApp) => {
                app.showHouseVsVoters((mb: MemberBubble) => {
                  return app.combinedDistrictData.sampleIdeology("headToHead", mb.member.district)
                })
                app.memberController.simulatedLayout = true
              }}/>

          <p className={styles.caption}>
            This shows the partisan lean and ideological distribution of the United States House of Representatives
            when the elections are simulated using Consensus Voting instead. The details of Consensus Voting are explained <a
              href={"/ConsensusVoting"}>here</a>.
          </p>

          <h3>Consensus Voting and 51%</h3>

          <p>
            Consensus Voting elects the Consensus Candidate {mdash} the candidate preferred by the voters over each of the
            other candidates when compared head-to-head. As districts the voters become more liberal or conservative,
            the winners become equally more liberal or conservative, but there is no gap in the middle. A balanced
            district will favor a balanced representative. Consensus Voting favors candidates who represent all of their
            voters, not just their primary voters and not just their party's voters.
          </p>

          <p>
            This one simple but profound change {mdash} electing the candidates represent all of their
            voters {mdash} would revolutionize American politics. The House of Representatives would resemble
            the simulation above. If the population shifted slightly to the left or right, so would makeup of
            the House.
          </p>

          <p>
            Legislative bodies have a "policy pivot point". This pivot point is the least partisan member of the
            majority required to pass legislation. In today's polarized United States House of Representatives,
            that pivot point and the resulting policy varies dramatically as control passes back and forth between
            two dramatically different groups.
          </p>

          <p>
            Any majority in a United States House elected using Consensus Voting would have to include some moderate
            members. The pivot point would shift, but that shift would reflect the actual degree of shifting in the
            population. For the tiny shift from 49.9% to 50.1% the shift in policy would be small, even though the
            nominal control of the chamber may pass from one party to the other. The idea is that policy shift should
            reflect the shift from the voters and not oscillate between extremes that aren't supported by the
            voters.
          </p>


          <VisualizationWidget
              metaData={props.metaData}
              className={styles.simulatedHouse}
              id={"consensusGovernment"}
              height={1000}
              width={1000}
              render={(app: ConsensusVotingApp) => {
                app.showLeanVsPolicyLines(true)
                app.showHouseVsVoters((mb: MemberBubble) => {
                  return app.combinedDistrictData.sampleIdeology("headToHead", mb.member.district)
                })
                app.memberController.simulatedLayout = true
                app.memberController.setOpacity((m: Bubble) => .5)
              }
              }/>
          <p className={styles.caption}>
            This shows the ideology versus partisan lean of the 435 House Members with the implied policy. The implied
            policy line is also shown. There is no gap as control shifts from one party to the other.
          </p>

          <p>
            Electing the U.S. Senate with Consensus Voting would erase the policy gap there as well. It might be possible
            to retain the filibuster <i>and</i> still function. Without a policy gap, or partisans able to enforce party
            orthodoxy, compromise and solving problems become essential survival skills instead of a suicide pact.
            Handing over power to the opposition is a policy adjustment instead a giant policy U-turn.
          </p>

          <p>
            This may sound boring, but personally, boring sounds good right now. We could focus debates on real policy
            and fixing the real problems we face instead of scoring partisan points.
          </p>

          <h3>A Philosophical Point</h3>
          <p>
            I've heard some more partisan critics describe this as boring and an impediment to "real change". They feel
            that candidates would be bland individuals without bold ideas who merely want to avoid offending anyone. By
            "bold ideas" they mean ideas they know are not supported by a majority of the population over less extreme
            ideas. Candidates who want to do things that are not supported by the electorate will no longer be able to
            get elected because their opponent is even worse.
          </p>
          <p>
            There is an unspoken alliance between the parties. Each party can become more extreme with the tacit
            cooperation of the other party also becoming more extreme in the opposite direction. Returning to the shower
            analogy, one side can turn down the temperature even more if the other side turns it up to match. Allowing a
            real option for a merely warm shower would undermine their partisan agenda. But that's where I disagree with
            their strategy.
          </p>
          <p>
            First of all, bold ideas should be <i><b>good</b></i> ideas. Bold ideas should inspire the voters and create
            the enthusiasm needed to be supported by the electorate. By opposing a system where ideas without
            popular support won't be successful, they are admitting that their ideas will never achieve majority
            support.
          </p>
          <p>
            Instead of trying to engineer a political system that allows partisans to impose their agenda on an
            unwilling electorate, activists should improve their proposals and do a better job of selling them. The
            should also find ways to do something less ambitious, but with broader support and demonstrate its
            effectiveness. This will ultimately do more to advance their cause than overreaching and being reversed as
            the pendulum swings. If your best strategy for implementing your goals is to game the democratic system
            to implement them without popular support, then you should alter your goals.
          </p>
          <p>
            On the other hand, if your objectives do have popular support then Consensus Voting will make implementing them
            not only easy, but inevitable. There is broad public support ideas for a wide range of issues

          </p>

          <h3>Minority Representation vs. Policy Influence</h3>

          <p>
            I am a Democrat who lives in Texas. Republicans have governed the state with a trifecta since 2003 and that
            will continue for the foreseeable future. Even though I am "represented", (there are 63 democrats in the
            Texas House and 13 Democrats in the Senate) Democrats are in the minority and have zero ability to influence
            policy. The ultimate goal of an election is to choose policy, having representatives in the minority party
            in a legislature has almost zero impact on policy.
          </p>

          <p>
            Under Consensus Voting I would probably have the same number of Democrats in the legislature, but the
            Republican majority would each have to represent all of the voters in their district. Candidates who
            are antagonistic toward members of the minority party may do slightly better amongst the more extreme
            elements of their own party but they will lose almost every voter from the minority party. These
            divisive candidates will always lose to a candidate from their own party who is less divisive.
          </p>

          <p>
            This gives voters in the minority a say in who will represent them and what the resultant policy will be
            even though they cannot elect a member of their own party. The result is more moderate policy that
            represents the entire spectrum of voters instead of representing just the views of the majority.
          </p>

          <p>
            I've been using the term 'minority' to refer to members of the minority party, but it can also apply to
            voters from racial or ethnic minorities. Even when minority voters are split across parties they will prefer
            candidates who are responsive to their needs regardless of party. This makes representing the needs minority
            voters a political advantage, that's what "representation" means.
          </p>

          <p>
            There is a huge difference between electing candidates to a legislative body and having influence on policy.
            Anything less than a majority in today's winner-take-all politics doesn't result in the ability to influence
            policy. But, if candidates have to represent all of their voters, then members of the majority will also
            have to consider members of the minority party when they implement policy. This give voters a voice even
            when their party is in the minority and is why the policy line is smooth when control changes parties.
          </p>

          <h3>Conclusions</h3>

          <p>
            There are several ways to approach the problem of polarization and how it is ripping our country apart.
            This is only way to look at the problem, but every way of analyzing the problem shares some common threads:
            Our election process favors divisive candidates and emphasizes our differences. The winners are divisive,
            toxic, incapable of compromise, empower divisive voices in the media, and are unable to govern effectively.
          </p>

          <p>
            Like every other aspect of the problem of polarization the solution lies in empowering voters to elect
            candidates who represent all of their voters, not just their primary voters and not just their party's
            voters. By offering the voters more choices and electing the candidate the voters want more than every
            other candidate and giving every voter a say, voter can select candidates who represent the entire
            electorate.
          </p>

          <p>
            Electing candidates represent the entire electorate eliminates the gap in policy as partisan control shifts
            from one party to the other. This stabilizes our democracy, ensures the peaceful transition of power, and
            results in policy that is supported by a majority of voters.
          </p>

        </div>
      </Article>
  )
}