import Point from "./Point";

abstract class SVGAxis {
  // define an abstract method called clear without
  abstract clear() : void
  abstract render() : void
  abstract id: string
  abstract topLeft: Point
}

export default SVGAxis