import Point from "./Point";
import Trajectory from "./Trajectory";

class DirectTrajectory extends Trajectory {
  path = (p0: Point, p1: Point): (t: number) => Point => {
    let deltaX = p1.x - p0.x
    let deltaY = p1.y - p0.y

    let p = (pctComplete: number): Point => {
      if (pctComplete >= 1) return p1
      else {
        let x = p0.x + pctComplete * deltaX
        let y = p0.y + pctComplete * deltaY
        return new Point(x, y)
      }
    }
    return p
  }
}
export default DirectTrajectory