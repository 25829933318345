import React from "react";
import styles from './VisualizationArea.module.scss'


interface VisualizationProps {
  id: string
  className?: string
  children?: JSX.Element | JSX.Element[];
}

class VisualizationArea extends React.Component<any, any> {
  props: VisualizationProps

  constructor(props: VisualizationProps) {
    super(props);
    this.props = props
  }

  render() {
    let className = this.props.className ? this.props.className : ""
    let id = this.props.id ? this.props.id : "drawingDiv"
    return (
        <div className={`${styles.divArea} ${className} foo`} id={id}>
          <div className={styles.htmlArea} id={"htmlArea"}> </div>
          <canvas className={styles.canvasArea} id={"voters"}/>
          <canvas className={styles.canvasArea} id={"voters1D"}/>
          <canvas className={styles.canvasArea} id={"members1"}/>
          <canvas className={styles.canvasArea} id={"members2"}/>
          <svg className={styles.svgArea} id={"svgArea"}>
            <g id={"drawingG"}>
            </g>
          </svg>
          {this.props.children}
        </div>
    )
  }
}

export default VisualizationArea
