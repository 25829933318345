import {Party} from "./Party";
import {Voter} from "./Voter";
import Random from "./Random";
import UnitGaussian from "./UnitGaussian";

class PopulationGroup {
  party: Party
  mean: number[]
  stddev: number[]
  weight: number
  primaryShift: number[]
  random = new Random()
  dim: number
  ivec: number[][]
  unitGaussian = new UnitGaussian()

  constructor(party: Party, mean: number | number[], stddev: number | number[], primaryShift: number | number[], weight: number) {
    this.party = party
    this.mean = (mean instanceof Array) ? mean : [mean]
    this.stddev = (stddev instanceof Array) ? stddev : [stddev]
    this.primaryShift = (primaryShift instanceof Array) ? primaryShift : [primaryShift]
    this.weight = weight
    this.dim = this.mean.length
    this.ivec = this.mean.map((m, i) => [m, this.stddev[i]])
  }

  // does not include population weight
  probability = (voterIvec: number[]) => {
    let p = 1
    voterIvec.forEach((v, i) => {
      let pDim = this.unitGaussian.pdf((v - this.ivec[i][0]) / this.ivec[i][1])
      p *= pDim
    })
    return p
  }

  voter(): Voter {
    let ivec = this.mean.map((m, i) => this.stddev[i] * this.random.normal() + m)
    return new Voter(ivec, this.party)
  }

  sampleVoters(n: number): Array<Voter> {
    if (this.dim === 1)
      return this.sampleVotersIdeal(n)
    else {
      return this.sampleVotersRandom(n)
    }

  }

  sampleVotersRandom(n: number): Array<Voter> {
    let voters: Voter[] = []
    for (let i = 0; i < n; i++) {
      voters.push(this.voter())
    }
    return voters
  }

  sampleVotersIdeal(n: number): Array<Voter> {
    let gaussian = new UnitGaussian()
    let unitValues = gaussian.generateIdealSamples(n)
    let scaledValues = unitValues.map(v => v * this.stddev[0] + this.mean[0])
    return scaledValues.map(sv => new Voter([sv], this.party))
  }
}

export default PopulationGroup