import {Population} from "../../core/CombinedPopulation";
import VoterBubble from "./VoterBubble";
import Random from "../../core/Random";
import {NoParty, Party} from "../../core/Party";
import {Voter} from "../../core/Voter";
import VoterController from "./VoterController";
import LayoutByIdeology2D from "./LayoutByIdeology2D";
import {rangesAndGradients} from "./RangesAndGradients";
import Bubble from "./Bubble";
import {directRoute} from "./LayoutAnimator";
import CategoryAxis from "./CategoryAxis";
import Point from "./Point";


let rotate = Math.PI / 4
// let cos_t = Math.cos(rotate)
// let sin_t = Math.sin(rotate)

let cos_tr = Math.cos(-rotate)
let sin_tr = Math.sin(-rotate)

class Voter2D extends VoterBubble {
  party = NoParty
  ideology = 0 // projection of 2D ideology onto 1D
  opacity = .5
  isCandidate = false
  candidateColor: string = 'red'
  partyPrimary: Party = NoParty
  distanceFromMean = 0
  sortOrder = 0


  constructor(voter: Voter, radius: number) {
    super(voter, radius);
    this.party = voter.party
    let x_raw = this.ivec()[0]
    let y_raw = this.ivec()[1]
    this.ideology = x_raw * cos_tr - y_raw * sin_tr
  }

  ivec = (): number[] => {
    return this.voter.ivec
  }


  distance = (vec: number[]): number => {
    let sum = 0
    vec.forEach((v, i) => {
      let d = (v - this.ivec()[i])
      sum += d * d
    })
    return Math.sqrt(sum)
  }

  nearest = (vecs: number[][]): number => {
    let nearest = 0
    let nearestDist = 1e10
    for (let i = 0; i < vecs.length; i++) {
      let d = this.distance(vecs[i])
      if (d < nearestDist) {
        nearestDist = d
        nearest = i
      }
    }
    return nearest
  }

  nearestR = (vecs: number[][], randScale: number, rand: Random): number => {
    let nearest = -1
    let nearestDist = 1e10
    for (let i = 0; i < vecs.length; i++) {
      let d = this.distance(vecs[i]) + randScale * rand.nextNormal()
      if (d < nearestDist) {
        nearestDist = d
        nearest = i
      }
    }
    return nearest
  }
}

class Voters2D extends VoterController {
  candidates: number[] = []
  bubbles: Array<Voter2D>

  setLayout2D = (duration: number): void => {
    this.setLayout(
        new LayoutByIdeology2D(rangesAndGradients.xScale2d, rangesAndGradients.yScale2d, directRoute, duration), "2D")
  }

  createAxes = (svg: d3.Selection<any, any, any, any>) => {
    let socialCategories = [
      "Socially Conservative",
      "Socially Liberal",
    ]

    let fiscalCategories = [
      "Fiscally Liberal",
      "Fiscally Conservative",
    ]
    this.addAxis(new CategoryAxis("fiscalAxis", svg, fiscalCategories, new Point(50, 500), 900, "", false))
    this.addAxis(new CategoryAxis("socialAxis", svg, socialCategories, new Point(500, 50), 900, "", true))
  }

  setColor = (colorFcn: (b: Bubble) => string, description: string) => {
    this.colorFcn = colorFcn
    this.colorName = description
    this.applyColorAndOpacity()
    this.renderFrame(1)
  }

  createPopulation = (lean: number): Array<Voter2D> => {
    let r = new Random()
    let vv = this.population.samplePopulation(this.nVoters, lean)
    return vv.map(v => {
      if (v.ivec.length === 1) {
        let i1 = r.normal()
        let i2 = r.normal()
        v.ivec = [i1, i2]
      }
      return new Voter2D(v, this.radius)
    })
  }

  sortRandom = () => {
    let r = new Random()
    this.bubbles.forEach(b => b.sortOrder = r.nextFloat())
    this.bubbles.sort((a, b) => a.sortOrder - b.sortOrder)
  }

  updatePopulation = (lean: number) => {
    let newPopulation = this.createPopulation(lean)
    this.bubbles.forEach((v2d, idx) => {
      let vNew = newPopulation[idx]
      v2d.ideology = vNew.ideology
      v2d.ivec = vNew.ivec
      v2d.voter.ivec = vNew.voter.ivec
      v2d.voter.party = vNew.voter.party
    })
    // this.sortRandom()
  }

  constructor(nVoters: number, canvasContext: CanvasRenderingContext2D, population: Population, radius: number) {
    super(nVoters, canvasContext, population, radius)
    this.createPopulation(0)
    this.bubbles = this.createPopulation(0)
    // this.sortRandom()
  }


}

export default Voters2D
export {Voter2D}