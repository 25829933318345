import * as React from 'react';
import styles from './Navbar.module.scss'

interface Props {
  link: string
}

function ReadingOrder() {


  let readingOrder: string[][] = [
    ["A Mathematical Leviathan", "/Leviathan"],
    ["The Purpose of Democracy", "/Democracy"],
    ["Our Factional Democracy", "/Factionalism"],
    ["Game Theory and Elections", "/GameTheory"],
    ["The Forces Dividing Us", "/Tribalism"],
    ["The Global Threat", "/Global"],
    // ["Nothing is Perfect", "/Perfect"],
    // ["Instant Primaries", "/IRV"],
    // ["Proportional Factionalism", "/ProportionalFactionalism"],
    // ["A Glimmer of Hope", "/Top2Hope"],
    ["Hints of a Solution", "/CaliforniaTop2"],
    ["The Math of the Leviathan", "/LeviathanMath"],
    ["Consensus Voting", "/ConsensusVoting"],
    ["Fostering Compromise", "/FosteringCompromise"],
    ["Defining Reality", "/DefiningReality"],
    ["Gerrymandering", "/Gerrymandering"],
    ["Shaping Politics", "/ShapingPolitics"],
    ["Restoring Democracy", "/RestoringDemocracy"],
    ["Conclusions", "/Conclusions"],
  ]

  let currentPath = window.location.pathname

  console.log(`reading order:  ${currentPath}`)

  let idx = readingOrder.findIndex(o => o[1] === currentPath)
  let next = null
  let prev = null
  if (idx >= 0) {
    if (idx < readingOrder.length - 1) {
      let pair = readingOrder[idx + 1]
      next = (
          <a href={pair[1]}>
            <button className={styles.readingOrderButton}>
              Next: {pair[0]}
            </button>
          </a>
      )
    }
    if (idx > 0) {
      let pair = readingOrder[idx - 1]
      prev = (
          <a href={pair[1]}>
            <button className={styles.readingOrderButton}>
              Back: {pair[0]}
            </button>
          </a>
      )
    }
  }


  return (
      <div className={styles.links}>
        {prev}
        <div className={styles.separatorDiv}/>
        {next}
      </div>
  )
}

const Navbar = (props: Props) => {
  return (
      <div className={styles.content}>
        <a href={props.link}>
          <div className={styles.logo}>
            <h1 className={styles.title}>
              Better Choices
            </h1>
          </div>
        </a>
        <div className={styles.nav}>
          <ReadingOrder/>
        </div>
      </div>
  )
}

export default Navbar