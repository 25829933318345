import Article from "../content/Article/Article";
import styles from "./About.module.scss";

export function About() {
  return (
      <Article title={"About the Author"}
               subtitle={""}>
        <div className={styles.textContent}>
          <p>

            Hello,

          </p>
          <p>

            My name is Robbie Robinette; I have no particular qualifications as either a political scientist or an
            election scientist. My only real qualification is that I'm a concerned citizen, but that and a lot of effort
            are the only qualifications needed to make a difference.

          </p>
          <p>
            I studied physics at U.T. Austin but left before graduating to work at IBM and then a series of startups,
            eventually founding RGM Advisors with my partners Mark Melton and Richard Gorelick in my living room in
            2001.

          </p>
          <p>
            My experience with chaos theory, machine learning, agent modeling, and artificial intelligence leads me to
            take a different approach to the political problems we face today. I have built this website in an attempt
            to make some relatively complex concepts clear and understandable to a broader audience.

          </p>
          <p>
            I want to thank my wife, Alex, and my children, Ian and Avery, for their endless patience and help. This
            website would not have been possible without their support. I also need to thank Dexter Weiss and Mark
            Melton for the technical help, site design, and editorial feedback. Thanks also to everyone who has helped
            by reading and providing feedback.

          </p>
          <p>
            I hope that you have found it helpful.

          </p>
          <p>

            Thank you,
          </p>
          <p>
            Robbie Robinette

          </p>
          <p>
            robbierobinette@betterchoices.vote
          </p>

        </div>
      </Article>
  )
}